import { useEffect, useState } from 'react'

import useAuthUser from 'hooks/useAuthUser'
import useQuery from 'hooks/useQuery'
import { WHO_AM_I } from 'apollo/queries/auth'
import { GET_TOTAL_COUNT } from 'apollo/queries/dashboard'

import Loading from 'components/_common/Loading'
import Unauthorized from 'components/_common/Unauthorized'
import StorePicker from 'components/stores/select/StorePicker'

import { doesUserHavePermissionType } from 'utils/permissions'

import {
  Flex,
  IconButton,
  useDisclosure,
  useColorModeValue
} from '@chakra-ui/react'

import { VscTriangleRight, VscTriangleLeft } from 'react-icons/vsc'

import useResponsiveDevice from 'hooks/useResponsiveDevice'

import Sidebar from 'components/_sidebar/Sidebar'

import { useSubscription } from '@apollo/client'
import { BILLING_PANEL_UPDATED } from 'apollo/subscriptions'

const withLayout = (WrappedComponent) => () => {
  const authUser = useAuthUser()

  // get the counts to show in sidebar/dashboard and refetch on any update
  const { loading: loadingCount } = useQuery(GET_TOTAL_COUNT, { onError: console.log })

  const bgColour = useColorModeValue('white', 'gray.800')

  const { isDesktop, responsive } = useResponsiveDevice()

  const { isOpen: isSidebarOpen, onOpen: setIsSidebarOpen, onClose: setIsSidebarClose } = useDisclosure({ defaultIsOpen: isDesktop })

  useSubscription(
    BILLING_PANEL_UPDATED, {
      onSubscriptionData: ({ client, subscriptionData }) => {
        try {
          client.reFetchObservableQueries()
        } catch (e) {
          console.log('Subscription error', e.message)
        }
      }
    }
  )

  const {
    title = 'Billing',
    isPublic = false,
    isAdminOnly = false,
    viewPermission = ''
  } = WrappedComponent.props

  // set document title
  useEffect(() => {
    window.document.title = title
  }, [])

  const [isStorePickerOpen, setIsStorePickerOpen] = useState(!isPublic)

  // check for auth session if not public route
  const { loading } = useQuery(WHO_AM_I, {
    skip: isPublic,
    onCompleted: ({ whoAmI }) => {
      // check for active store
      const activeStore = window.localStorage.getItem('ACTIVE_STORE')
      if (activeStore) {
        // check if auth user has access to the activeStore
        const hasAccess = whoAmI.stores.find(({ id }) => id === activeStore)
        if (hasAccess || (whoAmI.role === 'ADMIN' && activeStore === 'ALL')) {
          // auth user has access to activeStore
          setIsStorePickerOpen(false)
        } else {
          // auth user does not have access to activeStore
          window.localStorage.removeItem('ACTIVE_STORE')
          setIsStorePickerOpen(true)
        }
      } else {
        // no active store in local storage; show store picker
        setIsStorePickerOpen(true)
      }
    },
    onError: () => {
      window.localStorage.removeItem('AUTH_SESSION_ID')
      window.localStorage.setItem('REDIRECT_REFERRAL', window.location.href)
      window.location.href = '/login'
    }
  })

  // no need to perform any additional checks for public route
  if (isPublic) {
    return WrappedComponent
  }

  // show loading while verifying auth session
  if (loading || loadingCount) {
    return <Loading />
  }

  if (!authUser) {
    // wait for authUser to be set in cache
    return <Loading />
  }

  // if adminOnly page, check if the auth user is an admin
  if (isAdminOnly && authUser.role !== 'ADMIN') {
    return <Unauthorized />
  }

  if (viewPermission && !doesUserHavePermissionType(authUser, viewPermission)) {
    return <Unauthorized />
  }

  // show storePicker if user does not have access to the activeStore
  if (isStorePickerOpen) {
    return <StorePicker />
  }

  return (
    <Flex height='100%' overflow='hidden'>
      {!isDesktop && (
        <IconButton
          variant='ghost'
          aria-label={!isSidebarOpen ? 'Open Sidebar' : 'Close Sidebar'}
          ml='-3'
          icon={!isSidebarOpen ? <VscTriangleRight fontSize='38px' /> : <VscTriangleLeft fontSize='38px' />}
          alignSelf='center'
          onClick={isSidebarOpen ? setIsSidebarClose : setIsSidebarOpen}
          _focus={{ bg: '' }}
          _hover={{ bg: '' }}
          borderRadius='0'
          height='100%'
        />
      )}
      {(isDesktop || isSidebarOpen) && (
        <Flex as='nav' width={responsive(['100%', '206px'])} flexDir='column' p='4'>
          <Sidebar />
        </Flex>
      )}
      {(isDesktop || !isSidebarOpen) && (
        <Flex as='main' p='6' flex='1' pl={responsive(['0', '6'])} overflow='hidden'>
          <Flex bg={bgColour} width='100%' borderRadius='xl' p='4'>
            {WrappedComponent}
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

export default withLayout
