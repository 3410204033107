import { useEffect } from 'react'

import { CREATE_TICKET } from 'apollo/mutations/tickets'
import useMutation from 'hooks/useMutation'

import {
  Stack,
  Button,
  Textarea,
  FormLabel,
  FormControl,
  useDisclosure,
  FormErrorMessage
} from '@chakra-ui/react'

import { FaPlus } from 'react-icons/fa'

import FormDialog from 'components/_common/FormDialog'
import DateTimePicker from 'components/_inputs/DateTimePicker'
import EditTicketAssignedTo from 'components/tickets/edit/EditTicketAssignedTo'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'

import formatLocaleDate from 'utils/formatDate'

const schemaValidation = object().shape({
  details: string().required()
})

export default function CreateTicketTrigger ({ refetch }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleClose = (toRefetch = false) => {
    toRefetch && refetch()
    onClose()
  }

  return (
    <>
      <Button size='sm' leftIcon={<FaPlus />} onClick={onOpen}>
        New
      </Button>
      {isOpen && <CreateTicket onClose={handleClose} />}
    </>
  )
}

export function CreateTicket ({ onClose }) {
  const handleClose = (toRefetch = false) => {
    resetMutation()
    resetForm()
    onClose(toRefetch)
  }

  const [createTicket, { error, loading, reset: resetMutation }] = useMutation(CREATE_TICKET, {
    notification: 'Successfully created the ticket',
    isPromise: false,
    onCompleted: () => {
      handleClose(true)
    }
  })

  const defaultExpiryDate = new Date()
  defaultExpiryDate.setDate(defaultExpiryDate.getDate() + 14)

  const { register, handleSubmit, formState: { errors }, reset: resetForm, setFocus, setValue, getValues, watch } = useForm({
    resolver: yupResolver(schemaValidation),
    defaultValues: { expiresAt: defaultExpiryDate.toISOString() }
  })

  watch(['joinedDate', 'expiresAt'])

  useEffect(() => { setTimeout(() => setFocus('details'), 1) }, [])

  const onSubmit = ({ details, expiresAt, assignedToUserID }) => {
    const data = {
      date: formatLocaleDate(new Date()) + 'T04:00:00.000Z',
      details,
      expiresAt,
      assignedToUserID
    }
    createTicket({ variables: { data } })
  }

  return (
    <FormDialog
      title='Create Ticket'
      formID='#createTicket'
      error={error}
      loading={loading}
      onClose={handleClose}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing='8'>
        <FormControl isInvalid={errors?.assignedToUserID}>
          <FormLabel>Assign To User</FormLabel>
          <EditTicketAssignedTo inline onChange={({ value }) => setValue('assignedToUserID', value)} />
          <FormErrorMessage>{errors?.assignedToUserID?.message}</FormErrorMessage>
        </FormControl>
        <FormControl>
          <FormLabel>Expires At</FormLabel>
          <DateTimePicker
            inline
            fontSize='md'
            type='time'
            value={getValues('expiresAt')}
            onChange={expiresAt => setValue('expiresAt', expiresAt)}
            error={errors?.expiresAt}
            minDate={new Date()}
          />
        </FormControl>
        <FormControl isInvalid={errors?.details}>
          <FormLabel>Details</FormLabel>
          <Textarea {...register('details')} />
          <FormErrorMessage>
            {errors?.details?.message}
          </FormErrorMessage>
        </FormControl>
      </Stack>
    </FormDialog>
  )
}
