import useMutation from 'hooks/useMutation'
import { UPDATE_USER_OTP } from 'apollo/mutations/users'

import {
  Switch,
  FormLabel,
  FormControl
} from '@chakra-ui/react'

export default function EditUserOTP ({ user, ...props }) {
  const [updateOTP] = useMutation(UPDATE_USER_OTP, {
    notification: 'Successfully updated the OTP preference',
    isPromise: false
  })

  const handleOnChange = e => {
    const requiredOTP = e.target.checked
    const data = { id: user.id, requiredOTP }
    return updateOTP({ variables: { data } })
  }

  return (
    <FormControl {...props}>
      <FormLabel {...props}>Require OTP?</FormLabel>
      <Switch
        colorScheme='orange'
        size='lg'
        isChecked={user.requiredOTP}
        aria-label='Require OTP?'
        onChange={handleOnChange}
      />
    </FormControl>
  )
}
