import fuzzyBuilder from 'utils/search/fuzzyBuilder'

export default function buildSearch ({ where = [], orderBy = [], fuzzySearch = '', fuzzyKey, isEmbedded }) {
  const variables = { orderBy: [], where: {} }
  for (const { isActive, field, value } of orderBy) {
    if (isActive) {
      variables.orderBy.push({ [field]: value })
    }
  }

  if (fuzzySearch) {
    variables.where.OR = fuzzyBuilder[fuzzyKey](fuzzySearch, isEmbedded)
  } else {
    for (const { isActive, relations, single, field, filter, value, valueOptions, type, isSingle, transform } of where) {
      if (isActive) {
        let overideValue = (type === 'date' ? value + 'T00:00:00.0Z' : type === 'number' ? parseInt(value) : valueOptions ? value.map(({ value }) => value) : value)
        if (transform) overideValue = transform(overideValue)
        let overideFilter = filter
        if (isSingle) {
          overideFilter = 'equals'
          overideValue = overideValue[0]
        }
        if (type === 'number') overideValue = parseInt(overideValue)
        if (type === 'number' && overideValue > 4294967295) continue
        variables.where[field] = { [overideFilter]: overideValue }
        if (type !== 'date' && type !== 'datetime' && type !== 'number' && !valueOptions) {
          variables.where[field] = { ...variables.where[field], mode: 'insensitive' }
        }
      }
      if (relations) {
        for (const relation of relations) {
          if (relation.isActive) {
            if (relation.type === 'date') continue
            if (relation.type === 'datetime') continue
            let overideRelationValue = (relation.type === 'date' ? relation.value + 'T00:00:00.0Z' : relation.type === 'number' ? parseInt(relation.value) : relation.valueOptions ? relation.value.map(({ value }) => value) : relation.value)
            if (relation.transform) overideRelationValue = relation.transform(overideRelationValue)
            if (relation.type === 'number') overideRelationValue = parseInt(overideRelationValue)
            if (type === 'number' && overideRelationValue > 4294967295) continue
            if (single) {
              variables.where[field] = relation.type !== 'date' && type !== 'datetime' && relation.type !== 'number' && !relation.valueOptions
                ? {
                    [relation.field]: {
                      [relation.filter]: overideRelationValue,
                      mode: 'insensitive'
                    }
                  }
                : {
                    [relation.field]: {
                      [relation.filter]: overideRelationValue
                    }
                  }
            } else {
              variables.where[field] = {
                some: {
                  ...variables.where[field]?.some,
                  [relation.field]: relation.type !== 'date' && type !== 'datetime' && relation.type !== 'number' && !relation.valueOptions
                    ? {
                        [relation.filter]: overideRelationValue,
                        mode: 'insensitive'
                      }
                    : {
                        [relation.filter]: overideRelationValue
                      }
                }
              }
            }
          }
        }
      }
    }
  }
  return variables
}
