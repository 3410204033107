import TableWrapper from 'components/table/TableWrapper'
import SourcesSelectionMenu from 'components/sources/select/SourcesSelectionMenu'
import CreateSource from 'components/sources/CreateSource'

import { GET_ALL_SOURCES } from 'apollo/queries/sources'
import { selectedSourcesVar, sourceFilterWhereVar, sourceFilterOrderByVar } from 'utils/cache'

import fields from 'components/sources/fields'

export default function Sources () {
  return (
    <TableWrapper
      query={GET_ALL_SOURCES}
      fields={fields}
      rowKey='id'
      rowType='source'
      fuzzyKey='sources'
      defaultOrderBy={[{ name: 'asc' }]}
      filterWhereVar={sourceFilterWhereVar}
      filterOrderByVar={sourceFilterOrderByVar}
      createComponent={CreateSource}
      selectionComponent={SourcesSelectionMenu}
      selectionVar={selectedSourcesVar}
    />
  )
}
