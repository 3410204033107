import EditableInput from 'components/_inputs/EditableInput'

import { string } from 'yup'

import useMutation from 'hooks/useMutation'
import { UPDATE_USER_EMAIL } from 'apollo/mutations/users'

export default function EditUserEmail ({ user, inline = false, ...props }) {
  const [updateEmail, { error, loading, reset }] = useMutation(UPDATE_USER_EMAIL, {
    notification: 'Successfully updated the email'
  })

  const handleSubmit = email => {
    const data = { id: user.id, email }
    return updateEmail({ variables: { data } })
  }

  return (
    <EditableInput
      inline={inline}
      name='email'
      value={user.email || ''}
      label='Email'
      onSubmit={handleSubmit}
      validation={string().email().required()}
      isLoading={loading}
      error={error}
      reset={reset}
      {...props}
    />
  )
}
