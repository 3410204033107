import useQuery from 'hooks/useQuery'
import { GET_CUSTOMER_REMINDERS } from 'apollo/queries/customers'

import useMutation from 'hooks/useMutation'
import { UPDATE_CUSTOMER_REMINDER_TYPES } from 'apollo/mutations/customers'

import Loading from 'components/_common/Loading'
import ErrorAlert from 'components/_common/ErrorAlert'

import {
  Stack,
  Checkbox,
  FormLabel,
  FormControl
} from '@chakra-ui/react'

export default function ViewCustomerReminders ({ customer: { id } }) {
  const { data: { customer } = {}, loading, error } = useQuery(GET_CUSTOMER_REMINDERS, { variables: { id } })

  const [updateCustomerReminderTypes, { error: errorUpdating, loading: loadingUpdating }] = useMutation(UPDATE_CUSTOMER_REMINDER_TYPES, {
    notification: 'Successfully updated the email'
  })

  const handleSubmit = (isChecked, type) => {
    const sendReminderBy = isChecked ? [...customer.sendReminderBy, type] : customer.sendReminderBy.filter(t => t !== type)
    const data = { id: customer.id, sendReminderBy }
    updateCustomerReminderTypes({ variables: { data } })
  }

  if (loading || error) return <Loading />

  const checkedTypes = customer.sendReminderBy || []

  return (
    <Stack spacing='24' maxW='500px' mx='auto'>
      <FormControl isRequired>
        {loadingUpdating && <Loading />}
        {errorUpdating && <ErrorAlert> {errorUpdating.message} </ErrorAlert>}
        <FormLabel>Set Reminders Preference</FormLabel>
        <Stack mt='4' spacing='8'>
          <Checkbox
            isChecked={checkedTypes.includes('EMAIL')}
            onChange={(e) => handleSubmit(e.target.checked, 'EMAIL')}
          >
            EMAIL
          </Checkbox>
          <Checkbox
            isChecked={checkedTypes.includes('SMS')}
            onChange={(e) => handleSubmit(e.target.checked, 'SMS')}
          >
            SMS
          </Checkbox>
          <Checkbox
            isChecked={checkedTypes.includes('WHATSAPP')}
            onChange={(e) => handleSubmit(e.target.checked, 'WHATSAPP')}
          >
            WHATSAPP
          </Checkbox>
        </Stack>
      </FormControl>
    </Stack>
  )
}
