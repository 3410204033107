import UserSelectCheckbox from 'components/users/select/UserSelectCheckbox'
import OpenUser from 'components/users/view/OpenUser'
import EditUserFullName from 'components/users/edit/EditUserFullName'
import EditUserEmail from 'components/users/edit/EditUserEmail'
import ViewUserRole from 'components/users/view/ViewUserRole'
import ManageUserPhoneNumbers from 'components/users/edit/ManageUserPhoneNumbers'
import EditUserPermissions from 'components/users/edit/EditUserPermissions'
import ViewUserLoginApprovals from 'components/users/view/ViewUserLoginApprovals'
import ViewUserTickets from 'components/users/view/ViewUserTickets'

const fields = [
  { key: 'view', width: '40px', renderTD: (props) => <OpenUser {...props} self /> },
  { key: 'select', width: '40px', renderTD: UserSelectCheckbox },
  { key: 'fullName', label: 'Full Name', width: '150px', renderTD: EditUserFullName },
  { key: 'email', label: 'Email', width: '250px', renderTD: EditUserEmail },
  { key: 'role', label: 'Role', width: '120px', justifyContent: 'left', renderTD: ViewUserRole },
  { key: 'primaryPhoneNumber', label: 'Phone Number', width: '180px', renderTD: ManageUserPhoneNumbers },
  { key: 'storePermissions', label: 'Store Permissions', justifyContent: 'center', renderTD: EditUserPermissions },
  { key: 'loginApprovals', label: 'Login Approvals', justifyContent: 'center', renderTD: ViewUserLoginApprovals },
  { key: 'assignedToTickets', label: 'Tickets', justifyContent: 'center', renderTD: ViewUserTickets }
]

export default fields
