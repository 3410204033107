import { useState, useEffect } from 'react'

import { SketchPicker } from 'react-color'

import { CREATE_SERVICE_TYPE } from 'apollo/mutations/serviceTypes'
import useMutation from 'hooks/useMutation'

import {
  Input,
  Stack,
  Button,
  FormLabel,
  FormControl,
  NumberInput,
  useDisclosure,
  NumberInputField,
  FormErrorMessage,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper
} from '@chakra-ui/react'

import { FaPlus } from 'react-icons/fa'

import FormDialog from 'components/_common/FormDialog'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string, number } from 'yup'

const schemaValidation = object().shape({
  name: string().required(),
  details: string(),
  topupDueDays: number().required().integer().min(1).max(30),
  reminderDays: number().required().integer().min(1).max(30)
})

export default function CreateServiceType ({ refetch }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [color, setColor] = useState({ hex: '#9013FE' })

  const handleClose = () => {
    onClose()
    resetMutation()
    resetForm()
  }

  const [createServiceType, { error, loading, reset: resetMutation }] = useMutation(CREATE_SERVICE_TYPE, {
    notification: 'Successfully created the service type',
    isPromise: false,
    onCompleted: () => {
      handleClose()
      refetch()
    }
  })

  const { register, handleSubmit, formState: { errors }, reset: resetForm, setFocus } = useForm({
    resolver: yupResolver(schemaValidation)
  })

  useEffect(() => { isOpen && setTimeout(() => setFocus('name'), 1) }, [isOpen])

  const onSubmit = ({ name, details, topupDueDays, reminderDays }) => {
    const data = { name, details, topupDueDays, reminderDays, color: color.hex }
    createServiceType({ variables: { data } })
  }

  return (
    <>
      <Button size='sm' leftIcon={<FaPlus />} onClick={onOpen}>
        New
      </Button>
      {isOpen && (
        <FormDialog
          title='Create Service Type'
          formID='#createServiceType'
          error={error}
          loading={loading}
          onClose={handleClose}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack spacing='8'>
            <FormControl isRequired isInvalid={errors?.name}>
              <FormLabel>Name</FormLabel>
              <Input {...register('name')} />
              <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors?.details}>
              <FormLabel>Details</FormLabel>
              <Input {...register('details')} />
              <FormErrorMessage>{errors?.details?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors?.topupDueDays}>
              <FormLabel>Topup Due Days</FormLabel>
              <NumberInput min={1} max={30}>
                <NumberInputField {...register('topupDueDays')} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <FormErrorMessage>{errors?.topupDueDays?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors?.reminderDays}>
              <FormLabel>Reminder Days</FormLabel>
              <NumberInput min={1} max={30}>
                <NumberInputField {...register('reminderDays')} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <FormErrorMessage>{errors?.reminderDays?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors?.color}>
              <FormLabel htmlFor='color'>Color</FormLabel>
              <SketchPicker color={color} onChangeComplete={setColor} />
            </FormControl>
          </Stack>
        </FormDialog>
      )}
    </>
  )
}
