import useQuery from 'hooks/useQuery'

import CustomAreaChart from 'components/_charts/CustomAreaChart'

import { Stack } from '@chakra-ui/react'

export default function StatsWrapper ({ query, variables }) {
  const { data: { stats = [] } = {} } = useQuery(query, { variables, fetchPolicy: 'cache-and-network' })

  return (
    <Stack width='100%' h='500px'>
      <CustomAreaChart data={stats} />
    </Stack>
  )
}
