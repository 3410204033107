import useQuery from 'hooks/useQuery'
import { GET_ONE_SERVICE } from 'apollo/queries/services'

import Loading from 'components/_common/Loading'

import {
  Box,
  Text,
  Alert,
  Stack,
  AlertIcon,
  FormLabel,
  FormControl
} from '@chakra-ui/react'

import DateTimeRenderer from 'components/_common/DateTimeRenderer'
import EditServiceCreatedDate from 'components/services/edit/EditServiceCreatedDate'
import EditServiceMacID from 'components/services/edit/EditServiceMacID'
import EditServiceAccountNumber from 'components/services/edit/EditServiceAccountNumber'
import EditServiceServiceType from 'components/services/edit/EditServiceServiceType'
import EditServiceProductType from 'components/services/edit/EditServiceProductType'
import EditServiceExpiryDate from 'components/services/edit/EditServiceExpiryDate'
import EditServiceOnHold from 'components/services/edit/EditServiceOnHold'
import ManageServiceTopup from 'components/services/edit/ManageServiceTopup'
import ManageServiceCredits from 'components/services/edit/ManageServiceCredits'
import EditServiceInactive from 'components/services/edit/EditServiceInactive'

export default function ViewService ({ service: { id } }) {
  const { data: { service } = {}, loading, error } = useQuery(GET_ONE_SERVICE, { variables: { id } })

  if (loading || error) return <Loading />

  return (
    <Stack spacing='8'>
      {service.onHoldDate && (
        <Alert status='warning' borderRadius='lg'>
          <AlertIcon />
          <Stack direction='row' width='100%' justifyContent='space-between' alignItems='center'>
            <Text>Service currently on hold until</Text>
            <Box>
              <EditServiceOnHold inline service={service} size='md' textAlign='right' flex='1' />
            </Box>
          </Stack>
        </Alert>
      )}
      {service.inactive && (
        <Alert status='warning' borderRadius='lg'>
          <AlertIcon />
          <Stack direction='row' width='100%' justifyContent='space-between' alignItems='center'>
            <Text>Service currently not active</Text>
            <EditServiceInactive service={service} />
          </Stack>
        </Alert>
      )}
      <Stack direction='row' width='100%'>
        <FormControl>
          <FormLabel>Created Date</FormLabel>
          <EditServiceCreatedDate inline service={service} />
        </FormControl>
        <FormControl>
          <FormLabel textAlign='right'>Updated At</FormLabel>
          <DateTimeRenderer value={service.updatedAt} textAlign='right' type='time' fontSize='sm' />
        </FormControl>
      </Stack>
      <Stack direction='row' width='100%'>
        <EditServiceMacID inline service={service} />
        <EditServiceAccountNumber inline service={service} textAlign='right' />
      </Stack>
      <Stack direction='row' width='100%'>
        <FormControl>
          <FormLabel>Service Type</FormLabel>
          <EditServiceServiceType service={service} />
        </FormControl>
        <FormControl>
          <FormLabel textAlign='right'>Product Type</FormLabel>
          <EditServiceProductType service={service} />
        </FormControl>
      </Stack>
      <Stack direction='row' width='100%'>
        <FormControl>
          <FormLabel>Expiry Date</FormLabel>
          <EditServiceExpiryDate inline service={service} />
        </FormControl>
        <FormControl>
          <FormLabel textAlign='right'>Actual Expiry</FormLabel>
          <DateTimeRenderer value={service.actualExpiryDate} textAlign='right' fontSize='sm' />
        </FormControl>
      </Stack>
      <Stack direction='row' width='100%'>
        {!service.onHoldDate && (
          <FormControl>
            <FormLabel>Put On Hold</FormLabel>
            <EditServiceOnHold service={service} size='lg' editVisible />
          </FormControl>
        )}
        <FormControl>
          <FormLabel>Topup</FormLabel>
          <ManageServiceTopup service={service} btnSize='sm' />
        </FormControl>
        <FormControl width='auto'>
          <FormLabel>Credits</FormLabel>
          <ManageServiceCredits inline service={service} btnSize='sm' />
        </FormControl>
      </Stack>
    </Stack>
  )
}
