import ViewActivity from 'components/activities/ViewActivity'
import DateTimeRenderer from 'components/_common/DateTimeRenderer'
import ViewActivityType from 'components/activities/ViewActivityType'
import ViewActivityDetails from 'components/activities/ViewActivityDetails'
import OpenUser from 'components/users/view/OpenUser'

const fields = [
  { key: 'view', width: '40px', renderTD: ViewActivity },
  { key: 'date', label: 'Date', width: '200px', renderTD: ({ activity: { date } }) => <DateTimeRenderer value={date} type='time' /> },
  { key: 'type', label: 'Type', width: '250px', renderTD: ViewActivityType },
  { key: 'details', label: 'Details', renderTD: ViewActivityDetails },
  { key: 'createdByUser', label: 'User', width: '160px', renderTD: ({ activity: { createdByUser } }) => <OpenUser user={createdByUser} /> }
]

export default fields
