import {
  Text,
  Stack,
  Badge
} from '@chakra-ui/react'

export default function ViewServiceType ({ serviceType }) {
  return (
    <Stack direction='row' fontSize='xs'>
      <Badge bg={serviceType.color} width='6' borderRadius='lg'>
        &nbsp;
      </Badge>
      <Text>{serviceType.name}</Text>
    </Stack>

  )
}
