import EditableInput from 'components/_inputs/EditableInput'

import { number } from 'yup'

import useMutation from 'hooks/useMutation'
import { UPDATE_SERVICE_TYPE_ORDER } from 'apollo/mutations/serviceTypes'

export default function EditServiceTypeOrder ({ serviceType, justifyContent }) {
  const [updateOrder, { error, loading, reset }] = useMutation(UPDATE_SERVICE_TYPE_ORDER, {
    notification: 'Successfully updated the order'
  })

  const handleSubmit = order => {
    const data = { id: serviceType.id, order }
    return updateOrder({ variables: { data } })
  }

  return (
    <EditableInput
      name='order'
      type='number'
      numberInputProps={{ min: 1 }}
      value={serviceType.order || ''}
      label='Topup Due Days'
      onSubmit={handleSubmit}
      validation={number().min(1)}
      isLoading={loading}
      error={error}
      reset={reset}
      justifyContent={justifyContent}
    />
  )
}
