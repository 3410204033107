import { useState } from 'react'

import {
  Modal,
  Stack,
  Button,
  FormLabel,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormControl,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalCloseButton
} from '@chakra-ui/react'

import DateTimePicker from 'components/_inputs/DateTimePicker'
import Select from 'components/_select/CustomSelect'

import ErrorAlert from 'components/_common/ErrorAlert'

import {
  TOPUPS_REPORT,
  NEW_CUSTOMERS_REPORT,
  NEW_SERVICES_REPORT,
  TRANSACTIONS_REPORT,
  USER_ACTIVITIES_REPORT,
  CUSTOMER_CREDIT_REPORT,
  CREDITS_PURCHASED_REPORT
} from 'apollo/queries/reports'

import { useApolloClient } from '@apollo/client'

import saveReportAsPDF from 'utils/saveReportAsPDF'

import ServiceTypesFilter from 'components/reports/ServiceTypesFilter'
import ProductTypesFilter from 'components/reports/ProductTypesFilter'
import StoresFilter from 'components/reports/StoresFilter'
import TransactionTypesFilter from 'components/reports/TransactionTypesFilter'
import CustomersFilter from 'components/reports/CustomersFilter'
import UsersFilter from 'components/reports/UsersFilter'
import LanguagesFilter from 'components/reports/LanguagesFilter'
import SourcesFilter from 'components/reports/SourcesFilter'
import PostalCodesFilter from 'components/reports/PostalCodesFilter'
import ShipmentsFilter from 'components/reports/ShipmentsFilter'

import formatLocaleDate from 'utils/formatDate'

const reportTypes = [
  { label: 'TOPUPS', value: TOPUPS_REPORT, additionalFilterComponents: [] },
  { label: 'NEW CUSTOMERS', value: NEW_CUSTOMERS_REPORT, additionalFilterComponents: [StoresFilter, LanguagesFilter, SourcesFilter, PostalCodesFilter] },
  { label: 'NEW SERVICES', value: NEW_SERVICES_REPORT, additionalFilterComponents: [StoresFilter, ServiceTypesFilter, ProductTypesFilter] },
  { label: 'TRANSACTIONS', value: TRANSACTIONS_REPORT, additionalFilterComponents: [TransactionTypesFilter, ServiceTypesFilter, ShipmentsFilter] },
  { label: 'USER ACTIVITIES', value: USER_ACTIVITIES_REPORT, additionalFilterComponents: [UsersFilter] },
  { label: 'CUSTOMER CREDITS', value: CUSTOMER_CREDIT_REPORT, additionalFilterComponents: [CustomersFilter, TransactionTypesFilter, ServiceTypesFilter] },
  { label: 'CREDITS PURCHASED', value: CREDITS_PURCHASED_REPORT, additionalFilterComponents: [TransactionTypesFilter, ServiceTypesFilter, ShipmentsFilter] }
]

export default function GeneratePDFReport () {
  const client = useApolloClient()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleClose = () => {
    setReportType(reportTypes[0])
    setAdditionalVariables({})
    onClose()
  }

  const today = new Date()
  today.setDate(today.getDate() - 31)

  const [startDate, setStartDate] = useState(formatLocaleDate(today))
  const [endDate, setEndDate] = useState(formatLocaleDate(new Date()))

  const [reportType, setReportType] = useState(reportTypes[0])
  const [additionalVariables, setAdditionalVariables] = useState({})

  const [loading, setLoading] = useState()
  const [error, setError] = useState()

  const onGenerateReport = async () => {
    setLoading(true)
    const data = {
      startDate: startDate + 'T04:00:00.000Z',
      endDate: endDate + 'T04:00:00.000Z',
      ...additionalVariables
    }
    try {
      const { data: result, errors } = await client.query({
        query: reportType.value,
        variables: { data }
      })
      if (errors) {
        setError(errors[0])
      } else {
        saveReportAsPDF(result.stats)
      }
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  const handleReportTypeSelect = (newReportType) => {
    setReportType(newReportType)
    setError(null)
    setAdditionalVariables({})
  }

  return (
    <>
      <Button onClick={onOpen}>Generate PDF Report</Button>
      {isOpen && (
        <Modal isOpen onClose={handleClose} size='lg'>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Generate PDF Report</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing='8' width='100%'>
                <Stack direction='row' width='100%' justifyContent='space-between'>
                  <FormControl>
                    <FormLabel>Start Date</FormLabel>
                    <DateTimePicker
                      inline
                      noSubmit
                      label='Start Date'
                      value={startDate}
                      onChange={setStartDate}
                      fontSize='lg'
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel textAlign='right'>End Date</FormLabel>
                    <DateTimePicker
                      inline
                      noSubmit
                      label='End Date'
                      value={endDate}
                      onChange={setEndDate}
                      fontSize='lg'
                      textAlign='right'
                    />
                  </FormControl>
                </Stack>
                <FormControl>
                  <FormLabel>Report Type</FormLabel>
                  <Select
                    value={reportType}
                    options={reportTypes}
                    onChange={handleReportTypeSelect}
                    maxMenuHeight='300px'
                  />
                </FormControl>
                {reportType.additionalFilterComponents.length > 0 && (
                  <FormControl>
                    <FormLabel>Additional Filters</FormLabel>
                    <Stack>
                      {reportType.additionalFilterComponents.map((FilterComponent, idx) => (
                        <FilterComponent
                          key={idx}
                          additionalVariables={additionalVariables}
                          setAdditionalVariables={setAdditionalVariables}
                        />
                      ))}
                    </Stack>
                  </FormControl>
                )}
              </Stack>
            </ModalBody>
            <ModalFooter alignItems='center'>
              <Stack width='100%' spacing='4'>
                {error && <ErrorAlert>{error.message}</ErrorAlert>}
                <Button mr={3} onClick={onGenerateReport} isLoading={loading} isFullWidth>
                  Generate
                </Button>
              </Stack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}
