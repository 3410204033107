import { useState, useEffect } from 'react'

import useMutation from 'hooks/useMutation'
import { UPDATE_SERVICE_CREDITS } from 'apollo/mutations/services'

import {
  Text,
  Stack,
  Input,
  Select,
  Switch,
  Textarea,
  FormLabel,
  IconButton,
  FormControl,
  useDisclosure,
  FormErrorMessage
} from '@chakra-ui/react'

import { GrSubtract, GrAdd } from 'react-icons/gr'

import FormDialog from 'components/_common/FormDialog'
import DateTimePicker from 'components/_inputs/DateTimePicker'
import SelectServiceHandledByUser from 'components/services/select/SelectServiceHandledByUser'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string, number } from 'yup'

import useAuthUser from 'hooks/useAuthUser'

import formatLocaleDate from 'utils/formatDate'

const schemaValidation = object().shape({
  credits: number().positive().required(),
  transactionNumber: string(),
  notes: string()
})

export default function ManageServiceCredits ({ service, btnSize = 'xs', inline = false }) {
  const authUser = useAuthUser()

  const [isAdding, setIsAdding] = useState()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [updateServiceCredits, { error, loading, reset: resetMutation }] = useMutation(UPDATE_SERVICE_CREDITS, {
    notification: 'Successfully updated the service credits',
    isPromise: false,
    onCompleted: () => {
      handleClose()
      resetForm()
    }
  })

  const { register, handleSubmit, formState: { errors }, reset: resetForm, setFocus, getValues, setValue, watch } = useForm({
    resolver: yupResolver(schemaValidation),
    defaultValues: {
      isMonthly: service.isMonthly,
      handledByUserID: authUser.id,
      transactionDate: formatLocaleDate(new Date())
    }
  })

  watch(['transactionDate', 'transactionType'])

  useEffect(() => { isOpen && setTimeout(() => setFocus('transactionNumber'), 1) }, [isOpen])

  const onAddCreditsOpen = () => {
    setIsAdding(true)
    onOpen()
  }

  const onRemoveCreditsOpen = () => {
    setIsAdding(false)
    onOpen()
  }

  const handleClose = () => {
    resetMutation()
    resetForm()
    onClose()
  }

  const onSubmit = ({ handledByUserID, credits, transactionType, transactionNumber, transactionDate, isMonthly, notes }) => {
    const data = {
      id: service.id,
      isMonthly,
      handledByUserID,
      credits: isAdding ? credits : -credits,
      transactionType,
      transactionNumber,
      transactionDate: transactionDate + 'T04:00:00.000Z',
      transactionNotes: notes,
      serviceNotes: notes
    }
    updateServiceCredits({ variables: { data } })
  }

  let serviceInactiveProps = {}
  if (!inline && service.inactive) {
    serviceInactiveProps = {
      bg: 'red.500',
      p: '2'
    }
  }

  return (
    <>
      <Stack
        direction='row'
        justifyContent='space-around'
        width='100%'
        alignItems='center'
        {...serviceInactiveProps}
      >
        <IconButton
          size={btnSize}
          onClick={onRemoveCreditsOpen}
          aria-label='Remove credits'
          icon={<GrSubtract />}
          variant='outline'
          isDisabled={service.credits === 0}
        />
        <Text
          flex={1}
          fontSize={btnSize === 'xs' ? 'md' : 'xl'}
          fontWeight='bold'
          textAlign='right'
          mr={2}
          color={service.credits > 7 ? 'green.600' : service.credits > 2 ? 'yellow.600' : 'red.600'}
        >
          {service.credits}
        </Text>
        <IconButton
          size={btnSize}
          onClick={onAddCreditsOpen}
          aria-label='Add credits'
          icon={<GrAdd />}
          variant='outline'
        />
      </Stack>
      {isOpen && (
        <FormDialog
          title={`${isAdding ? 'Add' : 'Remove'} credits`}
          formID='#updateServiceCredits'
          label={isAdding ? 'Add' : 'Remove'}
          error={error}
          loading={loading}
          onClose={handleClose}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack spacing='8'>
            <Stack direction='row' width='100%' alignItems='center'>
              <FormControl isRequired>
                <FormLabel>Transaction Date</FormLabel>
                <DateTimePicker
                  inline
                  noSubmit
                  value={getValues('transactionDate')}
                  onChange={transactionDate => setValue('transactionDate', transactionDate)}
                  error={errors?.transactionDate}
                />
              </FormControl>
              <FormControl isRequired isInvalid={errors?.handledByUserID}>
                <FormLabel textAlign='right'>Processed By</FormLabel>
                <SelectServiceHandledByUser onSubmit={({ id }) => setValue('handledByUserID', id)} />
                <FormErrorMessage>{errors?.handledByUserID?.message}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack direction='row' justifyContent='space-between' width='100%'>
              <FormControl>
                <FormLabel>Type</FormLabel>
                <Select {...register('transactionType')}>
                  {['SALES', 'INVOICE', 'CREDIT', 'REVERSE', 'REFERRAL'].map(type => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </Select>
              </FormControl>
              <FormControl isInvalid={errors?.transactionNumber} isRequired={getValues('transactionType') !== 'REVERSE'}>
                <FormLabel>Number</FormLabel>
                <Input type='number' {...register('transactionNumber')} />
                <FormErrorMessage>
                  {errors?.transactionNumber?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl id='credits' isRequired isInvalid={errors?.credits}>
                <FormLabel>Credits</FormLabel>
                <Input {...register('credits')} type='number' min='1' max='100' />
                <FormErrorMessage>{errors?.credits?.message}</FormErrorMessage>
              </FormControl>
            </Stack>
            <FormControl display='flex' alignItems='center'>
              <FormLabel htmlFor='set-as-monthly' mb='0' mr='2'>{service.isMonthly ? 'Remove' : 'Set'} as Monthly</FormLabel>
              <Switch id='set-as-monthly' {...register('isMonthly')} />
            </FormControl>
            <FormControl isInvalid={errors?.notes}>
              <FormLabel>Notes</FormLabel>
              <Textarea {...register('notes')} />
              <FormErrorMessage>
                {errors?.notes?.message}
              </FormErrorMessage>
            </FormControl>
          </Stack>
        </FormDialog>
      )}
    </>
  )
}
