import useMutation from 'hooks/useMutation'
import { UPDATE_APP_CONFIG } from 'apollo/mutations/settings'

import EditableInput from 'components/_inputs/EditableInput'

import { string } from 'yup'

import { startCase } from 'lodash'

export default function EditSetting ({ label: key, value }) {
  const [updateAppConfig, { loading, error }] = useMutation(UPDATE_APP_CONFIG, {
    notification: 'Successfully updated the setting'
  })

  const handleSubmit = (key, value) => {
    const data = { key, value }
    return updateAppConfig({ variables: { data } })
  }

  return (
    <EditableInput
      inline
      name={key}
      value={value}
      label={startCase(key)}
      onSubmit={value => handleSubmit(key, value)}
      validation={string().required()}
      isLoading={loading}
      error={error}
    />
  )
}
