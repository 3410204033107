import { useState } from 'react'

import {
  Box,
  Stack
} from '@chakra-ui/react'

import useQuery from 'hooks/useQuery'
import { GET_ALL_USERS_LEAN } from 'apollo/queries/users'

import ErrorAlert from 'components/_common/ErrorAlert'
import Loading from 'components/_common/Loading'

import Select from 'components/_select/CustomSelect'

import StatsWrapper from 'components/_charts/StatsWrapper'

import UsersFilter from 'components/reports/UsersFilter'

import { USER_ACTIVITIES_REPORT } from 'apollo/queries/reports'

export default function UsersReports ({ query, additionalFilterComponents = [], isDisabled = false }) {
  const { data, loading: loadingUsers, error: errorLoadingUsers } = useQuery(GET_ALL_USERS_LEAN)

  const [userID, setUserID] = useState()

  const onSelectUser = ({ value }) => {
    setUserID(value)
  }

  if (loadingUsers) return <Loading />
  if (errorLoadingUsers) return <ErrorAlert>{errorLoadingUsers.message}</ErrorAlert>

  const users = data?.response || []

  const options = users.map(user => ({ value: user.id, label: user.fullName }))

  if (userID) {
    return (
      <Stack direction='row' width='100%'>
        <StatsWrapper
          query={USER_ACTIVITIES_REPORT}
          defaultVariables={{ userID }}
          additionalFilterComponents={[UsersFilter, ...additionalFilterComponents]}
        />
      </Stack>
    )
  } else {
    return (
      <Stack direction='row' width='100%' alignItems='center' height='fit-content'>
        <Box width='300px'>
          <Select
            placeholder='Select a User'
            options={options}
            onChange={onSelectUser}
            maxMenuHeight='200px'
            isDisabled={isDisabled}
          />
        </Box>
      </Stack>
    )
  }
}
