import { useState, useMemo } from 'react'

import useQuery from 'hooks/useQuery'

import Loading from 'components/_common/Loading'

import buildSearch from 'utils/search/buildSearch'
import { fuzzySearchVar } from 'utils/cache'

import DataTable from 'components/table/DataTable'

export default function TableWrapper (props) {
  const {
    query,
    fields,
    hideFields = [],
    rowKey,
    rowType,
    fuzzyKey,
    additionalWhere = {},
    defaultOrderBy = [],
    filterWhereVar,
    filterOrderByVar,
    createComponent: CreateNew = null,
    selectionComponent: SelectionMenu = null,
    selectionVar = null,
    isEmbedded = false
  } = props

  const { data = {}, error, loading, refetch, isRefetching, fetchMore, isRefetchingMore } = useQuery(query, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { take: 20, where: additionalWhere, orderBy: defaultOrderBy },
    onCompleted: () => {
      // set default orderBy in cache
      filterOrderByVar((filterOrderByVar().map(orderBy => {
        const defaultOrder = defaultOrderBy.find(defaultOrderBy => Object.keys(defaultOrderBy)[0] === orderBy.field)
        if (defaultOrder) {
          return {
            ...orderBy,
            isActive: true,
            value: defaultOrder[orderBy.field]
          }
        } else {
          return orderBy
        }
      })))
    }
  })

  const allData = data?.response?.allData || []
  const filteredCount = data?.response?.metrics?.filteredCount || 0
  const totalCount = data?.response?.metrics?.allCount || 0

  const [hasMoreItems, setHasMoreItems] = useState(true)

  const handleRefetch = (fuzzySearch = fuzzySearchVar()) => {
    const variables = buildSearch({ where: filterWhereVar(), orderBy: filterOrderByVar(), fuzzySearch, fuzzyKey, isEmbedded })
    if (variables.orderBy.length === 0) {
      variables.orderBy = defaultOrderBy
    }
    variables.where = {
      ...variables.where,
      ...additionalWhere
    }
    refetch(variables)
  }

  const handleFetchMore = () => {
    fetchMore({
      variables: { skip: allData.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return {
          response: {
            ...prev.response,
            ...fetchMoreResult.response,
            allData: [
              ...prev.response.allData,
              ...fetchMoreResult.response.allData
            ]
          }
        }
      }
    })
      .then(({ data }) => {
        if (data && data.response.allData.length === 20) {
          setHasMoreItems(true)
        } else {
          setHasMoreItems(false)
        }
      })
  }

  const handleSelectAll = () => {
    const newSelection = allData.reduce((obj, item) => {
      return {
        ...obj,
        [item[rowKey]]: item
      }
    }, {})
    selectionVar(newSelection)
  }

  const CreateNewComponent = CreateNew ? useMemo(() => <CreateNew {...props} refetch={refetch} />, []) : null

  const SelectionMenuComponent = SelectionMenu ? useMemo(() => <SelectionMenu {...props} onSelectAll={handleSelectAll} refetch={refetch} />, [allData.length]) : null

  if (loading || error || !allData) return <Loading />

  return (
    <DataTable
      rows={allData}
      fields={fields}
      hideFields={hideFields}
      rowKey={rowKey}
      rowType={rowType}
      refetch={handleRefetch}
      isRefetching={isRefetching}
      hasMoreItems={hasMoreItems}
      fetchMore={handleFetchMore}
      isRefetchingMore={isRefetchingMore}
      filterWhereVar={filterWhereVar}
      filterOrderByVar={filterOrderByVar}
      createComponent={CreateNewComponent}
      selectionComponent={SelectionMenuComponent}
      selectionVar={selectionVar}
      isEmbedded={isEmbedded}
      filteredCount={filteredCount}
      totalCount={totalCount}
    />
  )
}
