import useMutation from 'hooks/useMutation'
import { EXTEND_LOGIN_APPROVAL } from 'apollo/mutations/loginApprovals'

import DateTimePicker from 'components/_inputs/DateTimePicker'

export default function EditLoginApprovalExpiry ({ loginApproval }) {
  const [extendLoginApproval, { error, loading, reset }] = useMutation(EXTEND_LOGIN_APPROVAL, {
    notification: 'Successfully extended the expiry'
  })

  const handleSubmit = expiresAt => {
    const data = { id: loginApproval.id, expiresAt }
    return extendLoginApproval({ variables: { data } })
  }

  return (
    <DateTimePicker
      label='Approval Expiry'
      type='time'
      value={loginApproval.expiresAt}
      onChange={handleSubmit}
      error={error}
      loading={loading}
      reset={reset}
      minDate={new Date()}
      isDisabled={loginApproval.status !== 'APPROVED'}
    />
  )
}
