import useMutation from 'hooks/useMutation'
import { PUT_SERVICE_ON_HOLD } from 'apollo/mutations/services'

import DateTimePicker from 'components/_inputs/DateTimePicker'

export default function EditServiceOnHoldDate ({ service, justifyContent, inline = false, textAlign = 'left' }) {
  const [updateOnHoldDate, { error, loading, reset }] = useMutation(PUT_SERVICE_ON_HOLD, {
    notification: 'Successfully updated the expiry date',
    isPromise: false
  })

  const handleSubmit = onHoldDate => {
    const data = { id: service.id, onHoldDate }
    return updateOnHoldDate({ variables: { data } })
  }

  return (
    <DateTimePicker
      inline={inline}
      label='On Hold Date'
      value={service.onHoldDate}
      onChange={handleSubmit}
      error={error}
      loading={loading}
      reset={reset}
      isDisabled={!service.putOnHold}
      justifyContent={justifyContent}
      textAlign={textAlign}
    />
  )
}
