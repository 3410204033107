import { useEffect } from 'react'

import useAuthUser from 'hooks/useAuthUser'

import { CREATE_CUSTOMER } from 'apollo/mutations/customers'
import useMutation from 'hooks/useMutation'

import {
  Input,
  Stack,
  Button,
  FormLabel,
  FormControl,
  useDisclosure,
  FormErrorMessage
} from '@chakra-ui/react'

import { FaPlus } from 'react-icons/fa'

import FormDialog from 'components/_common/FormDialog'
import DateTimePicker from 'components/_inputs/DateTimePicker'
import EditCustomerStore from 'components/customers/edit/EditCustomerStore'
import EditCustomerSource from 'components/customers/edit/EditCustomerSource'
import EditCustomerLanguage from 'components/customers/edit/EditCustomerLanguage'
import SelectCustomerHandledByUser from 'components/customers/select/SelectCustomerHandledByUser'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string, number } from 'yup'

import formatLocaleDate from 'utils/formatDate'

const schemaValidation = object().shape({
  fullName: string().required(),
  handledByUserID: string().required('Processed User is required'),
  primaryPhoneNumber: string().required().matches(/^\+?\d{10,14}$/, { message: 'Invalid Phone Number' }),
  email: string().email(),
  postalCode: string().matches(/^(\d{5}(-\d{4})?|[A-CEGHJ-NPRSTVXY]\d[A-CEGHJ-NPRSTV-Z][ ]\d[A-CEGHJ-NPRSTV-Z]\d)$/, { message: 'Invalid Postal Code', excludeEmptyString: true }),
  storeID: string(),
  sourceID: string(),
  languageID: string(),
  pin: number().required()
})

export default function CreateCustomerTrigger ({ refetch }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleClose = (toRefetch = false) => {
    toRefetch && refetch()
    onClose()
  }

  return (
    <>
      <Button size='sm' leftIcon={<FaPlus />} onClick={onOpen}>
        New
      </Button>
      {isOpen && <CreateCustomer onClose={handleClose} />}
    </>
  )
}

export function CreateCustomer ({ onClose }) {
  const authUser = useAuthUser()

  const handleClose = (toRefetch = false) => {
    resetMutation()
    resetForm()
    onClose(toRefetch)
  }

  const [createCustomer, { error, loading, reset: resetMutation }] = useMutation(CREATE_CUSTOMER, {
    notification: 'Successfully created the customer',
    isPromise: false,
    onCompleted: () => {
      handleClose(true)
    }
  })

  const storeOptions = authUser.stores.map(store => ({ value: store.id, label: store.name }))

  const { register, handleSubmit, formState: { errors }, reset: resetForm, setFocus, setValue, getValues, watch } = useForm({
    resolver: yupResolver(schemaValidation),
    defaultValues: {
      handledByUserID: authUser.id,
      storeID: window.localStorage.ACTIVE_STORE !== 'ALL' ? storeOptions.find(({ value }) => value === window.localStorage.ACTIVE_STORE).value : storeOptions[0].value,
      joinedDate: formatLocaleDate(new Date())
    }
  })

  watch(['joinedDate'])

  useEffect(() => { setTimeout(() => setFocus('fullName'), 1) }, [])

  const onSubmit = ({ handledByUserID, fullName, primaryPhoneNumber, email, pin, joinedDate, storeID, sourceID, languageID, postalCode }) => {
    const code = primaryPhoneNumber.charAt(0) !== '+' ? '+1' : ''
    const phoneNumbers = [{
      type: 'MOBILE',
      value: code + primaryPhoneNumber
    }]
    const data = { handledByUserID, fullName, phoneNumbers, primaryPhoneNumber: code + primaryPhoneNumber, email, pin, joinedDate: joinedDate + 'T04:00:00.000Z', storeID, sourceID, languageID, postalCode }
    if (!data.email) {
      delete data.email
    }
    createCustomer({ variables: { data } })
  }

  return (
    <FormDialog
      title='Create Customer'
      formID='#createCustomer'
      error={error}
      loading={loading}
      onClose={handleClose}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing='8'>
        <Stack direction='row' alignItems='center'>
          <FormControl>
            <FormLabel>Joined Date</FormLabel>
            <DateTimePicker
              inline
              noSubmit
              label='Joined Date'
              value={getValues('joinedDate')}
              onChange={joinedDate => setValue('joinedDate', joinedDate)}
              error={errors?.joinedDate}
              fontSize='md'
            />
          </FormControl>
          <FormControl isInvalid={errors?.storeID}>
            <FormLabel textAlign='right'>Store</FormLabel>
            <EditCustomerStore onSubmit={({ id }) => setValue('storeID', id)} />
            <FormErrorMessage>{errors?.storeID?.message}</FormErrorMessage>
          </FormControl>
        </Stack>
        <FormControl isRequired isInvalid={errors?.handledByUserID}>
          <FormLabel>Processed By</FormLabel>
          <SelectCustomerHandledByUser onSubmit={({ id }) => setValue('handledByUserID', id)} />
          <FormErrorMessage>{errors?.handledByUserID?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={errors?.fullName}>
          <FormLabel>Full Name</FormLabel>
          <Input {...register('fullName')} />
          <FormErrorMessage>{errors?.fullName?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={errors?.primaryPhoneNumber}>
          <FormLabel>Primary Phone Number</FormLabel>
          <Input {...register('primaryPhoneNumber')} />
          <FormErrorMessage>
            {errors?.primaryPhoneNumber?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors?.email}>
          <FormLabel>Email</FormLabel>
          <Input {...register('email')} />
          <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors?.postalCode}>
          <FormLabel>Postal Code (Canada or US)</FormLabel>
          <Input {...register('postalCode')} onChange={e => setValue('postalCode', e.target.value.toUpperCase())} />
          <FormErrorMessage>{errors?.postalCode?.message}</FormErrorMessage>
        </FormControl>
        <Stack direction='row' justifyContent='space-between' width='100%' alignItems='center'>
          <FormControl isInvalid={errors?.sourceID}>
            <FormLabel>Source</FormLabel>
            <EditCustomerSource inline onSubmit={({ id }) => setValue('sourceID', id)} />
            <FormErrorMessage>{errors?.sourceID?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors?.languageID}>
            <FormLabel>Language</FormLabel>
            <EditCustomerLanguage inline onSubmit={({ id }) => setValue('languageID', id)} />
            <FormErrorMessage>{errors?.languageID?.message}</FormErrorMessage>
          </FormControl>
        </Stack>
        <FormControl id='pin' isRequired isInvalid={errors?.pin}>
          <FormLabel>Pin</FormLabel>
          <Input {...register('pin')} type='number' defaultValue={Math.floor(1000 + Math.random() * 9000)} />
          <FormErrorMessage>{errors?.pin?.message}</FormErrorMessage>
        </FormControl>
      </Stack>
    </FormDialog>
  )
}
