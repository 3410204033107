import ProductTypeSelectCheckbox from 'components/productTypes/select/ProductTypeSelectCheckbox'
import DateTimeRenderer from 'components/_common/DateTimeRenderer'
import EditProductTypeName from 'components/productTypes/edit/EditProductTypeName'
import EditProductTypeDetails from 'components/productTypes/edit/EditProductTypeDetails'
import SendProductTypeMessage from 'components/productTypes/SendProductTypeMessage'

const fields = [
  { key: 'select', width: '40px', renderTD: ProductTypeSelectCheckbox },
  { key: 'name', label: 'Name', width: '250px', renderTD: EditProductTypeName },
  { key: 'details', label: 'Details', width: '200px', renderTD: EditProductTypeDetails },
  { key: 'message', label: 'Message', width: '250px', justifyContent: 'center', renderTD: SendProductTypeMessage },
  { key: 'updatedAt', label: 'Updated At', renderTD: ({ productType: { updatedAt } }) => <DateTimeRenderer value={updatedAt} type='time' /> },
  { key: 'createdAt', label: 'Created At', renderTD: ({ productType: { createdAt } }) => <DateTimeRenderer value={createdAt} type='time' /> }
]

export default fields
