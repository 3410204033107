import { useEffect } from 'react'

import { ADD_SERVICE_NOTE } from 'apollo/mutations/services'
import useMutation from 'hooks/useMutation'

import FormDialog from 'components/_common/FormDialog'

import {
  Stack,
  Button,
  Textarea,
  FormLabel,
  FormControl,
  useDisclosure,
  FormErrorMessage
} from '@chakra-ui/react'

import { FaPlus } from 'react-icons/fa'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'

export default function CreateServiceNoteTrigger ({ service }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      {isOpen && <CreateServiceNote service={service} onClose={onClose} />}
      <Button leftIcon={<FaPlus />} onClick={onOpen}>
        Add New
      </Button>
    </>
  )
}

const schemaValidation = object().shape({
  details: string().required()
})

function CreateServiceNote ({ service, onClose }) {
  const handleClose = () => {
    onClose()
    resetMutation()
    resetForm()
  }

  const [createServiceNote, { error, loading, reset: resetMutation }] = useMutation(ADD_SERVICE_NOTE, {
    notification: 'Successfully added the service note',
    isPromise: false,
    onCompleted: handleClose
  })

  const { register, handleSubmit, formState: { errors }, reset: resetForm, setFocus } = useForm({
    resolver: yupResolver(schemaValidation)
  })

  useEffect(() => { setTimeout(() => setFocus('details'), 1) }, [])

  const onSubmit = ({ details }) => {
    const data = {
      serviceID: service.id,
      details
    }
    createServiceNote({ variables: { data } })
  }

  return (
    <FormDialog
      title='Add Service Note'
      formID='#createServiceNote'
      submitLabel='Create'
      error={error}
      loading={loading}
      onClose={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      size='lg'
    >
      <Stack spacing='8'>
        <FormControl isInvalid={errors?.details}>
          <FormLabel>Details</FormLabel>
          <Textarea {...register('details')} />
          <FormErrorMessage>
            {errors?.details?.message}
          </FormErrorMessage>
        </FormControl>
      </Stack>
    </FormDialog>
  )
}
