import { gql } from '@apollo/client'

export const UPDATE_TRANSACTION_DATE = gql`
  mutation UPDATE_TRANSACTION_DATE ($data: UpdateTransactionInput!) {
    updateTransaction (data: $data) {
      id
      date
    }
  }
`

export const UPDATE_TRANSACTION_TYPE = gql`
  mutation UPDATE_TRANSACTION_TYPE ($data: UpdateTransactionInput!) {
    updateTransaction(data: $data) {
      id
      transactionType
    }
  }
`

export const UPDATE_TRANSACTION_INVOICE_NUMBER = gql`
  mutation UPDATE_TRANSACTION_INVOICE_NUMBER ($data: UpdateTransactionInput!) {
    updateTransaction(data: $data) {
      id
      transactionNumber
    }
  }
`

export const UPDATE_TRANSACTION_SHIPMENT = gql`
  mutation UPDATE_TRANSACTION_SHIPMENT ($data: UpdateTransactionInput!) {
    updateTransaction(data: $data) {
      id
      shipment {
        id
      }
      updatedAt
    }
  }
`

export const ADD_TRANSACTION_NOTE = gql`
  mutation ADD_TRANSACTION_NOTE ($data: AddTransactionNotesInput!) {
    addTransactionNotes(data: $data) {
      id
      notes {
        id
        createdAt
        details
        markAsDone
        user {
          id
          fullName
          role
          isDeleted
        }
        updatedAt
      }
    }
  }
`

export const UPDATE_TRANSACTION_NOTE_DETAILS = gql`
  mutation UPDATE_TRANSACTION_NOTE_DETAILS ($data: UpdateNotesInput!) {
    updateTransactionNotes(data: $data) {
      id
      notes {
        id
        createdAt
        details
        markAsDone
        user {
          id
          fullName
          role
          isDeleted
        }
        updatedAt
      }
    }
  }
`

export const UPDATE_TRANSACTION_NOTE_MARK_AS_DONE = gql`
  mutation UPDATE_TRANSACTION_NOTE_MARK_AS_DONE ($data: UpdateNotesInput!) {
    updateTransactionNotes(data: $data) {
      id
      notes {
        id
        createdAt
        details
        markAsDone
        user {
          id
          fullName
          role
          isDeleted
        }
        updatedAt
      }
    }
  }
`
