import EditableInput from 'components/_inputs/EditableInput'

import { string } from 'yup'

import useMutation from 'hooks/useMutation'
import { UPDATE_STORE_LOCATION } from 'apollo/mutations/stores'

export default function EditStoreLocation ({ store }) {
  const [updateLocation, { error, loading, reset }] = useMutation(UPDATE_STORE_LOCATION, {
    notification: 'Successfully updated the location'
  })

  const handleSubmit = location => {
    const data = { id: store.id, location }
    return updateLocation({ variables: { data } })
  }

  return (
    <EditableInput
      name='location'
      value={store.location || ''}
      label='Location'
      onSubmit={handleSubmit}
      validation={string().required()}
      isLoading={loading}
      error={error}
      reset={reset}
    />
  )
}
