import useQuery from 'hooks/useQuery'
import { GET_CUSTOMER_GENERAL_INFO } from 'apollo/queries/customers'

import Loading from 'components/_common/Loading'

import {
  Text,
  Stack,
  FormLabel,
  FormControl
} from '@chakra-ui/react'

import DateTimeRenderer from 'components/_common/DateTimeRenderer'
import EditCustomerJoinedDate from 'components/customers/edit/EditCustomerJoinedDate'
import EditCustomerEmail from 'components/customers/edit/EditCustomerEmail'
import EditCustomerFullName from 'components/customers/edit/EditCustomerFullName'
import EditCustomerPostalCode from 'components/customers/edit/EditCustomerPostalCode'
import EditCustomerPin from 'components/customers/edit/EditCustomerPin'
import EditCustomerStore from 'components/customers/edit/EditCustomerStore'
import EditCustomerSource from 'components/customers/edit/EditCustomerSource'
import EditCustomerLanguage from 'components/customers/edit/EditCustomerLanguage'

import ManageCustomerPhoneNumbers from 'components/customers/edit/ManageCustomerPhoneNumbers'

import SendCustomerMessage from 'components/customers/SendCustomerMessage'

export default function ViewCustomerGeneral ({ customer: { id } }) {
  const { data: { customer } = {}, loading, error } = useQuery(GET_CUSTOMER_GENERAL_INFO, { variables: { id } })

  if (loading || error) return <Loading />

  return (
    <Stack spacing='8' maxW='500px' mx='auto'>
      <Stack direction='row'>
        <FormControl>
          <FormLabel>Joined At</FormLabel>
          <EditCustomerJoinedDate inline customer={customer} />
        </FormControl>
        <FormControl>
          <FormLabel textAlign='right'>Last Updated At</FormLabel>
          <DateTimeRenderer value={customer.updatedAt} textAlign='right' fontSize='sm' type='time' />
        </FormControl>
      </Stack>
      <Stack direction='row' width='100%'>
        <EditCustomerFullName inline customer={customer} />
        <EditCustomerEmail inline customer={customer} textAlign='right' />
      </Stack>
      <Stack direction='row' width='100%'>
        <EditCustomerPostalCode inline customer={customer} />
        <FormControl>
          <FormLabel textAlign='right'>Store</FormLabel>
          <EditCustomerStore existing customer={customer} />
        </FormControl>
      </Stack>
      <Stack direction='row' width='100%' alignItems='center'>
        <EditCustomerPin inline customer={customer} />
        <FormControl>
          <FormLabel textAlign='right'>Referral Credits</FormLabel>
          <Text fontSize='lg' textAlign='right'>{customer.totalReferralCredits}</Text>
        </FormControl>
      </Stack>
      <Stack direction='row' width='100%' alignItems='center'>
        <FormControl>
          <FormLabel>Source</FormLabel>
          <EditCustomerSource existing customer={customer} />
        </FormControl>
        <FormControl>
          <FormLabel textAlign='right'>Language</FormLabel>
          <EditCustomerLanguage existing customer={customer} />
        </FormControl>
      </Stack>
      <FormControl>
        <FormLabel>Phone Numbers</FormLabel>
        <ManageCustomerPhoneNumbers inline customer={customer} />
      </FormControl>
      <SendCustomerMessage customer={customer} />
    </Stack>
  )
}
