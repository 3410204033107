import TableWrapper from 'components/table/TableWrapper'
import ServiceTypesSelectionMenu from 'components/serviceTypes/select/ServiceTypesSelectionMenu'
import CreateServiceType from 'components/serviceTypes/CreateServiceType'

import { GET_ALL_SERVICE_TYPES } from 'apollo/queries/serviceTypes'
import { selectedServiceTypesVar, serviceTypeFilterWhereVar, serviceTypeFilterOrderByVar } from 'utils/cache'

import fields from 'components/serviceTypes/fields'

export default function ServiceTypes () {
  return (
    <TableWrapper
      query={GET_ALL_SERVICE_TYPES}
      fields={fields}
      rowKey='id'
      rowType='serviceType'
      fuzzyKey='serviceTypes'
      defaultOrderBy={[{ order: 'asc' }]}
      filterWhereVar={serviceTypeFilterWhereVar}
      filterOrderByVar={serviceTypeFilterOrderByVar}
      createComponent={CreateServiceType}
      selectionComponent={ServiceTypesSelectionMenu}
      selectionVar={selectedServiceTypesVar}
    />
  )
}
