import { useEffect, useState } from 'react'

import {
  Flex,
  Input,
  Stack,
  Button,
  FormLabel,
  FormControl,
  FormErrorMessage
} from '@chakra-ui/react'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'

import PasswordInput from 'components/_inputs/PasswordInput'
import ForgotPassword from 'components/auth/ForgotPassword'
import OTPLogin from 'components/auth/OTPLogin'

import useMutation from 'hooks/useMutation'
import { LOGIN } from 'apollo/mutations/auth'

import ErrorAlert from 'components/_common/ErrorAlert'

const schemaValidation = object().shape({
  email: string().email().required(),
  password: string().required().min(3)
})

export default function LoginForm () {
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false)
  const [otpData, setOTPData] = useState(null)

  const onLoginSuccess = (token) => {
    const prevPage = window.localStorage.getItem('REDIRECT_REFERRAL')
    window.localStorage.removeItem('REDIRECT_REFERRAL')
    window.localStorage.setItem('AUTH_SESSION_ID', token)
    window.location.href = prevPage || '/'
  }

  const onCompleted = data => {
    if (data?.login?.otpHash) {
      // show OTP
      const { id, otpHash } = data?.login
      setOTPData({ id, otpHash })
    } else {
      onLoginSuccess(data?.login?.token)
    }
  }

  const onOTPClose = (successOTPResponse = false) => {
    setOTPData(null)
    if (successOTPResponse) {
      onLoginSuccess(successOTPResponse?.validateOTP?.token)
    }
  }

  const [login, { error, loading }] = useMutation(LOGIN, { onCompleted, isPromise: false })

  const { register, handleSubmit, formState: { errors }, setFocus } = useForm({
    resolver: yupResolver(schemaValidation)
  })

  const onSubmit = ({ email, password }) => {
    const fingerprint = window.localStorage.getItem('FINGERPRINT')
    const agentDetails = require('ua-parser-js')(navigator.userAgent)
    const data = { email, password, fingerprint, agentDetails }
    login({ variables: { data } })
  }

  useEffect(() => { setTimeout(() => setFocus('email')) }, [])

  return (
    <>
      {isForgotPasswordOpen && <ForgotPassword onClose={() => setIsForgotPasswordOpen(false)} />}
      {otpData && <OTPLogin otpData={otpData} onClose={onOTPClose} />}
      <Stack
        as='form'
        onSubmit={handleSubmit(onSubmit)}
        bg='white'
        p='8'
        shadow='base'
        rounded='lg'
        spacing='12'
      >
        <FormControl id='email' isRequired isInvalid={errors?.email}>
          <FormLabel>Email</FormLabel>
          <Input {...register('email')} type='email' autoComplete='email' />
          <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
        </FormControl>
        <FormControl id='password' isRequired isInvalid={errors?.password}>
          <Flex justify='space-between'>
            <FormLabel>Password</FormLabel>
            <Button
              variant='link'
              color='purple.600'
              fontWeight='semibold'
              fontSize='sm'
              onClick={() => setIsForgotPasswordOpen(true)}
              tabIndex='-1'
            >
              Forgot Password?
            </Button>
          </Flex>
          <PasswordInput {...register('password')} />
          <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
        </FormControl>
        <Stack spacing='4' width='100%'>
          {error && <ErrorAlert>{error.message}</ErrorAlert>}
          <Button type='submit' size='lg' fontSize='md' isLoading={loading}>
            Sign in
          </Button>
        </Stack>
      </Stack>
    </>
  )
}
