import { useState } from 'react'

import {
  Box,
  Stack
} from '@chakra-ui/react'

import useQuery from 'hooks/useQuery'
import { SEARCH_CUSTOMERS } from 'apollo/queries/customers'

import ErrorAlert from 'components/_common/ErrorAlert'
import Loading from 'components/_common/Loading'

import AsyncSelect from 'components/_select/AsyncSelect'

import StatsWrapper from 'components/_charts/StatsWrapper'

import CustomersFilter from 'components/reports/CustomersFilter'

export default function CustomersReports ({ query, additionalFilterComponents = [], isDisabled = false }) {
  const { data, loading: loadingCustomers, error: errorLoadingCustomers, refetch } = useQuery(SEARCH_CUSTOMERS)

  const [customerID, setCustomerID] = useState()

  const onSelectCustomer = ({ value }) => {
    setCustomerID(value)
  }

  if (loadingCustomers) return <Loading />
  if (errorLoadingCustomers) return <ErrorAlert>{errorLoadingCustomers.message}</ErrorAlert>

  const customers = data?.response || []

  const transformCustomers = (customer) => ({ value: customer.id, label: customer.fullName })

  const options = customers.map(transformCustomers)

  const loadCustomers = async inputValue => {
    try {
      await refetch({ searchBy: inputValue })
      if (customers) {
        return customers.map(transformCustomers)
      } else {
        return []
      }
    } catch (error) {
      console.log(error)
      return []
    }
  }

  if (customerID) {
    return (
      <Stack direction='row' width='100%'>
        <StatsWrapper
          query={query}
          defaultVariables={{ customerID }}
          additionalFilterComponents={[CustomersFilter, ...additionalFilterComponents]}
        />
      </Stack>
    )
  } else {
    return (
      <Stack direction='row' width='100%' alignItems='center' height='fit-content'>
        <Box width='300px'>
          <AsyncSelect
            placeholder='Select a Customer'
            options={options}
            onChange={onSelectCustomer}
            maxMenuHeight='200px'
            loadOptions={loadCustomers}
            isDisabled={isDisabled}
          />
        </Box>
      </Stack>
    )
  }
}
