import { useEffect } from 'react'

import { CREATE_SHIPMENT } from 'apollo/mutations/shipments'
import useMutation from 'hooks/useMutation'

import {
  Input,
  Stack,
  Button,
  FormLabel,
  FormControl,
  useDisclosure,
  FormErrorMessage
} from '@chakra-ui/react'

import { FaPlus } from 'react-icons/fa'

import FormDialog from 'components/_common/FormDialog'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'

const schemaValidation = object().shape({
  name: string().required(),
  details: string()
})

export default function CreateShipment ({ refetch }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleClose = () => {
    onClose()
    resetMutation()
    resetForm()
  }

  const [createShipment, { error, loading, reset: resetMutation }] = useMutation(CREATE_SHIPMENT, {
    notification: 'Successfully created the shipment',
    isPromise: false,
    onCompleted: () => {
      handleClose()
      refetch()
    }
  })

  const { register, handleSubmit, formState: { errors }, reset: resetForm, setFocus } = useForm({
    resolver: yupResolver(schemaValidation)
  })

  useEffect(() => { isOpen && setTimeout(() => setFocus('name'), 1) }, [isOpen])

  const onSubmit = ({ name, details }) => {
    const data = { name, details }
    createShipment({ variables: { data } })
  }

  return (
    <>
      <Button size='sm' leftIcon={<FaPlus />} onClick={onOpen}>
        New
      </Button>
      {isOpen && (
        <FormDialog
          title='Create Product Type'
          formID='#createShipment'
          error={error}
          loading={loading}
          onClose={handleClose}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack spacing='8'>
            <FormControl isRequired isInvalid={errors?.name}>
              <FormLabel>Name</FormLabel>
              <Input {...register('name')} />
              <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors?.details}>
              <FormLabel>Details</FormLabel>
              <Input {...register('details')} />
              <FormErrorMessage>{errors?.details?.message}</FormErrorMessage>
            </FormControl>
          </Stack>
        </FormDialog>
      )}
    </>
  )
}
