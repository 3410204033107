import { useEffect } from 'react'

import {
  Grid,
  Flex,
  Text
} from '@chakra-ui/react'

import ViewMyConversations from 'components/chats/ViewMyConversations'
import ViewConversation from 'components/chats/ViewConversation'

export default function Chat ({ activeConversationID }) {
  // clean the url
  useEffect(() => { window.history.replaceState(null, null, '/chat') }, [])

  return (
    <Flex height='100%' width='100%'>
      <ViewMyConversations activeConversationID={activeConversationID} />
      {activeConversationID && <ViewConversation activeConversationID={activeConversationID} />}
      {!activeConversationID && (
        <Grid placeItems='center' width='100%'>
          <Text fontSize='4xl'>👈 Select a Conversation</Text>
        </Grid>
      )}
    </Flex>
  )
}
