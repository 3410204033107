import Select from 'components/_select/CustomSelect'

import useQuery from 'hooks/useQuery'
import { GET_ALL_LANGUAGES } from 'apollo/queries/languages'

import Loading from 'components/_common/Loading'
import AlertDialog from 'components/_common/AlertDialog'

export default function LanguagesFilter ({ additionalVariables, setAdditionalVariables, isDisabled = false }) {
  const { data, loading, error } = useQuery(GET_ALL_LANGUAGES, {
    variables: { orderBy: { name: 'asc' } }
  })

  const handleSelectLanguage = ({ value: languageID }) => {
    setAdditionalVariables({ ...additionalVariables, languageID })
  }

  const languages = data?.response?.allData || []

  if (loading) return <Loading />

  if (error) return <AlertDialog status='error'>{error.message}</AlertDialog>

  const options = languages.map(language => ({ value: language.id, label: language.name }))
  options.unshift({ value: undefined, label: 'All Languages' })

  return (
    <Select
      value={options.find(({ value }) => value === additionalVariables.languageID)}
      options={options}
      onChange={handleSelectLanguage}
      maxMenuHeight='300px'
      isDisabled={isDisabled}
    />
  )
}
