import React, { useCallback } from 'react'

import { debounce } from 'lodash'

import {
  Text,
  Input,
  Select,
  Checkbox
} from '@chakra-ui/react'

import DateTimePicker from 'components/_inputs/DateTimePicker'

import formatLocaleDate from 'utils/formatDate'

export default function StringFilter (props) {
  const {
    field = '',
    label = '',
    value = '',
    filter,
    filterOptions,
    isActive,
    onFilterChange,
    onValueChange,
    onActiveChange,
    type = 'text'
  } = props

  // eslint-disable-next-line
  const debouncedSearch = useCallback(debounce((search) => onValueChange(search), 400), [])

  const handleChange = e => {
    const search = type === 'number' ? parseInt(e.target.value) : e.target.value.trim()
    debouncedSearch(search)
  }

  const handleDateChange = date => {
    const search = date
    debouncedSearch(search)
  }

  return (
    <>
      <Checkbox size='lg' isChecked={isActive} onChange={e => onActiveChange(e.target.checked)}>
        <Text fontSize='sm'>{label}</Text>
      </Checkbox>
      <Select size='sm' value={filter || filterOptions[0]} onChange={e => onFilterChange(e.target.value)}>
        {filterOptions.map(filter => (
          <option key={filter} value={filter}>{filter}</option>
        ))}
      </Select>
      {(type !== 'date' && type !== 'datetime') && (
        <Input
          id={`${field}-${label}`.replaceAll(' ', '-')}
          size='sm'
          type={type}
          defaultValue={value}
          onChange={handleChange}
        />
      )}
      {(type === 'date' || type === 'datetime') && (
        <DateTimePicker
          inline
          label={label}
          justifyContent='flex-end'
          value={formatLocaleDate(new Date(value)) || formatLocaleDate(new Date())}
          onChange={handleDateChange}
          fontSize='sm'
        />
      )}
    </>
  )
}
