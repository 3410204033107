import { useEffect } from 'react'

import { BROADCAST_MESSAGE } from 'apollo/mutations/chats'
import useMutation from 'hooks/useMutation'

import {
  Stack,
  Button,
  Textarea,
  FormLabel,
  FormControl,
  useDisclosure,
  FormErrorMessage
} from '@chakra-ui/react'

import { FiSend } from 'react-icons/fi'

import FormDialog from 'components/_common/FormDialog'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'

const schemaValidation = object().shape({
  message: string().required()
})

export default function BroadcastMessage ({ refetch }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleClose = () => {
    onClose()
    resetMutation()
    resetForm()
  }

  const [broadcastMessage, { error, loading, reset: resetMutation }] = useMutation(BROADCAST_MESSAGE, {
    notification: 'Successfully broadcasted the message',
    isPromise: false,
    onCompleted: () => {
      handleClose()
      refetch()
    }
  })

  const { register, handleSubmit, formState: { errors }, reset: resetForm, setFocus } = useForm({
    resolver: yupResolver(schemaValidation)
  })

  useEffect(() => { isOpen && setTimeout(() => setFocus('message'), 1) }, [isOpen])

  const onSubmit = ({ message }) => {
    broadcastMessage({ variables: { message } })
  }

  return (
    <>
      <Button size='lg' leftIcon={<FiSend />} onClick={onOpen}>
        BROADCAST MESSAGE
      </Button>
      {isOpen && (
        <FormDialog
          title='Broadcast a Message'
          formID='#broadcastMessage'
          submitLabel='Send'
          error={error}
          loading={loading}
          onClose={handleClose}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack spacing='8'>
            <FormControl isRequired isInvalid={errors?.message}>
              <FormLabel>Message</FormLabel>
              <Textarea {...register('message')} rows={6} />
              <FormErrorMessage>{errors?.message?.message}</FormErrorMessage>
            </FormControl>
          </Stack>
        </FormDialog>
      )}
    </>
  )
}
