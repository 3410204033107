import {
  Text,
  Modal,
  Stack,
  Badge,
  FormLabel,
  ModalBody,
  IconButton,
  FormControl,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  ModalCloseButton
} from '@chakra-ui/react'

import { AiOutlineEye } from 'react-icons/ai'

import DateTimeRenderer from 'components/_common/DateTimeRenderer'

import { startCase } from 'lodash'

export default function ViewActivityTrigger ({ activity }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      {isOpen && <ViewActivityDetails activity={activity} onClose={onClose} />}
      <IconButton
        size='xs'
        variant='outline'
        icon={<AiOutlineEye fontSize='16px' />}
        onClick={onOpen}
      />
    </>
  )
}

function ViewActivityDetails ({ activity, onClose }) {
  return (
    <Modal isOpen onClose={onClose} size='xl' scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent pb='6'>
        <ModalHeader>Activity Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing='8'>
            <FormControl>
              <FormLabel>Date</FormLabel>
              <DateTimeRenderer value={activity.date} type='time' fontSize='md' />
            </FormControl>
            <FormControl>
              <FormLabel>User</FormLabel>
              <Text>{activity.createdByUser.fullName}</Text>
            </FormControl>
            <FormControl>
              <FormLabel>Type</FormLabel>
              <Badge borderRadius='md' colorScheme='orange' fontSize='sm'>
                {startCase(activity.type) || '-'}
              </Badge>
            </FormControl>
            <FormControl>
              <FormLabel>Details</FormLabel>
              <Text as='code' fontSize='sm'>
                {activity.details}
              </Text>
            </FormControl>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
