import { useState } from 'react'

import {
  Menu,
  Icon,
  Button,
  MenuList,
  MenuItem,
  MenuButton,
  MenuDivider
} from '@chakra-ui/react'

import { HiChevronDown } from 'react-icons/hi'
import { MdDelete, MdClear } from 'react-icons/md'
import { BiSelectMultiple } from 'react-icons/bi'
import { CgArrowsMergeAltH } from 'react-icons/cg'

import { useReactiveVar } from '@apollo/client'
import { selectedCustomersVar } from 'utils/cache'

import MergeCustomers from 'components/customers/MergeCustomers'
import DeleteCustomers from 'components/customers/DeleteCustomers'

export default function CustomersSelectionMenu ({ onSelectAll, refetch }) {
  const selectedVar = useReactiveVar(selectedCustomersVar)

  const [isMergeOpen, setIsMerge] = useState(false)
  const [isDeleteOpen, setIsDelete] = useState(false)

  const handleMergeOnClose = (clear = false) => {
    setIsMerge(false)
    clear && selectedCustomersVar({}) && refetch()
  }

  const handleDeleteOnClose = (clear = false) => {
    setIsDelete(false)
    clear && selectedCustomersVar({}) && refetch()
  }

  return (
    <>
      {isMergeOpen && (
        <MergeCustomers customers={selectedVar} onClose={handleMergeOnClose} />
      )}
      {isDeleteOpen && (
        <DeleteCustomers customers={selectedVar} onClose={handleDeleteOnClose} />
      )}
      <Menu>
        <MenuButton size='sm' as={Button} rightIcon={<HiChevronDown />} isDisabled={Object.keys(selectedVar).length === 0}>
          {Object.keys(selectedVar).length} Selected
        </MenuButton>
        <MenuList zIndex='2'>
          <MenuItem onClick={() => setIsMerge(true)} isDisabled={Object.keys(selectedVar).length < 2}>
            <Icon as={CgArrowsMergeAltH} mr={2} />
            <span>Merge</span>
          </MenuItem>
          <MenuItem onClick={() => setIsDelete(true)}>
            <Icon as={MdDelete} mr={2} />
            <span>Delete</span>
          </MenuItem>
          <MenuDivider />
          <MenuItem onClick={onSelectAll}>
            <Icon as={BiSelectMultiple} mr={2} />
            <span>Select All</span>
          </MenuItem>
          <MenuItem onClick={() => selectedCustomersVar({})}>
            <Icon as={MdClear} mr={2} />
            <span>Clear Selection</span>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  )
}
