import { Text } from '@chakra-ui/react'

export default function ViewServiceCredits ({ service }) {
  return (
    <Text
      textAlign='center'
      flex={1}
      fontSize='md'
      fontWeight='bold'
      mr={2}
      color={service.credits > 7 ? 'green.600' : service.credits > 2 ? 'yellow.600' : 'red.600'}
    >
      {service.credits}
    </Text>
  )
}
