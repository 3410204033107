import { forwardRef } from 'react'

import Select from 'react-select'

import useStyles from 'components/_select/useStyles'

const CustomSelect = forwardRef((props, ref) => {
  const styles = useStyles(props.isDark)

  return (
    <Select
      ref={ref}
      styles={styles}
      maxMenuHeight='200px'
      {...props}
    />
  )
})

export default CustomSelect
