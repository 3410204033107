import useMutation from 'hooks/useMutation'

import EditableInput from 'components/_inputs/EditableInput'

import {
  Grid,
  Button,
  Stack,
  Select,
  IconButton
} from '@chakra-ui/react'

import { BiTrash } from 'react-icons/bi'

import { string } from 'yup'

import ErrorAlert from 'components/_common/ErrorAlert'

export default function EditPhoneNumber (props) {
  const {
    id,
    phoneNumber,
    primaryPhoneNumber,
    updatePhoneNumberMutation,
    deletePhoneNumberMutation,
    setPrimaryNumberMutation
  } = props

  const [updatePhoneNumber, { error: errorUpdate, loading: loadingUpdate }] = useMutation(updatePhoneNumberMutation, {
    notification: 'Successfully updated the phone number',
    isPromise: false
  })

  const [setPrimaryNumber, { error: errorPrimaryNumberSet, loading: loadingPrimaryNumberSet }] = useMutation(setPrimaryNumberMutation, {
    notification: 'Successfully set the primary phone number',
    isPromise: false
  })

  const [deletePhoneNumber, { error: errorDelete, loading: loadingDelete }] = useMutation(deletePhoneNumberMutation, {
    notification: 'Successfully deleted the phone number',
    isPromise: false
  })

  const onTypeChange = (e) => {
    const newPhoneNumber = {
      type: e.target.value,
      value: phoneNumber.value
    }
    const data = { id: phoneNumber.id, newPhoneNumber }
    updatePhoneNumber({ variables: { data } })
  }

  const onValueChange = (value) => {
    const isPrimaryPhoneNumber = primaryPhoneNumber === phoneNumber.value
    const code = value.charAt(0) !== '+' ? '+1' : ''
    const newPhoneNumber = {
      type: phoneNumber.type,
      value: code + value
    }
    const data = { id: phoneNumber.id, newPhoneNumber }
    return updatePhoneNumber({ variables: { data } })
      .then(() => {
        if (isPrimaryPhoneNumber) {
          const data = { id, primaryPhoneNumber: newPhoneNumber.value }
          setPrimaryNumber({ variables: { data } })
        }
      })
  }

  const onPrimaryNumberSet = () => {
    const data = { id, primaryPhoneNumber: phoneNumber.value }
    setPrimaryNumber({ variables: { data } })
  }

  const onDelete = () => {
    deletePhoneNumber({ variables: { id: phoneNumber.id } })
  }

  return (
    <Stack width='100%'>
      <Grid gridTemplateColumns='0.5fr 1fr 2fr 0.5fr' width='100%' gridGap={4} alignItems='center' justifyItems='center'>
        <Button
          size='xs'
          width='fit-content'
          variant={primaryPhoneNumber === phoneNumber.value ? 'solid' : 'outline'}
          isLoading={loadingPrimaryNumberSet}
          onClick={onPrimaryNumberSet}
        >
          primary
        </Button>
        <Select value={phoneNumber.type} onChange={onTypeChange}>
          <option value='LANDLINE'>Landline</option>
          <option value='MOBILE'>Mobile</option>
          <option value='BUSINESS'>Business</option>
        </Select>
        <EditableInput
          inline
          justifyContent='space-around'
          name='value'
          value={phoneNumber.value}
          onSubmit={onValueChange}
          validation={string().required().matches(/^\+?\d{10,14}$/, { message: 'Invalid Phone Number' })}
          isLoading={loadingUpdate}
          error={errorUpdate}
        />
        <IconButton
          size='sm'
          icon={<BiTrash fontSize='16px' />}
          isDisabled={primaryPhoneNumber === phoneNumber.value}
          isLoading={loadingDelete}
          onClick={onDelete}
          colorScheme='red'
          width='fit-content'
        />
      </Grid>
      {errorDelete && <ErrorAlert> {errorDelete.message} </ErrorAlert>}
      {errorPrimaryNumberSet && <ErrorAlert> {errorPrimaryNumberSet.message} </ErrorAlert>}
    </Stack>
  )
}
