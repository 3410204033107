import { Fragment } from 'react'

import { useReactiveVar } from '@apollo/client'

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  useDisclosure,
  DrawerCloseButton
} from '@chakra-ui/react'

import SearchFilter from 'components/_filters/SearchFilter'

export default function AdvancedSearchTrigger ({ refetch, filterVar }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const filterValues = useReactiveVar(filterVar)

  let activeFiltersCount = 0
  for (const { isActive, relations } of filterValues) {
    if (isActive) activeFiltersCount += 1
    if (relations) {
      for (const { isActive } of relations) {
        if (isActive) activeFiltersCount += 1
      }
    }
  }

  return (
    <>
      {isOpen && <AdvancedSearch refetch={refetch} filterVar={filterVar} onClose={onClose} />}
      <Button
        size='sm'
        onClick={onOpen}
        variant={activeFiltersCount > 0 ? 'solid' : 'outline'}
        colorScheme={activeFiltersCount > 0 ? 'orange' : 'purple'}
      >
        Filter | {activeFiltersCount}
      </Button>
    </>
  )
}

function AdvancedSearch ({ refetch, filterVar, onClose }) {
  return (
    <Drawer isOpen onClose={onClose} size='md'>
      <DrawerContent pb={8}>
        <DrawerHeader>
          Advanced Filter
        </DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody>
          <SearchFilter
            filterVar={filterVar}
            refetch={refetch}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
