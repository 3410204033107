import { Badge } from '@chakra-ui/react'

import { startCase } from 'lodash'

export default function ViewActivityType ({ activity }) {
  return (
    <Badge borderRadius='md' colorScheme='orange'>
      {startCase(activity.type) || '-'}
    </Badge>
  )
}
