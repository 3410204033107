import { gql } from '@apollo/client'

export const GET_ALL_SERVICES = gql`
  query GET_ALL_SERVICES ($where: ServiceWhereInput $orderBy: [ServiceOrderByInput!] $take: Int $skip: Int) {
    response: getAllServices (where: $where orderBy: $orderBy take: $take skip: $skip) {
      allData: services {
        id
        createdDate
        macID
        accountNumber
        customer {
          id
          fullName
          primaryPhoneNumber
          phoneNumbers {
            id
            type
            value
          }
          isDeleted
        }
        serviceType {
          id
          name
          color
        }
        notes {
          id
          createdAt
          details
          markAsDone
          user {
            id
            fullName
            role
            isDeleted
          }
          updatedAt
        }
        inactive
        isMonthly
        credits
        expiryDate
        onHoldDate
        putOnHold
        actualExpiryDate
      }
      metrics {
        allCount
        filteredCount
      }
    }
  }
`

export const GET_ONE_SERVICE = gql`
  query GET_ONE_SERVICE ($id: String!) {
    service: getOneService (id: $id) {
      id
      createdDate
      macID
      accountNumber
      serviceType {
        id
        name
        color
      }
      productType {
        id
        name
      }
      credits
      inactive
      isMonthly
      notes {
        id
        createdAt
        details
        markAsDone
        user {
          id
          fullName
          role
          isDeleted
        }
        updatedAt
      }
      expiryDate
      actualExpiryDate
      onHoldDate
      putOnHold
      updatedAt
    }
  }
`

export const GET_TODAYS_TOPUP_SERVICES = gql`
  query GET_TODAYS_TOPUP_SERVICES ($where: ServiceWhereInput $orderBy: [ServiceOrderByInput!] $take: Int $skip: Int) {
    response: getTodaysTopupServices (where: $where orderBy: $orderBy take: $take skip: $skip) {
      allData: services {
        id
        createdDate
        macID
        accountNumber
        customer {
          id
          fullName
          primaryPhoneNumber
          isDeleted
        }
        serviceType {
          id
          name
          color
        }
        notes {
          id
          createdAt
          details
          markAsDone
          user {
            id
            fullName
            role
            isDeleted
          }
          updatedAt
        }
        credits
        expiryDate
        onHoldDate
        putOnHold
        actualExpiryDate
      }
    }
  }
`
