import useAuthUser from 'hooks/useAuthUser'

import { version } from '../../../package.json'

import {
  Box,
  Flex,
  Text,
  Image,
  Stack,
  Heading
} from '@chakra-ui/react'

import LogoSVG from 'images/logo.svg'

import ProfileMenu from 'components/auth/ProfileMenu'
import StoreDropdown from 'components/stores/select/StoreDropdown'

import routes from 'components/_sidebar/routes'

import SidebarLink from 'components/_sidebar/SidebarLink'

export default function Sidebar () {
  const authUser = useAuthUser()

  return (
    <Flex direction='column' height='100%'>
      <ProfileMenu />
      <Stack spacing='1' color='white' py='4' alignItems='left' flex='1' overflowY='auto' px='1'>
        {routes(authUser).map(SidebarLink)}
      </Stack>
      <StoreDropdown />
      <Box p='1' textAlign='center' color='white'>
        <Image src={LogoSVG} mx='auto' h='8' />
        <Heading fontSize='xl' letterSpacing='2px'>
          BILLING
        </Heading>
        <Text fontWeight='semibold' fontSize='sm'>v{version} </Text>
      </Box>
    </Flex>
  )
}
