import useMutation from 'hooks/useMutation'
import { UPDATE_APP_CONFIG } from 'apollo/mutations/settings'

import useQuery from 'hooks/useQuery'
import { GET_ALL_APP_CONFIGS } from 'apollo/queries/settings'

import Loading from 'components/_common/Loading'
import ErrorAlert from 'components/_common/ErrorAlert'

import {
  Text,
  Modal,
  Stack,
  Button,
  Checkbox,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalCloseButton
} from '@chakra-ui/react'

import { AiFillEye } from 'react-icons/ai'

import { startCase } from 'lodash'

const graphOptions = [
  'TOPUPS',
  'NEW_CUSTOMERS',
  'NEW_SERVICES',
  'TRANSACTIONS',
  'CREDITS_PURCHASED'
]

export default function ToggleGraphs () {
  const { data: dataSettings, loading: loadingSettings, error: errorLoading, refetch } = useQuery(GET_ALL_APP_CONFIGS)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleClose = (toRefetch) => {
    toRefetch && refetch()
    onClose()
  }

  const [updateAppConfig, { loading, error }] = useMutation(UPDATE_APP_CONFIG, {
    notification: 'Successfully updated the graph settings',
    isPromise: false,
    onCompleted: () => handleClose(true)
  })

  const toggleSelect = (option) => {
    const updatedReports = dashboardReports.includes(option) ? dashboardReports.filter(report => report !== option) : [...dashboardReports, option]
    const data = { key: 'DASHBOARD_REPORTS', value: JSON.stringify(updatedReports) }
    updateAppConfig({ variables: { data } })
  }

  const settings = dataSettings?.getAllAppConfigs?.settings || []

  const dashboardReports = JSON.parse(settings.find(({ key }) => key === 'DASHBOARD_REPORTS')?.value)

  return (
    <>
      <Button size='sm' leftIcon={<AiFillEye />} onClick={onOpen} variant='outline'>
        Show/Hide Graphs
      </Button>
      {isOpen && (
        <Modal isOpen onClose={handleClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Choose Dashboard Graphs</ModalHeader>
            <ModalCloseButton />
            <ModalBody p='4'>
              <Stack spacing='8' p='4'>
                {(loadingSettings || loading) && <Loading />}
                {errorLoading && <ErrorAlert> {error.message} </ErrorAlert>}
                {graphOptions.map(option => (
                  <Stack key={option} direction='row' alignItems='center'>
                    <Checkbox
                      size='lg'
                      isChecked={dashboardReports.includes(option)}
                      onChange={() => toggleSelect(option)}
                      aria-label='Select'
                    />
                    <Text>{startCase(option)}</Text>
                  </Stack>
                ))}
              </Stack>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}
