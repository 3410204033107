import TableWrapper from 'components/table/TableWrapper'

import { GET_ALL_TICKETS } from 'apollo/queries/tickets'
import {
  selectedTicketsVar,
  ticketFilterWhereVar,
  ticketFilterOrderByVar
} from 'utils/cache'

import fields from 'components/tickets/fields'

import TicketsSelectionMenu from 'components/tickets/select/TicketsSelectionMenu'

import CreateUserTicket from 'components/tickets/CreateUserTicket'

import {
  Modal,
  Button,
  ModalBody,
  ButtonGroup,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalCloseButton
} from '@chakra-ui/react'

export default function ViewUserTickets ({ inline = false, ...props }) {
  return inline ? <ViewUserTicketsInline {...props} /> : <OpenUserTicketsTrigger {...props} />
}

function ViewUserTicketsInline ({ user }) {
  const additionalWhere = {
    assignedToUser: {
      id: { equals: user.id }
    }
  }

  const hideFields = ['assignedToUser']

  return (
    <TableWrapper
      isEmbedded
      query={GET_ALL_TICKETS}
      fields={fields}
      hideFields={hideFields}
      rowKey='id'
      rowType='ticket'
      fuzzyKey='tickets'
      defaultOrderBy={[{ date: 'desc' }]}
      additionalWhere={additionalWhere}
      filterWhereVar={ticketFilterWhereVar}
      filterOrderByVar={ticketFilterOrderByVar}
      selectionVar={selectedTicketsVar}
      selectionComponent={TicketsSelectionMenu}
    />
  )
}

function OpenUserTicketsTrigger ({ user }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      {isOpen && <OpenUserTickets user={user} onClose={onClose} />}
      <ButtonGroup isAttached variant='outline'>
        <Button mr='-px' onClick={onOpen} size='xs' variant='outline' isDisabled={user?.assignedToTickets?.length === 0}>
          {user?.assignedToTickets?.length || 0} | Tickets
        </Button>
        <CreateUserTicket user={user} />
      </ButtonGroup>
    </>
  )
}

export function OpenUserTickets ({ user, onClose }) {
  return (
    <Modal isOpen onClose={onClose} size='' scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent pb='6' width='80vw'>
        <ModalHeader>Tickets</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ViewUserTickets inline user={user} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
