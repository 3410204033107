import {
  Grid,
  Text,
  Stack
} from '@chakra-ui/react'

import ReportTypesFilter from 'components/reports/ReportTypesFilter'
import StatsWrapper from 'components/_charts/StatsWrapper'

import { startCase } from 'lodash'

import {
  TOPUPS_REPORT,
  REMAINING_TOPUPS_REPORT,
  NEW_CUSTOMERS_REPORT,
  NEW_SERVICES_REPORT,
  TRANSACTIONS_REPORT,
  USER_ACTIVITIES_REPORT,
  CUSTOMER_CREDIT_REPORT,
  CREDITS_PURCHASED_REPORT,
  SERVICE_TYPES_CREDITS,
  ACTIVE_SERVICES
} from 'apollo/queries/reports'

import ServiceTypesFilter from 'components/reports/ServiceTypesFilter'
import ProductTypesFilter from 'components/reports/ProductTypesFilter'
import StoresFilter from 'components/reports/StoresFilter'
import TransactionTypesFilter from 'components/reports/TransactionTypesFilter'
import UsersReports from 'components/reports/UsersReports'
import CustomersReports from 'components/reports/CustomersReports'
import ActivityTypesFilter from 'components/reports/ActivityTypesFilter'
import LanguagesFilter from 'components/reports/LanguagesFilter'
import SourcesFilter from 'components/reports/SourcesFilter'
import PostalCodesFilter from 'components/reports/PostalCodesFilter'
import ShipmentsFilter from 'components/reports/ShipmentsFilter'
import UsersFilter from 'components/reports/UsersFilter'

const reportTypes = {
  TOPUPS: <StatsWrapper query={TOPUPS_REPORT} additionalFilterComponents={[ServiceTypesFilter, UsersFilter]} />,
  REMAINING_TOPUPS: <StatsWrapper query={REMAINING_TOPUPS_REPORT} additionalFilterComponents={[ServiceTypesFilter]} minFilterRequired={1} hiddenPeriods={['DAY', 'SEMIYEAR', 'YEAR', 'FULL']} />,
  NEW_CUSTOMERS: <StatsWrapper query={NEW_CUSTOMERS_REPORT} additionalFilterComponents={[StoresFilter, LanguagesFilter, SourcesFilter, PostalCodesFilter, UsersFilter]} />,
  NEW_SERVICES: <StatsWrapper query={NEW_SERVICES_REPORT} additionalFilterComponents={[StoresFilter, ServiceTypesFilter, ProductTypesFilter, UsersFilter]} />,
  TRANSACTIONS: <StatsWrapper query={TRANSACTIONS_REPORT} additionalFilterComponents={[TransactionTypesFilter, ServiceTypesFilter, ShipmentsFilter]} />,
  USER_ACTIVITIES: <UsersReports query={USER_ACTIVITIES_REPORT} additionalFilterComponents={[ActivityTypesFilter]} />,
  CUSTOMER_CREDITS: <CustomersReports query={CUSTOMER_CREDIT_REPORT} additionalFilterComponents={[TransactionTypesFilter, ServiceTypesFilter]} />,
  CREDITS_PURCHASED: <StatsWrapper query={CREDITS_PURCHASED_REPORT} additionalFilterComponents={[TransactionTypesFilter, ServiceTypesFilter, ShipmentsFilter]} />,
  SERVICE_TYPE_CREDITS: <StatsWrapper query={SERVICE_TYPES_CREDITS} dataKey='label' isBarChart />,
  ACTIVE_SERVICES: <StatsWrapper query={ACTIVE_SERVICES} dataKey='label' isBarChart />

}

export default function Reports ({ reportType }) {
  if (reportType && reportTypes[reportType]) {
    const Report = reportTypes[reportType]
    return (
      <Stack direction='row' width='100%'>
        <ReportTypesFilter reportTypes={Object.keys(reportTypes)} />
        <Stack id='capture' width='100%' px='4' overflowY='auto'>
          <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
            <Text fontWeight='bold'>{startCase(reportType)}</Text>
          </Stack>
          {Report}
        </Stack>
      </Stack>
    )
  } else {
    return (
      <Stack direction='row' width='100%'>
        <ReportTypesFilter reportTypes={Object.keys(reportTypes)} />
        <Grid placeItems='center' width='100%'>
          <Text fontSize='4xl'>👈 Choose a Report Type</Text>
        </Grid>
      </Stack>
    )
  }
}
