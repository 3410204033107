import EditableInput from 'components/_inputs/EditableInput'

import { string } from 'yup'

import useMutation from 'hooks/useMutation'
import { UPDATE_SERVICE_NOTE_DETAILS } from 'apollo/mutations/services'

export default function EditServiceNoteDetails ({ note }) {
  const [updateServiceNoteDetails, { error, loading, reset }] = useMutation(UPDATE_SERVICE_NOTE_DETAILS, {
    notification: 'Successfully updated the note details'
  })

  const handleSubmit = details => {
    const data = { id: note.id, details }
    return updateServiceNoteDetails({ variables: { data } })
  }

  return (
    <EditableInput
      name='details'
      type='textarea'
      value={note.details.trim() || ''}
      label='Note Details'
      onSubmit={handleSubmit}
      validation={string().required()}
      isLoading={loading}
      error={error}
      reset={reset}
    />
  )
}
