import useMutation from 'hooks/useMutation'
import { UPDATE_CUSTOMER_JOINED_DATE } from 'apollo/mutations/customers'

import DateTimePicker from 'components/_inputs/DateTimePicker'

export default function EditCustomerJoinedDate ({ customer, inline = false }) {
  const [updateJoinedDate, { error, loading, reset }] = useMutation(UPDATE_CUSTOMER_JOINED_DATE, {
    notification: 'Successfully updated the joined date'
  })

  const handleSubmit = joinedDate => {
    const data = { id: customer.id, joinedDate }
    return updateJoinedDate({ variables: { data } })
  }

  return (
    <DateTimePicker
      inline={inline}
      label='Joined Date'
      value={customer.joinedDate}
      onChange={handleSubmit}
      error={error}
      loading={loading}
      reset={reset}
    />
  )
}
