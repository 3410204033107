import { Stack } from '@chakra-ui/react'

import FuzzySearch from 'components/_filters/FuzzySearch'
import AdvancedSearch from 'components/_filters/AdvancedSearch'

export default function Search (props) {
  const {
    refetch,
    isRefetching,
    filterVar,
    fuzzySearchVar,
    isEmbedded
  } = props

  return (
    <Stack direction='row' width='100%' alignItems='center'>
      <FuzzySearch
        refetch={refetch}
        isRefetching={isRefetching}
        fuzzySearchVar={fuzzySearchVar}
        filterVar={filterVar}
      />
      {!isEmbedded && (
        <AdvancedSearch
          refetch={refetch}
          filterVar={filterVar}
        />
      )}
    </Stack>
  )
}
