import { useState, useEffect } from 'react'

import useResponsiveDevice from 'hooks/useResponsiveDevice'

import useQuery from 'hooks/useQuery'

import Loading from 'components/_common/Loading'

import CustomAreaChart from 'components/_charts/CustomAreaChart'
import CustomBarChart from 'components/_charts/CustomBarChart'

import { startCase } from 'lodash'

import {
  Box,
  Text,
  Grid,
  Stack,
  Alert,
  Button,
  AlertIcon
} from '@chakra-ui/react'

import SimpleTable from 'components/table/SimpleTable'

export default function StatsWrapper (props) {
  const {
    query,
    defaultVariables = {},
    additionalFilterComponents = [],
    minFilterRequired = 0,
    hiddenPeriods = [],
    height = '900px',
    dataKey = 'day',
    isBarChart = false
  } = props

  const { isDesktop, responsive } = useResponsiveDevice()

  const [period, setPeriod] = useState('WEEK')
  const [additionalVariables, setAdditionalVariables] = useState(defaultVariables)

  const skip = Object.values(additionalVariables).filter(Boolean).length < minFilterRequired

  const { data: { stats = [] } = {}, error, loading, refetch, isRefetching, called } = useQuery(query, {
    variables: { data: { period, ...additionalVariables } },
    skip
  })

  useEffect(() => {
    if (!skip && called) {
      refetch({ ...additionalVariables })
    }
    // eslint-disable-next-line
  }, [additionalVariables])

  useEffect(() => {
    if (!skip && called) {
      refetch({ period })
    }
    // eslint-disable-next-line
  }, [period])

  const handleRetry = async () => {
    refetch({ period: 'WEEK' })
  }

  const fields = [
    { key: dataKey, label: startCase(dataKey), width: '130px', renderTD: ({ stat }) => <Text>{stat[dataKey]}</Text> },
    { key: 'count', label: 'Count', width: '120px', justifyContent: 'center', renderTD: ({ stat }) => <Text>{stat.count}</Text> }
  ]

  const periods = [
    { range: 'DAY', label: 'Last 24 Hours' },
    { range: 'WEEK', label: 'Last 7 Days' },
    { range: 'MONTH', label: 'Last 30 Days' },
    { range: 'SEMIYEAR', label: 'Last 6 Months' },
    { range: 'YEAR', label: 'Last year' },
    { range: 'FULL', label: 'Lifetime' }
  ].filter(({ range }) => !hiddenPeriods.includes(range))

  return (
    <Stack width='100%'>
      <Stack width='100%' spacing='2'>
        <Stack direction='row' alignItems='center'>
          {additionalFilterComponents.map((FilterComponent, idx) => (
            <Box key={idx} w='220px'>
              <FilterComponent key={idx} additionalVariables={additionalVariables} setAdditionalVariables={setAdditionalVariables} />
            </Box>
          ))}
          {skip && <Text>👈 Choose a filter</Text>}
        </Stack>
        {!skip && (loading || isRefetching) && <Loading />}
        {error && (
          <Alert status='error' borderRadius='lg'>
            <AlertIcon />{error.message}
            <Button onClick={handleRetry} isLoading={isRefetching}>Retry</Button>
          </Alert>
        )}
      </Stack>
      <Grid width='100%' templateColumns={responsive(['1fr', '3fr 2fr'])} gap='2' overflow='auto' justifyItems='center'>
        {isDesktop && (
          <Stack width='100%' height={height}>
            {!isBarChart && <CustomAreaChart data={stats} />}
            {isBarChart && <CustomBarChart data={stats} />}
          </Stack>
        )}
        <Stack maxW='270px' alignItems='center'>
          {!isBarChart && (
            <Stack>
              {periods.map(({ range, label }) => (
                <Button
                  key={range}
                  aria-label={label}
                  onClick={() => setPeriod(range)}
                  variant={period === range ? 'solid' : 'outline'}
                  size={responsive(['xs', 'sm'])}
                >
                  <Text>{label}</Text>
                </Button>
              ))}
              <Text textAlign='center' fontWeight='bold' fontSize='3xl'>
                Total: {stats.reduce((total, { count }) => total + (count || 0), 0)}
              </Text>
            </Stack>
          )}
          <SimpleTable
            rows={stats.slice(0).reverse()}
            fields={fields}
            rowKey={dataKey}
            rowType='stat'
            minWidth='100px'
          />
        </Stack>
      </Grid>
    </Stack>
  )
}
