import useAuthUser from 'hooks/useAuthUser'

import { Box } from '@chakra-ui/react'

import Select from 'components/_select/CustomSelect'

export default function StoreDropdown () {
  const authUser = useAuthUser()

  const handleSelectStore = ({ value }) => {
    window.localStorage.setItem('ACTIVE_STORE', value)
    window.location.reload()
  }

  const options = authUser.stores.map(store => ({ value: store.id, label: store.name }))
  if (authUser.role === 'ADMIN') {
    options.unshift({ value: 'ALL', label: 'All Stores' })
  }

  return (
    <Box my='1'>
      <Select
        isDark
        options={options}
        onChange={handleSelectStore}
        defaultValue={options.find(({ value }) => value === window.localStorage.getItem('ACTIVE_STORE'))}
        maxMenuHeight='200px'
        menuPlacement='top'
      />
    </Box>
  )
}
