import { useState } from 'react'

import EditableInputInline from 'components/_inputs/EditableInput/EditableInputInline'

import {
  Text,
  Modal,
  Stack,
  ModalBody,
  IconButton,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalCloseButton
} from '@chakra-ui/react'

import { BiEdit } from 'react-icons/bi'

export default function EditableInputDialogTrigger ({ reset, justifyContent = 'left', ...inputProps }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [isEditVisible, setIsEditVisible] = useState(false)

  const marginOffset = isEditVisible && justifyContent === 'center' ? '8' : ''

  const handleClose = () => {
    reset()
    onClose()
  }

  return (
    <>
      {isOpen && <EditableInputDialog {...inputProps} onClose={handleClose} />}
      <Stack
        direction='row'
        alignItems='center'
        width='100%'
        onMouseEnter={() => setIsEditVisible(true)}
        onMouseLeave={() => setIsEditVisible(false)}
        justifyContent={justifyContent}
      >
        <Text
          fontSize='sm'
          ml={marginOffset}
          overflowX='hidden'
        >
          {inputProps.value || '-'}
        </Text>
        {isEditVisible && (
          <IconButton
            size='xs'
            variant='outline'
            icon={<BiEdit fontSize='16px' />}
            onClick={onOpen}
          />
        )}
      </Stack>
    </>
  )
}

function EditableInputDialog ({ onClose, label, ...inputProps }) {
  return (
    <Modal isOpen onClose={onClose} scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent pb='6'>
        <ModalHeader>{label}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <EditableInputInline {...inputProps} isInitiallyEditing onDialogClose={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
