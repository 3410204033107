import useMutation from 'hooks/useMutation'
import { PUT_SERVICE_ON_HOLD, REMOVE_SERVICE_ON_HOLD } from 'apollo/mutations/services'

import { Switch } from '@chakra-ui/react'

import { format, addYears } from 'date-fns'

export default function EditServiceOnHold ({ service, justifyContent, size = 'sm', inline = false, textAlign = 'left' }) {
  const [putServiceOnHold] = useMutation(PUT_SERVICE_ON_HOLD, {
    notification: 'Successfully put the service on hold',
    isPromise: false
  })

  const [removeServiceOnHold] = useMutation(REMOVE_SERVICE_ON_HOLD, {
    notification: 'Successfully removed the service from on hold',
    isPromise: false
  })

  const handleOnChange = e => {
    if (e.target.checked) {
      putServiceOnHold({ variables: { data: { id: service.id, onHoldDate: format(addYears(new Date(), 50), 'yyyy-MM-dd') + 'T04:00:00.000Z' } } })
    } else {
      removeServiceOnHold({ variables: { id: service.id } })
    }
  }

  return (
    <Switch
      colorScheme='red'
      size={size}
      isChecked={service.putOnHold}
      aria-label='Manage service on hold'
      onChange={handleOnChange}
    />
  )
}
