import useAuthUser from 'hooks/useAuthUser'

import TableWrapper from 'components/table/TableWrapper'

import { GET_ALL_SERVICES } from 'apollo/queries/services'
import { selectedServicesVar, serviceFilterWhereVar, serviceFilterOrderByVar } from 'utils/cache'

import fields from 'components/services/fields'

import ServicesSelectionMenu from 'components/services/select/ServicesSelectionMenu'

import { doesUserHavePermissionType } from 'utils/permissions'

const additionalWhere = {}
if (window.localStorage.getItem('ACTIVE_STORE') !== 'ALL') {
  additionalWhere.customer = { store: { id: { equals: window.localStorage.getItem('ACTIVE_STORE') } } }
}

export default function Services () {
  const authUser = useAuthUser()

  const hideTopUp = !doesUserHavePermissionType(authUser, 'SPECIAL_TOP_UP')

  const hideFields = []
  if (hideTopUp) hideFields.push('topUp')

  return (
    <TableWrapper
      query={GET_ALL_SERVICES}
      fields={fields}
      hideFields={hideFields}
      rowKey='id'
      rowType='service'
      fuzzyKey='services'
      defaultOrderBy={[{ createdDate: 'desc' }]}
      additionalWhere={additionalWhere}
      filterWhereVar={serviceFilterWhereVar}
      filterOrderByVar={serviceFilterOrderByVar}
      selectionVar={selectedServicesVar}
      selectionComponent={ServicesSelectionMenu}
    />
  )
}
