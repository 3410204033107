import {
  Text,
  Stack,
  Button
} from '@chakra-ui/react'

export default function LoadMoreChats (props) {
  const {
    chatsCount,
    filteredCount,
    totalCount,
    fetchMore,
    hasMoreItems,
    isRefetchingMore
  } = props

  return (
    <Stack direction='row' alignItems='center' flex='1'>
      <Stack justifyContent='space-evenly' direction='row' minW='300px'>
        <Text fontSize='sm'>Showing: <strong>{chatsCount}</strong></Text>
        <Text fontSize='sm'>Filtered: <strong>{filteredCount}</strong></Text>
        <Text fontSize='sm'>Total: <strong>{totalCount}</strong></Text>
      </Stack>
      <Button
        isFullWidth
        variant='outline'
        size='sm'
        onClick={fetchMore}
        isLoading={isRefetchingMore}
        my={totalCount > 0 ? '2' : '0'}
        isDisabled={!(hasMoreItems && chatsCount >= 20)}
        minW='200px'
      >
        Load More
      </Button>
    </Stack>
  )
}
