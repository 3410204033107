import EditableInput from 'components/_inputs/EditableInput'

import { string } from 'yup'

import useMutation from 'hooks/useMutation'
import { UPDATE_CUSTOMER_FULL_NAME } from 'apollo/mutations/customers'

export default function EditCustomerFullName ({ customer, inline = false, ...props }) {
  const [updateFullName, { error, loading, reset }] = useMutation(UPDATE_CUSTOMER_FULL_NAME, {
    notification: 'Successfully updated the full name'
  })

  const handleSubmit = fullName => {
    const data = { id: customer.id, fullName }
    return updateFullName({ variables: { data } })
  }

  return (
    <EditableInput
      inline={inline}
      name='fullName'
      value={customer.fullName || ''}
      label='Full Name'
      onSubmit={handleSubmit}
      validation={string().required()}
      isLoading={loading}
      error={error}
      reset={reset}
      {...props}
    />
  )
}
