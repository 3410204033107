import { gql } from '@apollo/client'

export const BILLING_PANEL_UPDATED = gql`
  subscription BILLING_PANEL_UPDATED {
    billingPanelUpdated {
      id
    }
  }
`

export const CHAT_UPDATED = gql`
  subscription CHAT_UPDATED ($id: String!) {
    chatUpdated (id: $id)
  }
`

export const NEW_CHAT = gql`
  subscription NEW_CHAT ($id: String!) {
    newChat (id: $id) {
      id
      message
      read
      fromUser {
        id
        fullName
      }
      createdAt
    }
  }
`
