import { useState } from 'react'

import {
  Input,
  Button,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react'

import { BiSend } from 'react-icons/bi'

import useMutation from 'hooks/useMutation'
import { SEND_CHAT } from 'apollo/mutations/chats'

export default function SendMessage ({ toUserID, conversationID, refetch }) {
  const [sendChat, { loading, reset }] = useMutation(SEND_CHAT, {
    notification: 'Message Sent'
  })

  const [message, setMessage] = useState('')

  const handleSubmit = (e) => {
    if (message) {
      e.preventDefault()
      const data = { toUserID, conversationID, message }
      sendChat({ variables: { data } })
        .then(() => {
          reset()
          setMessage('')
          refetch()
        })
        .catch(console.log)
    }
  }

  return (
    <InputGroup size='lg' borderRadius='lg' bg='white' as='form' onSubmit={handleSubmit}>
      <Input
        height='80px'
        pr='4.5rem'
        placeholder='Enter new message ...'
        value={message}
        onChange={e => setMessage(e.target.value)}
        border='2px solid purple !important'
      />
      <InputRightElement width='6.5rem' pr='2' height='100%'>
        <Button size='md' rightIcon={<BiSend />} type='submit' isLoading={loading}>
          Send
        </Button>
      </InputRightElement>
    </InputGroup>
  )
}
