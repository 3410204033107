import LanguageSelectCheckbox from 'components/languages/select/LanguageSelectCheckbox'
import DateTimeRenderer from 'components/_common/DateTimeRenderer'
import EditLanguageName from 'components/languages/edit/EditLanguageName'
import EditLanguageDetails from 'components/languages/edit/EditLanguageDetails'
import SendLanguageMessage from 'components/languages/SendLanguageMessage'

const fields = [
  { key: 'select', width: '40px', renderTD: LanguageSelectCheckbox },
  { key: 'name', label: 'Name', width: '250px', renderTD: EditLanguageName },
  { key: 'details', label: 'Details', width: '250px', renderTD: EditLanguageDetails },
  { key: 'message', label: 'Message', width: '250px', justifyContent: 'center', renderTD: SendLanguageMessage },
  { key: 'updatedAt', label: 'Updated At', renderTD: ({ language: { updatedAt } }) => <DateTimeRenderer value={updatedAt} type='time' /> },
  { key: 'createdAt', label: 'Created At', renderTD: ({ language: { createdAt } }) => <DateTimeRenderer value={createdAt} type='time' /> }
]

export default fields
