import TableWrapper from 'components/table/TableWrapper'
import ShipmentsSelectionMenu from 'components/shipments/select/ShipmentsSelectionMenu'
import CreateShipment from 'components/shipments/CreateShipment'

import { GET_ALL_SHIPMENTS } from 'apollo/queries/shipments'
import { selectedShipmentsVar, shipmentFilterWhereVar, shipmentFilterOrderByVar } from 'utils/cache'

import fields from 'components/shipments/fields'

export default function Shipments () {
  return (
    <TableWrapper
      query={GET_ALL_SHIPMENTS}
      fields={fields}
      rowKey='id'
      rowType='shipment'
      fuzzyKey='shipments'
      defaultOrderBy={[{ name: 'asc' }]}
      filterWhereVar={shipmentFilterWhereVar}
      filterOrderByVar={shipmentFilterOrderByVar}
      createComponent={CreateShipment}
      selectionComponent={ShipmentsSelectionMenu}
      selectionVar={selectedShipmentsVar}
    />
  )
}
