import { startCase } from 'lodash'

import { Badge } from '@chakra-ui/react'

export default function TicketStatusBadge ({ status }) {
  return (
    <Badge
      colorScheme={status === 'RESOLVED' ? 'green' : status === 'IN_PROGRESS' ? 'orange' : 'red'}
      borderRadius='lg'
    >
      {startCase(status)}
    </Badge>
  )
}
