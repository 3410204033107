import { gql } from '@apollo/client'

export const GET_ALL_LOGIN_APPROVALS = gql`
  query GET_ALL_LOGIN_APPROVALS ($where: LoginApprovalWhereInput $orderBy: [LoginApprovalOrderByInput!] $take: Int $skip: Int) {
    response: getAllLoginApprovals (where: $where orderBy: $orderBy take: $take skip: $skip) {
      allData: loginApprovals {
        id
        requestedAt
        requestedUser {
          id
          fullName
          role
          isDeleted
        }
        agentDetails
        status
        expiresAt
        processedAdmin {
          id
          fullName
          role
          isDeleted
        }
        approvedAt
      }
      metrics {
        allCount
        filteredCount
      }
    }
  }
`

export const GET_ONE_LOGIN_APPROVAL = gql`
  query GET_ONE_LOGIN_APPROVAL ($id: String!) {
    loginApproval: getOneLoginApproval (id: $id) {
      id
      requestedAt
      requestedUser {
        id
        fullName
        role
        isDeleted
      }
      agentDetails
      status
    }
  }
`
