import EditableInput from 'components/_inputs/EditableInput'

import { number } from 'yup'

import useMutation from 'hooks/useMutation'
import { UPDATE_SERVICE_TYPE_TOPUP_DUE_DAYS } from 'apollo/mutations/serviceTypes'

export default function EditServiceTypeTopupDueDays ({ serviceType, justifyContent }) {
  const [updateTopupDueDays, { error, loading, reset }] = useMutation(UPDATE_SERVICE_TYPE_TOPUP_DUE_DAYS, {
    notification: 'Successfully updated the topupDueDays'
  })

  const handleSubmit = topupDueDays => {
    const data = { id: serviceType.id, topupDueDays }
    return updateTopupDueDays({ variables: { data } })
  }

  return (
    <EditableInput
      name='topupDueDays'
      type='number'
      numberInputProps={{ min: 1, max: 30 }}
      value={serviceType.topupDueDays || ''}
      label='Topup Due Days'
      onSubmit={handleSubmit}
      validation={number().required().integer().min(1).max(30)}
      isLoading={loading}
      error={error}
      reset={reset}
      justifyContent={justifyContent}
    />
  )
}
