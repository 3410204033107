import { useState } from 'react'

import { useMutation } from '@apollo/client'

import { Text, useToast } from '@chakra-ui/react'

import AlertDialog from 'components/_common/AlertDialog'

const sessionExpiredMessage = {
  render: () => <AlertDialog status='error'><Text>Your session has expired, please login again</Text></AlertDialog>,
  position: 'top',
  duration: null,
  isClosable: false
}

export default function useCustomMutation (query, { notification, isPromise = true, ...options } = {}) {
  const toast = useToast()

  const handleError = error => {
    if (error?.message && error?.message.includes('token')) {
      toast(sessionExpiredMessage)
    } else {
      notification && toast({
        title: 'Error',
        description: error?.message,
        status: 'error',
        position: 'top',
        duration: 4000,
        isClosable: true
      })
    }
  }

  const [mutationState, setMutationState] = useState({ data: undefined, error: undefined, loading: false })

  const [mutateFunction] = useMutation(query, { ...options, onCompleted: undefined, onError: undefined })

  const mutateWrapper = async (opts) => {
    try {
      setMutationState({ data: undefined, loading: true, error: undefined })
      const response = await mutateFunction(opts)
      const { data, errors } = response
      if (errors && errors.length > 0) {
        handleError(errors[0])
        setMutationState({ data: undefined, loading: false, error: errors[0] })
        options.onError && options.onError(errors[0])
        return isPromise ? Promise.reject(errors[0]) : errors[0]
      } else if (data) {
        notification && toast({
          title: notification,
          status: 'success',
          position: 'top',
          duration: 3000,
          isClosable: true
        })
        setMutationState({ data, loading: false, error: undefined })
        options.onCompleted && options.onCompleted(data)
        return isPromise ? Promise.resolve(data) : data
      }
      return isPromise ? Promise.resolve(response) : response
    } catch (error) {
      const parsedError = JSON.parse(JSON.stringify(error))
      let errorData = parsedError
      if (parsedError.graphQLErrors) {
        handleError(parsedError.graphQLErrors[0])
      }
      if (parsedError.networkError && parsedError.networkError.result) {
        parsedError.networkError.result.errors.forEach(handleError)
        errorData = parsedError.networkError.result.errors[0]
      }
      options.onError && options.onError(errorData)
      setMutationState({ data: undefined, loading: false, error: errorData })
      return isPromise ? Promise.reject(errorData) : errorData
    }
  }

  const reset = () => {
    setMutationState({ data: undefined, loading: false, error: undefined })
  }

  return [mutateWrapper, { ...mutationState, reset }]
}
