import TableWrapper from 'components/table/TableWrapper'
import UsersSelectionMenu from 'components/users/select/UsersSelectionMenu'
import CreateUser from 'components/users/CreateUser'

import { GET_ALL_USERS } from 'apollo/queries/users'
import { selectedUsersVar, userFilterWhereVar, userFilterOrderByVar } from 'utils/cache'

import fields from 'components/users/fields'

export default function Users () {
  return (
    <TableWrapper
      query={GET_ALL_USERS}
      fields={fields}
      rowKey='id'
      rowType='user'
      fuzzyKey='users'
      defaultOrderBy={[{ fullName: 'asc' }]}
      filterWhereVar={userFilterWhereVar}
      filterOrderByVar={userFilterOrderByVar}
      createComponent={CreateUser}
      selectionComponent={UsersSelectionMenu}
      selectionVar={selectedUsersVar}
    />
  )
}
