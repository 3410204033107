import StoreSelectCheckbox from 'components/stores/select/StoreSelectCheckbox'
import DateTimeRenderer from 'components/_common/DateTimeRenderer'
import EditStoreName from 'components/stores/edit/EditStoreName'
import EditStoreLocation from 'components/stores/edit/EditStoreLocation'
import SendStoreMessage from 'components/stores/SendStoreMessage'

const fields = [
  { key: 'select', width: '40px', renderTD: StoreSelectCheckbox },
  { key: 'name', label: 'Name', width: '250px', renderTD: EditStoreName },
  { key: 'location', label: 'Location', width: '250px', renderTD: EditStoreLocation },
  { key: 'message', label: 'Message', width: '250px', justifyContent: 'center', renderTD: SendStoreMessage },
  { key: 'updatedAt', label: 'Updated At', renderTD: ({ store: { updatedAt } }) => <DateTimeRenderer value={updatedAt} type='time' /> },
  { key: 'createdAt', label: 'Created At', renderTD: ({ store: { createdAt } }) => <DateTimeRenderer value={createdAt} type='time' /> }
]

export default fields
