import { gql } from '@apollo/client'

export const GET_ALL_SERVICE_TYPES = gql`
  query GET_ALL_SERVICE_TYPES ($where: ServiceTypeWhereInput $orderBy: [ServiceTypeOrderByInput!] $take: Int $skip: Int) {
    response: getAllServiceTypes (where: $where orderBy: $orderBy take: $take skip: $skip) {
      allData: serviceTypes {
        id
        name
        topupDueDays
        reminderDays
        color
        order
        details
        updatedAt
        createdAt
      }
      metrics {
        allCount
        filteredCount
      }
    }
  }
`
