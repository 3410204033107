import Select from 'components/_select/CustomSelect'

import { startCase } from 'lodash'

export default function ActivityTypesFilter ({ additionalVariables, setAdditionalVariables, isDisabled = false }) {
  const activityTypes = [
    'TOP_UP_SERVICE',
    'CREATE_SERVICE',
    'CREATE_CUSTOMER',
    'CREATE_TICKET',
    'CREATE_SOURCE',
    'CREATE_SHIPMENT',
    'CREATE_LANGUAGE',
    'CREATE_SERVICE_TYPE',
    'CREATE_PRODUCT_TYPE',
    'CREATE_USER',
    'APPROVE_LOGIN_APPROVAL',
    'UPDATE_SERVICE_EXPIRY_DATE',
    'UPDATE_TRANSACTION_TYPE',
    'MERGE_CUSTOMERS',
    'UPDATE_CUSTOMER_FULL_NAME',
    'UPDATE_TRANSACTION_INVOICE_NUMBER',
    'REMOVE_SERVICE_ON_HOLD',
    'DELETE_SUPPORT_TICKET',
    'UPDATE_SERVICE_MAC_ID',
    'UPDATE_SERVICE_TYPE_TOPUP_DUE_DAYS',
    'UPDATE_SERVICE_NOTE_DETAILS',
    'UPDATE_TICKET_STATUS',
    'ADD_CUSTOMER_PHONE_NUMBER',
    'UPDATE_CUSTOMER_POSTAL_CODE',
    'UPDATE_CUSTOMER_JOINED_DATE',
    'UPDATE_SERVICE_CREATED_DATE',
    'SEPERATE_CUSTOMER_FROM_SERVICE',
    'ADD_TICKET_NOTE',
    'UPDATE_PRODUCT_TYPE_NAME',
    'UPDATE_TICKET_ASSIGNED_TO',
    'DELETE_SERVICE',
    'UPDATE_CUSTOMER_LANGUAGE',
    'UPDATE_CUSTOMER_SOURCE',
    'ADD_SERVICE_NOTE',
    'UPDATE_TRANSACTION_SHIPMENT',
    'UPDATE_SERVICE_CREDITS',
    'TOGGLE_USER_PERMISSION',
    'UPDATE_CUSTOMER_PRIMARY_PHONE_NUMBER',
    'UPDATE_SERVICE_INACTIVE',
    'UPDATE_SERVICE_IS_MONTHLY',
    'UPDATE_SERVICE_SERVICE_TYPE',
    'PUT_SERVICE_ON_HOLD',
    'ADD_TRANSACTION_NOTE',
    'UPDATE_SERVICE_TYPE_COLOR',
    'UPDATE_SOURCE_NAME',
    'UPDATE_SERVICE_PRODUCT_TYPE',
    'UPDATE_LANGUAGE_NAME'
  ]

  const options = activityTypes.map(activityType => ({ value: activityType, label: startCase(activityType) }))
  options.unshift({ value: undefined, label: 'All Activity Types' })

  const handleSelectActivityType = ({ value: activityType }) => {
    setAdditionalVariables({ ...additionalVariables, activityType })
  }

  return (
    <Select
      value={options.find(({ value }) => value === additionalVariables.activityType)}
      options={options}
      onChange={handleSelectActivityType}
      maxMenuHeight='300px'
      isDisabled={isDisabled}
    />
  )
}
