import { MdDashboard, MdStore, MdAttachMoney, MdDevices, MdLanguage } from 'react-icons/md'
import { FaUsers, FaUsersCog, FaInbox, FaServicestack, FaShippingFast } from 'react-icons/fa'
import { BiSupport, BiShieldQuarter } from 'react-icons/bi'
import { FiActivity } from 'react-icons/fi'
import { FcMoneyTransfer } from 'react-icons/fc'
import { RiOpenSourceFill } from 'react-icons/ri'
import { BsChatDots } from 'react-icons/bs'
import { AiOutlineAreaChart } from 'react-icons/ai'

import { doesUserHavePermissionType } from 'utils/permissions'

import MyTicketsCount from './MyTicketsCount'
import TodaysTopupsCount from './TodaysTopupsCount'
import ChatNotification from './ChatNotification'

export default (authUser) => [
  {
    label: 'Dashboard',
    to: '/dashboard',
    icon: MdDashboard,
    isHidden: () => !doesUserHavePermissionType(authUser, 'VIEW_DASHBOARD')
  },
  {
    label: 'TopUps',
    to: '/topUps',
    icon: FcMoneyTransfer,
    rightIcon: <TodaysTopupsCount />,
    isHidden: () => !doesUserHavePermissionType(authUser, 'VIEW_TOPUPS')
  },
  {
    label: 'Customers',
    to: '/customers',
    icon: FaUsers,
    isHidden: () => !doesUserHavePermissionType(authUser, 'VIEW_CUSTOMERS')
  },
  {
    label: 'Services',
    to: '/services',
    icon: MdDevices,
    isHidden: () => !doesUserHavePermissionType(authUser, 'VIEW_SERVICES')
  },
  {
    label: 'My Tickets',
    to: '/myTickets',
    icon: BiSupport,
    rightIcon: <MyTicketsCount />,
    isHidden: () => !doesUserHavePermissionType(authUser, 'VIEW_MY_TICKETS')
  },
  {
    label: 'All Tickets',
    to: '/allTickets',
    icon: BiSupport,
    isHidden: () => !doesUserHavePermissionType(authUser, 'VIEW_ALL_TICKETS')
  },
  {
    label: 'Transactions',
    to: '/transactions',
    icon: MdAttachMoney,
    isHidden: () => !doesUserHavePermissionType(authUser, 'VIEW_TRANSACTIONS')
  },
  {
    label: 'Activities',
    to: '/activities',
    icon: FiActivity,
    isHidden: () => !doesUserHavePermissionType(authUser, 'VIEW_ACTIVITIES')
  },
  {
    label: 'Reports',
    to: '/reports',
    icon: AiOutlineAreaChart,
    isHidden: () => !doesUserHavePermissionType(authUser, 'VIEW_REPORTS')
  },
  {
    label: 'Users',
    to: '/users',
    icon: FaUsersCog,
    isHidden: () => !doesUserHavePermissionType(authUser, 'VIEW_USERS')
  },
  {
    label: 'Chat',
    to: '/chat',
    alternate: '/',
    icon: BsChatDots,
    rightIcon: <ChatNotification />
  },
  {
    label: 'Stores',
    to: '/stores',
    icon: MdStore,
    isHidden: () => !doesUserHavePermissionType(authUser, 'VIEW_STORES')
  },
  {
    label: 'Sources',
    to: '/sources',
    icon: RiOpenSourceFill,
    isHidden: () => !doesUserHavePermissionType(authUser, 'VIEW_SOURCES')
  },
  {
    label: 'Languages',
    to: '/languages',
    icon: MdLanguage,
    isHidden: () => !doesUserHavePermissionType(authUser, 'VIEW_LANGUAGES')
  },
  {
    label: 'Shipments',
    to: '/shipments',
    icon: FaShippingFast,
    isHidden: () => !doesUserHavePermissionType(authUser, 'VIEW_SHIPMENTS')
  },
  {
    label: 'Service Types',
    to: '/serviceTypes',
    icon: FaServicestack,
    isHidden: () => !doesUserHavePermissionType(authUser, 'VIEW_SERVICE_TYPES')
  },
  {
    label: 'Product Types',
    to: '/productTypes',
    icon: FaInbox,
    isHidden: () => !doesUserHavePermissionType(authUser, 'VIEW_PRODUCT_TYPES')
  },
  {
    label: 'Login Approvals',
    to: '/loginApprovals',
    icon: BiShieldQuarter,
    isHidden: () => authUser.role !== 'ADMIN'
  }
]
