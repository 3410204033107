import { useState, useEffect } from 'react'

import { SEND_BULK_CUSTOMER_MESSAGE } from 'apollo/mutations/customers'
import useMutation from 'hooks/useMutation'

import { startCase } from 'lodash'

import {
  Alert,
  Radio,
  Input,
  Stack,
  Button,
  Switch,
  Textarea,
  FormLabel,
  AlertIcon,
  RadioGroup,
  FormControl,
  useDisclosure,
  FormErrorMessage,
  AlertDescription
} from '@chakra-ui/react'

import { FiSend } from 'react-icons/fi'

import FormDialog from 'components/_common/FormDialog'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'

const schemaValidation = object().shape({
  subject: string(),
  message: string().required()
})

export default function SendBulkCustomerMessage ({ filterBy, filterID, filterLabel }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [type, setType] = useState()

  const handleClose = () => {
    onClose()
    resetMutation()
    resetForm()
  }

  const [sendBulkCustomerMessage, { error, loading, reset: resetMutation }] = useMutation(SEND_BULK_CUSTOMER_MESSAGE, {
    notification: 'Successfully sent the message',
    isPromise: false,
    onCompleted: () => {
      handleClose()
    }
  })

  const { register, handleSubmit, formState: { errors }, reset: resetForm, setFocus } = useForm({
    resolver: yupResolver(schemaValidation)
  })

  useEffect(() => { isOpen && setTimeout(() => setFocus('message'), 1) }, [isOpen])

  const onSubmit = ({ subject, message, includeInactive = false }) => {
    const data = { filterBy, filterID, subject, message, type, includeInactive }
    sendBulkCustomerMessage({ variables: { data } })
  }

  return (
    <>
      <Button size='xs' leftIcon={<FiSend />} onClick={onOpen} variant='outline'>
        Send Message
      </Button>
      {isOpen && (
        <FormDialog
          title='Send a Message'
          formID='#sendBulkCustomerMessage'
          submitLabel='Send'
          error={error}
          loading={loading}
          onClose={handleClose}
          isDisabled={!type}
          onSubmit={handleSubmit(onSubmit)}
          size='xl'
        >
          <Stack spacing='8'>
            <Alert status='warning' borderRadius='lg'>
              <AlertIcon />
              <AlertDescription>Notifying customers for <strong>{startCase(filterBy)}: {filterLabel}</strong></AlertDescription>
            </Alert>
            {type === 'EMAIL' && (
              <FormControl isRequired isInvalid={errors?.subject}>
                <FormLabel>Subject</FormLabel>
                <Input {...register('subject')} />
                <FormErrorMessage>{errors?.subject?.subject}</FormErrorMessage>
              </FormControl>
            )}
            <FormControl isRequired isInvalid={errors?.message}>
              <FormLabel>Message</FormLabel>
              <Textarea {...register('message')} rows={6} />
              <FormErrorMessage>{errors?.message?.message}</FormErrorMessage>
            </FormControl>
            <FormControl display='flex' alignItems='center'>
              <FormLabel htmlFor='inactive-users' mb='0' mr='2'>Include Inactive & Expired Users?</FormLabel>
              <Switch id='inactive-users' {...register('includeInactive')} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Notification Type</FormLabel>
              <RadioGroup onChange={setType} value={type}>
                <Stack direction='row' justifyContent='space-between' alignItems='center' mt='4' px='2'>
                  <Radio value='EMAIL'>EMAIL</Radio>
                  <Radio value='SMS'>SMS</Radio>
                  <Radio value='WHATSAPP'>WHATSAPP</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
          </Stack>
        </FormDialog>
      )}
    </>
  )
}
