import { useState } from 'react'

import useMutation from 'hooks/useMutation'
import { DELETE_LANGUAGE } from 'apollo/mutations/languages'

import {
  List,
  Stack,
  Modal,
  Button,
  ListItem,
  ListIcon,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton
} from '@chakra-ui/react'

import { MdLanguage } from 'react-icons/md'

import ErrorAlert from 'components/_common/ErrorAlert'

export default function DeleteLanguages ({ languages, onClose }) {
  const [loading, setLoading] = useState(false)
  const [deleteLanguage, { error }] = useMutation(DELETE_LANGUAGE, {
    notification: 'Successfully deleted the language(s)'
  })

  const count = Object.keys(languages).length

  const onDelete = async () => {
    setLoading(true)
    try {
      for (const id of Object.keys(languages)) {
        await deleteLanguage({ variables: { id } })
      }
      onClose(true)
    } catch (e) {
      console.log(e.message)
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    // close without clearing selection
    onClose()
  }

  return (
    <Modal isOpen onClose={handleClose} closeOnOverlayClick={false} scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Deleting {count} Language{count > 1 && 's'}</ModalHeader>
        <ModalCloseButton isDisabled={loading} />
        <ModalBody>
          <List spacing={3}>
            {Object.values(languages).map(language => (
              <ListItem key={language.id}>
                <ListIcon as={MdLanguage} />
                {language.name}
              </ListItem>
            ))}
          </List>
        </ModalBody>
        <ModalFooter>
          <Stack spacing='4' width='100%'>
            {error && <ErrorAlert> {error.message} </ErrorAlert>}
            <Button isFullWidth onClick={onDelete} isLoading={loading} colorScheme='red'>
              Delete
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
