import { useEffect } from 'react'

import useAuthUser from 'hooks/useAuthUser'

import {
  Button,
  Stack,
  FormLabel,
  FormControl,
  FormErrorMessage
} from '@chakra-ui/react'

import PasswordInput from 'components/_inputs/PasswordInput'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string, ref } from 'yup'

import useMutation from 'hooks/useMutation'
import { UPDATE_USER_PASSWORD } from 'apollo/mutations/users'

import FormDialog from 'components/_common/FormDialog'

import AlertDialog from 'components/_common/AlertDialog'

const schemaValidation = object().shape({
  password: string().required().min(3),
  confirmPassword: string().required().oneOf([ref('password'), null], 'Passwords do not match')
})

export default function ResetUserPassword ({ onClose }) {
  const authUser = useAuthUser()

  const handleClose = () => {
    resetMutation()
    resetForm()
    onClose()
  }

  const [updatePassword, { data, error, loading, reset: resetMutation }] = useMutation(UPDATE_USER_PASSWORD, {
    notification: null,
    isPromise: false
  })

  const { register, handleSubmit, formState: { errors }, reset: resetForm, setFocus } = useForm({
    resolver: yupResolver(schemaValidation)
  })

  useEffect(() => { setTimeout(() => setFocus('password'), 1) }, [])

  const onSubmit = ({ password }) => {
    const data = { id: authUser.id, password }
    updatePassword({ variables: { data } })
  }

  const onLogout = () => {
    window.localStorage.removeItem('AUTH_SESSION_ID')
    window.localStorage.removeItem('ACTIVE_STORE')
    window.location.href = '/login'
  }

  if (data?.updateUser) {
    return (
      <AlertDialog isCentered status='success'>
        You have reset your password <br />
        <Button mt='4' onClick={onLogout}>Logout</Button>
      </AlertDialog>
    )
  }

  return (
    <FormDialog
      title='Reset Password?'
      formID='#resetPassword'
      error={error}
      loading={loading}
      onClose={handleClose}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing='8'>
        <FormControl id='password' isRequired isInvalid={errors?.password}>
          <FormLabel>New Password</FormLabel>
          <PasswordInput {...register('password')} />
          <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
        </FormControl>
        <FormControl id='confirmPassword' isRequired isInvalid={errors?.confirmPassword}>
          <FormLabel>Confirm New Password</FormLabel>
          <PasswordInput {...register('confirmPassword')} />
          <FormErrorMessage>{errors?.confirmPassword?.message}</FormErrorMessage>
        </FormControl>
      </Stack>
    </FormDialog>
  )
}
