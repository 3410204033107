import { render } from 'react-dom'

import App from 'App'

import { ChakraProvider } from '@chakra-ui/react'
import theme from 'utils/theme'

import { ApolloProvider } from '@apollo/client/react'
import client from 'apollo/client'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

render(
  <ChakraProvider theme={theme}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </ChakraProvider>,
  document.getElementById('root')
)

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://cda449376a93493ba822412c1eabbb17@o249532.ingest.sentry.io/5430191',
    integrations: [
      new Integrations.BrowserTracing()
    ],
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    release: require('../package.json').version
  })
}
