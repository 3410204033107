import EditableInput from 'components/_inputs/EditableInput'

import { string } from 'yup'

import useMutation from 'hooks/useMutation'
import { UPDATE_TRANSACTION_INVOICE_NUMBER } from 'apollo/mutations/transactions'

export default function EditTransactionNumber ({ transaction }) {
  const [updateTransactionNumber, { error, loading, reset }] = useMutation(UPDATE_TRANSACTION_INVOICE_NUMBER, {
    notification: 'Successfully updated the invoice number'
  })

  const handleSubmit = transactionNumber => {
    const data = { id: transaction.id, transactionNumber }
    return updateTransactionNumber({ variables: { data } })
  }

  return (
    <EditableInput
      name='transactionNumber'
      value={transaction.transactionNumber || ''}
      label='Transaction Number'
      onSubmit={handleSubmit}
      validation={string().required()}
      isLoading={loading}
      error={error}
      reset={reset}
    />
  )
}
