import useMutation from 'hooks/useMutation'
import { UPDATE_TRANSACTION_DATE } from 'apollo/mutations/transactions'

import DateTimePicker from 'components/_inputs/DateTimePicker'

export default function EditTransactionDate ({ transaction }) {
  const [updateTransactionDate, { error, loading, reset }] = useMutation(UPDATE_TRANSACTION_DATE, {
    notification: 'Successfully updated the transaction date'
  })

  const handleSubmit = date => {
    const data = { id: transaction.id, date }
    return updateTransactionDate({ variables: { data } })
  }

  return (
    <DateTimePicker
      label='Transaction Date'
      value={transaction.date}
      onChange={handleSubmit}
      error={error}
      loading={loading}
      reset={reset}
    />
  )
}
