import {
  Link,
  Button
} from '@chakra-ui/react'

import { navigate } from 'hooks/useRoutes'

export default function SidebarLink ({ label, to, alternate, icon: Icon, isHidden = false, ...rest }) {
  return (
    <Button
      key={label}
      as={Link}
      href={to}
      onClick={event => navigate(event, to)}
      colorScheme=''
      bg={(window.location.pathname.startsWith(to) || window.location.pathname === alternate) ? 'purple.800' : ''}
      leftIcon={<Icon />}
      isFullWidth
      justifyContent='left'
      textTransform='uppercase'
      fontSize='xs'
      borderRadius='lg'
      minH='8'
      _hover={{ textDecoration: 'none', bg: 'purple.800' }}
      display={isHidden && isHidden() ? 'none' : 'inline-flex'}
      my='1'
      {...rest}
    >
      {label}
    </Button>
  )
}
