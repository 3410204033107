import { Checkbox } from '@chakra-ui/react'

export default function CustomCheckbox ({ isChecked, toggleSelect }) {
  return (
    <Checkbox
      size='lg'
      isChecked={isChecked}
      onChange={toggleSelect}
      aria-label='Select'
    />
  )
}
