import useResponsiveDevice from 'hooks/useResponsiveDevice'

import {
  Box,
  Text,
  Flex,
  Grid,
  Alert,
  Stack,
  Button,
  AlertIcon,
  IconButton
} from '@chakra-ui/react'

import { AiOutlineSortDescending, AiOutlineSortAscending } from 'react-icons/ai'

import Search from 'components/_filters/Search'

import { fuzzySearchVar } from 'utils/cache'

import { useReactiveVar } from '@apollo/client'

const TableHeaderCell = ({ children, isLeftEdge, isRightEdge, field, filterOrderByVar, refetch, ...rest }) => {
  const orderByField = filterOrderByVar().find(orderBy => orderBy.field === field)

  const sortBy = (value) => {
    filterOrderByVar(filterOrderByVar().map(orderBy => {
      if (orderBy.field === field) {
        return {
          ...orderBy,
          isActive: Boolean(value),
          value
        }
      } else {
        return {
          ...orderBy,
          // we're only allowing to sort by one field at this time
          isActive: false
        }
      }
    }))
    refetch()
  }

  const onSort = () => {
    if (orderByField.isActive) {
      if (orderByField.value === 'asc') {
        sortBy('desc')
      } else if (orderByField.value === 'desc') {
        sortBy(undefined)
      }
    } else {
      sortBy('asc')
    }
  }

  return (
    <Flex
      as='th'
      position='sticky'
      top='0px'
      bgColor='purple.50'
      zIndex={1}
      textTransform='uppercase'
      alignItems='center'
      minH='8'
      px='2'
      borderLeftRadius={isLeftEdge ? 'md' : ''}
      borderRightRadius={isRightEdge ? 'md' : ''}
      onClick={orderByField && onSort}
      cursor={orderByField ? 'pointer' : ''}
      {...rest}
    >
      {orderByField && orderByField.isActive && (
        <IconButton
          size='8px'
          variant='outline'
          border='none'
          mr='1'
          icon={orderByField.value === 'asc' ? <AiOutlineSortAscending fontSize='18px' /> : <AiOutlineSortDescending fontSize='18px' />}
        />
      )}
      {children}
    </Flex>
  )
}

const TableDataCell = ({ children, ...rest }) => (
  <Flex
    as='td'
    borderBottom='1px solid #E2E8F0'
    alignItems='center'
    minH='8'
    maxH='12'
    px='2'
    overflowY='auto'
    {...rest}
  >
    {children}
  </Flex>
)

export default function DataTable (props) {
  const { responsive } = useResponsiveDevice()

  const {
    rows,
    fields,
    hideFields = [],
    rowKey,
    rowType,
    refetch,
    isRefetching,
    hasMoreItems,
    fetchMore,
    isRefetchingMore,
    filterWhereVar,
    filterOrderByVar,
    createComponent: CreateRecord,
    selectionComponent: SelectionMenu,
    selectionVar,
    isEmbedded,
    filteredCount,
    totalCount
  } = props

  const selectedVar = SelectionMenu && useReactiveVar(selectionVar)

  const filteredFields = fields.filter(({ key }) => hideFields ? !hideFields.includes(key) : true)

  const gridTemplateColumns = filteredFields.reduce((accum, currentValue) => {
    if (typeof accum === 'object') {
      return `minmax(${accum.width || '200px'}, ${accum.width || '1fr'}) minmax(${currentValue.width || '200px'}, ${currentValue.width || '1fr'})`
    } else {
      return `${accum} minmax(${currentValue.width || '200px'}, ${currentValue.width || '1fr'})`
    }
  })

  const isSelectionActive = selectedVar && Object.keys(selectedVar).length > 0

  return (
    <Box overflow='hidden' width='100%' height='100%'>
      {(refetch || CreateRecord) && (
        <Flex
          justifyContent='space-between'
          alignItems='center'
          height={responsive(['75x', '45px'])}
          px={responsive(['0', '4'])}
          mb={responsive(['2', '4'])}
          direction={responsive(['column', 'row'])}
        >
          {SelectionMenu && isSelectionActive && SelectionMenu}
          {CreateRecord && !isSelectionActive && CreateRecord}
          <Flex flex='1' mx={responsive(['1', '8'])} mt={responsive(['2', '0'])}>
            <Search
              refetch={refetch}
              isRefetching={isRefetching}
              filterVar={filterWhereVar}
              fuzzySearchVar={fuzzySearchVar}
              isEmbedded={isEmbedded}
            />
          </Flex>
        </Flex>
      )}
      <Box height={responsive(['calc(100% - 165px)', 'calc(100% - 100px)'])} overflow='auto'>
        <Grid as='table' gridTemplateColumns={gridTemplateColumns}>
          <Box as='thead' display='contents'>
            <Box as='tr' display='contents'>
              {filteredFields.map(({ key, label, justifyContent = 'left' }, idx) => (
                <TableHeaderCell
                  key={key}
                  field={key}
                  fontSize='xs'
                  justifyContent={justifyContent}
                  isLeftEdge={idx === 0}
                  isRightEdge={idx === filteredFields.length - 1}
                  filterOrderByVar={filterOrderByVar}
                  refetch={refetch}
                >
                  {label}
                </TableHeaderCell>
              ))}
            </Box>
          </Box>
          <Box as='tbody' my='20px' display='contents'>
            {rows.map(row => (
              <Box key={row[rowKey]} as='tr' display='contents'>
                {filteredFields.map(({ key: columnKey, renderTD: RenderTD, justifyContent = 'left', forwardRefetch, ...rest }) => {
                  const cellProps = { ...rest, [rowType]: row, justifyContent }
                  if (forwardRefetch) cellProps.refetch = refetch
                  return (
                    <TableDataCell key={`${rowKey}-${columnKey}`} justifyContent={justifyContent} fontSize='sm'>
                      <RenderTD {...cellProps} />
                    </TableDataCell>
                  )
                })}
              </Box>
            ))}
            {rows.length === 0 && (
              <Flex as='tr' display='contents'>
                <Alert as='td' gridColumn='1/-1' status='warning' justifyContent='center' borderRadius='md' my='2'>
                  <AlertIcon />
                  <Text>No Data</Text>
                </Alert>
              </Flex>
            )}
          </Box>
        </Grid>
      </Box>
      <Stack direction={responsive(['column', 'row'])} alignItems='center' mt={isEmbedded && rows.length < 10 ? '12' : '1'}>
        {totalCount > 0 && (
          <Stack justifyContent='space-evenly' direction='row' minW={responsive(['100%', '300px'])}>
            <Text fontSize='sm'>Showing: <strong>{rows.length}</strong></Text>
            <Text fontSize='sm'>Filtered: <strong>{filteredCount}</strong></Text>
            <Text fontSize='sm'>Total: <strong>{totalCount}</strong></Text>
          </Stack>
        )}
        <Button
          isFullWidth
          variant='outline'
          size='sm'
          onClick={fetchMore}
          isLoading={isRefetchingMore}
          my={totalCount > 0 ? '2' : '0'}
          isDisabled={!(hasMoreItems && rows && rows.length >= 20)}
        >
          Load More
        </Button>
      </Stack>
    </Box>
  )
}
