import SourceSelectCheckbox from 'components/sources/select/SourceSelectCheckbox'
import DateTimeRenderer from 'components/_common/DateTimeRenderer'
import EditSourceName from 'components/sources/edit/EditSourceName'
import EditSourceDetails from 'components/sources/edit/EditSourceDetails'

const fields = [
  { key: 'select', width: '40px', renderTD: SourceSelectCheckbox },
  { key: 'name', label: 'Name', width: '250px', renderTD: EditSourceName },
  { key: 'details', label: 'Details', width: '250px', renderTD: EditSourceDetails },
  { key: 'updatedAt', label: 'Updated At', renderTD: ({ source: { updatedAt } }) => <DateTimeRenderer value={updatedAt} type='time' /> },
  { key: 'createdAt', label: 'Created At', renderTD: ({ source: { createdAt } }) => <DateTimeRenderer value={createdAt} type='time' /> }
]

export default fields
