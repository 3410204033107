import { useState } from 'react'

import {
  Text,
  Stack,
  IconButton,
  useDisclosure
} from '@chakra-ui/react'

import { AiOutlineEye } from 'react-icons/ai'

import ViewNotes from 'components/notes/ViewNotes'
import AddCustomerNote from 'components/customers/notes/AddCustomerNote'
import EditCustomerNoteDetails from 'components/customers/notes/EditCustomerNoteDetails'
import EditCustomerNoteMarkAsDone from 'components/customers/notes/EditCustomerNoteMarkAsDone'

export default function ViewCustomerNotesTrigger ({ customer }) {
  const [isEditVisible, setIsEditVisible] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()

  // get the first not markAsDone note
  const latestNote = customer.notes?.find(note => !note.markAsDone)

  return (
    <>
      {isOpen && (
        <ViewNotes
          label={`Notes for ${customer?.macID || customer?.accountNumber}`}
          notes={customer.notes}
          onClose={onClose}
          createComponent={<AddCustomerNote customer={customer} />}
          editDetailsComponent={EditCustomerNoteDetails}
          editMarkAsDoneComponent={EditCustomerNoteMarkAsDone}
        />
      )}
      <Stack
        direction='row'
        alignItems='center'
        width='100%'
        onMouseEnter={() => setIsEditVisible(true)}
        onMouseLeave={() => setIsEditVisible(false)}
      >
        <Text fontSize='sm' overflowX='hidden'>
          {latestNote?.details.trim() || '-'}
        </Text>
        {isEditVisible && <IconButton size='xs' variant='outline' icon={<AiOutlineEye fontSize='16px' />} onClick={onOpen} isDisabled={!customer} />}
      </Stack>
    </>
  )
}
