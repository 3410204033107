import { useState, useRef } from 'react'

import {
  Modal,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton
} from '@chakra-ui/react'

import SimpleTable from 'components/table/SimpleTable'
import DateTimeRenderer from 'components/_common/DateTimeRenderer'

import OpenUser from 'components/users/view/OpenUser'

export default function ViewNotes (props) {
  const {
    label,
    notes,
    createComponent: CreateNew = null,
    editDetailsComponent: EditDetails = null,
    editMarkAsDoneComponent: EditMarkAsDone = null,
    onClose
  } = props

  const fields = [
    { key: 'createdAt', label: 'Created At', width: '180px', renderTD: ({ note: { createdAt } }) => <DateTimeRenderer value={createdAt} type='time' /> },
    { key: 'user', label: 'Created By', width: '160px', renderTD: ({ note: { user } }) => <OpenUser user={user} /> },
    { key: 'details', label: 'Details', renderTD: EditDetails },
    { key: 'markAsDone', label: 'Done', width: '130px', renderTD: EditMarkAsDone },
    { key: 'updatedAt', label: 'Updated At', width: '180px', renderTD: ({ note: { updatedAt } }) => <DateTimeRenderer value={updatedAt} type='time' /> }
  ]

  const [fuzzySearch, setFuzzySearch] = useState('')

  const searchRef = useRef()

  const filterNotes = () => {
    if (fuzzySearch) {
      return notes.filter(({ details }) => details.toLocaleLowerCase().includes(fuzzySearch.toLocaleLowerCase()))
    } else {
      return notes
    }
  }

  return (
    <Modal isOpen onClose={onClose} size='6xl' scrollBehavior='inside' initialFocusRef={searchRef}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {label}
          <Input
            mt='4'
            ref={searchRef}
            value={fuzzySearch}
            onChange={e => setFuzzySearch(e.target.value)}
            placeholder='Filter by details...'
            type='search'
          />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SimpleTable
            rows={filterNotes()}
            fields={fields}
            rowKey='id'
            rowType='note'
          />
        </ModalBody>
        <ModalFooter>
          {CreateNew}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
