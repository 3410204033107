export const doesUserHavePermissionType = (user, permissionType) => {
  if (user.role === 'ADMIN') return true
  const activeStore = window.localStorage.getItem('ACTIVE_STORE')
  const findUserOtherPermissions = user.permissions.find(({ storeID }) => storeID === null)?.permissionTypes || []
  if (user.role === 'STAFF' && findUserOtherPermissions.includes(permissionType)) return true
  if (activeStore === 'ALL') {
    return user.role === 'STAFF' && user.permissions.every(({ permissionTypes }) => permissionTypes.includes(permissionType))
  } else {
    const findUserStorePermissions = user.permissions.find(({ storeID }) => storeID === activeStore)?.permissionTypes || []
    return user.role === 'STAFF' && findUserStorePermissions.includes(permissionType)
  }
}
