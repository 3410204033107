import useQuery from 'hooks/useQuery'
import { GET_USER_GENERAL_INFO } from 'apollo/queries/users'

import Loading from 'components/_common/Loading'

import {
  Stack,
  FormLabel,
  FormControl
} from '@chakra-ui/react'

import DateTimeRenderer from 'components/_common/DateTimeRenderer'
import EditUserEmail from 'components/users/edit/EditUserEmail'
import EditUserFullName from 'components/users/edit/EditUserFullName'
import EditUserAccountLock from 'components/users/edit/EditUserAccountLock'
import EditUserOTP from 'components/users/edit/EditUserOTP'
import ManageUserPhoneNumbers from 'components/users/edit/ManageUserPhoneNumbers'

export default function ViewUserGeneral ({ user: { id } }) {
  const { data: { user } = {}, loading, error } = useQuery(GET_USER_GENERAL_INFO, { variables: { id } })

  if (loading || error) return <Loading />

  return (
    <Stack spacing='8' maxW='500px' mx='auto'>
      <Stack direction='row'>
        <FormControl>
          <FormLabel>Created At</FormLabel>
          <DateTimeRenderer value={user.createdAt} type='time' />
        </FormControl>
        <FormControl>
          <FormLabel textAlign='right'>Updated At</FormLabel>
          <DateTimeRenderer value={user.updatedAt} textAlign='right' type='time' />
        </FormControl>
      </Stack>
      <Stack direction='row' width='100%'>
        <EditUserFullName inline user={user} />
        <EditUserEmail inline user={user} textAlign='right' />
      </Stack>
      <Stack direction='row' width='100%'>
        <EditUserAccountLock user={user} />
        <EditUserOTP user={user} textAlign='right' />
      </Stack>
      <FormControl>
        <FormLabel>Phone Numbers</FormLabel>
        <ManageUserPhoneNumbers inline user={user} />
      </FormControl>
    </Stack>
  )
}
