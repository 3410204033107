import Select from 'components/_select/CustomSelect'

import useQuery from 'hooks/useQuery'
import { GET_ALL_SHIPMENTS } from 'apollo/queries/shipments'

import Loading from 'components/_common/Loading'
import AlertDialog from 'components/_common/AlertDialog'

export default function ShipmentsFilter ({ additionalVariables, setAdditionalVariables, isDisabled = false }) {
  const { data, loading, error } = useQuery(GET_ALL_SHIPMENTS, {
    variables: { orderBy: { name: 'asc' } }
  })

  const handleSelectShipment = ({ value: shipmentID }) => {
    setAdditionalVariables({ ...additionalVariables, shipmentID })
  }

  const shipments = data?.response?.allData || []

  if (loading) return <Loading />

  if (error) return <AlertDialog status='error'>{error.message}</AlertDialog>

  const options = shipments.map(shipment => ({ value: shipment.id, label: shipment.name }))
  options.unshift({ value: undefined, label: 'All Shipments' })

  return (
    <Select
      value={options.find(({ value }) => value === additionalVariables.shipmentID)}
      options={options}
      onChange={handleSelectShipment}
      maxMenuHeight='300px'
      isDisabled={isDisabled}
    />
  )
}
