import EditableInput from 'components/_inputs/EditableInput'

import { string } from 'yup'

import useMutation from 'hooks/useMutation'
import { UPDATE_SERVICE_ACCOUNT_NUMBER } from 'apollo/mutations/services'

export default function EditServiceAccountNumber ({ service, inline = false, ...props }) {
  const [updateAccountNumber, { error, loading, reset }] = useMutation(UPDATE_SERVICE_ACCOUNT_NUMBER, {
    notification: 'Successfully updated the account number'
  })

  const handleSubmit = accountNumber => {
    const data = { id: service.id, accountNumber: accountNumber || null }
    return updateAccountNumber({ variables: { data } })
  }

  return (
    <EditableInput
      {...props}
      inline={inline}
      name='accountNumber'
      value={service.accountNumber || ''}
      label='Account Number'
      onSubmit={handleSubmit}
      validation={string()}
      isLoading={loading}
      error={error}
      reset={reset}
    />
  )
}
