import { useState } from 'react'

import {
  Text,
  Stack,
  IconButton,
  useDisclosure
} from '@chakra-ui/react'

import { AiOutlineEye } from 'react-icons/ai'

import ViewNotes from 'components/notes/ViewNotes'
import AddServiceNote from 'components/services/notes/AddServiceNote'
import EditServiceNoteDetails from 'components/services/notes/EditServiceNoteDetails'
import EditServiceNoteMarkAsDone from 'components/services/notes/EditServiceNoteMarkAsDone'

export default function ViewServiceNotesTrigger ({ service }) {
  const [isEditVisible, setIsEditVisible] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()

  // get the first not markAsDone note
  const latestNote = service.notes.find(note => !note.markAsDone)

  return (
    <>
      {isOpen && (
        <ViewNotes
          label={`Notes for ${service?.macID || service?.accountNumber}`}
          notes={service.notes}
          onClose={onClose}
          createComponent={<AddServiceNote service={service} />}
          editDetailsComponent={EditServiceNoteDetails}
          editMarkAsDoneComponent={EditServiceNoteMarkAsDone}
        />
      )}
      <Stack
        direction='row'
        alignItems='center'
        width='100%'
        onMouseEnter={() => setIsEditVisible(true)}
        onMouseLeave={() => setIsEditVisible(false)}
      >
        <Text fontSize='sm' overflowX='hidden'>
          {latestNote?.details.trim() || '-'}
        </Text>
        {isEditVisible && <IconButton size='xs' variant='outline' icon={<AiOutlineEye fontSize='16px' />} onClick={onOpen} isDisabled={!service} />}
      </Stack>
    </>
  )
}
