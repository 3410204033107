import useMutation from 'hooks/useMutation'
import { UPDATE_USER_ACCOUNT_LOCK } from 'apollo/mutations/users'

import {
  Switch,
  FormLabel,
  FormControl
} from '@chakra-ui/react'

export default function EditUserAccountLock ({ user }) {
  const [updateAccountLock] = useMutation(UPDATE_USER_ACCOUNT_LOCK, {
    notification: 'Successfully updated the account lock',
    isPromise: false
  })

  const handleOnChange = e => {
    const isLocked = e.target.checked
    const data = { id: user.id, isLocked }
    return updateAccountLock({ variables: { data } })
  }

  return (
    <FormControl>
      <FormLabel>Lock Account?</FormLabel>
      <Switch
        colorScheme='red'
        size='lg'
        isChecked={user.isLocked}
        aria-label='Lock Account'
        onChange={handleOnChange}
      />
    </FormControl>
  )
}
