import {
  Text,
  Icon,
  Stack
} from '@chakra-ui/react'

const numberWithCommas = (x = '0') => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export default function ServicesCountCard ({ label, values, icon: CardIcon, bg = 'purple.100' }) {
  return (
    <Stack
      p='1'
      bg={bg}
      height='120px'
      width='260px'
      borderRadius='lg'
      textAlign='center'
      alignItems='center'
      justifyContent='space-evenly'
    >
      <Stack alignItems='center' spacing='0'>
        <Icon as={CardIcon} boxSize='22px' />
        <Text fontWeight='bold' fontSize='sm'>{label}</Text>
      </Stack>
      <Text fontSize='2xl' fontWeight='semibold' m='0' lineHeight='none'>{numberWithCommas(values.total)}</Text>
      <Stack direction='row'>
        <Text fontSize='xs'>Inactive <strong>{numberWithCommas(values.inactive)}</strong></Text>
        <Text fontSize='xs'>OnHold <strong>{numberWithCommas(values.putOnHold)}</strong></Text>
        <Text fontSize='xs'>NoCredits <strong>{numberWithCommas(values.with0Credits)}</strong></Text>
        <Text fontSize='xs'>Operational <strong>{numberWithCommas(values.operational)}</strong></Text>
      </Stack>
    </Stack>
  )
}
