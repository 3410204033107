import { useState } from 'react'

import useResponsiveDevice from 'hooks/useResponsiveDevice'

import useQuery from 'hooks/useQuery'
import { GET_ALL_SHIPMENTS } from 'apollo/queries/shipments'

import useMutation from 'hooks/useMutation'
import { UPDATE_TRANSACTION_SHIPMENT } from 'apollo/mutations/transactions'

import Select from 'components/_select/CustomSelect'

import Loading from 'components/_common/Loading'
import ErrorAlert from 'components/_common/ErrorAlert'

import {
  Text,
  Modal,
  Stack,
  ModalBody,
  IconButton,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  ModalCloseButton
} from '@chakra-ui/react'

import { BiEdit } from 'react-icons/bi'

export default function EditTransactionShipment ({ inline = false, ...props }) {
  return inline ? <EditTransactionShipmentInline {...props} /> : <EditTransactionShipmentTrigger {...props} />
}

function EditTransactionShipmentInline ({ onSubmit }) {
  const { data, loading, error } = useQuery(GET_ALL_SHIPMENTS)

  const shipments = data?.response?.allData || []

  if (loading || error) return <Loading />

  const handleSubmit = ({ value, label }) => {
    const data = { id: value, name: label }
    return onSubmit(data)
  }

  const options = shipments.map(shipment => ({ value: shipment.id, label: shipment.name }))

  return (
    <Select
      options={options}
      onChange={handleSubmit}
      maxMenuHeight='200px'
    />
  )
}

function EditTransactionShipmentTrigger ({ transaction, justifyContent = 'left' }) {
  const { isTouch } = useResponsiveDevice()

  const [isVisible, setIsVisible] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const touchDeviceProps = isTouch ? { onClick: onOpen, cursor: 'pointer' } : {}

  const marginOffset = isVisible && justifyContent === 'center' ? '8' : ''

  return (
    <>
      {isOpen && <EditTransactionShipmentStandalone transaction={transaction} onClose={onClose} />}
      <Stack
        direction='row'
        alignItems='center'
        width='100%'
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        justifyContent={justifyContent}
      >
        <Text ml={marginOffset} {...touchDeviceProps}>{transaction.shipment?.name || '-'}</Text>
        {isVisible && (
          <IconButton
            size='xs'
            variant='outline'
            icon={<BiEdit fontSize='16px' />}
            onClick={onOpen}
          />
        )}
      </Stack>
    </>
  )
}

function EditTransactionShipmentStandalone ({ transaction, onClose }) {
  const { data, loading: loadingShipments, error: errorShipments } = useQuery(GET_ALL_SHIPMENTS)

  const shipments = data?.response?.allData || []

  const handleOnClose = () => {
    reset()
    onClose()
  }

  const [updateShipment, { reset, error, loading }] = useMutation(UPDATE_TRANSACTION_SHIPMENT, {
    notification: 'Successfully updated the shipment',
    isPromise: false
  })

  const handleSubmit = ({ value: shipmentID }) => {
    const data = { id: transaction.id, shipmentID }
    return updateShipment({ variables: { data } })
  }

  const options = shipments.map(shipment => ({ value: shipment.id, label: shipment.name }))

  return (
    <Modal isOpen onClose={handleOnClose} size='xs' scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Transaction Shipment</ModalHeader>
        <ModalCloseButton />
        <ModalBody minH='200px'>
          <Select
            options={options}
            onChange={handleSubmit}
            defaultValue={options.find(({ value }) => value === transaction.shipment?.id)}
            maxMenuHeight='200px'
          />
        </ModalBody>
        <ModalFooter>
          <Stack spacing='4' width='100%'>
            {loadingShipments && <Loading />}
            {loading && <Loading />}
            {error && <ErrorAlert> {error.message} </ErrorAlert>}
            {errorShipments && <ErrorAlert> {errorShipments.message} </ErrorAlert>}
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
