import { gql } from '@apollo/client'

export const GET_ALL_SOURCES = gql`
  query GET_ALL_SOURCES ($where: SourceWhereInput $orderBy: [SourceOrderByInput!] $take: Int $skip: Int) {
    response: getAllSources (where: $where orderBy: $orderBy take: $take skip: $skip) {
      allData: sources {
        id
        name
        details
        updatedAt
        createdAt
      }
      metrics {
        allCount
        filteredCount
      }
    }
  }
`
