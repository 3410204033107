import { useState } from 'react'

import {
  Text,
  Modal,
  Stack,
  Button,
  ModalBody,
  IconButton,
  ModalFooter,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  ModalCloseButton
} from '@chakra-ui/react'

import { AiOutlineEye } from 'react-icons/ai'
import { BiEdit } from 'react-icons/bi'
import { FaUsers } from 'react-icons/fa'

import ViewService from 'components/services/view/ViewService'
import SeparateCustomerFromService from 'components/services/SeparateCustomerFromService'
import EditServiceInactive from 'components/services/edit/EditServiceInactive'
import EditServiceIsMonthly from 'components/services/edit/EditServiceIsMonthly'
import DeleteServices from 'components/services/DeleteServices'
import EditServiceCustomer from 'components/services/edit/EditServiceCustomer'

// import { fuzzySearchVar } from 'utils/cache'

export default function OpenServiceTrigger ({ service, self, refetch }) {
  const [isEditVisible, setIsEditVisible] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleOnClose = () => {
    // clear all filters
    // fuzzySearchVar(null)
    onClose()
  }

  return (
    <>
      {isOpen && <OpenService service={service} onClose={handleOnClose} refetch={refetch} />}
      {self && (
        <IconButton
          size='xs'
          variant='outline'
          icon={<BiEdit fontSize='16px' />}
          onClick={onOpen}
        />
      )}
      {!self && (
        <Stack
          direction='row'
          alignItems='center'
          width='100%'
          onMouseEnter={() => setIsEditVisible(true)}
          onMouseLeave={() => setIsEditVisible(false)}
        >
          <Text fontSize='sm' overflowX='hidden'>
            {service?.macID || service?.accountNumber}
          </Text>
          {isEditVisible && <IconButton size='xs' variant='outline' icon={<AiOutlineEye fontSize='16px' />} onClick={onOpen} isDisabled={!service} />}
        </Stack>
      )}
    </>
  )
}

function OpenService ({ service, onClose, refetch }) {
  const [isSeparateCustomerOpen, setIsSeparateCustomer] = useState(false)
  const [isMoveCustomerOpen, setIsMoveCustomer] = useState(false)

  const [isDeleteOpen, setIsDelete] = useState(false)

  const handleSeparateCustomerOnClose = (toRefetch = false) => {
    setIsSeparateCustomer(false)
    if (toRefetch) {
      refetch()
      onClose()
    }
  }

  const handleMoveCustomerOnClose = (toRefetch = false) => {
    setIsMoveCustomer(false)
    if (toRefetch) {
      refetch()
      onClose()
    }
  }

  const handleDeleteOnClose = (toRefetch = false) => {
    setIsDelete(false)
    if (toRefetch) {
      refetch()
      onClose()
    }
  }

  return (
    <>
      {isSeparateCustomerOpen && (
        <SeparateCustomerFromService service={service} onClose={handleSeparateCustomerOnClose} />
      )}
      {isMoveCustomerOpen && (
        <EditServiceCustomer service={service} onClose={handleMoveCustomerOnClose} />
      )}
      {isDeleteOpen && (
        <DeleteServices services={{ [service.id]: service }} onClose={handleDeleteOnClose} />
      )}
      <Modal isOpen onClose={onClose} size='lg' scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{service?.macID || service?.accountNumber}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ViewService service={service} />
          </ModalBody>
          <ModalFooter>
            <Stack width='100%' spacing='8' pt='4'>
              <Stack direction='row' justifyContent='space-between'>
                <Button onClick={() => setIsSeparateCustomer(true)} variant='outline' leftIcon={<FaUsers />}>
                  Create New Customer
                </Button>
                <Button onClick={() => setIsMoveCustomer(true)} variant='outline' leftIcon={<FaUsers />}>
                  Move To Customer
                </Button>
              </Stack>
              <Stack direction='row' justifyContent='space-between'>
                {!service.inactive && <EditServiceInactive service={service} />}
                <EditServiceIsMonthly service={service} />
              </Stack>
              <Button onClick={() => setIsDelete(true)} colorScheme='red' minW='200px'>
                Delete
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
