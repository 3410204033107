import TableWrapper from 'components/table/TableWrapper'
import ProductTypesSelectionMenu from 'components/productTypes/select/ProductTypesSelectionMenu'
import CreateProductType from 'components/productTypes/CreateProductType'

import { GET_ALL_PRODUCT_TYPES } from 'apollo/queries/productTypes'
import { selectedProductTypesVar, productTypeFilterWhereVar, productTypeFilterOrderByVar } from 'utils/cache'

import fields from 'components/productTypes/fields'

export default function ProductTypes () {
  return (
    <TableWrapper
      query={GET_ALL_PRODUCT_TYPES}
      fields={fields}
      rowKey='id'
      rowType='productType'
      fuzzyKey='productTypes'
      defaultOrderBy={[{ name: 'asc' }]}
      filterWhereVar={productTypeFilterWhereVar}
      filterOrderByVar={productTypeFilterOrderByVar}
      createComponent={CreateProductType}
      selectionComponent={ProductTypesSelectionMenu}
      selectionVar={selectedProductTypesVar}
    />
  )
}
