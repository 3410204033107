import Select from 'components/_select/CustomSelect'

import useQuery from 'hooks/useQuery'
import { GET_ALL_SERVICE_TYPES } from 'apollo/queries/serviceTypes'

import Loading from 'components/_common/Loading'
import AlertDialog from 'components/_common/AlertDialog'

export default function ServiceTypesFilter ({ additionalVariables, setAdditionalVariables, isDisabled = false }) {
  const { data, loading, error } = useQuery(GET_ALL_SERVICE_TYPES, {
    variables: { orderBy: { order: 'asc' } }
  })

  const handleSelectServiceType = ({ value: serviceTypeID }) => {
    setAdditionalVariables({ ...additionalVariables, serviceTypeID })
  }

  const serviceTypes = data?.response?.allData || []

  if (loading) return <Loading />

  if (error) return <AlertDialog status='error'>{error.message}</AlertDialog>

  const options = serviceTypes.map(serviceType => ({ value: serviceType.id, label: serviceType.name, color: serviceType.color }))
  options.unshift({ value: undefined, label: 'All Service Types' })

  return (
    <Select
      value={options.find(({ value }) => value === additionalVariables.serviceTypeID)}
      options={options}
      onChange={handleSelectServiceType}
      maxMenuHeight='300px'
      isDisabled={isDisabled}
    />
  )
}
