import Checkbox from 'components/_inputs/Checkbox'

import { useReactiveVar } from '@apollo/client'
import { selectedSourcesVar } from 'utils/cache'

export default function SourceCheckbox ({ source }) {
  const selectedVar = useReactiveVar(selectedSourcesVar)

  const isChecked = Boolean(selectedVar[source.id])

  const toggleSelect = () => {
    if (isChecked) {
      const newSelection = { ...selectedVar }
      delete newSelection[source.id]
      selectedSourcesVar(newSelection)
    } else {
      selectedSourcesVar({
        ...selectedVar,
        [source.id]: source
      })
    }
  }

  return (
    <Checkbox isChecked={isChecked} toggleSelect={toggleSelect} />
  )
}
