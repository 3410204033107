import EditTransactionDate from 'components/transactions/edit/EditTransactionDate'
import ViewServiceType from 'components/serviceTypes/view/ViewServiceType'
import EditServiceMacID from 'components/services/edit/EditServiceMacID'
import EditTransactionType from 'components/transactions/edit/EditTransactionType'
import EditTransactionNumber from 'components/transactions/edit/EditTransactionNumber'
import ViewTransactionCredits from 'components/transactions/view/ViewTransactionCredits'
import EditTransactionShipment from 'components/transactions/edit/EditTransactionShipment'
import ViewTransactionNotes from 'components/transactions/notes/ViewTransactionNotes'

import OpenUser from 'components/users/view/OpenUser'
import OpenCustomer from 'components/customers/view/OpenCustomer'

const fields = [
  { key: 'date', label: 'Date', width: '150px', renderTD: EditTransactionDate },
  { key: 'transactionType', label: 'Type', width: '120px', renderTD: EditTransactionType },
  { key: 'transactionNumber', label: 'Number', width: '120px', renderTD: EditTransactionNumber },
  { key: 'credits', label: 'Credits', width: '150px', justifyContent: 'center', renderTD: ViewTransactionCredits },
  { key: 'serviceType', label: 'Service Type', width: '150px', renderTD: ({ transaction: { serviceType } }) => <ViewServiceType serviceType={serviceType} /> },
  { key: 'service', label: 'MAC', width: '180px', renderTD: ({ transaction: { service } }) => <EditServiceMacID service={service} isReadOnly /> },
  { key: 'shipment', label: 'Shipment', width: '120px', renderTD: EditTransactionShipment },
  { key: 'notes', label: 'Notes', renderTD: ViewTransactionNotes },
  { key: 'customer', label: 'Customer', renderTD: ({ transaction: { customer } }) => <OpenCustomer customer={customer} /> },
  { key: 'handledByUser', label: 'Processed By', renderTD: ({ transaction: { handledByUser } }) => <OpenUser user={handledByUser} /> }
]

export default fields
