import {
  Box,
  Flex,
  Grid
} from '@chakra-ui/react'

const TableHeaderCell = ({ children, isLeftEdge, isRightEdge, field, ...rest }) => {
  return (
    <Flex
      as='th'
      position='sticky'
      top='0px'
      bgColor='purple.50'
      zIndex={1}
      textTransform='uppercase'
      alignItems='center'
      minH='8'
      px='2'
      borderLeftRadius={isLeftEdge ? 'md' : ''}
      borderRightRadius={isRightEdge ? 'md' : ''}
      {...rest}
    >
      {children}
    </Flex>
  )
}

const TableDataCell = ({ children, ...rest }) => (
  <Flex
    as='td'
    borderBottom='1px solid #E2E8F0'
    alignItems='center'
    minH='8'
    maxH='12'
    px='2'
    overflowY='auto'
    {...rest}
  >
    {children}
  </Flex>
)

export default function SimpleTable (props) {
  const {
    rows,
    fields,
    rowKey,
    rowType
  } = props

  const gridTemplateColumns = fields.reduce((accum, currentValue) => {
    if (typeof accum === 'object') {
      return `minmax(${accum.width || '200px'}, ${accum.width || '1fr'}) minmax(${currentValue.width || '200px'}, ${currentValue.width || '1fr'})`
    } else {
      return `${accum} minmax(${currentValue.width || '200px'}, ${currentValue.width || '1fr'})`
    }
  })

  return (
    <Grid as='table' gridTemplateColumns={gridTemplateColumns}>
      <Box as='thead' display='contents'>
        <Box as='tr' display='contents'>
          {fields.map(({ key, label, justifyContent = 'left' }, idx) => (
            <TableHeaderCell
              key={key}
              field={key}
              fontSize='xs'
              justifyContent={justifyContent}
              isLeftEdge={idx === 0}
              isRightEdge={idx === fields.length - 1}
            >
              {label}
            </TableHeaderCell>
          ))}
        </Box>
      </Box>
      <Box as='tbody' my='20px' display='contents'>
        {rows.map(row => (
          <Box key={row[rowKey]} as='tr' display='contents'>
            {fields.map(({ key: columnKey, renderTD: RenderTD, justifyContent = 'left', ...rest }) => {
              const cellProps = { ...rest, [rowType]: row, justifyContent }
              return (
                <TableDataCell key={`${rowKey}-${columnKey}`} justifyContent={justifyContent} fontSize='sm'>
                  <RenderTD {...cellProps} />
                </TableDataCell>
              )
            })}
          </Box>
        ))}
      </Box>
    </Grid>
  )
}
