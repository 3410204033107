import EditableInput from 'components/_inputs/EditableInput'

import { number } from 'yup'

import useMutation from 'hooks/useMutation'
import { UPDATE_CUSTOMER_PIN } from 'apollo/mutations/customers'

export default function EditCustomerPin ({ customer, inline = false, ...props }) {
  const [updatePin, { error, loading, reset }] = useMutation(UPDATE_CUSTOMER_PIN, {
    notification: 'Successfully updated the pin'
  })

  const handleSubmit = pin => {
    const data = { id: customer.id, pin }
    return updatePin({ variables: { data } })
  }

  return (
    <EditableInput
      inline={inline}
      name='pin'
      type='number'
      value={customer.pin || ''}
      label='Pin'
      onSubmit={handleSubmit}
      validation={number().required()}
      isLoading={loading}
      error={error}
      reset={reset}
      {...props}
    />
  )
}
