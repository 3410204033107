import { Text } from '@chakra-ui/react'

export default function ViewTransactionCredits ({ transaction }) {
  return (
    <Text
      flex='1'
      textAlign='center'
      fontSize='md'
      fontWeight='bold'
      mr={2}
      color={transaction.credits < 0 ? 'red.600' : 'green.600'}
    >
      {transaction.credits}
    </Text>
  )
}
