import CustomSelect from 'components/_select/CustomSelect'

import {
  Box,
  Text,
  Checkbox
} from '@chakra-ui/react'

export default function SelectFilter (props) {
  const {
    label = '',
    value,
    valueOptions,
    isActive,
    onValueChange,
    onActiveChange,
    isSingle
  } = props

  const onSelectChange = (selected) => {
    onValueChange(isSingle ? [selected] : selected)
  }

  return (
    <>
      <Checkbox size='lg' isChecked={isActive} onChange={e => onActiveChange(e.target.checked)}>
        <Text fontSize='sm'>{label}</Text>
      </Checkbox>
      <Box gridColumn='2/-1'>
        <CustomSelect
          isMulti={!isSingle}
          options={valueOptions}
          onChange={onSelectChange}
          value={value}
        />
      </Box>
    </>
  )
}
