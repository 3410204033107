import useServiceWorker from 'hooks/useServiceWorker'
import useFingerprint from 'hooks/useFingerprint'
import useRoutes from 'hooks/useRoutes'

import withLayout from 'hocs/withLayout'

import Dashboard from 'pages/Dashboard'
import TopUps from 'pages/TopUps'
import Services from 'pages/Services'
import Customers from 'pages/Customers'
import Transactions from 'pages/Transactions'
import Reports from 'pages/Reports'
import MyTickets from 'pages/MyTickets'
import AllTickets from 'pages/AllTickets'
import Activities from 'pages/Activities'
import Users from 'pages/Users'
import Chat from 'pages/Chat'
import Sources from 'pages/Sources'
import Languages from 'pages/Languages'
import Stores from 'pages/Stores'
import ProductTypes from 'pages/ProductTypes'
import ServiceTypes from 'pages/ServiceTypes'
import Shipments from 'pages/Shipments'
import LoginApprovals from 'pages/LoginApprovals'
import LoginApproval from 'pages/LoginApproval'
import Login from 'pages/Login'
import ResetPassword from 'pages/ResetPassword'

// import OfflineDialog from 'components/_common/OfflineDialog'

// import { Offline, Online } from 'react-detect-offline'

const routes = {
  '/': [Chat, { title: 'Chat' }],
  '/dashboard': [Dashboard, { title: 'Dashboard', viewPermission: 'VIEW_DASHBOARD' }],
  '/topUps': [TopUps, { title: 'TopUps', viewPermission: 'VIEW_TOPUPS' }],
  '/topUps/:serviceType': [TopUps, { title: 'TopUps', viewPermission: 'VIEW_TOPUPS' }],
  '/services': [Services, { title: 'Services', viewPermission: 'VIEW_SERVICES' }],
  '/customers': [Customers, { title: 'Customers', viewPermission: 'VIEW_CUSTOMERS' }],
  '/transactions': [Transactions, { title: 'Transactions', viewPermission: 'VIEW_TRANSACTIONS' }],
  '/myTickets': [MyTickets, { title: 'MyTickets', viewPermission: 'VIEW_MY_TICKETS' }],
  '/allTickets': [AllTickets, { title: 'AllTickets', viewPermission: 'VIEW_ALL_TICKETS' }],
  '/activities': [Activities, { title: 'Activities' }],
  '/reports': [Reports, { title: 'Reports', viewPermission: 'VIEW_REPORTS' }],
  '/reports/:reportType': [Reports, { title: 'Reports', viewPermission: 'VIEW_REPORTS' }],
  '/users': [Users, { title: 'Users', viewPermission: 'VIEW_USERS' }],
  '/chat': [Chat, { title: 'Chat' }],
  '/chat/:activeConversationID': [Chat, { title: 'Chat' }],
  '/sources': [Sources, { title: 'Sources', viewPermission: 'VIEW_SOURCES' }],
  '/languages': [Languages, { title: 'Languages', viewPermission: 'VIEW_LANGUAGES' }],
  '/stores': [Stores, { title: 'Stores', viewPermission: 'VIEW_STORES' }],
  '/productTypes': [ProductTypes, { title: 'Product Types', viewPermission: 'VIEW_PRODUCT_TYPES' }],
  '/serviceTypes': [ServiceTypes, { title: 'Service Types', viewPermission: 'VIEW_SERVICE_TYPES' }],
  '/shipments': [Shipments, { title: 'Shipments', viewPermission: 'VIEW_SHIPMENTS' }],
  '/loginApprovals': [LoginApprovals, { title: 'Login Approvals', isAdminOnly: true }],
  '/loginApprovals/:id': [LoginApproval, { title: 'Login Approval', isAdminOnly: true }],
  '/login': [Login, { title: 'Login', isPublic: true }],
  '/resetPassword/:userID/:hash': [ResetPassword, { title: 'Reset Password', isPublic: true }]
}

export default function App () {
  useServiceWorker()
  useFingerprint()

  const MatchedComponent = withLayout(useRoutes(routes, '/'))

  return <MatchedComponent />
}
