import { useState, useCallback } from 'react'

import { Input } from '@chakra-ui/react'

import { debounce } from 'lodash'

export default function PostalCodesFilter ({ additionalVariables, setAdditionalVariables, isDisabled = false }) {
  const [search, setSearch] = useState(additionalVariables.postalCode || '')

  const handleSelectPostalCode = (postalCode) => {
    setAdditionalVariables({ ...additionalVariables, postalCode })
  }

  const debouncedSearch = useCallback(debounce(handleSelectPostalCode, 400), [additionalVariables])

  const handleChange = event => {
    const search = event.target.value.trimStart().toUpperCase()
    setSearch(search)
    debouncedSearch(search.trim())
  }

  return (
    <Input
      placeholder='All Postal Codes'
      value={search}
      onChange={handleChange}
      isDisabled={isDisabled}
    />
  )
}
