import { useColorModeValue } from '@chakra-ui/react'

const dot = (color) => {
  if (!color) return
  return {
    alignItems: 'center',
    display: 'flex',
    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 15,
      width: 25
    }
  }
}

export default function useStyles (isDark = false) {
  const isDarkTheme = isDark || useColorModeValue(false, true)

  const inputBgColor = !isDarkTheme ? 'white' : 'purple.800'
  const inputColor = !isDarkTheme ? 'black' : 'white'

  const controlBgColor = !isDarkTheme ? 'white' : 'var(--chakra-colors-purple-900)'
  const controlColor = !isDarkTheme ? 'black' : 'white'

  const menuBgColor = !isDarkTheme ? 'white' : 'var(--chakra-colors-purple-900)'
  const menuColor = 'black'

  const optionFocusedBgColor = !isDarkTheme ? 'var(--chakra-colors-purple-100)' : 'var(--chakra-colors-purple-600)'
  const optionFocusedColor = !isDarkTheme ? 'black' : 'white'
  const optionNormalBgColor = !isDarkTheme ? 'white' : 'var(--chakra-colors-purple-800)'
  const optionNormalColor = !isDarkTheme ? 'black' : 'white'

  const multiValueBgColor = 'var(--chakra-colors-purple-500)'
  const multiValueColor = 'white'

  const singleValueColor = !isDarkTheme ? 'black' : 'white'

  const dropdownIndicatorColor = 'var(--chakra-colors-purple-500)'

  return {
    dropdownIndicator: (styles, { isFocused }) => ({
      ...styles,
      color: dropdownIndicatorColor
    }),
    input: (styles) => ({
      ...styles,
      backgroundColor: inputBgColor,
      color: inputColor
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: controlBgColor,
      color: controlColor,
      borderColor: 'var(--chakra-colors-purple-800)',
      ':hover': {
        borderColor: 'var(--chakra-colors-purple-500)'
      }
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: menuBgColor,
      color: menuColor
    }),
    option: (styles, { isFocused, data }) => ({
      ...styles,
      backgroundColor: isFocused ? optionFocusedBgColor : optionNormalBgColor,
      color: isFocused ? optionFocusedColor : optionNormalColor,
      borderRadius: '3px',
      ...dot(data.color)

    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: multiValueBgColor,
      color: multiValueColor
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      ':hover': {
        backgroundColor: 'var(--chakra-colors-purple-800)',
        color: 'white'
      }
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: multiValueColor
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      color: singleValueColor,
      ...dot(data.color)
    })
  }
}
