import useMutation from 'hooks/useMutation'
import { UPDATE_TICKET_EXPIRY } from 'apollo/mutations/tickets'

import DateTimePicker from 'components/_inputs/DateTimePicker'

import { isBefore } from 'date-fns'

export default function EditTicketExpiry ({ ticket }) {
  const [extendTicket, { error, loading, reset }] = useMutation(UPDATE_TICKET_EXPIRY, {
    notification: 'Successfully updated the expiry'
  })

  const handleSubmit = expiresAt => {
    const data = { id: ticket.id, expiresAt }
    return extendTicket({ variables: { data } })
  }

  return (
    <DateTimePicker
      label='Ticket Expiry'
      type='time'
      value={ticket.expiresAt}
      onChange={handleSubmit}
      error={error}
      loading={loading}
      reset={reset}
      minDate={new Date()}
      isDisabled={ticket.status === 'RESOLVED'}
      highlight={ticket.status !== 'RESOLVED' && isBefore(new Date(ticket.expiresAt), new Date())}
    />
  )
}
