import useAuthUser from 'hooks/useAuthUser'

import {
  Text,
  Icon,
  Modal,
  Stack,
  Button,
  ModalBody,
  SimpleGrid,
  ModalOverlay,
  ModalContent
} from '@chakra-ui/react'

import { MdStore } from 'react-icons/md'

const Store = ({ id, name, location }) => {
  const handleSelectStore = (store) => {
    window.localStorage.setItem('ACTIVE_STORE', store)
    window.location.reload()
  }

  return (
    <Button
      key={id}
      colorScheme='gray'
      _hover={{ bg: 'purple.500', color: 'white' }}
      _active={{ bg: 'purple.500', color: 'white' }}
      boxSize='150px'
      onClick={() => handleSelectStore(id)}
      borderRadius='xl'
    >
      <Stack alignItems='center'>
        <Icon as={MdStore} boxSize='8' />
        <Text fontWeight='semibold' fontSize='xl'>{name}</Text>
      </Stack>
    </Button>
  )
}

export default function StorePicker () {
  const authUser = useAuthUser()

  const handleLogout = () => {
    window.localStorage.removeItem('AUTH_SESSION_ID')
    window.localStorage.removeItem('ACTIVE_STORE')
    window.location.href = '/login'
  }

  return (
    <Modal isOpen isCentered scrollBehavior='inside' size='6xl'>
      <ModalOverlay />
      <ModalContent bg='transparent' boxShadow='none'>
        <ModalBody py='8'>
          <SimpleGrid minChildWidth='150px' justifyItems='center'>
            {authUser.role === 'ADMIN' && (
              <Store id='ALL' name='All Stores' location='ADMIN' />
            )}
            {authUser.stores.map(Store)}
          </SimpleGrid>
          {authUser.stores.length === 0 && (
            <Stack spacing='8'>
              <Text fontSize='xl' textAlign='center' color='red.400'>
                You don't have access to any stores <br /> Please contact your admin
              </Text>
              <Button size='lg' onClick={handleLogout} colorScheme='red'>
                LOGOUT
              </Button>
            </Stack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
