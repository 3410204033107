import { useState } from 'react'

import useResponsiveDevice from 'hooks/useResponsiveDevice'

import {
  Modal,
  Stack,
  Alert,
  ModalBody,
  AlertIcon,
  IconButton,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalCloseButton
} from '@chakra-ui/react'

import { BiCalendarEdit } from 'react-icons/bi'

import ReactDatePicker from 'react-datepicker'
import DateTimeRenderer from 'components/_common/DateTimeRenderer'
import CustomHeader from './CustomHeader'

import 'react-datepicker/dist/react-datepicker.css'
import './date-picker.css'

import { parseISO } from 'date-fns'

import formatLocaleDate from 'utils/formatDate'

export default function DateTimePickerDialogTrigger (props) {
  const {
    reset,
    isDisabled = false,
    inline = false,
    justifyContent = 'flex-start',
    onChange,
    fontSize = 'sm',
    textAlign = 'left',
    highlight = false,
    noSubmit = false,
    ...rest
  } = props

  const { isTouch } = useResponsiveDevice()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [isVisible, setIsVisible] = useState(inline)

  const marginOffset = isVisible && justifyContent === 'center' ? '8' : ''

  const handleClose = () => {
    reset && reset()
    onClose()
  }

  const handleOnSubmit = async (newValue) => {
    try {
      await onChange(rest.type === 'time' ? newValue.toISOString() : noSubmit ? formatLocaleDate(newValue) : formatLocaleDate(newValue) + 'T04:00:00.000Z')
      onClose()
    } catch (e) {
      console.log(e.message)
    }
  }

  const touchDeviceProps = isTouch ? { onClick: onOpen, cursor: 'pointer' } : {}

  return (
    <>
      {isOpen && <DateTimePickerDialog {...rest} onClose={handleClose} onSubmit={handleOnSubmit} />}
      <Stack
        direction='row'
        alignItems='center'
        width='100%'
        onMouseEnter={!inline ? () => setIsVisible(true) : null}
        onMouseLeave={!inline ? () => setIsVisible(false) : null}
        justifyContent={textAlign === 'right' ? 'flex-end' : justifyContent}
      >
        <DateTimeRenderer
          value={props.value}
          type={rest.type} // eslint-disable-next-line
          fontSize={fontSize ? fontSize : inline ? 'md' : 'sm'}
          ml={marginOffset}
          highlight={highlight}
          {...touchDeviceProps}
        />
        {isVisible && (
          <IconButton
            size={inline ? 'sm' : 'xs'}
            variant='outline'
            icon={<BiCalendarEdit fontSize='16px' />}
            onClick={onOpen}
            isDisabled={isDisabled}
          />
        )}
      </Stack>
    </>
  )
}

function DateTimePickerDialog (props) {
  const {
    title,
    onClose,
    loading,
    value,
    onSubmit,
    error,
    children,
    label,
    fontSize = 'xl',
    type = 'date',
    ...rest
  } = props

  return (
    <Modal isOpen onClose={onClose} scrollBehavior='inside' size={type === 'date' ? 'sm' : 'lg'}>
      <ModalOverlay />
      <ModalContent pb={!children ? '0' : '6'}>
        <ModalHeader>{title || label}</ModalHeader>
        <ModalCloseButton isDisabled={loading} />
        <ModalBody textAlign='center'>
          <Stack spacing='2'>
            <DateTimeRenderer value={value} type={type} fontSize={fontSize} fontWeight='bold' />
            <ReactDatePicker
              inline
              selected={parseISO(value || new Date().toISOString())}
              onChange={onSubmit}
              showTimeSelect={type === 'time'}
              showYearDropdown
              showMonthDropdown
              dropdownMode='select'
              renderCustomHeader={CustomHeader}
              {...rest}
            />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Stack spacing='4' width='100%'>
            {error && <Alert status='error'> <AlertIcon /> {error.message} </Alert>}
            {children}
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
