import { gql } from '@apollo/client'

export const GET_ALL_CUSTOMERS = gql`
  query GET_ALL_CUSTOMERS(
    $where: CustomerWhereInput
    $orderBy: [CustomerOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    response: getAllCustomers(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      allData: customers {
        id
        joinedDate
        fullName
        email
        primaryPhoneNumber
        phoneNumbers {
          id
          type
          value
        }
        services {
          id
          credits
          inactive
        }
        transactions {
          id
        }
        tickets {
          id
        }
        notes {
          id
          createdAt
          details
          markAsDone
          user {
            id
            fullName
            role
            isDeleted
          }
          updatedAt
        }
        isDeleted
      }
      metrics {
        allCount
        filteredCount
      }
    }
  }
`

export const GET_CUSTOMER_GENERAL_INFO = gql`
  query GET_CUSTOMER_GENERAL_INFO($id: String!) {
    customer: getOneCustomer(id: $id) {
      id
      joinedDate
      fullName
      email
      pin
      store {
        id
      }
      source {
        id
      }
      language {
        id
      }
      postalCode
      primaryPhoneNumber
      phoneNumbers {
        id
        type
        value
      }
      totalReferralCredits
      createdAt
      updatedAt
    }
  }
`

export const GET_CUSTOMER_REMINDERS = gql`
  query GET_CUSTOMER_REMINDERS($id: String!) {
    customer: getOneCustomer(id: $id) {
      id
      sendReminderBy
    }
  }
`

export const SEARCH_CUSTOMERS = gql`
  query SEARCH_CUSTOMERS($searchBy: String) {
    response: listCustomers(searchBy: $searchBy) {
      id
      fullName
    }
  }
`
