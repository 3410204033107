import { gql } from '@apollo/client'

export const GET_ALL_PRODUCT_TYPES = gql`
  query GET_ALL_PRODUCT_TYPES ($where: ProductTypeWhereInput $orderBy: [ProductTypeOrderByInput!] $take: Int $skip: Int) {
    response: getAllProductTypes (where: $where orderBy: $orderBy take: $take skip: $skip) {
      allData: productTypes {
        id
        name
        details
        updatedAt
        createdAt
      }
      metrics {
        allCount
        filteredCount
      }
    }
  }
`
