import { gql } from '@apollo/client'

export const CREATE_LANGUAGE = gql`
  mutation CREATE_LANGUAGE ($data: CreateLanguageInput!) {
    createLanguage(data: $data) {
      id
    }
  }
`

export const UPDATE_LANGUAGE_NAME = gql`
  mutation UPDATE_LANGUAGE_NAME ($data: UpdateLanguageInput!) {
    updateLanguage(data: $data) {
      id
      name
      updatedAt
    }
  }
`

export const UPDATE_LANGUAGE_DETAILS = gql`
  mutation UPDATE_LANGUAGE_DETAILS ($data: UpdateLanguageInput!) {
    updateLanguage(data: $data) {
      id
      details
      updatedAt
    }
  }
`

export const DELETE_LANGUAGE = gql`
  mutation DELETE_LANGUAGE ($id: String!) {
    deleteLanguage(id: $id) {
      id
    }
  }
`
