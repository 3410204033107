import { gql } from '@apollo/client'

export const GET_MY_CONVERSATIONS = gql`
  query GET_MY_CONVERSATIONS ($where: ConversationWhereInput $orderBy: [ConversationOrderByInput!] $take: Int $skip: Int) {
    response: getMyConversations (where: $where orderBy: $orderBy take: $take skip: $skip) {
      conversations {
        id
        toUser {
          id
          fullName
        }
        lastChatAt
        lastMessageStatus
        lastMessage
      }
    }
  }
`

export const GET_CONVERSATION_CHATS = gql`
  query GET_CONVERSATION_CHATS ($where: ChatWhereInput $orderBy: [ChatOrderByInput!] $take: Int $skip: Int) {
    response: getConversationChats (where: $where orderBy: $orderBy take: $take skip: $skip) {
      chats {
        id
        message
        fromUser {
          id
          fullName
        }
        toUser {
          id
          fullName
        }
        read
        createdAt
      }
      metrics {
        allCount
        filteredCount
      }
    }
  }
`
