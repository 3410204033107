import {
  Link,
  Stack,
  Button
} from '@chakra-ui/react'

import { navigate } from 'hooks/useRoutes'

import { startCase } from 'lodash'

import GeneratePDFReport from 'components/reports/GeneratePDFReport'

export default function ReportTypesFilter ({ reportTypes = [] }) {
  return (
    <Stack>
      <Stack
        w='220px'
        overflowY='auto'
        px='2'
        boxShadow='3px 0 3px -2px #D6BCFA'
        spacing='4'
        flex='1'
      >
        {reportTypes.map(reportType => (
          <Stack key={reportType} direction='row'>
            <Button
              isFullWidth
              as={Link}
              size='sm'
              href={`/reports/${reportType}`}
              onClick={event => navigate(event, `/reports/${reportType}`)}
              _hover={{ textDecoration: 'none' }}
              variant={decodeURIComponent(window.location.pathname) === `/reports/${reportType}` ? 'solid' : 'outline'}
            >
              {startCase(reportType)}
            </Button>
          </Stack>
        ))}
      </Stack>
      <GeneratePDFReport />
    </Stack>

  )
}
