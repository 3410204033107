import { gql } from '@apollo/client'

export const GET_ALL_ACTIVITIES = gql`
  query GET_ALL_ACTIVITIES ($where: ActivityWhereInput $orderBy: [ActivityOrderByInput!] $take: Int $skip: Int) {
    response: getAllActivities (where: $where orderBy: $orderBy take: $take skip: $skip) {
      allData: activities {
        id
        date
        type
        details
        createdByUser {
          id
          fullName
          role
          isDeleted
        }
      }
      metrics {
        allCount
        filteredCount
      }
    }
  }
`
