import { useEffect } from 'react'

import useAuthUser from 'hooks/useAuthUser'

import useMutation from 'hooks/useMutation'
import { MARK_AS_READ } from 'apollo/mutations/chats'
import { GET_MY_CONVERSATIONS } from 'apollo/queries/chats'

import {
  Icon,
  Text,
  Stack
} from '@chakra-ui/react'
import DateTimeRenderer from 'components/_common/DateTimeRenderer'

import { BiCheck, BiCheckDouble } from 'react-icons/bi'

export default function ViewChat ({ chat }) {
  const [markAsRead] = useMutation(MARK_AS_READ, {
    notification: '',
    isPromise: false,
    refetchQueries: [{ query: GET_MY_CONVERSATIONS }]
  })

  const authUser = useAuthUser()

  const { fromUser, message, read, createdAt } = chat

  useEffect(() => {
    if (!read) {
      markAsRead({ variables: { id: fromUser.id } })
    }
  }, [read])

  return (
    <Stack
      alignItems='flex-start'
      bg='purple.50'
      borderRadius='lg'
      p='2'
      width='fit-content'
      alignSelf={authUser.id === fromUser.id ? 'flex-end' : 'flex-start'}
      maxW='600px'
    >
      <Stack direction='row' alignItems='center'>
        <Text fontSize='xs' fontWeight='semibold' color='gray.400'>{fromUser.fullName}</Text>
      </Stack>
      <Text fontSize='md' as='code' pr='2'>
        {message}
      </Text>
      <Stack direction='row' alignItems='center'>
        <DateTimeRenderer fontSize='xs' value={createdAt} type='time' width='100%' textAlign='right' fontWeight='semibold' color='gray.400' />
        {authUser.id === fromUser.id && (
          <Icon as={read ? BiCheckDouble : BiCheck} color={read ? 'green' : 'gray'} boxSize='22px' />
        )}
      </Stack>
    </Stack>
  )
}
