import useQuery from 'hooks/useQuery'
import { GET_ALL_PRODUCT_TYPES } from 'apollo/queries/productTypes'

import useMutation from 'hooks/useMutation'
import { UPDATE_SERVICE_PRODUCT_TYPE } from 'apollo/mutations/services'

import Select from 'components/_select/CustomSelect'

import Loading from 'components/_common/Loading'

export default function EditServiceProductType ({ inline = false, ...props }) {
  return inline ? <EditServiceProductTypeInline {...props} /> : <EditServiceProductTypeStandalone {...props} />
}

function EditServiceProductTypeInline ({ onSubmit }) {
  const { data, loading, error } = useQuery(GET_ALL_PRODUCT_TYPES)

  const productTypes = data?.response?.allData || []

  if (loading || error) return <Loading />

  const handleSubmit = ({ value, label }) => {
    const data = { id: value, name: label }
    return onSubmit(data)
  }

  const options = productTypes.map(productType => ({ value: productType.id, label: productType.name }))

  return (
    <Select
      options={options}
      onChange={handleSubmit}
      maxMenuHeight='200px'
    />
  )
}

function EditServiceProductTypeStandalone ({ service }) {
  const { data, loading, error } = useQuery(GET_ALL_PRODUCT_TYPES)

  const productTypes = data?.response?.allData || []

  const [updateProductType] = useMutation(UPDATE_SERVICE_PRODUCT_TYPE, {
    notification: 'Successfully updated the product type',
    isPromise: false
  })

  if (loading || error) return <Loading />

  const handleSubmit = ({ value: productTypeID }) => {
    const data = { id: service.id, productTypeID }
    return updateProductType({ variables: { data } })
  }

  const options = productTypes.map(productType => ({ value: productType.id, label: productType.name }))

  return (
    <Select
      options={options}
      onChange={handleSubmit}
      defaultValue={options.find(({ value }) => value === service?.productType?.id)}
      maxMenuHeight='200px'
    />
  )
}
