import AsyncSelect from 'components/_select/AsyncSelect'

import useQuery from 'hooks/useQuery'
import { SEARCH_CUSTOMERS } from 'apollo/queries/customers'

import Loading from 'components/_common/Loading'
import AlertDialog from 'components/_common/AlertDialog'

export default function CustomersFilter ({ additionalVariables, setAdditionalVariables, isDisabled = false }) {
  const { data, loading, error, refetch } = useQuery(SEARCH_CUSTOMERS)

  const handleSelectCustomer = ({ value: customerID }) => {
    setAdditionalVariables({ ...additionalVariables, customerID })
  }

  const customers = data?.response || []

  if (loading) return <Loading />

  if (error) return <AlertDialog status='error'>{error.message}</AlertDialog>

  const transformCustomers = (customer) => ({ value: customer.id, label: customer.fullName })

  const options = customers.map(transformCustomers)

  const loadCustomers = async inputValue => {
    try {
      await refetch({ searchBy: inputValue })
      if (customers) {
        return customers.map(transformCustomers)
      } else {
        return []
      }
    } catch (error) {
      console.log(error)
      return []
    }
  }

  return (
    <AsyncSelect
      placeholder='Select a Customer'
      value={options.find(({ value }) => value === additionalVariables.customerID)}
      options={options}
      onChange={handleSelectCustomer}
      maxMenuHeight='300px'
      loadOptions={loadCustomers}
      isDisabled={isDisabled}
    />
  )
}
