import ServiceSelectCheckbox from 'components/services/select/ServiceSelectCheckbox'
import OpenService from 'components/services/view/OpenService'
import EditServiceCreatedDate from 'components/services/edit/EditServiceCreatedDate'
import EditServiceMacID from 'components/services/edit/EditServiceMacID'
import EditServiceAccountNumber from 'components/services/edit/EditServiceAccountNumber'
import OpenCustomer from 'components/customers/view/OpenCustomer'
import ManageCustomerPhoneNumbers from 'components/customers/edit/ManageCustomerPhoneNumbers'
import ViewServiceType from 'components/serviceTypes/view/ViewServiceType'
import ManageServiceTopup from 'components/services/edit/ManageServiceTopup'
import ManageServiceCredits from 'components/services/edit/ManageServiceCredits'
import EditServiceExpiryDate from 'components/services/edit/EditServiceExpiryDate'
import ViewServiceNotes from 'components/services/notes/ViewServiceNotes'
import EditServiceOnHold from 'components/services/edit/EditServiceOnHold'
import EditServiceOnHoldDate from 'components/services/edit/EditServiceOnHoldDate'
import DateTimeRenderer from 'components/_common/DateTimeRenderer'

const fields = [
  { key: 'view', width: '40px', forwardRefetch: true, renderTD: (props) => <OpenService {...props} self /> },
  { key: 'select', width: '40px', renderTD: ServiceSelectCheckbox },
  { key: 'createdDate', label: 'Created At', width: '130px', renderTD: EditServiceCreatedDate },
  { key: 'macID', label: 'MAC', width: '210px', renderTD: EditServiceMacID },
  { key: 'accountNumber', label: 'Acc Number', width: '130px', renderTD: EditServiceAccountNumber },
  { key: 'customer', label: 'Customer', width: '160px', renderTD: ({ service: { customer } }) => <OpenCustomer customer={customer} /> },
  { key: 'phoneNumber', label: 'Phone Number', width: '180px', renderTD: ({ service: { customer } }) => <ManageCustomerPhoneNumbers customer={customer} /> },
  { key: 'serviceType', label: 'Service Type', width: '130px', renderTD: ({ service: { serviceType } }) => <ViewServiceType serviceType={serviceType} /> },
  { key: 'topUp', label: 'TopUp', width: '75px', justifyContent: 'center', renderTD: ManageServiceTopup },
  { key: 'expiryDate', label: 'Expiry Date', width: '160px', justifyContent: 'center', renderTD: EditServiceExpiryDate },
  { key: 'credits', label: 'Credits', width: '100px', justifyContent: 'center', renderTD: ManageServiceCredits },
  { key: 'actualExpiryDate', label: 'Actual Expiry', width: '140px', justifyContent: 'center', renderTD: ({ service: { actualExpiryDate } }) => <DateTimeRenderer value={actualExpiryDate} fontSize='sm' /> },
  { key: 'putOnHold', label: 'On Hold', width: '100px', justifyContent: 'center', renderTD: EditServiceOnHold },
  { key: 'onHoldDate', label: 'On Hold Date', width: '160px', renderTD: EditServiceOnHoldDate },
  { key: 'notes', label: 'Notes', renderTD: ViewServiceNotes }
]

export default fields
