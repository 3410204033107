import { useEffect, useState } from 'react'

import useAuthUser from 'hooks/useAuthUser'

import {
  Text,
  Stack,
  Alert,
  AlertIcon
} from '@chakra-ui/react'

import useQuery from 'hooks/useQuery'
import { GET_CONVERSATION_CHATS, GET_MY_CONVERSATIONS } from 'apollo/queries/chats'
import { CHAT_UPDATED } from 'apollo/subscriptions'

import SearchMessages from 'components/chats/SearchMessages'
import ViewChat from 'components/chats/ViewChat'
import SendMessage from 'components/chats/SendMessage'
import LoadMoreChats from 'components/chats/LoadMoreChats'

import Loading from 'components/_common/Loading'
import ErrorAlert from 'components/_common/ErrorAlert'

export default function ViewConversation ({ activeConversationID }) {
  const authUser = useAuthUser()

  const { data, loading, error, fetchMore, isRefetchingMore, refetch, subscribeToMore } = useQuery(GET_CONVERSATION_CHATS, {
    variables: { where: { conversation: { id: { equals: activeConversationID } } }, take: 20 },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  })

  useEffect(() => {
    const unsubscribe = subscribeToMore({ document: CHAT_UPDATED, variables: { id: authUser.id }, updateQuery: () => { refetch() } })
    return () => unsubscribe()
  }, [])

  const chats = data?.response?.chats || []
  const filteredCount = data?.response?.metrics?.filteredCount || 0
  const totalCount = data?.response?.metrics?.allCount || 0

  const { data: dataConversations } = useQuery(GET_MY_CONVERSATIONS, { fetchPolicy: 'cache-only' })

  const conversations = dataConversations?.response?.conversations || []
  const conversation = conversations.find(({ id }) => id === activeConversationID)

  const handleRefetch = (search = '') => {
    const variables = {
      where: {
        conversation: { id: { equals: activeConversationID } },
        message: { contains: search, mode: 'insensitive' }
      }
    }
    refetch(variables)
  }

  const [hasMoreItems, setHasMoreItems] = useState(true)

  const handleFetchMore = () => {
    fetchMore({
      variables: { skip: chats.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return {
          response: {
            ...prev.response,
            ...fetchMoreResult.response,
            chats: [
              ...prev.response.chats,
              ...fetchMoreResult.response.chats
            ]
          }
        }
      }
    })
      .then(({ data }) => {
        if (data && data.response.chats.length === 20) {
          setHasMoreItems(true)
        } else {
          setHasMoreItems(false)
        }
      })
  }

  if (loading) return <Loading />

  if (error) return <ErrorAlert>{error.message}</ErrorAlert>

  return (
    <Stack p='4' py='1' width='100%' borderRadius='lg'>
      <Stack direction='row' width='100%' justifyContent='space-between' alignItems='center' spacing='4'>
        <SearchMessages onSearch={handleRefetch} refetch={refetch} />
        <LoadMoreChats
          chatsCount={chats.length}
          filteredCount={filteredCount}
          totalCount={totalCount}
          fetchMore={handleFetchMore}
          hasMoreItems={hasMoreItems}
          isRefetchingMore={isRefetchingMore}
        />
      </Stack>
      {chats.length === 0 && (
        <Alert status='warning' justifyContent='center' borderRadius='md'>
          <AlertIcon />
          <Text>No Data</Text>
        </Alert>
      )}
      <Stack direction='column-reverse' flex='1' overflowY='auto' spacing='2' px='2'>
        {chats.map(chat => (
          <ViewChat key={chat.id} chat={chat} />
        ))}
      </Stack>
      {conversation && (
        <SendMessage
          conversationID={activeConversationID}
          toUserID={conversation.toUser.id}
          refetch={refetch}
        />
      )}
    </Stack>
  )
}
