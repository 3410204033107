import useAuthUser from 'hooks/useAuthUser'

import TableWrapper from 'components/table/TableWrapper'
import TicketsSelectionMenu from 'components/tickets/select/TicketsSelectionMenu'
import CreateTicket from 'components/tickets/CreateTicket'

import { GET_ALL_TICKETS } from 'apollo/queries/tickets'
import { selectedTicketsVar, ticketFilterWhereVar, ticketFilterOrderByVar } from 'utils/cache'

import fields from 'components/tickets/fields'

import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels
} from '@chakra-ui/react'

import TicketStatusBadge from 'components/_badges/TicketStatusBadge'

const TicketsByFilter = ({ additionalWhere, hideFields = [] }) => {
  return (
    <TableWrapper
      query={GET_ALL_TICKETS}
      fields={fields}
      hideFields={hideFields}
      rowKey='id'
      rowType='ticket'
      fuzzyKey='tickets'
      defaultOrderBy={[{ date: 'desc' }]}
      filterWhereVar={ticketFilterWhereVar}
      additionalWhere={additionalWhere}
      filterOrderByVar={ticketFilterOrderByVar}
      selectionComponent={TicketsSelectionMenu}
      selectionVar={selectedTicketsVar}
      createComponent={CreateTicket}
    />
  )
}

export default function MyTickets () {
  const authUser = useAuthUser()

  return (
    <Tabs isLazy isFitted width='100%'>
      <TabList>
        <Tab>USER TICKETS</Tab>
        <Tab>CUSTOMER TICKETS</Tab>
      </TabList>

      <TabPanels height='calc(100% - 25px)'>
        <TabPanel height='100%'>
          <Tabs isLazy width='100%' height='100%'>
            <TabList>
              <Tab><TicketStatusBadge status='OPEN' /></Tab>
              <Tab><TicketStatusBadge status='IN_PROGRESS' /></Tab>
              <Tab><TicketStatusBadge status='RESOLVED' /></Tab>
            </TabList>

            <TabPanels height='calc(100% - 25px)'>
              {['OPEN', 'IN_PROGRESS', 'RESOLVED'].map(status => {
                const additionalWhere = {
                  AND: [
                    {
                      OR: [
                        {
                          assignedToUser: {
                            id: { equals: authUser.id }
                          }
                        },
                        {
                          createdByUser: {
                            id: { equals: authUser.id }
                          }
                        }
                      ]
                    },
                    {
                      status: { equals: status }
                    },
                    {
                      customer: null
                    }
                  ]
                }
                return (
                  <TabPanel key={status} height='100%' px='0'>
                    <TicketsByFilter additionalWhere={additionalWhere} hideFields={['customer', 'assignedToUser']} />
                  </TabPanel>
                )
              })}
            </TabPanels>
          </Tabs>
        </TabPanel>
        <TabPanel height='100%'>
          <Tabs isLazy width='100%' height='100%'>
            <TabList>
              <Tab><TicketStatusBadge status='OPEN' /></Tab>
              <Tab><TicketStatusBadge status='IN_PROGRESS' /></Tab>
              <Tab><TicketStatusBadge status='RESOLVED' /></Tab>
            </TabList>

            <TabPanels height='calc(100% - 25px)'>
              {['OPEN', 'IN_PROGRESS', 'RESOLVED'].map(status => {
                const additionalWhere = {
                  AND: [
                    {
                      OR: [
                        {
                          assignedToUser: {
                            id: { equals: authUser.id }
                          }
                        },
                        {
                          createdByUser: {
                            id: { equals: authUser.id }
                          }
                        }
                      ]
                    },
                    {
                      status: { equals: status }
                    },
                    {
                      NOT: { customer: null }
                    }
                  ]
                }
                return (
                  <TabPanel key={status} height='100%' px='0'>
                    <TicketsByFilter additionalWhere={additionalWhere} hideFields={['assignedToUser']} />
                  </TabPanel>
                )
              })}
            </TabPanels>
          </Tabs>
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
