import useQuery from 'hooks/useQuery'
import { GET_ALL_USERS_LEAN } from 'apollo/queries/users'

import useAuthUser from 'hooks/useAuthUser'

import Select from 'components/_select/CustomSelect'

import Loading from 'components/_common/Loading'

export default function SelectServiceHandledByUser ({ onSubmit }) {
  const authUser = useAuthUser()

  const { data, loading, error } = useQuery(GET_ALL_USERS_LEAN)

  const users = data?.response || []

  if (loading || error) return <Loading />

  const handleSubmit = ({ value, label }) => {
    const data = { id: value, name: label }
    return onSubmit(data)
  }

  const options = users.map(user => ({ value: user.id, label: user.fullName }))

  return (
    <Select
      defaultValue={{ value: authUser.id, label: authUser.fullName }}
      options={options}
      onChange={handleSubmit}
      maxMenuHeight='200px'
    />
  )
}
