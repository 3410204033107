import { useEffect } from 'react'

import {
  Stack,
  Button,
  FormLabel,
  FormControl,
  FormErrorMessage
} from '@chakra-ui/react'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string, ref } from 'yup'

import PasswordInput from 'components/_inputs/PasswordInput'

import useMutation from 'hooks/useMutation'
import { RESET_PASSWORD } from 'apollo/mutations/auth'

import AlertDialog from 'components/_common/AlertDialog'
import ErrorAlert from 'components/_common/ErrorAlert'

const schemaValidation = object().shape({
  newPassword: string().required().min(3),
  confirmPassword: string().required().oneOf([ref('newPassword'), null], 'Passwords do not match')
})

export default function ResetForgotPassword ({ userID, hash }) {
  const [resetPassword, { data, error, loading }] = useMutation(RESET_PASSWORD, {
    notification: 'Successfully reset the password',
    isPromise: false
  })

  const { register, handleSubmit, formState: { errors }, setFocus } = useForm({
    resolver: yupResolver(schemaValidation)
  })

  useEffect(() => { setTimeout(() => setFocus('newPassword'), 1) }, [])

  const onSubmit = ({ newPassword }) => {
    const fingerprint = window.localStorage.getItem('FINGERPRINT')
    const data = { id: userID, hash, fingerprint, newPassword }
    resetPassword({ variables: { data } })
  }

  const onClose = () => {
    window.location.href = '/login'
  }

  if (data?.resetPassword) {
    return (
      <AlertDialog isCentered status='success'>
        Your password has been reset <br />
        <Button mt='4' onClick={onClose}>Login</Button>
      </AlertDialog>
    )
  }

  return (
    <Stack
      as='form'
      onSubmit={handleSubmit(onSubmit)}
      bg='white'
      p='8'
      shadow='base'
      rounded='lg'
      spacing='12'
    >
      <FormControl id='newPassword' isRequired isInvalid={errors?.newPassword}>
        <FormLabel>New Password</FormLabel>
        <PasswordInput {...register('newPassword')} />
        <FormErrorMessage>{errors?.newPassword?.message}</FormErrorMessage>
      </FormControl>
      <FormControl id='confirmPassword' isRequired isInvalid={errors?.confirmPassword}>
        <FormLabel>Confirm New Password</FormLabel>
        <PasswordInput {...register('confirmPassword')} />
        <FormErrorMessage>{errors?.confirmPassword?.message}</FormErrorMessage>
      </FormControl>
      <Stack spacing='4' width='100%'>
        {error && <ErrorAlert> {error.message} </ErrorAlert>}
        <Button type='submit' size='lg' fontSize='md' isLoading={loading}>
          Reset
        </Button>
      </Stack>
    </Stack>
  )
}
