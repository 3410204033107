import { useState } from 'react'

import useMutation from 'hooks/useMutation'
import { TOP_UP_SERVICE } from 'apollo/mutations/services'

import {
  Icon,
  Stack,
  Modal,
  Button,
  ModalBody,
  IconButton,
  ButtonGroup,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalCloseButton
} from '@chakra-ui/react'

import { FcMoneyTransfer } from 'react-icons/fc'
import { BiCalendarEdit } from 'react-icons/bi'

import ReactDatePicker from 'react-datepicker'
import CustomHeader from 'components/_inputs/DateTimePicker/CustomHeader'
import DateTimeRenderer from 'components/_common/DateTimeRenderer'

import ErrorAlert from 'components/_common/ErrorAlert'

import 'react-datepicker/dist/react-datepicker.css'
import 'components/_inputs/DateTimePicker/date-picker.css'

import formatLocaleDate from 'utils/formatDate'

export default function ManageServiceTopup ({ service, btnSize = 'xs' }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const removeServiceFromCache = (cache) => {
    const normalizedId = cache.identify(service)
    cache.evict({ id: normalizedId })
    cache.gc()
  }

  const handleClose = () => {
    reset()
    onClose()
  }

  const [topupService, { error, loading, reset }] = useMutation(TOP_UP_SERVICE, {
    notification: 'Successfully topup the service',
    onCompleted: handleClose,
    update: window.location.pathname.startsWith('/topUps') ? removeServiceFromCache : null
  })

  const defaultDate = new Date(service.expiryDate)
  defaultDate.setDate(defaultDate.getDate() + 31)

  const minDate = new Date(defaultDate)
  minDate.setDate(defaultDate.getDate() - 60)

  const maxDate = new Date(defaultDate)
  maxDate.setDate(defaultDate.getDate() + 60)

  const [expiryDate, setExpiryDate] = useState(defaultDate)

  const onTopUp = (sendDate = false) => {
    // only send expiryDate if it was changed
    const data = {
      id: service.id,
      expiryDate: sendDate ? formatLocaleDate(expiryDate) + 'T04:00:00.000Z' : undefined
    }
    topupService({ variables: { data } })
      .then(({ topup }) => {
        const defaultDate = new Date(topup.expiryDate)
        defaultDate.setDate(defaultDate.getDate() + 31)
        setExpiryDate(defaultDate)
      })
      .catch(console.log)
  }

  return (
    <>
      <ButtonGroup size={btnSize} isAttached variant='outline' isDisabled={service.credits < 1}>
        <Button mr='-px' fontSize='18' onClick={() => onTopUp(false)} isDisabled={loading}>
          <Icon as={FcMoneyTransfer} />
        </Button>
        <IconButton
          onClick={onOpen}
          aria-label='Top Up'
          icon={<BiCalendarEdit size='16px' />}
        />
      </ButtonGroup>
      {isOpen && (
        <Modal isOpen onClose={handleClose} scrollBehavior='inside' size='xs'>
          <ModalOverlay />
          <ModalContent pb='4'>
            <ModalHeader>Adjust the expiry date?</ModalHeader>
            <ModalCloseButton isDisabled={loading} />
            <ModalBody textAlign='center'>
              <Stack>
                <ReactDatePicker
                  inline
                  selected={expiryDate}
                  onChange={setExpiryDate}
                  minDate={minDate}
                  maxDate={maxDate}
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode='select'
                  renderCustomHeader={CustomHeader}
                />
                <DateTimeRenderer value={formatLocaleDate(expiryDate)} fontSize='md' fontWeight='bold' />
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Stack spacing='4' width='100%'>
                {error && <ErrorAlert> {error.message} </ErrorAlert>}
                <Button
                  isFullWidth
                  leftIcon={<FcMoneyTransfer />}
                  onClick={() => onTopUp(true)}
                  isLoading={loading}
                  variant='solid'
                  size='md'
                >
                  Top Up
                </Button>
              </Stack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}
