import EditableInput from 'components/_inputs/EditableInput'

import { string } from 'yup'

import useMutation from 'hooks/useMutation'
import { UPDATE_SERVICE_MAC_ID } from 'apollo/mutations/services'

import {
  Flex,
  Text,
  useToast,
  IconButton,
  useClipboard
} from '@chakra-ui/react'
import { BiCopy } from 'react-icons/bi'

export default function EditServiceMacID ({ service, inline = false, isReadOnly = false, ...props }) {
  const toast = useToast()

  const { onCopy } = useClipboard(service?.macID || '')

  const copyToClipboard = () => {
    onCopy()
    toast({
      title: 'Copied to clipboard',
      status: 'info',
      position: 'top',
      duration: 1000,
      isClosable: true
    })
  }

  const [updateServiceMacID, { error, loading, reset }] = useMutation(UPDATE_SERVICE_MAC_ID, {
    notification: 'Successfully updated the MAC address'
  })

  const handleSubmit = macID => {
    const data = { id: service.id, macID: macID || null }
    return updateServiceMacID({ variables: { data } })
  }

  if (!service) {
    return <Text>-</Text>
  }

  if (isReadOnly) {
    return (
      <Flex alignItems='center'>
        <Text mr='1'>{service?.macID || ''}</Text>
        <IconButton
          size='sm'
          variant='ghost'
          onClick={copyToClipboard}
          aria-label='Copy MAC ID'
          icon={<BiCopy />}
        />
      </Flex>

    )
  }

  return (
    <Flex alignItems='center'>
      <EditableInput
        inline={inline}
        name='macID'
        value={service?.macID || ''}
        label='MAC Address'
        onSubmit={handleSubmit}
        validation={string().uppercase().matches(/^([0-9a-fA-F]{2}[:]){5}([0-9a-fA-F]{2})$/, { message: 'Invalid Mac Address', excludeEmptyString: true })}
        isLoading={loading}
        error={error}
        reset={reset}
      />
      <IconButton
        size='sm'
        variant='ghost'
        onClick={copyToClipboard}
        aria-label='Copy MAC ID'
        icon={<BiCopy />}
      />
    </Flex>
  )
}
