import Checkbox from 'components/_inputs/Checkbox'

import { useReactiveVar } from '@apollo/client'
import { selectedProductTypesVar } from 'utils/cache'

export default function ProductTypeCheckbox ({ productType }) {
  const selectedVar = useReactiveVar(selectedProductTypesVar)

  const isChecked = Boolean(selectedVar[productType.id])

  const toggleSelect = () => {
    if (isChecked) {
      const newSelection = { ...selectedVar }
      delete newSelection[productType.id]
      selectedProductTypesVar(newSelection)
    } else {
      selectedProductTypesVar({
        ...selectedVar,
        [productType.id]: productType
      })
    }
  }

  return (
    <Checkbox isChecked={isChecked} toggleSelect={toggleSelect} />
  )
}
