import useMutation from 'hooks/useMutation'
import { UPDATE_SERVICE_INACTIVE } from 'apollo/mutations/services'

import { Button } from '@chakra-ui/react'

export default function EditServiceInactive ({ service }) {
  const [updateServiceInactive, { loading }] = useMutation(UPDATE_SERVICE_INACTIVE, {
    notification: 'Successfully updated the service',
    isPromise: false
  })

  const onToggleInactive = () => {
    const data = { id: service.id, inactive: !service.inactive }
    updateServiceInactive({ variables: { data } })
  }

  return (
    <Button
      onClick={onToggleInactive}
      colorScheme='orange'
      isLoading={loading}
      isDisabled={service.credits > 0}
      variant={service.inactive ? 'solid' : 'outline'}
    >
      {!service.inactive ? 'Set as Inactive' : 'Set as Active'}
    </Button>
  )
}
