import { gql } from '@apollo/client'

export const GET_ALL_TRANSACTIONS = gql`
  query GET_ALL_TRANSACTIONS ($where: TransactionWhereInput $orderBy: [TransactionOrderByInput!] $take: Int $skip: Int) {
    response: getAllTransactions (where: $where orderBy: $orderBy take: $take skip: $skip) {
      allData: transactions {
        id
        date
        transactionType
        transactionNumber
        credits
        serviceType {
          id
          name
          color
        }
        service {
          macID
        }
        shipment {
          id
          name
        }
        notes {
          id
          createdAt
          details
          markAsDone
          user {
            id
            fullName
            role
            isDeleted
          }
          updatedAt
        }
        customer {
          id
          fullName
          isDeleted
        }
        handledByUser {
          id
          fullName
          role
          isDeleted
        }
      }
      metrics {
        allCount
        filteredCount
      }
    }
  }
`
