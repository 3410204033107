import { useEffect } from 'react'

import useAuthUser from 'hooks/useAuthUser'

import useQuery from 'hooks/useQuery'
import { GET_MY_CONVERSATIONS } from 'apollo/queries/chats'
import { CHAT_UPDATED } from 'apollo/subscriptions'

import Loading from 'components/_common/Loading'
import AlertDialog from 'components/_common/AlertDialog'

import {
  Icon,
  Text,
  Stack,
  Button
} from '@chakra-ui/react'

import { BiCheck, BiCheckDouble } from 'react-icons/bi'

import { navigate } from 'hooks/useRoutes'

import DateTimeRenderer from 'components/_common/DateTimeRenderer'

import NewConversation from 'components/chats/NewConversation'
import BroadcastMessage from 'components/chats/BroadcastMessage'

export default function ViewMyConversations ({ activeConversationID }) {
  const authUser = useAuthUser()

  const { data, loading, error, subscribeToMore, refetch } = useQuery(GET_MY_CONVERSATIONS, { fetchPolicy: 'cache-and-network' })

  useEffect(() => {
    if (subscribeToMore) {
      const unsubscribe = subscribeToMore({ document: CHAT_UPDATED, variables: { id: authUser.id }, updateQuery: () => { refetch() } })
      return () => unsubscribe()
    }
  }, [])

  if (loading) return <Loading />

  if (error) return <AlertDialog status='error'>{error.message}</AlertDialog>

  const conversations = data?.response?.conversations || []

  return (
    <Stack height='100%' width='500px' borderRadius='lg' p='1'>
      <NewConversation conversations={conversations} />
      <Stack spacing='2' flex='1' overflowY='auto'>
        {conversations.map(conversation => (
          <SelectConversation key={conversation.id} conversation={conversation} isActive={conversation.id === activeConversationID} />
        ))}
      </Stack>
      <BroadcastMessage refetch={refetch} />
    </Stack>
  )
}

function SelectConversation ({ isActive, conversation }) {
  return (
    <Button
      variant={isActive ? 'solid' : 'ghost'}
      onClick={event => navigate(event, `/chat/${conversation.id}`)}
      minHeight='60px'
      _hover={{
        bg: isActive ? '' : 'purple.200'
      }}
      _focus={{
        boxShadow: 'none'
      }}
      py='4'
      border={conversation?.lastMessageStatus === 'NEW_MESSAGE' ? '3px solid red' : '1px solid lightgray'}
    >
      <Stack alignItems='center' justifyContent='space-between' width='100%'>
        <Stack direction='row' justifyContent='space-between' width='100%'>
          <Text>{conversation.toUser.fullName}</Text>
          <DateTimeRenderer
            value={conversation?.lastChatAt}
            type='time'
            color={isActive ? 'gray.100' : conversation?.lastMessageStatus === 'NEW_MESSAGE' ? 'green.600' : 'gray.500'}
          />
        </Stack>
        <Stack direction='row' width='100%' alignItems='center'>
          {conversation?.lastMessageStatus.endsWith('BY_REC') && <Icon as={conversation?.lastMessageStatus === 'OPENED_BY_REC' ? BiCheckDouble : BiCheck} color={conversation?.lastMessageStatus === 'OPENED_BY_REC' ? 'green' : 'gray'} boxSize='22px' />}
          <Text color='gray.400' fontSize='sm'>{conversation?.lastMessage}</Text>
        </Stack>
      </Stack>
    </Button>
  )
}
