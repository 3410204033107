import { useState } from 'react'

import {
  Text,
  Icon,
  Modal,
  Badge,
  Stack,
  ModalBody,
  IconButton,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  ModalCloseButton
} from '@chakra-ui/react'

import { AiOutlineEye, AiFillEyeInvisible } from 'react-icons/ai'
import { BiEdit } from 'react-icons/bi'

import ViewCustomer from 'components/customers/view/ViewCustomer'

// import { fuzzySearchVar } from 'utils/cache'

export default function OpenCustomerTrigger ({ customer, self }) {
  const [isEditVisible, setIsEditVisible] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleOnClose = () => {
    // clear all filters
    // fuzzySearchVar(null)
    onClose()
  }

  if (!customer) return <Text>-</Text>

  if (customer?.isDeleted) {
    return (
      <Badge borderRadius='md' colorScheme='gray'>Deleted <Icon as={AiFillEyeInvisible} /></Badge>
    )
  }

  return (
    <>
      {isOpen && <OpenCustomer customer={customer} onClose={handleOnClose} />}
      {self && (
        <IconButton
          size='xs'
          variant='outline'
          icon={<BiEdit fontSize='16px' />}
          onClick={onOpen}
        />
      )}
      {!self && (
        <Stack
          direction='row'
          alignItems='center'
          width='100%'
          onMouseEnter={() => setIsEditVisible(true)}
          onMouseLeave={() => setIsEditVisible(false)}
        >
          <Text fontSize='sm' overflowX='hidden'>
            {customer.fullName || '-'}
          </Text>
          {isEditVisible && <IconButton size='xs' variant='outline' icon={<AiOutlineEye fontSize='16px' />} onClick={onOpen} />}
        </Stack>
      )}
    </>
  )
}

function OpenCustomer ({ customer, onClose }) {
  return (
    <Modal isOpen onClose={onClose} size='' scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent pb='6' width='fit-content' minW='500px' minH='400px'>
        <ModalHeader>{customer.fullName}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ViewCustomer customer={customer} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
