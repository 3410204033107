import EditableInput from 'components/_inputs/EditableInput'

import { string } from 'yup'

import useMutation from 'hooks/useMutation'
import { UPDATE_SERVICE_TYPE_DETAILS } from 'apollo/mutations/serviceTypes'

export default function EditServiceTypeDetails ({ serviceType }) {
  const [updateDetails, { error, loading, reset }] = useMutation(UPDATE_SERVICE_TYPE_DETAILS, {
    notification: 'Successfully updated the details'
  })

  const handleSubmit = details => {
    const data = { id: serviceType.id, details }
    return updateDetails({ variables: { data } })
  }

  return (
    <EditableInput
      name='details'
      value={serviceType.details || ''}
      label='Details'
      onSubmit={handleSubmit}
      validation={string().required()}
      isLoading={loading}
      error={error}
      reset={reset}
    />
  )
}
