import { ApolloClient, InMemoryCache, HttpLink, split } from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'

const authContext = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      AUTH_SESSION_ID: window.localStorage.getItem('AUTH_SESSION_ID')
    }
  }
})

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL
})

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_SUBSCRIPTION_URL,
  options: {
    reconnect: true
  }
})

// After initializing both a WebSocketLink and an HttpLink, we use the split function
// to combine those two Links into a single Link that uses one or the other according
// to the type of operation being executed.
const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  authContext.concat(httpLink)
)

// always use incoming array value as new value
const merge = (existing, incoming) => incoming

const cache = new InMemoryCache({
  typePolicies: {
    AppConfig: {
      keyFields: ['key']
    },
    User: {
      fields: {
        stores: { merge },
        phoneNumbers: { merge },
        permissions: { merge },
        requestedLoginApprovals: { merge }
      }
    },
    Customer: {
      fields: {
        services: { merge },
        tickets: { merge },
        transactions: { merge },
        phoneNumbers: { merge }
      }
    },
    Service: {
      fields: {
        notes: {
          merge,
          keyArgs: false
        }
      }
    },
    Query: {
      fields: {
        getAllUsers: { merge },
        getAllCustomers: { merge },
        getAllStores: { merge },
        getAllServices: { merge },
        getAllServiceTypes: { merge },
        getAllProductTypes: { merge },
        getAllTickets: { merge },
        getTodaysTopupServices: { merge },
        getAllActivities: { merge }
      }
    }
  }
})

const defaultOptions = {
  watchQuery: {
    errorPolicy: 'all'
  },
  query: {
    errorPolicy: 'all'
  },
  mutate: {
    errorPolicy: 'all'
  }
}

export default new ApolloClient({ link, cache, defaultOptions })
