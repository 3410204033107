import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels
} from '@chakra-ui/react'

import ViewCustomerGeneral from 'components/customers/view/ViewCustomerGeneral'
import ViewCustomerServices from 'components/customers/view/ViewCustomerServices'
import ViewCustomerTransactions from 'components/customers/view/ViewCustomerTransactions'
import ViewCustomerTickets from 'components/customers/view/ViewCustomerTickets'
import ViewCustomerReminders from 'components/customers/view/ViewCustomerReminders'

export default function ViewCustomer ({ customer }) {
  return (
    <Tabs isLazy>
      <TabList>
        <Tab>General</Tab>
        <Tab>Services</Tab>
        <Tab>Transactions</Tab>
        <Tab>Tickets</Tab>
        <Tab>Reminders</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <ViewCustomerGeneral inline customer={customer} />
        </TabPanel>
        <TabPanel>
          <ViewCustomerServices inline customer={customer} />
        </TabPanel>
        <TabPanel>
          <ViewCustomerTransactions inline customer={customer} />
        </TabPanel>
        <TabPanel>
          <ViewCustomerTickets inline customer={customer} />
        </TabPanel>
        <TabPanel>
          <ViewCustomerReminders inline customer={customer} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
