import {
  Area,
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts'

export default function CustomAreaChart ({ data, ...props }) {
  const tickColor = 'var(--chakra-colors-gray-400)'
  const tooltipBgColor = 'white'
  const tooltipLabelColor = 'var(--chakra-colors-purple-700)'

  return (
    <ResponsiveContainer>
      <AreaChart data={data} {...props} margin={{ right: 20 }}>
        <CartesianGrid strokeDasharray='1 1' />
        <XAxis
          dataKey='day'
          angle={270}
          tickMargin={50}
          height={150}
          tick={{ stroke: tickColor }}
          interval={0}
        />
        <YAxis
          tick={{ stroke: tickColor }}
        />
        <Tooltip
          contentStyle={{ backgroundColor: tooltipBgColor, borderRadius: '10px' }}
          labelStyle={{ fontWeight: 'bold', textAlign: 'center' }}
          itemStyle={{ color: tooltipLabelColor, fontWeight: 'bold' }}
        />
        <Area type='monotone' dataKey='count' stroke='var(--chakra-colors-purple-400)' fill='var(--chakra-colors-purple-400)' />
      </AreaChart>
    </ResponsiveContainer>
  )
}
