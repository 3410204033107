import { useState, useCallback } from 'react'

import {
  Input,
  InputGroup,
  IconButton,
  InputLeftElement,
  InputRightElement
} from '@chakra-ui/react'

import { MdRefresh, MdClear } from 'react-icons/md'

import { debounce } from 'lodash'

export default function SearchMessages (props) {
  const [search, setSearch] = useState('')

  const {
    refetch,
    isRefetching,
    onSearch
  } = props

  // eslint-disable-next-line
  const debouncedSearch = useCallback(debounce(onSearch, 400), [])

  const handleChange = event => {
    const search = event.target.value.trimStart()
    setSearch(search)
    debouncedSearch(search.trim())
  }

  const handleClear = () => {
    setSearch('')
    debouncedSearch(undefined)
  }

  return (
    <InputGroup minW={['auto', '380px']}>
      <InputLeftElement>
        <IconButton
          size='sm'
          aria-label='Refresh data'
          fontSize='20px'
          icon={<MdRefresh />}
          onClick={() => refetch()}
          isLoading={isRefetching}
          variant='outline'
          border='none'
        />
      </InputLeftElement>
      <Input
        placeholder='Search messages ...'
        value={search}
        onChange={handleChange}
        size='md'
      />
      {search && (
        <InputRightElement>
          <IconButton icon={<MdClear />} fontSize='20px' onClick={handleClear} size='sm' variant='outline' border='none' />
        </InputRightElement>
      )}
    </InputGroup>
  )
}
