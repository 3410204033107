import Loading from 'components/_common/Loading'

import useQuery from 'hooks/useQuery'
import { GET_USER_PERMISSIONS } from 'apollo/queries/users'

import useAuthUser from 'hooks/useAuthUser'

import useMutation from 'hooks/useMutation'
import { TOGGLE_USER_PERMISSION } from 'apollo/mutations/users'

import SimpleTable from 'components/table/SimpleTable'

import {
  Tab,
  Tabs,
  Text,
  Modal,
  Stack,
  Badge,
  Button,
  TabList,
  Checkbox,
  TabPanel,
  TabPanels,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalCloseButton
} from '@chakra-ui/react'

import { startCase } from 'lodash'

const STORE_PERMISSIONS = [
  'ADD_EDIT_CUSTOMER',
  'DELETE_CUSTOMER',
  'MERGE_CUSTOMERS',
  'SEPERATE_CUSTOMERS',
  'ADD_EDIT_SERVICE',
  'DELETE_SERVICE',
  'TOP_UP',
  'SPECIAL_TOP_UP',
  'ADD_CREDITS',
  'REMOVE_CREDITS',
  'PUT_ON_HOLD',
  'REMOVE_ON_HOLD',
  'ADD_EDIT_TRANSACTION',
  'DELETE_TRANSACTION',
  'ADD_EDIT_SUPPORT_TICKET',
  'DELETE_SUPPORT_TICKET',
  'EDIT_EXPIRY_DATE',
  'VIEW_ALL_ACTIVITIES',
  'MESSAGE_SINGLE_CUSTOMER'
]

const VIEW_PERMISSIONS = [
  'VIEW_DASHBOARD',
  'VIEW_TOPUPS',
  'VIEW_CUSTOMERS',
  'VIEW_SERVICES',
  'VIEW_MY_TICKETS',
  'VIEW_ALL_TICKETS',
  'VIEW_ACTIVITIES',
  'VIEW_TRANSACTIONS',
  'VIEW_USERS',
  'VIEW_STORES',
  'VIEW_SHIPMENTS',
  'VIEW_SERVICE_TYPES',
  'VIEW_PRODUCT_TYPES',
  'VIEW_SOURCES',
  'VIEW_LANGUAGES',
  'VIEW_REPORTS'
]

const OTHER_PERMISSIONS = [
  'ADD_EDIT_PRODUCT_TYPE',
  'DELETE_PRODUCT_TYPE',
  'ADD_EDIT_SERVICE_TYPE',
  'DELETE_SERVICE_TYPE',
  'ADD_EDIT_STORE',
  'DELETE_STORE',
  'ADD_EDIT_SHIPMENT',
  'DELETE_SHIPMENT',
  'ADD_EDIT_SOURCE',
  'DELETE_SOURCE',
  'ADD_EDIT_LANGUAGE',
  'DELETE_LANGUAGE',
  'MESSAGE_MULTIPLE_CUSTOMERS'
]

export default function EditUserStorePermissions ({ inline = false, ...props }) {
  return inline ? <EditUserStorePermissionsInline {...props} /> : <EditUserStorePermissionsTrigger {...props} />
}

function EditUserStorePermissionsTrigger ({ user }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  if (user.role === 'ADMIN') {
    return (
      <Badge borderRadius='md'>Full Access</Badge>
    )
  }

  return (
    <>
      {isOpen && <EditUserStorePermissionsDialog user={user} onClose={onClose} />}
      <Button onClick={onOpen} size='xs' variant='outline'>
        Permissions
      </Button>
    </>
  )
}

function EditUserStorePermissionsInline ({ user: { id } }) {
  const { data: { user } = {}, loading, error } = useQuery(GET_USER_PERMISSIONS, { variables: { id } })

  const authUser = useAuthUser()

  if (loading || error) return <Loading />

  const storeRows = authUser.stores.reduce((obj, item) => ({ ...obj, [item.id]: item }), {})
  const rows = STORE_PERMISSIONS.map(permissionType => ({ permissionType, ...storeRows }))

  const storeFields = authUser.stores.map(store => (
    { key: store.id, label: store.name, justifyContent: 'center', renderTD: ({ storePermission: { permissionType } }) => <ToggleStorePermission permissionType={permissionType} store={store} user={user} /> }
  ))

  const fields = [
    { key: 'permissionType', label: 'Permission', width: '250px', renderTD: ({ storePermission: { permissionType } }) => <Text>{startCase(permissionType)}</Text> },
    ...storeFields
  ]

  return (
    <Tabs isLazy>
      <TabList>
        <Tab>Store Permissions</Tab>
        <Tab>View Permissions</Tab>
        <Tab>Other Permissions</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <SimpleTable
            rows={rows}
            fields={fields}
            rowKey='permissionType'
            rowType='storePermission'
          />
        </TabPanel>
        <TabPanel>
          <Stack spacing='4' maxW='400px'>
            {VIEW_PERMISSIONS.map(permissionType => (
              <Stack key={permissionType} direction='row' justifyContent='space-between'>
                <Text fontSize='sm'>{startCase(permissionType)}</Text>
                <ToggleUserPermission permissionType={permissionType} user={user} />
              </Stack>
            ))}
          </Stack>
        </TabPanel>
        <TabPanel>
          <Stack spacing='4' maxW='400px'>
            {OTHER_PERMISSIONS.map(permissionType => (
              <Stack key={permissionType} direction='row' justifyContent='space-between'>
                <Text fontSize='sm'>{startCase(permissionType)}</Text>
                <ToggleUserPermission permissionType={permissionType} user={user} />
              </Stack>
            ))}
          </Stack>
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

function EditUserStorePermissionsDialog ({ user, onClose }) {
  return (
    <Modal isOpen onClose={onClose} scrollBehavior='inside' size=''>
      <ModalOverlay />
      <ModalContent pb='6' width='fit-content'>
        <ModalHeader>Manage Permissions</ModalHeader>
        <ModalCloseButton />
        <ModalBody d='flex'>
          <EditUserStorePermissionsInline user={user} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

function ToggleStorePermission ({ permissionType, store, user }) {
  const [toggleUserPermission] = useMutation(TOGGLE_USER_PERMISSION, {
    notification: 'Successfully updated permission',
    isPromise: false
  })

  const findUserStorePermissions = user.permissions.find(({ storeID }) => storeID === store.id)?.permissionTypes || []

  const toggleSelect = () => {
    const data = { userID: user.id, storeID: store.id, permissionType }
    return toggleUserPermission({ variables: { data } })
  }

  return (
    <Checkbox
      size='lg'
      isChecked={findUserStorePermissions.includes(permissionType)}
      onChange={toggleSelect}
      aria-label='Select'
    />
  )
}

function ToggleUserPermission ({ permissionType, user }) {
  const [toggleUserPermission] = useMutation(TOGGLE_USER_PERMISSION, {
    notification: 'Successfully updated permission',
    isPromise: false
  })

  const findUserPermissions = user.permissions.find(({ storeID }) => !storeID)?.permissionTypes || []

  const toggleSelect = () => {
    const data = { userID: user.id, permissionType }
    return toggleUserPermission({ variables: { data } })
  }

  return (
    <Checkbox
      size='lg'
      isChecked={findUserPermissions.includes(permissionType)}
      onChange={toggleSelect}
      aria-label='Select'
    />
  )
}
