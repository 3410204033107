import TableWrapper from 'components/table/TableWrapper'

import { GET_ALL_TICKETS } from 'apollo/queries/tickets'
import {
  selectedTicketsVar,
  ticketFilterWhereVar,
  ticketFilterOrderByVar
} from 'utils/cache'

import fields from 'components/tickets/fields'

import TicketsSelectionMenu from 'components/tickets/select/TicketsSelectionMenu'

import CreateCustomerTicket from 'components/tickets/CreateCustomerTicket'

import {
  Modal,
  Button,
  ModalBody,
  ButtonGroup,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalCloseButton
} from '@chakra-ui/react'

export default function ViewCustomerTickets ({ inline = false, ...props }) {
  return inline ? <ViewCustomerTicketsInline {...props} /> : <OpenCustomerTicketsTrigger {...props} />
}

function ViewCustomerTicketsInline ({ customer }) {
  const additionalWhere = {}
  if (window.localStorage.getItem('ACTIVE_STORE') !== 'ALL') {
    additionalWhere.customer = {
      store: { id: { equals: window.localStorage.getItem('ACTIVE_STORE') } }
    }
  }

  additionalWhere.customer = {
    ...additionalWhere?.customer,
    id: { equals: customer.id }
  }

  const hideFields = ['customer']

  return (
    <TableWrapper
      isEmbedded
      query={GET_ALL_TICKETS}
      fields={fields}
      hideFields={hideFields}
      rowKey='id'
      rowType='ticket'
      fuzzyKey='tickets'
      defaultOrderBy={[{ date: 'desc' }]}
      additionalWhere={additionalWhere}
      filterWhereVar={ticketFilterWhereVar}
      filterOrderByVar={ticketFilterOrderByVar}
      selectionVar={selectedTicketsVar}
      selectionComponent={TicketsSelectionMenu}
    />
  )
}

function OpenCustomerTicketsTrigger ({ customer }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      {isOpen && <OpenCustomerTickets customer={customer} onClose={onClose} />}
      <ButtonGroup isAttached variant='outline'>
        <Button mr='-px' onClick={onOpen} size='xs' variant='outline' isDisabled={customer.tickets.length === 0}>
          {customer.tickets.length} | Tickets
        </Button>
        <CreateCustomerTicket customer={customer} />
      </ButtonGroup>
    </>
  )
}

export function OpenCustomerTickets ({ customer, onClose }) {
  return (
    <Modal isOpen onClose={onClose} size='' scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent pb='6' width='80vw'>
        <ModalHeader>Tickets</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ViewCustomerTickets inline customer={customer} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
