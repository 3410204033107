import {
  Box,
  Image,
  Heading
} from '@chakra-ui/react'

import LogoSVG from 'images/logo.svg'

import LoginForm from 'components/auth/LoginForm'

export default function Login () {
  return (
    <Box height='100%' bg='#22193b' py='12' px='8'>
      <Box maxW='md' mx='auto'>
        <Box mb={{ base: '12', md: '24' }} textAlign='center'>
          <Image src={LogoSVG} mx='auto' h='16' />
          <Heading
            fontSize='5xl'
            color='white'
            letterSpacing='8px'
          >
            BILLING
          </Heading>
        </Box>
        <LoginForm />
      </Box>
    </Box>
  )
}
