import useQuery from 'hooks/useQuery'
import { GET_ALL_LANGUAGES } from 'apollo/queries/languages'

import useMutation from 'hooks/useMutation'
import { UPDATE_CUSTOMER_LANGUAGE } from 'apollo/mutations/customers'

import Select from 'components/_select/CustomSelect'

import Loading from 'components/_common/Loading'

export default function EditCustomerLanguage ({ existing = false, ...props }) {
  return existing ? <EditCustomerLanguageExisting {...props} /> : <EditCustomerLanguageNew {...props} />
}

function EditCustomerLanguageExisting ({ customer, onSubmit }) {
  const { data, loading: loadingLanguages, error: errorLanguages } = useQuery(GET_ALL_LANGUAGES)

  const languages = data?.response?.allData || []

  const options = languages.map(language => ({ value: language.id, label: language.name }))

  const [updateLanguage, { error, loading }] = useMutation(UPDATE_CUSTOMER_LANGUAGE, {
    notification: 'Successfully updated the language',
    isPromise: false
  })

  const handleSubmit = ({ value: languageID }) => {
    const data = { id: customer.id, languageID }
    return updateLanguage({ variables: { data } })
  }

  if (loadingLanguages || errorLanguages) return <Loading />

  if (loading || error) return <Loading />

  return (
    <Select
      options={options}
      onChange={handleSubmit}
      maxMenuHeight='200px'
      defaultValue={options.find(({ value }) => value === customer.language?.id)}
      menuPlacement='top'
    />
  )
}

function EditCustomerLanguageNew ({ onSubmit }) {
  const { data, loading: loadingLanguages, error: errorLanguages } = useQuery(GET_ALL_LANGUAGES)

  const languages = data?.response?.allData || []

  const options = languages.map(language => ({ value: language.id, label: language.name }))

  const handleSubmit = ({ value, label }) => {
    const data = { id: value, name: label }
    return onSubmit(data)
  }

  if (loadingLanguages || errorLanguages) return <Loading />

  return (
    <Select
      options={options}
      onChange={handleSubmit}
      maxMenuHeight='200px'
      menuPlacement='top'
    />
  )
}
