import useMutation from 'hooks/useMutation'
import { UPDATE_SERVICE_CREATED_DATE } from 'apollo/mutations/services'

import DateTimePicker from 'components/_inputs/DateTimePicker'

export default function EditServiceCreatedDate ({ service, inline = false }) {
  const [updateServiceCreatedDate, { error, loading, reset }] = useMutation(UPDATE_SERVICE_CREATED_DATE, {
    notification: 'Successfully updated the created date'
  })

  const handleSubmit = createdDate => {
    const data = { id: service.id, createdDate }
    return updateServiceCreatedDate({ variables: { data } })
  }

  return (
    <DateTimePicker
      inline={inline}
      label='Created Date'
      value={service.createdDate}
      onChange={handleSubmit}
      error={error}
      loading={loading}
      reset={reset}
    />
  )
}
