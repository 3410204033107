import { useState } from 'react'

import useMutation from 'hooks/useMutation'
import { APPROVE_LOGIN_APPROVAL, DENY_LOGIN_APPROVAL } from 'apollo/mutations/loginApprovals'

import {
  Icon,
  Text,
  Modal,
  Badge,
  Stack,
  Button,
  ModalBody,
  IconButton,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalCloseButton
} from '@chakra-ui/react'

import { BiPencil } from 'react-icons/bi'
import { FcApprove, FcDisapprove } from 'react-icons/fc'

import ErrorAlert from 'components/_common/ErrorAlert'

export default function EditLoginApprovalStatusTrigger ({ loginApproval }) {
  const [isEditVisible, setIsEditVisible] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      {isOpen && <EditLoginApprovalStatus loginApproval={loginApproval} onClose={onClose} />}
      <Stack
        direction='row'
        alignItems='center'
        width='100%'
        onMouseEnter={() => setIsEditVisible(true)}
        onMouseLeave={() => setIsEditVisible(false)}
      >
        <Badge
          colorScheme={loginApproval.status === 'APPROVED' ? 'green' : loginApproval.status === 'ACTIVE' ? 'orange' : 'red'}
          borderRadius='lg'
        >
          {loginApproval.status}
        </Badge>
        {isEditVisible && <IconButton size='xs' variant='outline' icon={<BiPencil fontSize='16px' />} onClick={onOpen} />}
      </Stack>
    </>
  )
}

function EditLoginApprovalStatus ({ onClose, loginApproval }) {
  const handleClose = () => {
    resetApprove()
    resetDeny()
    onClose()
  }

  const [approveLogin, { error: approveError, loading: approveLoading, reset: resetApprove }] = useMutation(APPROVE_LOGIN_APPROVAL, {
    notification: 'Approved the login',
    isPromise: false,
    onCompleted: handleClose
  })

  const [denyLogin, { error: denyError, loading: denyLoading, reset: resetDeny }] = useMutation(DENY_LOGIN_APPROVAL, {
    notification: 'Denied the login',
    isPromise: false,
    onCompleted: handleClose
  })

  const onApprove = () => {
    const today = new Date()
    const expiresAt = loginApproval.expiresAt ? new Date(loginApproval.expiresAt) : today
    expiresAt.setDate(today.getDate() + 14)
    const data = { id: loginApproval.id, expiresAt }
    approveLogin({ variables: { data } })
  }

  const onDeny = () => {
    denyLogin({ variables: { id: loginApproval.id } })
  }

  return (
    <Modal isOpen onClose={handleClose} scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent pb='6'>
        <ModalHeader>Login Approval</ModalHeader>
        <ModalCloseButton isDisabled={approveLoading || denyLoading} />
        <ModalBody>
          {approveError && <ErrorAlert>{approveError.message} </ErrorAlert>}
          {denyError && <ErrorAlert>{denyError.message} </ErrorAlert>}
          <Stack justifyContent='space-around' direction='row' alignItems='center'>
            <Button
              variant='outline'
              _hover={{ bg: 'purple.100' }}
              boxSize='140px'
              onClick={onApprove}
              borderRadius='xl'
              isDisabled={loginApproval.status === 'APPROVED'}
            >
              <Stack alignItems='center'>
                <Icon as={FcApprove} boxSize='20' />
                <Text fontWeight='semibold' fontSize='lg' color='green'>APPROVE</Text>
              </Stack>
            </Button>
            <Button
              variant='outline'
              _hover={{ bg: 'purple.100' }}
              boxSize='140px'
              onClick={onDeny}
              borderRadius='xl'
              isDisabled={loginApproval.status === 'DENIED'}
            >
              <Stack alignItems='center'>
                <Icon as={FcDisapprove} boxSize='20' />
                <Text fontWeight='semibold' fontSize='lg' color='red'>DENY</Text>
              </Stack>
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
