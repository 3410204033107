import { gql } from '@apollo/client'

export const CREATE_SERVICE = gql`
  mutation CREATE_SERVICE ($data: CreateServiceInput!) {
    createService(data: $data) {
      id
      customer {
        id
        services {
          id
          credits
        }
      }
    }
  }
`

export const UPDATE_SERVICE_MAC_ID = gql`
  mutation UPDATE_SERVICE_MAC_ID ($data: UpdateServiceInput!) {
    updateService(data: $data) {
      id
      macID
      updatedAt
    }
  }
`

export const UPDATE_SERVICE_ACCOUNT_NUMBER = gql`
  mutation UPDATE_SERVICE_ACCOUNT_NUMBER ($data: UpdateServiceInput!) {
    updateService(data: $data) {
      id
      accountNumber
      updatedAt
    }
  }
`

export const UPDATE_SERVICE_CREATED_DATE = gql`
  mutation UPDATE_SERVICE_CREATED_DATE ($data: UpdateServiceInput!) {
    updateService(data: $data) {
      id
      createdDate
      updatedAt
    }
  }
`

export const UPDATE_SERVICE_SERVICE_TYPE = gql`
  mutation UPDATE_SERVICE_SERVICE_TYPE ($data: UpdateServiceInput!) {
    updateService(data: $data) {
      id
      serviceType {
        id
      }
      updatedAt
    }
  }
`

export const UPDATE_SERVICE_PRODUCT_TYPE = gql`
  mutation UPDATE_SERVICE_PRODUCT_TYPE ($data: UpdateServiceInput!) {
    updateService(data: $data) {
      id
      productType {
        id
      }
      updatedAt
    }
  }
`

export const UPDATE_SERVICE_EXPIRY_DATE = gql`
  mutation UPDATE_SERVICE_EXPIRY_DATE ($data: UpdateServiceInput!) {
    updateService(data: $data) {
      id
      expiryDate
      updatedAt
    }
  }
`

export const UPDATE_SERVICE_INACTIVE = gql`
  mutation UPDATE_SERVICE_INACTIVE ($data: UpdateServiceInput!) {
    updateService(data: $data) {
      id
      inactive
      updatedAt
    }
  }
`

export const UPDATE_SERVICE_IS_MONTHLY = gql`
  mutation UPDATE_SERVICE_IS_MONTHLY ($data: UpdateServiceInput!) {
    updateService(data: $data) {
      id
      isMonthly
      updatedAt
    }
  }
`

export const UPDATE_SERVICE_CREDITS = gql`
  mutation UPDATE_SERVICE_CREDITS ($data: AddCreditsInput!) {
    addCredits(data: $data) {
      id
      credits
      expiryDate
      onHoldDate
      updatedAt
      notes {
        id
        createdAt
        details
        markAsDone
        user {
          id
          fullName
          role
          isDeleted
        }
        updatedAt
      }
    }
  }
`

export const TOP_UP_SERVICE = gql`
  mutation TOP_UP_SERVICE ($data: TopupInput!) {
    topup(data: $data) {
      id
      credits
      expiryDate
      onHoldDate
      updatedAt
    }
  }
`

export const PUT_SERVICE_ON_HOLD = gql`
  mutation PUT_SERVICE_ON_HOLD ($data: PutOnHoldInput!) {
    putOnHold(data: $data) {
      id
      onHoldDate
      putOnHold
      updatedAt
    }
  }
`

export const REMOVE_SERVICE_ON_HOLD = gql`
  mutation REMOVE_SERVICE_ON_HOLD ($id: String!) {
    removeOnHold(id: $id) {
      id
      onHoldDate
      putOnHold
      updatedAt
    }
  }
`

export const DELETE_SERVICE = gql`
  mutation DELETE_SERVICE ($id: String!) {
    deleteService(id: $id) {
      id
    }
  }
`

export const ADD_SERVICE_NOTE = gql`
  mutation ADD_SERVICE_NOTE ($data: AddServiceNotesInput!) {
    addServiceNotes(data: $data) {
      id
      notes {
        id
        createdAt
        details
        markAsDone
        user {
          id
          fullName
          role
          isDeleted
        }
        updatedAt
      }
    }
  }
`

export const UPDATE_SERVICE_NOTE_DETAILS = gql`
  mutation UPDATE_SERVICE_NOTE_DETAILS ($data: UpdateNotesInput!) {
    updateServiceNotes(data: $data) {
      id
      notes {
        id
        createdAt
        details
        markAsDone
        user {
          id
          fullName
          role
          isDeleted
        }
        updatedAt
      }
    }
  }
`

export const UPDATE_SERVICE_NOTE_MARK_AS_DONE = gql`
  mutation UPDATE_SERVICE_NOTE_MARK_AS_DONE ($data: UpdateNotesInput!) {
    updateServiceNotes(data: $data) {
      id
      notes {
        id
        createdAt
        details
        markAsDone
        user {
          id
          fullName
          role
          isDeleted
        }
        updatedAt
      }
    }
  }
`

export const UPDATE_SERVICE_CUSTOMER = gql`
  mutation UPDATE_SERVICE_CUSTOMER ($data: UpdateServiceInput!) {
    updateService(data: $data) {
      id
      customer {
        id
      }
      updatedAt
    }
  }
`
