import EditableInput from 'components/_inputs/EditableInput'

import { string } from 'yup'

import useMutation from 'hooks/useMutation'
import { UPDATE_SOURCE_NAME } from 'apollo/mutations/sources'

export default function EditSourceName ({ source }) {
  const [updateName, { error, loading, reset }] = useMutation(UPDATE_SOURCE_NAME, {
    notification: 'Successfully updated the name'
  })

  const handleSubmit = name => {
    const data = { id: source.id, name }
    return updateName({ variables: { data } })
  }

  return (
    <EditableInput
      name='name'
      value={source.name || ''}
      label='Name'
      onSubmit={handleSubmit}
      validation={string().required()}
      isLoading={loading}
      error={error}
      reset={reset}
    />
  )
}
