import useAuthUser from 'hooks/useAuthUser'

import TableWrapper from 'components/table/TableWrapper'

import { GET_ALL_SERVICES } from 'apollo/queries/services'
import {
  selectedServicesVar,
  serviceFilterWhereVar,
  serviceFilterOrderByVar
} from 'utils/cache'

import fields from 'components/services/fields'

import ServicesSelectionMenu from 'components/services/select/ServicesSelectionMenu'

import CreateCustomerService from 'components/services/CreateCustomerService'

import { doesUserHavePermissionType } from 'utils/permissions'

import {
  Modal,
  Button,
  ModalBody,
  ButtonGroup,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalCloseButton
} from '@chakra-ui/react'

export default function ViewCustomerServices ({ inline = false, ...props }) {
  return inline ? <ViewCustomerServicesInline {...props} /> : <OpenCustomerServicesTrigger {...props} />
}

function ViewCustomerServicesInline ({ customer }) {
  const authUser = useAuthUser()

  const additionalWhere = {}
  if (window.localStorage.getItem('ACTIVE_STORE') !== 'ALL') {
    additionalWhere.customer = {
      store: { id: { equals: window.localStorage.getItem('ACTIVE_STORE') } }
    }
  }

  additionalWhere.customer = {
    ...additionalWhere?.customer,
    id: { equals: customer.id }
  }

  const hideFields = ['customer']

  if (!doesUserHavePermissionType(authUser, 'SPECIAL_TOP_UP')) {
    hideFields.push('topUp')
  }

  return (
    <TableWrapper
      isEmbedded
      query={GET_ALL_SERVICES}
      fields={fields}
      hideFields={hideFields}
      rowKey='id'
      rowType='service'
      fuzzyKey='services'
      defaultOrderBy={[{ expiryDate: 'asc' }]}
      additionalWhere={additionalWhere}
      filterWhereVar={serviceFilterWhereVar}
      filterOrderByVar={serviceFilterOrderByVar}
      selectionVar={selectedServicesVar}
      selectionComponent={ServicesSelectionMenu}
    />
  )
}

function OpenCustomerServicesTrigger ({ customer }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      {isOpen && <OpenCustomerServices customer={customer} onClose={onClose} />}
      <ButtonGroup isAttached variant='outline'>
        <Button mr='-px' onClick={onOpen} size='xs' variant='outline' isDisabled={customer.services.length === 0}>
          {customer.services.filter(({ inactive }) => !inactive).length} | Services
        </Button>
        <CreateCustomerService customer={customer} />
      </ButtonGroup>
    </>
  )
}

export function OpenCustomerServices ({ customer, onClose }) {
  return (
    <Modal isOpen onClose={onClose} size='' scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent pb='6' width='80vw'>
        <ModalHeader>Services</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ViewCustomerServices inline customer={customer} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
