import { useEffect } from 'react'

import {
  Text,
  Input,
  Button,
  Stack,
  FormLabel,
  FormControl,
  FormErrorMessage
} from '@chakra-ui/react'

import FormDialog from 'components/_common/FormDialog'

import AlertDialog from 'components/_common/AlertDialog'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'

import useMutation from 'hooks/useMutation'
import { FORGOT_PASSWORD } from 'apollo/mutations/auth'

const schemaValidation = object().shape({
  email: string().email().required()
})

export default function ForgotPassword ({ onClose }) {
  const handleClose = () => {
    resetMutation()
    resetForm()
    onClose()
  }

  const [forgotPassword, { data, error, loading, reset: resetMutation }] = useMutation(FORGOT_PASSWORD, {
    notification: null,
    isPromise: false
  })

  const { register, handleSubmit, formState: { errors }, reset: resetForm, setFocus } = useForm({
    resolver: yupResolver(schemaValidation)
  })

  useEffect(() => { setTimeout(() => setFocus('email'), 1) }, [])

  const onSubmit = ({ email }) => {
    const fingerprint = window.localStorage.getItem('FINGERPRINT')
    const data = { fingerprint, email }
    forgotPassword({ variables: { data } })
  }

  if (data?.forgotPassword) {
    return (
      <AlertDialog isCentered status='success'>
        Sent an email with a link to reset your password <br />
        <Button mt='4' onClick={onClose}>Close</Button>
      </AlertDialog>
    )
  }

  return (
    <FormDialog
      isCentered
      title='Forgot your password?'
      formID='#forgotPassword'
      label='Send Email'
      error={error}
      loading={loading}
      onClose={handleClose}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Text fontSize='sm' mb='4'>
        Don't worry! Just fill in your email and we'll send you a link to reset your password.
      </Text>
      <Stack spacing='8'>
        <FormControl id='email' isRequired isInvalid={errors?.email}>
          <FormLabel>Email</FormLabel>
          <Input {...register('email')} type='email' autoComplete='email' />
          <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
        </FormControl>
      </Stack>
    </FormDialog>
  )
}
