import { gql } from '@apollo/client'

export const CREATE_USER = gql`
  mutation CREATE_USER ($data: CreateUserInput!) {
    createUser(data: $data) {
      id
    }
  }
`

export const UPDATE_USER_EMAIL = gql`
  mutation UPDATE_USER_EMAIL ($data: UpdateUserInput!) {
    updateUser(data: $data) {
      id
      fullName
      updatedAt
    }
  }
`

export const UPDATE_USER_FULL_NAME = gql`
  mutation UPDATE_USER_FULL_NAME ($data: UpdateUserInput!) {
    updateUser(data: $data) {
      id
      fullName
      updatedAt
    }
  }
`

export const UPDATE_USER_PRIMARY_PHONE_NUMBER = gql`
  mutation UPDATE_USER_PRIMARY_PHONE_NUMBER ($data: UpdateUserInput!) {
    updateUser(data: $data) {
      id
      primaryPhoneNumber
      updatedAt
    }
  }
`

export const UPDATE_USER_ACCOUNT_LOCK = gql`
  mutation UPDATE_USER_ACCOUNT_LOCK ($data: UpdateUserInput!) {
    updateUser(data: $data) {
      id
      isLocked
      updatedAt
    }
  }
`

export const UPDATE_USER_OTP = gql`
  mutation UPDATE_USER_OTP ($data: UpdateUserInput!) {
    updateUser(data: $data) {
      id
      requiredOTP
      updatedAt
    }
  }
`

export const UPDATE_USER_PASSWORD = gql`
  mutation UPDATE_USER_PASSWORD ($data: UpdateUserInput!) {
    updateUser(data: $data) {
      id
      updatedAt
    }
  }
`

export const DELETE_USER = gql`
  mutation DELETE_USER ($id: String!) {
    deleteUser(id: $id) {
      id
      isDeleted
    }
  }
`

export const ADD_USER_PHONE_NUMBER = gql`
  mutation ADD_USER_PHONE_NUMBER ($data: AddPhoneNumberInput!) {
    addUserPhoneNumber(data: $data) {
      id
      phoneNumbers {
        type
        value
      }
    }
  }
`

export const UPDATE_USER_PHONE_NUMBER = gql`
  mutation UPDATE_USER_PHONE_NUMBER ($data: UpdatePhoneNumberInput!) {
    updateUserPhoneNumber(data: $data) {
      id
      phoneNumbers {
        id
        type
        value
      }
    }
  }
`

export const DELETE_USER_PHONE_NUMBER = gql`
  mutation DELETE_USER_PHONE_NUMBER ($id: String!) {
    deleteUserPhoneNumber(id: $id) {
      id
      phoneNumbers {
        id
        type
        value
      }
    }
  }
`

export const TOGGLE_USER_PERMISSION = gql`
  mutation TOGGLE_USER_PERMISSION ($data: updatePermissionInput!) {
    updatePermission(data: $data) {
      id
      permissions {
        storeID
        permissionTypes
      }
    }
  }
`

export const SEND_USER_MESSAGE = gql`
  mutation SEND_USER_MESSAGE ($data: SingleMessageInput!) {
    sendMessageToAUser(data: $data) {
      id
    }
  }
`
