import { Switch } from '@chakra-ui/react'

import useMutation from 'hooks/useMutation'
import { UPDATE_SERVICE_NOTE_MARK_AS_DONE } from 'apollo/mutations/services'

export default function EditServiceNoteMarkAsDone ({ note }) {
  const [updateServiceNoteMarkAsDone] = useMutation(UPDATE_SERVICE_NOTE_MARK_AS_DONE, {
    notification: 'Successfully updated the note mark as done',
    isPromise: false
  })

  const handleChange = () => {
    const data = { id: note.id, markAsDone: !note.markAsDone }
    updateServiceNoteMarkAsDone({ variables: { data } })
  }

  return (
    <Switch
      isChecked={note.markAsDone}
      onChange={handleChange}
    />
  )
}
