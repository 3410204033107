import {
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts'

export default function CustomBarChart ({ data, dataKey = 'label', ...props }) {
  const tickColor = 'var(--chakra-colors-gray-400)'
  const tooltipBgColor = 'white'
  const tooltipLabelColor = 'var(--chakra-colors-purple-700)'

  return (
    <ResponsiveContainer>
      <BarChart data={data} {...props}>
        <CartesianGrid strokeDasharray='1 1' />
        <XAxis
          dataKey={dataKey}
          angle={270}
          tickMargin={40}
          height={100}
          tick={{ stroke: tickColor }}
          interval={0}
        />
        <YAxis
          tick={{ stroke: tickColor }}
        />
        <Tooltip
          contentStyle={{ backgroundColor: tooltipBgColor, borderRadius: '10px' }}
          labelStyle={{ fontWeight: 'bold', textAlign: 'center' }}
          itemStyle={{ color: tooltipLabelColor, fontWeight: 'bold' }}
        />
        <Bar dataKey='count' fill='var(--chakra-colors-purple-400)' maxBarSize={20} />
      </BarChart>
    </ResponsiveContainer>
  )
}
