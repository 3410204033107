import EditableInput from 'components/_inputs/EditableInput'

import useMutation from 'hooks/useMutation'
import { UPDATE_SERVICE_TYPE_REMINDER_DAYS } from 'apollo/mutations/serviceTypes'

export default function EditServiceTypeReminderDays ({ serviceType, justifyContent }) {
  const [updateReminderDays, { error, loading, reset }] = useMutation(UPDATE_SERVICE_TYPE_REMINDER_DAYS, {
    notification: 'Successfully updated the reminderDays'
  })

  const handleSubmit = reminderDays => {
    const data = { id: serviceType.id, reminderDays: reminderDays || null }
    return updateReminderDays({ variables: { data } })
  }

  return (
    <EditableInput
      name='reminderDays'
      type='number'
      numberInputProps={{ min: 1, max: 30 }}
      value={serviceType.reminderDays || ''}
      label='Reminder Days'
      onSubmit={handleSubmit}
      isLoading={loading}
      error={error}
      reset={reset}
      justifyContent={justifyContent}
    />
  )
}
