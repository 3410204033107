import Select from 'components/_select/CustomSelect'

export default function TransactionTypesFilter ({ additionalVariables, setAdditionalVariables, isDisabled = false }) {
  const transactionTypes = [
    'SALES',
    'INVOICE',
    'REVERSE',
    'REFERRAL',
    'CREDIT'
  ]

  const options = transactionTypes.map(transactionType => ({ value: transactionType, label: transactionType }))
  options.unshift({ value: undefined, label: 'All Transaction Types' })

  const handleSelectTransactionType = ({ value: transactionType }) => {
    setAdditionalVariables({ ...additionalVariables, transactionType })
  }

  return (
    <Select
      value={options.find(({ value }) => value === additionalVariables.transactionType)}
      options={options}
      onChange={handleSelectTransactionType}
      maxMenuHeight='300px'
      isDisabled={isDisabled}
    />
  )
}
