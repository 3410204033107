import EditableInput from 'components/_inputs/EditableInput'

import { string } from 'yup'

import useMutation from 'hooks/useMutation'
import { UPDATE_STORE_NAME } from 'apollo/mutations/stores'

export default function EditStoreName ({ store }) {
  const [updateName, { error, loading, reset }] = useMutation(UPDATE_STORE_NAME, {
    notification: 'Successfully updated the name'
  })

  const handleSubmit = name => {
    const data = { id: store.id, name }
    return updateName({ variables: { data } })
  }

  return (
    <EditableInput
      name='name'
      value={store.name || ''}
      label='Name'
      onSubmit={handleSubmit}
      validation={string().required()}
      isLoading={loading}
      error={error}
      reset={reset}
    />
  )
}
