import useAuthUser from 'hooks/useAuthUser'

import useMutation from 'hooks/useMutation'
import { UPDATE_CUSTOMER_STORE } from 'apollo/mutations/customers'

import Select from 'components/_select/CustomSelect'

import Loading from 'components/_common/Loading'

export default function EditCustomerStore ({ existing = false, ...props }) {
  return existing ? <EditCustomerStoreExisting {...props} /> : <EditCustomerStoreNew {...props} />
}

function EditCustomerStoreExisting ({ customer, onSubmit }) {
  const authUser = useAuthUser()

  const stores = authUser.stores

  const options = stores.map(store => ({ value: store.id, label: store.name }))

  const [updateStore, { error, loading }] = useMutation(UPDATE_CUSTOMER_STORE, {
    notification: 'Successfully updated the store',
    isPromise: false
  })

  const handleSubmit = ({ value: storeID }) => {
    const data = { id: customer.id, storeID }
    return updateStore({ variables: { data } })
  }

  if (loading || error) return <Loading />

  return (
    <Select
      options={options}
      onChange={handleSubmit}
      maxMenuHeight='200px'
      defaultValue={options.find(({ value }) => value === customer.store?.id)}
      menuPlacement='top'
    />
  )
}

function EditCustomerStoreNew ({ onSubmit }) {
  const authUser = useAuthUser()

  const stores = authUser.stores

  const options = stores.map(store => ({ value: store.id, label: store.name }))

  const handleSubmit = ({ value, label }) => {
    const data = { id: value, name: label }
    return onSubmit(data)
  }

  return (
    <Select
      options={options}
      onChange={handleSubmit}
      maxMenuHeight='200px'
      defaultValue={window.localStorage.ACTIVE_STORE !== 'ALL' ? options.find(({ value }) => value === window.localStorage.ACTIVE_STORE) : options[0]}
    />
  )
}
