import { gql } from '@apollo/client'

export const GET_ALL_APP_CONFIGS = gql`
  query GET_ALL_APP_CONFIGS {
    getAllAppConfigs {
      settings: appConfigs {
        value
        key
      }
    }
  }
`
