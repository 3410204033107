import TableWrapper from 'components/table/TableWrapper'

import { GET_TODAYS_TOPUP_SERVICES } from 'apollo/queries/services'
import { serviceFilterWhereVar, serviceFilterOrderByVar } from 'utils/cache'

import EditServiceMacID from 'components/services/edit/EditServiceMacID'
import EditServiceAccountNumber from 'components/services/edit/EditServiceAccountNumber'
import OpenCustomer from 'components/customers/view/OpenCustomer'
import ManageCustomerPhoneNumbers from 'components/customers/edit/ManageCustomerPhoneNumbers'
import ViewServiceType from 'components/serviceTypes/view/ViewServiceType'
import ManageServiceTopup from 'components/services/edit/ManageServiceTopup'
import ViewServiceCredits from 'components/services/view/ViewServiceCredits'
import ViewServiceNotes from 'components/services/notes/ViewServiceNotes'
import DateTimeRenderer from 'components/_common/DateTimeRenderer'

import {
  Grid,
  Stack,
  Text
} from '@chakra-ui/react'

import ServiceTypesFilter from 'components/serviceTypes/view/ServiceTypesFilter'

const fields = [
  { key: 'macID', label: 'MAC', width: '180px', renderTD: props => <EditServiceMacID {...props} isReadOnly /> },
  { key: 'accountNumber', label: 'Acc Number', width: '150px', renderTD: EditServiceAccountNumber },
  { key: 'customer', label: 'Customer', width: '170px', renderTD: ({ service: { customer } }) => <OpenCustomer customer={customer} /> },
  { key: 'phoneNumber', label: 'Phone Number', width: '180px', renderTD: ({ service: { customer } }) => <ManageCustomerPhoneNumbers disabled customer={customer} /> },
  { key: 'serviceType', label: 'Service Type', width: '150px', renderTD: ({ service: { serviceType } }) => <ViewServiceType serviceType={serviceType} /> },
  { key: 'credits', label: 'Credits', width: '80px', justifyContent: 'center', renderTD: ViewServiceCredits },
  { key: 'topUp', label: 'TopUp', width: '100px', justifyContent: 'center', renderTD: ManageServiceTopup },
  { key: 'expiryDate', label: 'Expiry Date', width: '160px', justifyContent: 'center', renderTD: ({ service: { expiryDate } }) => <DateTimeRenderer value={expiryDate} /> },
  { key: 'actualExpiryDate', label: 'Actual Expiry', width: '160px', justifyContent: 'center', renderTD: ({ service: { actualExpiryDate } }) => <DateTimeRenderer value={actualExpiryDate} /> },
  { key: 'notes', label: 'Notes', renderTD: ViewServiceNotes }
]

export default function Topups ({ serviceType }) {
  if (serviceType) {
    const additionalWhere = { serviceType: { name: { equals: serviceType } } }

    if (window.localStorage.getItem('ACTIVE_STORE') !== 'ALL') {
      additionalWhere.customer = { store: { id: { equals: window.localStorage.getItem('ACTIVE_STORE') } } }
    }

    return (
      <Stack direction='row' width='100%'>
        <ServiceTypesFilter />
        <TableWrapper
          query={GET_TODAYS_TOPUP_SERVICES}
          fields={fields}
          rowKey='id'
          rowType='service'
          fuzzyKey='services'
          defaultOrderBy={[{ createdAt: 'asc' }, { id: 'asc' }]}
          additionalWhere={additionalWhere}
          filterWhereVar={serviceFilterWhereVar}
          filterOrderByVar={serviceFilterOrderByVar}
        />
      </Stack>
    )
  } else {
    return (
      <Stack direction='row' width='100%'>
        <ServiceTypesFilter />
        <Grid placeItems='center' width='100%'>
          <Text fontSize='4xl'>👈 Choose a Service Type</Text>
        </Grid>
      </Stack>
    )
  }
}
