import { gql } from '@apollo/client'

export const CREATE_TICKET = gql`
  mutation CREATE_TICKET ($data: CreateTicketInput!) {
    createTicket(data: $data) {
      id
      customer {
        id
        tickets {
          id
        }
      }
    }
  }
`

export const UPDATE_TICKET_STATUS = gql`
  mutation UPDATE_TICKET_STATUS ($data: UpdateTicketInput!) {
    updateTicket(data: $data) {
      id
      status
      updatedAt
    }
  }
`

export const ADD_TICKET_NOTE = gql`
  mutation ADD_TICKET_NOTE ($data: AddTicketNotesInput!) {
    addTicketNotes(data: $data) {
      id
      notes {
        id
        createdAt
        details
        markAsDone
        user {
          id
          fullName
          role
          isDeleted
        }
        updatedAt
      }
    }
  }
`

export const UPDATE_TICKET_NOTE_DETAILS = gql`
  mutation UPDATE_TICKET_NOTE_DETAILS ($data: UpdateNotesInput!) {
    updateTicketNotes(data: $data) {
      id
      notes {
        id
        createdAt
        details
        markAsDone
        user {
          id
          fullName
          role
          isDeleted
        }
        updatedAt
      }
    }
  }
`

export const UPDATE_TICKET_NOTE_MARK_AS_DONE = gql`
  mutation UPDATE_TICKET_NOTE_MARK_AS_DONE ($data: UpdateNotesInput!) {
    updateTicketNotes(data: $data) {
      id
      notes {
        id
        createdAt
        details
        markAsDone
        user {
          id
          fullName
          role
          isDeleted
        }
        updatedAt
      }
    }
  }
`

export const UPDATE_TICKET_EXPIRY = gql`
  mutation UPDATE_TICKET_EXPIRY ($data: UpdateTicketInput!) {
    updateTicket (data: $data) {
      id
      expiresAt
      updatedAt
    }
  }
`

export const UPDATE_TICKET_ASSIGNED_TO = gql`
  mutation UPDATE_TICKET_ASSIGNED_TO ($data: UpdateTicketInput!) {
    updateTicket (data: $data) {
      id
      assignedToUser {
        id
        fullName
        role
        isDeleted
      }
      updatedAt
    }
  }
`

export const DELETE_SUPPORT_TICKET = gql`
  mutation DELETE_SUPPORT_TICKET ($id: String!) {
    deleteTicket(id: $id) {
      id
    }
  }
`
