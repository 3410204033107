import { gql } from '@apollo/client'

export const APPROVE_LOGIN_APPROVAL = gql`
  mutation APPROVE_LOGIN_APPROVAL ($data: ApproveInput!) {
    approve (data: $data) {
      id
      expiresAt
      status
      processedAdmin {
        id
        fullName
      }
      approvedAt
    }
  }
`

export const EXTEND_LOGIN_APPROVAL = gql`
  mutation EXTEND_LOGIN_APPROVAL ($data: ApproveInput!) {
    extendLoginExpiry (data: $data) {
      id
      expiresAt
      processedAdmin {
        id
        fullName
      }
      approvedAt
    }
  }
`

export const DENY_LOGIN_APPROVAL = gql`
  mutation DENY_LOGIN_APPROVAL ($id: String!) {
    deny (id: $id) {
      id
      status
      processedAdmin {
        id
        fullName
      }
      approvedAt
    }
  }
`
