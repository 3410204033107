import { jsPDF as JsPDF } from 'jspdf'

import 'jspdf-autotable'

import jsonToTable from 'json-to-table'

export default (data) => {
  // Create a document
  const doc = new JsPDF('p', 'mm', 'a4')
  const table = jsonToTable(data.table)
  let y = 0
  doc.setFont('helvetica', 'bold')
  doc.setFontSize(20)
  doc.text(data.title, 105, y = y + 10, null, null, 'center')
  doc.setFont('courier', 'normal')
  doc.setFontSize(12)
  doc.text(`Start Date: ${data.startDate}`, 105, y = y + 10, null, null, 'center')
  doc.text(`End Date: ${data.endDate}`, 105, y = y + 10, null, null, 'center')
  if (data.userName) doc.text(`User: ${data.userName}`, 105, y = y + 10, null, null, 'center')
  if (data.customerName) doc.text(`Customer: ${data.customerName}`, 105, y = y + 10, null, null, 'center')
  if (data.serviceType) doc.text(`Service Type: ${data.serviceType}`, 105, y = y + 10, null, null, 'center')
  if (data.productType) doc.text(`Product Type: ${data.productType}`, 105, y = y + 10, null, null, 'center')
  if (data.shipment) doc.text(`Shipment: ${data.shipment}`, 105, y = y + 10, null, null, 'center')
  if (data.source) doc.text(`Source: ${data.source}`, 105, y = y + 10, null, null, 'center')
  if (data.store) doc.text(`Store: ${data.store}`, 105, y = y + 10, null, null, 'center')
  if (data.language) doc.text(`Language: ${data.language}`, 105, y = y + 10, null, null, 'center')
  if (data.postalCode) doc.text(`PostalCode: ${data.postalCode}`, 105, y = y + 10, null, null, 'center')
  if (data.transactionType) doc.text(`Transaction Type: ${data.transactionType}`, 105, y = y + 10, null, null, 'center')
  if (data.total) doc.text(`Total: ${data.total}`, 105, y = y + 10, null, null, 'center')
  doc.autoTable({
    theme: 'grid',
    startY: y = y + 10,
    head: [table.shift()],
    body: table
  })
  doc.save(data.pdfName + '_' + data.startDate.replaceAll(' ', '_').replaceAll(',', '') + '-' + data.endDate.replaceAll(' ', '_').replaceAll(',', '') + '.pdf')
  return doc
}
