import { useState } from 'react'

import {
  Menu,
  Icon,
  Button,
  MenuList,
  MenuItem,
  MenuButton,
  MenuDivider
} from '@chakra-ui/react'

import { HiChevronDown } from 'react-icons/hi'
import { MdDelete, MdClear } from 'react-icons/md'
import { BiSelectMultiple } from 'react-icons/bi'

import { useReactiveVar } from '@apollo/client'
import { selectedUsersVar } from 'utils/cache'

import DeleteUsers from 'components/users/DeleteUsers'

export default function UsersSelectionMenu ({ onSelectAll, refetch }) {
  const selectedVar = useReactiveVar(selectedUsersVar)

  const [isDeleteOpen, setIsDelete] = useState(false)

  const handleDeleteOnClose = (clear = false) => {
    setIsDelete(false)
    clear && selectedUsersVar({}) && refetch()
  }

  return (
    <>
      {isDeleteOpen && (
        <DeleteUsers users={selectedVar} onClose={handleDeleteOnClose} />
      )}
      <Menu>
        <MenuButton size='sm' as={Button} rightIcon={<HiChevronDown />} isDisabled={Object.keys(selectedVar).length === 0}>
          {Object.keys(selectedVar).length} Selected
        </MenuButton>
        <MenuList zIndex='2'>
          <MenuItem onClick={() => setIsDelete(true)}>
            <Icon as={MdDelete} mr={2} />
            <span>Delete</span>
          </MenuItem>
          <MenuDivider />
          <MenuItem onClick={onSelectAll}>
            <Icon as={BiSelectMultiple} mr={2} />
            <span>Select All</span>
          </MenuItem>
          <MenuItem onClick={() => selectedUsersVar({})}>
            <Icon as={MdClear} mr={2} />
            <span>Clear Selection</span>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  )
}
