import DateTimeRenderer from 'components/_common/DateTimeRenderer'
import OpenUser from 'components/users/view/OpenUser'
import ViewLoginApprovalAgentDetails from 'components/loginApprovals/view/ViewLoginApprovalAgentDetails'
import EditLoginApprovalStatus from 'components/loginApprovals/edit/EditLoginApprovalStatus'
import EditLoginApprovalExpiry from 'components/loginApprovals/edit/EditLoginApprovalExpiry'

const fields = [
  { key: 'requestedAt', label: 'Requested At', width: '230px', renderTD: ({ loginApproval: { requestedAt } }) => <DateTimeRenderer value={requestedAt} type='time' /> },
  { key: 'requestedUser', label: 'User', width: '200px', renderTD: ({ loginApproval: { requestedUser } }) => <OpenUser user={requestedUser} /> },
  { key: 'agentDetails', label: 'Device', width: '140px', renderTD: ViewLoginApprovalAgentDetails },
  { key: 'status', label: 'Status', width: '180px', renderTD: EditLoginApprovalStatus },
  { key: 'expiresAt', label: 'Expires At', renderTD: EditLoginApprovalExpiry },
  { key: 'processedAdmin', label: 'Approved By', renderTD: ({ loginApproval: { processedAdmin } }) => <OpenUser user={processedAdmin} /> },
  { key: 'approvedAt', label: 'Approved At', renderTD: ({ loginApproval: { approvedAt } }) => <DateTimeRenderer value={approvedAt} type='time' /> }
]

export default fields
