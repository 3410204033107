import { gql } from '@apollo/client'

export const CREATE_SERVICE_TYPE = gql`
  mutation CREATE_SERVICE_TYPE ($data: CreateServiceTypeInput!) {
    createServiceType(data: $data) {
      id
    }
  }
`

export const UPDATE_SERVICE_TYPE_NAME = gql`
  mutation UPDATE_SERVICE_TYPE_NAME ($data: UpdateServiceTypeInput!) {
    updateServiceType(data: $data) {
      id
      name
      updatedAt
    }
  }
`

export const UPDATE_SERVICE_TYPE_DETAILS = gql`
  mutation UPDATE_SERVICE_TYPE_DETAILS ($data: UpdateServiceTypeInput!) {
    updateServiceType(data: $data) {
      id
      details
      updatedAt
    }
  }
`

export const UPDATE_SERVICE_TYPE_TOPUP_DUE_DAYS = gql`
  mutation UPDATE_SERVICE_TYPE_TOPUP_DUE_DAYS ($data: UpdateServiceTypeInput!) {
    updateServiceType(data: $data) {
      id
      topupDueDays
      updatedAt
    }
  }
`

export const UPDATE_SERVICE_TYPE_REMINDER_DAYS = gql`
  mutation UPDATE_SERVICE_TYPE_REMINDER_DAYS ($data: UpdateServiceTypeInput!) {
    updateServiceType(data: $data) {
      id
      reminderDays
      updatedAt
    }
  }
`

export const UPDATE_SERVICE_TYPE_COLOR = gql`
  mutation UPDATE_SERVICE_TYPE_COLOR ($data: UpdateServiceTypeInput!) {
    updateServiceType(data: $data) {
      id
      color
      updatedAt
    }
  }
`

export const UPDATE_SERVICE_TYPE_ORDER = gql`
  mutation UPDATE_SERVICE_TYPE_ORDER ($data: UpdateServiceTypeInput!) {
    updateServiceType(data: $data) {
      id
      order
      updatedAt
    }
  }
`

export const DELETE_SERVICE_TYPE = gql`
  mutation DELETE_SERVICE_TYPE ($id: String!) {
    deleteServiceType(id: $id) {
      id
    }
  }
`
