import Select from 'components/_select/CustomSelect'

import useQuery from 'hooks/useQuery'
import { GET_ALL_PRODUCT_TYPES } from 'apollo/queries/productTypes'

import Loading from 'components/_common/Loading'
import AlertDialog from 'components/_common/AlertDialog'

export default function ProductTypesFilter ({ additionalVariables, setAdditionalVariables, isDisabled = false }) {
  const { data, loading, error } = useQuery(GET_ALL_PRODUCT_TYPES, {
    variables: { orderBy: { name: 'asc' } }
  })

  const handleSelectProductType = ({ value: productTypeID }) => {
    setAdditionalVariables({ ...additionalVariables, productTypeID })
  }

  const productTypes = data?.response?.allData || []

  if (loading) return <Loading />

  if (error) return <AlertDialog status='error'>{error.message}</AlertDialog>

  const options = productTypes.map(productType => ({ value: productType.id, label: productType.name }))
  options.unshift({ value: undefined, label: 'All Product Types' })

  return (
    <Select
      value={options.find(({ value }) => value === additionalVariables.productTypeID)}
      options={options}
      onChange={handleSelectProductType}
      maxMenuHeight='300px'
      isDisabled={isDisabled}
    />
  )
}
