import { gql } from '@apollo/client'

export const CREATE_STORE = gql`
  mutation CREATE_STORE ($data: CreateStoreInput!) {
    createStore(data: $data) {
      id
    }
  }
`

export const UPDATE_STORE_NAME = gql`
  mutation UPDATE_STORE_NAME ($data: UpdateStoreInput!) {
    updateStore(data: $data) {
      id
      name
      updatedAt
    }
  }
`

export const UPDATE_STORE_LOCATION = gql`
  mutation UPDATE_STORE_LOCATION ($data: UpdateStoreInput!) {
    updateStore(data: $data) {
      id
      location
      updatedAt
    }
  }
`

export const DELETE_STORE = gql`
  mutation DELETE_STORE ($id: String!) {
    deleteStore(id: $id) {
      id
    }
  }
`

export const ADD_USER_STORE = gql`
  mutation ADD_USER_STORE ($data: UserStoreInput!) {
    addUserStore(data: $data) {
      id
      stores {
        id
      }
    }
  }
`

export const REMOVE_USER_STORE = gql`
  mutation REMOVE_USER_STORE ($data: UserStoreInput!) {
    removeUserStore(data: $data) {
      id
      stores {
        id
      }
    }
  }
`
