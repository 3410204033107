import Checkbox from 'components/_inputs/Checkbox'

import { useReactiveVar } from '@apollo/client'
import { selectedStoresVar } from 'utils/cache'

export default function StoreCheckbox ({ store }) {
  const selectedVar = useReactiveVar(selectedStoresVar)

  const isChecked = Boolean(selectedVar[store.id])

  const toggleSelect = () => {
    if (isChecked) {
      const newSelection = { ...selectedVar }
      delete newSelection[store.id]
      selectedStoresVar(newSelection)
    } else {
      selectedStoresVar({
        ...selectedVar,
        [store.id]: store
      })
    }
  }

  return (
    <Checkbox isChecked={isChecked} toggleSelect={toggleSelect} />
  )
}
