import { useState } from 'react'

import {
  Text,
  Modal,
  Stack,
  ModalBody,
  IconButton,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalCloseButton
} from '@chakra-ui/react'

import { AiOutlineEye } from 'react-icons/ai'

export default function ViewLoginApprovalAgentDetails ({ loginApproval }) {
  const [isEditVisible, setIsEditVisible] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Stack
        direction='row'
        alignItems='center'
        width='100%'
        onMouseEnter={() => setIsEditVisible(true)}
        onMouseLeave={() => setIsEditVisible(false)}
      >
        <Text fontSize='sm' overflowX='hidden'>
          {loginApproval.agentDetails?.ip || 'Unknown'}
        </Text>
        {isEditVisible && <IconButton size='xs' variant='outline' icon={<AiOutlineEye fontSize='16px' />} onClick={onOpen} />}
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='3xl'>
        <ModalOverlay />
        <ModalContent pb='6'>
          <ModalHeader>Device Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text as='pre' fontSize='sm'>
              {JSON.stringify(loginApproval.agentDetails, null, 2)}
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
