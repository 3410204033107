import TableWrapper from 'components/table/TableWrapper'
import CustomersSelectionMenu from 'components/customers/select/CustomersSelectionMenu'
import CreateCustomer from 'components/customers/CreateCustomer'

import { GET_ALL_CUSTOMERS } from 'apollo/queries/customers'
import { selectedCustomersVar, customerFilterWhereVar, customerFilterOrderByVar } from 'utils/cache'

import fields from 'components/customers/fields'

const additionalWhere = { isDeleted: { equals: false } }
if (window.localStorage.getItem('ACTIVE_STORE') !== 'ALL') {
  additionalWhere.store = { id: { equals: window.localStorage.getItem('ACTIVE_STORE') } }
}

export default function Customers () {
  return (
    <TableWrapper
      query={GET_ALL_CUSTOMERS}
      fields={fields}
      rowKey='id'
      rowType='customer'
      fuzzyKey='customers'
      defaultOrderBy={[{ joinedDate: 'desc' }]}
      filterWhereVar={customerFilterWhereVar}
      filterOrderByVar={customerFilterOrderByVar}
      createComponent={CreateCustomer}
      selectionComponent={CustomersSelectionMenu}
      selectionVar={selectedCustomersVar}
      additionalWhere={additionalWhere}
    />
  )
}
