import TableWrapper from 'components/table/TableWrapper'

import { GET_ALL_LOGIN_APPROVALS } from 'apollo/queries/loginApprovals'
import { loginApprovalFilterWhereVar, loginApprovalFilterOrderByVar } from 'utils/cache'

import fields from 'components/loginApprovals/fields'

export default function LoginApprovals () {
  return (
    <TableWrapper
      query={GET_ALL_LOGIN_APPROVALS}
      fields={fields}
      rowKey='id'
      rowType='loginApproval'
      fuzzyKey='loginApprovals'
      defaultOrderBy={[{ requestedAt: 'desc' }]}
      filterWhereVar={loginApprovalFilterWhereVar}
      filterOrderByVar={loginApprovalFilterOrderByVar}
    />
  )
}
