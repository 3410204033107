import { useRef, useEffect, useCallback } from 'react'

import {
  Input,
  InputGroup,
  IconButton,
  InputLeftElement,
  InputRightElement
} from '@chakra-ui/react'

import { MdRefresh, MdClear } from 'react-icons/md'

import { debounce } from 'lodash'

import { useReactiveVar } from '@apollo/client'

export default function Search (props) {
  const searchRef = useRef()

  useEffect(() => {
    searchRef && searchRef.current.focus()
  }, [searchRef.current])

  const {
    refetch,
    isRefetching,
    filterVar,
    fuzzySearchVar
  } = props

  const fuzzySearch = useReactiveVar(fuzzySearchVar)

  const handleSearch = (value) => {
    // disable all active filters
    filterVar(filterVar().map(where => {
      if (where.relations) {
        return {
          ...where,
          relations: where.relations.map(relation => {
            return { ...relation, isActive: false }
          })
        }
      } else {
        return {
          ...where,
          isActive: false
        }
      }
    }))
    refetch(value)
  }

  // eslint-disable-next-line
  const debouncedSearch = useCallback(debounce(handleSearch, 400), [])

  const handleChange = event => {
    const search = event.target.value.trimStart()
    fuzzySearchVar(search)
    debouncedSearch(search.trim())
  }

  const handleClear = () => {
    fuzzySearchVar(undefined)
    debouncedSearch(undefined)
  }

  return (
    <InputGroup minW={['auto', '380px']}>
      <InputLeftElement>
        <IconButton
          size='sm'
          aria-label='Refresh data'
          fontSize='20px'
          icon={<MdRefresh />}
          onClick={() => refetch()}
          isLoading={isRefetching}
          variant='outline'
          border='none'
        />
      </InputLeftElement>
      <Input
        ref={searchRef}
        placeholder='Search ...'
        value={fuzzySearch || ''}
        onChange={handleChange}
        size='md'
      />
      {fuzzySearch && (
        <InputRightElement>
          <IconButton icon={<MdClear />} fontSize='20px' onClick={handleClear} size='sm' variant='outline' border='none' />
        </InputRightElement>
      )}
    </InputGroup>
  )
}
