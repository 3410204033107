import { useEffect } from 'react'

import { useApolloClient } from '@apollo/client'

import { GET_TOTAL_COUNT } from 'apollo/queries/dashboard'

import {
  Badge,
  useToast
} from '@chakra-ui/react'

export default function MyTicketsCount () {
  const toast = useToast()

  const client = useApolloClient()

  const { getTotalCount: data } = client.readQuery({ query: GET_TOTAL_COUNT }) || {}

  useEffect(() => {
    toast.closeAll()
    data?.ticket?.assignedToMe?.open > 0 && toast({
      title: `You have ${data?.ticket?.assignedToMe?.open} open tickets`,
      status: 'warning',
      position: 'top',
      duration: 4000,
      isClosable: true
    })
  }, [data?.ticket?.assignedToMe?.open])

  useEffect(() => {
    toast.closeAll()
    data?.ticket?.assignedByMe?.expired > 0 && toast({
      title: `There are ${data?.ticket?.assignedByMe?.expired} expired tickets assigned by you`,
      status: 'error',
      position: 'top',
      duration: 4000,
      isClosable: true
    })
  }, [data?.ticket?.assignedByMe?.expired])

  return (
    <Badge colorScheme='red' mb='1' fontSize='sm' borderRadius='md' px='2'>
      {data?.ticket?.assignedToMe?.unresolved}
    </Badge>
  )
}
