import useQuery from 'hooks/useQuery'
import { GET_ALL_SOURCES } from 'apollo/queries/sources'

import useMutation from 'hooks/useMutation'
import { UPDATE_CUSTOMER_SOURCE } from 'apollo/mutations/customers'

import Select from 'components/_select/CustomSelect'

import Loading from 'components/_common/Loading'

export default function EditCustomerSource ({ existing = false, ...props }) {
  return existing ? <EditCustomerSourceExisting {...props} /> : <EditCustomerSourceNew {...props} />
}

function EditCustomerSourceExisting ({ customer, onSubmit }) {
  const { data, loading: loadingSources, error: errorSources } = useQuery(GET_ALL_SOURCES)

  const sources = data?.response?.allData || []

  const options = sources.map(source => ({ value: source.id, label: source.name }))

  const [updateSource, { error, loading }] = useMutation(UPDATE_CUSTOMER_SOURCE, {
    notification: 'Successfully updated the source',
    isPromise: false
  })

  const handleSubmit = ({ value: sourceID }) => {
    const data = { id: customer.id, sourceID }
    return updateSource({ variables: { data } })
  }

  if (loadingSources || errorSources) return <Loading />

  if (loading || error) return <Loading />

  return (
    <Select
      options={options}
      onChange={handleSubmit}
      maxMenuHeight='200px'
      defaultValue={options.find(({ value }) => value === customer.source?.id)}
      menuPlacement='top'
    />
  )
}

function EditCustomerSourceNew ({ onSubmit }) {
  const { data, loading: loadingSources, error: errorSources } = useQuery(GET_ALL_SOURCES)

  const sources = data?.response?.allData || []

  const options = sources.map(source => ({ value: source.id, label: source.name }))

  const handleSubmit = ({ value, label }) => {
    const data = { id: value, name: label }
    return onSubmit(data)
  }

  if (loadingSources || errorSources) return <Loading />

  return (
    <Select
      options={options}
      onChange={handleSubmit}
      maxMenuHeight='200px'
      menuPlacement='top'
    />
  )
}
