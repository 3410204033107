import useMutation from 'hooks/useMutation'

import {
  Grid,
  Input,
  Stack,
  Select,
  IconButton,
  FormControl,
  FormErrorMessage
} from '@chakra-ui/react'

import { TiPlus } from 'react-icons/ti'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'

import ErrorAlert from 'components/_common/ErrorAlert'

const schemaValidation = object().shape({
  type: string().required(),
  value: string().required().matches(/^\+?\d{10,14}$/, { message: 'Invalid Phone Number' })
})

export default function AddPhoneNumber ({ mutation, id }) {
  const [addPhoneNumber, { error, loading }] = useMutation(mutation, {
    notification: 'Successfully added the phone number',
    isPromise: false,
    onCompleted: () => {
      reset()
    }
  })

  const { handleSubmit, formState: { errors }, register, reset } = useForm({
    resolver: yupResolver(schemaValidation)
  })

  const onSubmit = ({ type, value }) => {
    const code = value.charAt(0) !== '+' ? '+1' : ''
    const phoneNumber = {
      type,
      value: code + value
    }
    const data = { id, phoneNumber }
    addPhoneNumber({ variables: { data } })
  }

  return (
    <Stack as='form' onSubmit={handleSubmit(onSubmit)} width='100%'>
      <Grid gridTemplateColumns='1fr 2fr 0.4fr' gridGap={4} alignItems='start'>
        <FormControl isInvalid={errors?.type}>
          <Select {...register('type')}>
            <option value='LANDLINE'>Landline</option>
            <option value='MOBILE'>Mobile</option>
            <option value='BUSINESS'>Business</option>
          </Select>
        </FormControl>
        <FormControl isInvalid={errors?.value}>
          <Input {...register('value')} placeholder='Add new number' />
          <FormErrorMessage>
            {errors?.value?.message}
          </FormErrorMessage>
        </FormControl>
        <IconButton
          size='md'
          icon={<TiPlus fontSize='16px' />}
          isDisabled={errors?.value}
          type='submit'
          isLoading={loading}
          width='fit-content'
          mx='auto'
        />
      </Grid>
      {error && <ErrorAlert> {error.message} </ErrorAlert>}
    </Stack>
  )
}
