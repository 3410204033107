import Select from 'components/_select/CustomSelect'

import useQuery from 'hooks/useQuery'
import { GET_ALL_USERS_LEAN } from 'apollo/queries/users'

import Loading from 'components/_common/Loading'
import AlertDialog from 'components/_common/AlertDialog'

export default function UsersFilter ({ additionalVariables, setAdditionalVariables, isDisabled = false }) {
  const { data, loading, error } = useQuery(GET_ALL_USERS_LEAN)

  const handleSelectUser = ({ value: userID }) => {
    setAdditionalVariables({ ...additionalVariables, userID })
  }

  const users = data?.response || []

  if (loading) return <Loading />

  if (error) return <AlertDialog status='error'>{error.message}</AlertDialog>

  const options = users.map(user => ({ value: user.id, label: user.fullName }))

  return (
    <Select
      placeholder='Select a User'
      value={options.find(({ value }) => value === additionalVariables.userID)}
      options={options}
      onChange={handleSelectUser}
      maxMenuHeight='300px'
      isDisabled={isDisabled}
    />
  )
}
