import {
  Text,
  Icon,
  Stack
} from '@chakra-ui/react'

const numberWithCommas = (x = '0') => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export default function UsersCountCard ({ label, values, icon: CardIcon, bg = 'purple.100' }) {
  return (
    <Stack
      p='1'
      bg={bg}
      height='120px'
      width='200px'
      borderRadius='lg'
      textAlign='center'
      alignItems='center'
      justifyContent='space-evenly'
    >
      <Stack alignItems='center' spacing='0'>
        <Icon as={CardIcon} boxSize='22px' />
        <Text fontWeight='bold' fontSize='sm'>{label}</Text>
      </Stack>
      <Text fontSize='2xl' fontWeight='semibold' m='0' lineHeight='none'>{numberWithCommas(values.total)}</Text>
      <Stack direction='row'>
        <Text fontSize='xs'>Admins <strong>{numberWithCommas(values.admins)}</strong></Text>
        <Text fontSize='xs'>Staffs <strong>{numberWithCommas(values.staffs)}</strong></Text>
      </Stack>
    </Stack>
  )
}
