import { gql } from '@apollo/client'

export const WHO_AM_I = gql`
  query WHO_AM_I {
    whoAmI {
      id
      email
      fullName
      role
      stores {
        id
        name
        location
      }
      permissions {
        storeID
        permissionTypes
      }
      updatedAt
    }
  }
`
