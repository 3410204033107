import { useReactiveVar } from '@apollo/client'

import { startCase } from 'lodash'

import {
  Grid,
  Text,
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
  AccordionButton
} from '@chakra-ui/react'

import StringFilter from './StringFilter'
import SelectFilter from './SelectFilter'

export default function SearchFilter ({ label, filterVar, refetch, hideFields }) {
  const filterWhere = useReactiveVar(filterVar)

  const onFilterChange = (filter, field, relation) => {
    if (relation) {
      filterVar(filterVar().map(where => {
        if (where.field === relation) {
          return {
            ...where,
            relations: where.relations.map(relation => {
              if (relation.field === field) {
                return { ...relation, filter, isActive: true }
              } else {
                return relation
              }
            })
          }
        } else {
          return where
        }
      }))
    } else {
      filterVar(filterVar().map(where => {
        if (where.field === field) {
          return { ...where, filter, isActive: true }
        } else {
          return where
        }
      }))
    }
    refetch()
  }

  const onValueChange = (value, field, relation) => {
    if (relation) {
      filterVar(filterVar().map(where => {
        if (where.field === relation) {
          return {
            ...where,
            relations: where.relations.map(relation => {
              if (relation.field === field) {
                return { ...relation, value, isActive: true }
              } else {
                return relation
              }
            })
          }
        } else {
          return where
        }
      }))
    } else {
      filterVar(filterVar().map(where => {
        if (where.field === field) {
          return { ...where, value, isActive: true }
        } else {
          return where
        }
      }))
    }
    refetch()
  }

  const onActiveChange = (isActive, field, relation) => {
    if (relation) {
      filterVar(filterVar().map(where => {
        if (where.field === relation) {
          return {
            ...where,
            relations: where.relations.map(relation => {
              if (relation.field === field) {
                return { ...relation, isActive }
              } else {
                return relation
              }
            })
          }
        } else {
          return where
        }
      }))
    } else {
      filterVar(filterVar().map(where => {
        if (where.field === field) {
          return { ...where, isActive }
        } else {
          return where
        }
      }))
    }
    refetch()
  }

  return (
    <Grid
      templateColumns='1fr 1fr 220px'
      gridGap={2}
      alignItems='center'
    >
      {filterWhere.map(({ field, value, filter, isActive, valueOptions, filterOptions, relations, type = 'text', isSingle }) => {
        if (relations) {
          if (hideFields && hideFields.includes(field)) return null
          return (
            <Accordion key={field} allowToggle gridColumn='1/-1' defaultIndex={0}>
              <AccordionItem border={0}>
                <AccordionButton>
                  <Text fontSize='sm' fontWeight='bold' flex='1' textAlign='left'>
                    {startCase(field)}
                  </Text>
                  <AccordionIcon />
                </AccordionButton>
                {relations.map(relation => {
                  const selectRender = (
                    <SelectFilter
                      key={relation.field}
                      label={startCase(relation.field)}
                      value={relation.value}
                      valueOptions={relation.valueOptions}
                      filter={relation.filter}
                      filterOptions={relation.filterOptions}
                      isActive={relation.isActive}
                      onFilterChange={filter => onFilterChange(filter, relation.field, field)}
                      onValueChange={value => onValueChange(value, relation.field, field)}
                      onActiveChange={isActive => onActiveChange(isActive, relation.field, field)}
                      isSingle={isSingle}
                    />
                  )
                  const stringRender = (
                    <StringFilter
                      key={relation.field}
                      field={field}
                      label={startCase(relation.field)}
                      value={relation.value}
                      filter={relation.filter}
                      filterOptions={relation.filterOptions}
                      isActive={relation.isActive}
                      onFilterChange={filter => onFilterChange(filter, relation.field, field)}
                      onValueChange={value => onValueChange(value, relation.field, field)}
                      onActiveChange={isActive => onActiveChange(isActive, relation.field, field)}
                      type={relation.type}
                    />
                  )
                  const filterRender = relation.valueOptions ? selectRender : relation.filterOptions ? stringRender : null
                  return (
                    <AccordionPanel key={relation.field} display='grid' gridTemplateColumns='1fr 1fr 155px' gridGap={2} p={1}>
                      {filterRender}
                    </AccordionPanel>
                  )
                })}
              </AccordionItem>
            </Accordion>
          )
        } else {
          const selectRender = (
            <SelectFilter
              key={field}
              label={startCase(field)}
              value={value}
              valueOptions={valueOptions}
              filter={filter}
              filterOptions={filterOptions}
              isActive={isActive}
              onFilterChange={filter => onFilterChange(filter, field)}
              onValueChange={value => onValueChange(value, field)}
              onActiveChange={isActive => onActiveChange(isActive, field)}
              isSingle={isSingle}
            />
          )
          const stringRender = (
            <StringFilter
              key={field}
              field={field}
              label={startCase(field)}
              value={value}
              filter={filter}
              filterOptions={filterOptions}
              isActive={isActive}
              onFilterChange={filter => onFilterChange(filter, field)}
              onValueChange={value => onValueChange(value, field)}
              onActiveChange={isActive => onActiveChange(isActive, field)}
              type={type}
            />
          )
          return valueOptions ? selectRender : filterOptions ? stringRender : <></>
        }
      })}
    </Grid>
  )
}
