const notes = (search, isEmbedded = false) => {
  const OR = []

  OR.push({ details: { contains: search, mode: 'insensitive' } })

  return OR
}

const topUps = (search, isEmbedded = false) => {
  const isNumber = !isNaN(search)
  const isBigInt = isNumber && parseInt(search) > 294967295

  const OR = []

  !isEmbedded && OR.push({ customer: { OR: customers(search, true) } })
  OR.push({ macID: { contains: search, mode: 'insensitive' } })
  OR.push({ accountNumber: { contains: search, mode: 'insensitive' } })
  OR.push({ serviceType: { OR: serviceTypes(search, true) } })
  isNumber && !isBigInt && OR.push({ credits: { equals: parseInt(search) } })
  OR.push({ notes: { some: { OR: notes(search, true) } } })

  return OR
}

const services = (search, isEmbedded = false) => {
  const isNumber = !isNaN(search)
  const isBigInt = isNumber && parseInt(search) > 294967295
  const isMac = search.length > 1 && search.includes(':')

  const OR = []

  isMac && OR.push({ macID: { contains: search, mode: 'insensitive' } })
  isNumber && !isBigInt && OR.push({ credits: { equals: parseInt(search) } })
  OR.push({ accountNumber: { contains: search, mode: 'insensitive' } })
  !isEmbedded && OR.push({ customer: { OR: customers(search, true) } })

  return OR
}

const customers = (search, isEmbedded = false) => {
  const isNumber = !isNaN(search)
  const isEmail = search.length > 1 && search.includes('@')
  const isName = /^[a-zA-Z()]+$/.test(search)

  const OR = []

  isEmail && OR.push({ email: { contains: search, mode: 'insensitive' } })
  isName && OR.push({ fullName: { contains: search, mode: 'insensitive' } })
  isNumber && OR.push({ phoneNumbers: { some: { OR: phoneNumbers(search, true) } } })
  !isEmbedded && OR.push({ services: { some: { OR: services(search, true) } } })

  return OR
}

const transactions = (search, isEmbedded = false) => {
  // const isNumber = !isNaN(search)
  // const isBigInt = isNumber && search > 294967295

  const OR = []
  OR.push({ transactionNumber: { contains: search, mode: 'insensitive' } })
  OR.push({ notes: { some: { OR: notes(search, true) } } })
  OR.push({ shipment: { OR: shipments(search, true) } })
  !isEmbedded && OR.push({ customer: { OR: customers(search, true) } })
  !isEmbedded && OR.push({ createdByUser: { OR: users(search, true) } })

  return OR
}

const tickets = (search, isEmbedded = false) => {
  // const isNumber = !isNaN(search)
  // const isBigInt = isNumber && search > 294967295

  const OR = []
  if (['OPEN', 'IN_PROGRESS', 'RESOLVED'].includes(search.toUpperCase())) OR.push({ status: { equals: search.toUpperCase() } })
  OR.push({ notes: { some: { OR: notes(search, true) } } })
  !isEmbedded && OR.push({ customer: { OR: customers(search, true) } })
  !isEmbedded && OR.push({ createdByUser: { OR: users(search, true) } })

  return OR
}

const activities = (search, isEmbedded = false) => {
  // const isNumber = !isNaN(search)
  // const isBigInt = isNumber && search > 294967295

  const OR = []
  OR.push({ details: { contains: search, mode: 'insensitive' } })
  OR.push({ type: { contains: search.replace(/ /g, '_'), mode: 'insensitive' } })
  !isEmbedded && OR.push({ createdByUser: { OR: users(search, true) } })

  return OR
}

const users = (search, isEmbedded = false) => {
  // const isNumber = !isNaN(search)
  // const isBigInt = isNumber && search > 294967295

  const OR = []
  OR.push({ email: { contains: search, mode: 'insensitive' } })
  OR.push({ fullName: { contains: search, mode: 'insensitive' } })
  if (['ADMIN', 'STAFF'].includes(search.toUpperCase())) OR.push({ role: { equals: search.toUpperCase() } })
  OR.push({ phoneNumbers: { some: { OR: phoneNumbers(search, true) } } })
  !isEmbedded && OR.push({ requestedLoginApprovals: { some: { OR: loginApprovals(search, true) } } })

  return OR
}

const loginApprovals = (search, isEmbedded = false) => {
  // const isNumber = !isNaN(search)
  // const isBigInt = isNumber && search > 294967295

  const OR = []
  if (['ACTIVE', 'APPROVED', 'DENIED'].includes(search.toUpperCase())) OR.push({ status: { equals: search.toUpperCase() } })
  !isEmbedded && OR.push({ requestedUser: { OR: users(search, true) } })
  !isEmbedded && OR.push({ processedAdmin: { OR: users(search, true) } })

  return OR
}

const shipments = (search) => {
  const OR = []
  OR.push({ name: { contains: search, mode: 'insensitive' } })
  OR.push({ details: { contains: search, mode: 'insensitive' } })

  return OR
}

const sources = (search) => {
  const OR = []
  OR.push({ name: { contains: search, mode: 'insensitive' } })
  OR.push({ details: { contains: search, mode: 'insensitive' } })

  return OR
}

const languages = (search) => {
  const OR = []
  OR.push({ name: { contains: search, mode: 'insensitive' } })
  OR.push({ details: { contains: search, mode: 'insensitive' } })

  return OR
}

const productTypes = (search) => {
  const OR = []
  OR.push({ name: { contains: search, mode: 'insensitive' } })
  OR.push({ details: { contains: search, mode: 'insensitive' } })

  return OR
}

const serviceTypes = (search) => {
  // const isNumber = !isNaN(search)

  const OR = []

  OR.push({ name: { contains: search, mode: 'insensitive' } })
  OR.push({ details: { contains: search, mode: 'insensitive' } })
  // isNumber && OR.push({ topupDueDays: { equals: search } })
  // isNumber && OR.push({ order: { equals: search } })

  return OR
}

const stores = (search, isEmbedded = false) => {
  // const isNumber = !isNaN(search)
  // const isBigInt = isNumber && search > 294967295

  const OR = []
  OR.push({ name: { contains: search, mode: 'insensitive' } })
  OR.push({ location: { contains: search, mode: 'insensitive' } })

  return OR
}

const phoneNumbers = (search, isEmbedded = false) => {
  // const isNumber = !isNaN(search)
  // const isBigInt = isNumber && search > 294967295

  const OR = []
  OR.push({ value: { contains: search, mode: 'insensitive' } })
  if (['MOBILE', 'LANDLINE', 'BUSINESS'].includes(search.toUpperCase())) OR.push({ type: { equals: search.toUpperCase() } })
  !isEmbedded && OR.push({ customer: { OR: customers(search, true) } })
  !isEmbedded && OR.push({ user: { OR: users(search, true) } })

  return OR
}

const fuzzySearchBuilder = {
  topUps,
  services,
  customers,
  transactions,
  tickets,
  activities,
  users,
  phoneNumbers,
  stores,
  shipments,
  sources,
  languages,
  productTypes,
  serviceTypes,
  loginApprovals
}

export default fuzzySearchBuilder
