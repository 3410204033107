import { gql } from '@apollo/client'

export const GET_ALL_USERS = gql`
  query GET_ALL_USERS ($where: UserWhereInput $orderBy: [UserOrderByInput!] $take: Int $skip: Int) {
    response: getAllUsers (where: $where orderBy: $orderBy take: $take skip: $skip) {
      allData: users {
        id
        email
        fullName
        role
        primaryPhoneNumber
        phoneNumbers {
          id
          type
          value
        }
        permissions {
          id
          storeID
          permissionTypes
        }
        requestedLoginApprovals {
          id
        }
        assignedToTickets {
          id
        }
        assignedByTickets {
          id
        }
        isDeleted
      }
      metrics {
        allCount
        filteredCount
      }
    }
  }
`

export const GET_USER_GENERAL_INFO = gql`
  query GET_USER_GENERAL_INFO ($id: String!) {
    user: getOneUser (id: $id) {
      id
      email
      fullName
      isLocked
      requiredOTP
      primaryPhoneNumber
      phoneNumbers {
        id
        type
        value
      }
      updatedAt
      createdAt
    }
  }
`

export const GET_USER_PERMISSIONS = gql`
  query GET_USER_PERMISSIONS ($id: String!) {
    user: getOneUser (id: $id) {
      id
      permissions {
        id
        storeID
        permissionTypes
      }
    }
  }
`

export const GET_ALL_USERS_LEAN = gql`
  query GET_ALL_USERS_LEAN {
    response: listUsers {
      id
      fullName
    }
  }
`
