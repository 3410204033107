import {
  Box,
  Icon,
  Heading
} from '@chakra-ui/react'

import { RiLockPasswordLine } from 'react-icons/ri'

import ResetForgotPassword from 'components/auth/ResetForgotPassword'

export default function ResetPassword (props) {
  return (
    <Box height='100%' bg='purple.900' py='12' px='8'>
      <Box maxW='md' mx='auto'>
        <Box mb={{ base: '12', md: '24' }} textAlign='center'>
          <Icon as={RiLockPasswordLine} color='white' fontSize='5xl' />
          <Heading
            fontSize='2xl'
            color='white'
            letterSpacing='2px'
          >
            Reset Password
          </Heading>
        </Box>
        <ResetForgotPassword {...props} />
      </Box>
    </Box>
  )
}
