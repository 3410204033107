import ServiceTypeSelectCheckbox from 'components/serviceTypes/select/ServiceTypeSelectCheckbox'
import DateTimeRenderer from 'components/_common/DateTimeRenderer'
import EditServiceTypeName from 'components/serviceTypes/edit/EditServiceTypeName'
import EditServiceTypeDetails from 'components/serviceTypes/edit/EditServiceTypeDetails'
import SendServiceTypeMessage from 'components/serviceTypes/SendServiceTypeMessage'
import EditServiceTypeTopupDueDays from 'components/serviceTypes/edit/EditServiceTypeTopupDueDays'
import EditServiceTypeReminderDays from 'components/serviceTypes/edit/EditServiceTypeReminderDays'
import EditServiceTypeColor from 'components/serviceTypes/edit/EditServiceTypeColor'
import EditServiceTypeOrder from 'components/serviceTypes/edit/EditServiceTypeOrder'

const fields = [
  { key: 'select', width: '40px', renderTD: ServiceTypeSelectCheckbox },
  { key: 'name', label: 'Name', width: '180px', renderTD: EditServiceTypeName },
  { key: 'details', label: 'Details', width: '180px', renderTD: EditServiceTypeDetails },
  { key: 'message', label: 'Message', width: '150px', justifyContent: 'center', renderTD: SendServiceTypeMessage },
  { key: 'topupDueDays', label: 'Topup Due Days', width: '150px', justifyContent: 'center', renderTD: EditServiceTypeTopupDueDays },
  { key: 'reminderDays', label: 'Reminder Days', width: '150px', justifyContent: 'center', renderTD: EditServiceTypeReminderDays },
  { key: 'color', label: 'Color', width: '180px', justifyContent: 'center', renderTD: EditServiceTypeColor },
  { key: 'order', label: 'Order', width: '180px', justifyContent: 'center', renderTD: EditServiceTypeOrder },
  { key: 'updatedAt', label: 'Updated At', renderTD: ({ serviceType: { updatedAt } }) => <DateTimeRenderer value={updatedAt} type='time' /> },
  { key: 'createdAt', label: 'Created At', renderTD: ({ serviceType: { createdAt } }) => <DateTimeRenderer value={createdAt} type='time' /> }
]

export default fields
