import ShipmentSelectCheckbox from 'components/shipments/select/ShipmentSelectCheckbox'
import DateTimeRenderer from 'components/_common/DateTimeRenderer'
import EditShipmentName from 'components/shipments/edit/EditShipmentName'
import EditShipmentDetails from 'components/shipments/edit/EditShipmentDetails'

const fields = [
  { key: 'select', width: '40px', renderTD: ShipmentSelectCheckbox },
  { key: 'name', label: 'Name', width: '250px', renderTD: EditShipmentName },
  { key: 'details', label: 'Details', width: '250px', renderTD: EditShipmentDetails },
  { key: 'updatedAt', label: 'Updated At', renderTD: ({ shipment: { updatedAt } }) => <DateTimeRenderer value={updatedAt} type='time' /> },
  { key: 'createdAt', label: 'Created At', renderTD: ({ shipment: { createdAt } }) => <DateTimeRenderer value={createdAt} type='time' /> }
]

export default fields
