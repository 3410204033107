import { useState } from 'react'

import FormDialog from 'components/_common/FormDialog'

import { VALIDATE_OTP } from 'apollo/mutations/auth'
import useMutation from 'hooks/useMutation'

import {
  Alert,
  Stack,
  PinInput,
  AlertIcon,
  PinInputField,
  AlertDescription
} from '@chakra-ui/react'

export default function OTPLogin ({ otpData, onClose }) {
  const [otp, setOTP] = useState()

  const handleClose = () => {
    reset()
    onClose()
  }

  const [validateOTP, { error, loading, reset }] = useMutation(VALIDATE_OTP, {
    notification: 'Successfully authenticated',
    isPromise: false,
    onCompleted: (data) => {
      reset()
      onClose(data)
    }
  })

  const onPinInputComplete = (value) => {
    setOTP(value)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const data = { otp, ...otpData }
    validateOTP({ variables: { data } })
  }

  return (
    <FormDialog
      title='Enter OTP Code'
      formID='#validateOTP'
      submitLabel='Verify'
      error={error}
      loading={loading}
      onClose={handleClose}
      onSubmit={onSubmit}
      isCentered
    >
      <Stack direction='row' justifyContent='space-evenly' py='16'>
        <PinInput otp autoFocus type='number' size='lg' onComplete={onPinInputComplete}>
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
        </PinInput>
      </Stack>
      <Alert status='warning' borderRadius='lg'>
        <AlertIcon />
        <AlertDescription>Please check your Email or SMS for the OTP code</AlertDescription>
      </Alert>
    </FormDialog>
  )
}
