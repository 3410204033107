import { useState } from 'react'

import useAuthUser from 'hooks/useAuthUser'

import {
  Box,
  Text,
  Flex,
  Menu,
  Button,
  Avatar,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuButton,
  MenuDivider
} from '@chakra-ui/react'

import { CgProfile } from 'react-icons/cg'
import { RiLockPasswordLine } from 'react-icons/ri'
import { FiSettings, FiLogOut } from 'react-icons/fi'

import AdminOnlyBadge from 'components/_badges/AdminOnlyBadge'

import EditProfile from 'components/auth/EditProfile'
import ResetMyPassword from 'components/auth/ResetMyPassword'
import ViewSettings from 'components/settings/ViewSettings'

export default function ProfileMenu () {
  const authUser = useAuthUser()

  const [isEditProfileOpen, setIsEditProfileOpen] = useState(false)
  const [isResetMyPasswordOpen, setIsResetMyPasswordOpen] = useState(false)
  const [isViewSettingsOpen, setIsViewSettingsOpen] = useState(false)

  const onLogout = () => {
    window.localStorage.removeItem('AUTH_SESSION_ID')
    window.localStorage.removeItem('ACTIVE_STORE')
    window.location.href = '/login'
  }

  return (
    <>
      {isEditProfileOpen && <EditProfile onClose={() => setIsEditProfileOpen(false)} />}
      {isResetMyPasswordOpen && <ResetMyPassword onClose={() => setIsResetMyPasswordOpen(false)} />}
      {isViewSettingsOpen && <ViewSettings onClose={() => setIsViewSettingsOpen(false)} />}
      <Menu isLazy>
        <MenuButton
          as={Button}
          variant='sidebar'
          color='white'
          borderRadius='md'
          height='fit-content'
          width='100%'
          pl='1'
        >
          <Flex py='2' alignItems='center'>
            <Avatar name={authUser.fullName} size='sm' bg='purple.300' />
            <Box ml='2' textAlign='left'>
              <Text fontSize='sm' fontWeight='semibold'>{authUser.fullName}</Text>
              <Text fontSize='xs' mt='1' maxW='140px' overflow='hidden' textOverflow='ellipsis'>
                {authUser.email}
              </Text>
            </Box>
          </Flex>

        </MenuButton>
        <MenuList minW='' width='180px'>
          <MenuGroup title='Account'>
            <MenuItem icon={<CgProfile />} onClick={() => setIsEditProfileOpen(true)}>My Profile</MenuItem>
            <MenuItem icon={<RiLockPasswordLine />} onClick={() => setIsResetMyPasswordOpen(true)}>Reset Password</MenuItem>
          </MenuGroup>
          {authUser.role === 'ADMIN' && (
            <>
              <MenuDivider />
              <MenuGroup title={<AdminOnlyBadge />}>
                <MenuItem icon={<FiSettings />} onClick={() => setIsViewSettingsOpen(true)}>
                  Settings
                </MenuItem>
              </MenuGroup>
            </>
          )}
          <MenuDivider />
          <MenuItem icon={<FiLogOut />} onClick={onLogout}>Logout</MenuItem>
        </MenuList>
      </Menu>
    </>
  )
}
