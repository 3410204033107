import { gql } from '@apollo/client'

export const CREATE_CUSTOMER = gql`
  mutation CREATE_CUSTOMER ($data: CreateCustomerInput!) {
    createCustomer(data: $data) {
      id
    }
  }
`

export const MERGE_CUSTOMERS = gql`
  mutation MERGE_CUSTOMERS ($data: MergeMultipleCustomersInput!) {
    mergeMultipleCustomers(data: $data) {
      id
    }
  }
`

export const SEPERATE_CUSTOMER_FROM_SERVICE = gql`
  mutation SEPERATE_CUSTOMER_FROM_SERVICE ($data: SeparateCustomerFromServiceInput!) {
    separateCustomerFromService(data: $data) {
      id
    }
  }
`

export const UPDATE_CUSTOMER_JOINED_DATE = gql`
  mutation UPDATE_CUSTOMER_JOINED_DATE ($data: UpdateCustomerInput!) {
    updateCustomer(data: $data) {
      id
      joinedDate
      updatedAt
    }
  }
`

export const UPDATE_CUSTOMER_EMAIL = gql`
  mutation UPDATE_CUSTOMER_EMAIL ($data: UpdateCustomerInput!) {
    updateCustomer(data: $data) {
      id
      email
      updatedAt
    }
  }
`

export const UPDATE_CUSTOMER_FULL_NAME = gql`
  mutation UPDATE_CUSTOMER_FULL_NAME($data: UpdateCustomerInput!) {
    updateCustomer(data: $data) {
      id
      fullName
      updatedAt
    }
  }
`

export const UPDATE_CUSTOMER_PIN = gql`
  mutation UPDATE_CUSTOMER_PIN($data: UpdateCustomerInput!) {
    updateCustomer(data: $data) {
      id
      pin
      updatedAt
    }
  }
`

export const UPDATE_CUSTOMER_PRIMARY_PHONE_NUMBER = gql`
  mutation UPDATE_CUSTOMER_PRIMARY_PHONE_NUMBER ($data: UpdateCustomerInput!) {
    updateCustomer(data: $data) {
      id
      primaryPhoneNumber
      updatedAt
    }
  }
`

export const UPDATE_CUSTOMER_STORE = gql`
  mutation UPDATE_CUSTOMER_STORE ($data: UpdateCustomerInput!) {
    updateCustomer(data: $data) {
      id
      store {
        id
      }
      updatedAt
    }
  }
`

export const UPDATE_CUSTOMER_SOURCE = gql`
  mutation UPDATE_CUSTOMER_SOURCE ($data: UpdateCustomerInput!) {
    updateCustomer(data: $data) {
      id
      source {
        id
      }
      updatedAt
    }
  }
`

export const UPDATE_CUSTOMER_LANGUAGE = gql`
  mutation UPDATE_CUSTOMER_LANGUAGE ($data: UpdateCustomerInput!) {
    updateCustomer(data: $data) {
      id
      language {
        id
      }
      updatedAt
    }
  }
`

export const UPDATE_CUSTOMER_POSTAL_CODE = gql`
  mutation UPDATE_CUSTOMER_POSTAL_CODE ($data: UpdateCustomerInput!) {
    updateCustomer(data: $data) {
      id
      postalCode
      updatedAt
    }
  }
`

export const DELETE_CUSTOMER = gql`
  mutation DELETE_CUSTOMER ($id: String!) {
    deleteCustomer(id: $id) {
      id
      isDeleted
    }
  }
`

export const ADD_CUSTOMER_PHONE_NUMBER = gql`
  mutation ADD_CUSTOMER_PHONE_NUMBER ($data: AddPhoneNumberInput!) {
    addCustomerPhoneNumber(data: $data) {
      id
      phoneNumbers {
        type
        value
      }
    }
  }
`

export const UPDATE_CUSTOMER_PHONE_NUMBER = gql`
  mutation UPDATE_CUSTOMER_PHONE_NUMBER ($data: UpdatePhoneNumberInput!) {
    updateCustomerPhoneNumber(data: $data) {
      id
      phoneNumbers {
        id
        type
        value
      }
    }
  }
`

export const DELETE_CUSTOMER_PHONE_NUMBER = gql`
  mutation DELETE_CUSTOMER_PHONE_NUMBER ($id: String!) {
    deleteCustomerPhoneNumber(id: $id) {
      id
      phoneNumbers {
        id
        type
        value
      }
    }
  }
`

export const SEND_CUSTOMER_MESSAGE = gql`
  mutation SEND_CUSTOMER_MESSAGE ($data: SingleMessageInput!) {
    sendMessageToACustomer(data: $data) {
      id
    }
  }
`

export const SEND_BULK_CUSTOMER_MESSAGE = gql`
  mutation SEND_BULK_CUSTOMER_MESSAGE ($data: BulkMessageInput!) {
    sendBulkMessagesToCustomers(data: $data)
  }
`

export const UPDATE_CUSTOMER_REMINDER_TYPES = gql`
  mutation UPDATE_CUSTOMER_REMINDER_TYPES ($data: UpdateCustomerInput!) {
    updateCustomer(data: $data) {
      id
      sendReminderBy
      updatedAt
    }
  }
`

export const ADD_CUSTOMER_NOTE = gql`
  mutation ADD_CUSTOMER_NOTE ($data: AddCustomerNotesInput!) {
    addCustomerNotes(data: $data) {
      id
      notes {
        id
        createdAt
        details
        markAsDone
        user {
          id
          fullName
          role
          isDeleted
        }
        updatedAt
      }
    }
  }
`

export const UPDATE_CUSTOMER_NOTE_DETAILS = gql`
  mutation UPDATE_CUSTOMER_NOTE_DETAILS ($data: UpdateNotesInput!) {
    updateCustomerNotes(data: $data) {
      id
      notes {
        id
        createdAt
        details
        markAsDone
        user {
          id
          fullName
          role
          isDeleted
        }
        updatedAt
      }
    }
  }
`

export const UPDATE_CUSTOMER_NOTE_MARK_AS_DONE = gql`
  mutation UPDATE_CUSTOMER_NOTE_MARK_AS_DONE ($data: UpdateNotesInput!) {
    updateCustomerNotes(data: $data) {
      id
      notes {
        id
        createdAt
        details
        markAsDone
        user {
          id
          fullName
          role
          isDeleted
        }
        updatedAt
      }
    }
  }
`
