import { gql } from '@apollo/client'

export const CREATE_PRODUCT_TYPE = gql`
  mutation CREATE_PRODUCT_TYPE ($data: CreateProductTypeInput!) {
    createProductType(data: $data) {
      id
    }
  }
`

export const UPDATE_PRODUCT_TYPE_NAME = gql`
  mutation UPDATE_PRODUCT_TYPE_NAME ($data: UpdateProductTypeInput!) {
    updateProductType(data: $data) {
      id
      name
      updatedAt
    }
  }
`

export const UPDATE_PRODUCT_TYPE_DETAILS = gql`
  mutation UPDATE_PRODUCT_TYPE_DETAILS ($data: UpdateProductTypeInput!) {
    updateProductType(data: $data) {
      id
      details
      updatedAt
    }
  }
`

export const DELETE_PRODUCT_TYPE = gql`
  mutation DELETE_PRODUCT_TYPE ($id: String!) {
    deleteProductType(id: $id) {
      id
    }
  }
`
