import useQuery from 'hooks/useQuery'
import { GET_ALL_SERVICE_TYPES } from 'apollo/queries/serviceTypes'

import useMutation from 'hooks/useMutation'
import { UPDATE_SERVICE_SERVICE_TYPE } from 'apollo/mutations/services'

import Select from 'components/_select/CustomSelect'

import Loading from 'components/_common/Loading'

export default function EditServiceServiceType ({ inline = false, ...props }) {
  return inline ? <EditServiceServiceTypeInline {...props} /> : <EditServiceServiceTypeStandalone {...props} />
}

function EditServiceServiceTypeInline ({ onSubmit }) {
  const { data, loading, error } = useQuery(GET_ALL_SERVICE_TYPES)

  const serviceTypes = data?.response?.allData || []

  if (loading || error) return <Loading />

  const handleSubmit = ({ value, label }) => {
    const data = { id: value, name: label }
    return onSubmit(data)
  }

  const options = serviceTypes.map(serviceType => ({ value: serviceType.id, label: serviceType.name, color: serviceType.color }))

  return (
    <Select
      options={options}
      onChange={handleSubmit}
      maxMenuHeight='200px'
    />
  )
}

function EditServiceServiceTypeStandalone ({ service }) {
  const { data, loading, error } = useQuery(GET_ALL_SERVICE_TYPES)

  const serviceTypes = data?.response?.allData || []

  const [updateServiceType] = useMutation(UPDATE_SERVICE_SERVICE_TYPE, {
    notification: 'Successfully updated the service type',
    isPromise: false
  })

  if (loading || error) return <Loading />

  const handleSubmit = ({ value: serviceTypeID }) => {
    const data = { id: service.id, serviceTypeID }
    return updateServiceType({ variables: { data } })
  }

  const options = serviceTypes.map(serviceType => ({ value: serviceType.id, label: serviceType.name, color: serviceType.color }))

  return (
    <Select
      options={options}
      onChange={handleSubmit}
      defaultValue={options.find(({ value }) => value === service.serviceType.id)}
      maxMenuHeight='200px'
    />
  )
}
