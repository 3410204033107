import { useEffect } from 'react'

import { CREATE_TICKET } from 'apollo/mutations/tickets'
import useMutation from 'hooks/useMutation'

import FormDialog from 'components/_common/FormDialog'
import DateTimePicker from 'components/_inputs/DateTimePicker'

import {
  Text,
  Stack,
  Textarea,
  FormLabel,
  IconButton,
  FormControl,
  useDisclosure,
  FormErrorMessage
} from '@chakra-ui/react'

import { BiPlus } from 'react-icons/bi'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'

import formatLocaleDate from 'utils/formatDate'

export default function CreateUserTicketTrigger ({ user }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      {isOpen && <CreateUserTicket user={user} onClose={onClose} />}
      <IconButton
        size='xs'
        variant='outline'
        icon={<BiPlus fontSize='16px' />}
        onClick={onOpen}
      />
    </>
  )
}

const schemaValidation = object().shape({
  details: string().required()
})

function CreateUserTicket ({ user, onClose }) {
  const handleClose = () => {
    onClose()
    resetMutation()
    resetForm()
  }

  const [createUserTicket, { error, loading, reset: resetMutation }] = useMutation(CREATE_TICKET, {
    notification: 'Successfully created the support ticket',
    isPromise: false,
    onCompleted: () => {
      handleClose()
    }
  })

  const { register, handleSubmit, formState: { errors }, reset: resetForm, setFocus, watch, getValues, setValue } = useForm({
    resolver: yupResolver(schemaValidation),
    defaultValues: {
      expiresAt: new Date().toISOString()
    }
  })

  watch(['expiresAt'])

  useEffect(() => { setTimeout(() => setFocus('details'), 1) }, [])

  const onSubmit = ({ details, expiresAt }) => {
    const data = {
      date: formatLocaleDate(new Date()) + 'T04:00:00.000Z',
      details,
      expiresAt,
      assignedToUserID: user.id
    }
    createUserTicket({ variables: { data } })
  }

  return (
    <FormDialog
      title='Create Ticket'
      formID='#createUserTicket'
      error={error}
      loading={loading}
      onClose={handleClose}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing='8'>
        <FormControl>
          <FormLabel>Assign To User</FormLabel>
          <Text>{user.fullName}</Text>
        </FormControl>
        <FormControl>
          <FormLabel>Expires At</FormLabel>
          <DateTimePicker
            inline
            fontSize='md'
            type='time'
            value={getValues('expiresAt')}
            onChange={expiresAt => setValue('expiresAt', expiresAt)}
            error={errors?.expiresAt}
            minDate={new Date()}
          />
        </FormControl>
        <FormControl isInvalid={errors?.details}>
          <FormLabel>Details</FormLabel>
          <Textarea {...register('details')} />
          <FormErrorMessage>
            {errors?.details?.message}
          </FormErrorMessage>
        </FormControl>
      </Stack>
    </FormDialog>
  )
}
