import useAuthUser from 'hooks/useAuthUser'

import {
  Text,
  Modal,
  Stack,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalCloseButton
} from '@chakra-ui/react'

import EditUserEmail from 'components/users/edit/EditUserEmail'
import EditUserFullName from 'components/users/edit/EditUserFullName'
import DateTimeRender from 'components/_common/DateTimeRenderer'

export default function EditProfile ({ onClose }) {
  const authUser = useAuthUser()

  return (
    <Modal isOpen onClose={onClose} scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>My Profile</ModalHeader>
        <ModalCloseButton />
        <ModalBody mb='6'>
          <Stack spacing='8'>
            <EditUserEmail inline user={authUser} />
            <EditUserFullName inline user={authUser} />
          </Stack>
        </ModalBody>
        <ModalFooter fontSize='xs'>
          <Text>Last updated at:&nbsp;</Text>
          <DateTimeRender value={authUser.updatedAt} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
