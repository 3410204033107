import TableWrapper from 'components/table/TableWrapper'

import { GET_ALL_TRANSACTIONS } from 'apollo/queries/transactions'
import {
  transactionFilterWhereVar,
  transactionFilterOrderByVar
} from 'utils/cache'

import fields from 'components/transactions/fields'

import {
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalCloseButton
} from '@chakra-ui/react'

export default function ViewCustomerTransactions ({ inline = false, ...props }) {
  return inline ? <ViewCustomerTransactionsInline {...props} /> : <OpenCustomerTransactionsTrigger {...props} />
}

function ViewCustomerTransactionsInline ({ customer }) {
  const additionalWhere = {}
  if (window.localStorage.getItem('ACTIVE_STORE') !== 'ALL') {
    additionalWhere.customer = {
      store: { id: { equals: window.localStorage.getItem('ACTIVE_STORE') } }
    }
  }

  additionalWhere.customer = {
    ...additionalWhere?.customer,
    id: { equals: customer.id }
  }

  const hideFields = ['customer']

  return (
    <TableWrapper
      isEmbedded
      query={GET_ALL_TRANSACTIONS}
      fields={fields}
      hideFields={hideFields}
      rowKey='id'
      rowType='transaction'
      fuzzyKey='transactions'
      defaultOrderBy={[{ date: 'desc' }]}
      additionalWhere={additionalWhere}
      filterWhereVar={transactionFilterWhereVar}
      filterOrderByVar={transactionFilterOrderByVar}
    />
  )
}

function OpenCustomerTransactionsTrigger ({ customer }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      {isOpen && <OpenCustomerTransactions customer={customer} onClose={onClose} />}
      <Button mr='-px' onClick={onOpen} size='xs' variant='outline' isDisabled={customer.transactions.length === 0}>
        {customer.transactions.length} | Transactions
      </Button>
    </>
  )
}

export function OpenCustomerTransactions ({ customer, onClose }) {
  return (
    <Modal isOpen onClose={onClose} size='' scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent pb='6' width='80vw'>
        <ModalHeader>Transactions</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ViewCustomerTransactions inline customer={customer} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
