import {
  Text,
  Icon,
  Modal,
  Stack,
  Button,
  ModalBody,
  FormLabel,
  ModalHeader,
  FormControl,
  ModalOverlay,
  ModalContent,
  ModalCloseButton
} from '@chakra-ui/react'

import { FcApprove, FcDisapprove } from 'react-icons/fc'

import useQuery from 'hooks/useQuery'
import { GET_ONE_LOGIN_APPROVAL } from 'apollo/queries/loginApprovals'

import useMutation from 'hooks/useMutation'
import { APPROVE_LOGIN_APPROVAL, DENY_LOGIN_APPROVAL } from 'apollo/mutations/loginApprovals'

import Loading from 'components/_common/Loading'
import AlertDialog from 'components/_common/AlertDialog'
import ErrorAlert from 'components/_common/ErrorAlert'

import DateTimeRenderer from 'components/_common/DateTimeRenderer'

export default function LoginApproval ({ id }) {
  const { data: { loginApproval } = {}, error, loading } = useQuery(GET_ONE_LOGIN_APPROVAL, {
    variables: { id }
  })

  const handleClose = () => {
    resetApprove()
    resetDeny()
    window.location.href = '/loginApprovals'
  }

  const [approveLogin, { error: approveError, loading: approveLoading, reset: resetApprove }] = useMutation(APPROVE_LOGIN_APPROVAL, {
    notification: 'Approved the login',
    isPromise: false,
    onCompleted: handleClose
  })

  const [denyLogin, { error: denyError, loading: denyLoading, reset: resetDeny }] = useMutation(DENY_LOGIN_APPROVAL, {
    notification: 'Denied the login',
    isPromise: false,
    onCompleted: handleClose
  })

  const onApprove = () => {
    const today = new Date()
    const expiresAt = loginApproval.expiresAt ? new Date(loginApproval.expiresAt) : today
    expiresAt.setDate(today.getDate() + 14)
    const data = { id: loginApproval.id, expiresAt }
    approveLogin({ variables: { data } })
  }

  const onDeny = () => {
    denyLogin({ variables: { id: loginApproval.id } })
  }

  if (error) return <AlertDialog status='error'>{error.message}</AlertDialog>

  return (
    <Modal isOpen closeOnOverlayClick={false} onClose={handleClose} scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Login Approval</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb='6'>
          {loading && <Loading />}
          {!loading && (
            <Stack spacing='8'>
              {approveLoading && <Loading />}
              {denyLoading && <Loading />}
              {approveError && <ErrorAlert>{approveError.message} </ErrorAlert>}
              {denyError && <ErrorAlert>{denyError.message} </ErrorAlert>}
              <FormControl>
                <FormLabel>Requested By</FormLabel>
                <Text>{loginApproval.requestedUser.fullName}</Text>
              </FormControl>
              <FormControl>
                <FormLabel>Requested At</FormLabel>
                <DateTimeRenderer value={loginApproval.requestedAt} type='time' fontSize='md' />
              </FormControl>
              <Stack justifyContent='space-around' width='100%' direction='row' alignItems='center'>
                <Button
                  variant='outline'
                  _hover={{ bg: 'purple.100' }}
                  boxSize='140px'
                  onClick={onApprove}
                  borderRadius='xl'
                  isDisabled={loginApproval.status === 'APPROVED'}
                >
                  <Stack>
                    <Icon as={FcApprove} boxSize='20' />
                    <Text fontWeight='semibold' fontSize='lg' color='green'>APPROVE</Text>
                  </Stack>
                </Button>
                <Button
                  variant='outline'
                  _hover={{ bg: 'purple.100' }}
                  boxSize='140px'
                  onClick={onDeny}
                  borderRadius='xl'
                  isDisabled={loginApproval.status === 'DENIED'}
                >
                  <Stack>
                    <Icon as={FcDisapprove} boxSize='20' />
                    <Text fontWeight='semibold' fontSize='lg' color='red'>DENY</Text>
                  </Stack>
                </Button>
              </Stack>
              <FormControl>
                <FormLabel>Browser Details</FormLabel>
                <Text fontSize='sm' as='code'>
                  {JSON.stringify(loginApproval.agentDetails, null, 2)}
                </Text>
              </FormControl>
            </Stack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
