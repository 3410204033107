import Checkbox from 'components/_inputs/Checkbox'

import { useReactiveVar } from '@apollo/client'
import { selectedCustomersVar } from 'utils/cache'

export default function CustomerCheckbox ({ customer }) {
  const selectedVar = useReactiveVar(selectedCustomersVar)

  const isChecked = Boolean(selectedVar[customer.id])

  const toggleSelect = () => {
    if (isChecked) {
      const newSelection = { ...selectedVar }
      delete newSelection[customer.id]
      selectedCustomersVar(newSelection)
    } else {
      selectedCustomersVar({
        ...selectedVar,
        [customer.id]: customer
      })
    }
  }

  return (
    <Checkbox isChecked={isChecked} toggleSelect={toggleSelect} />
  )
}
