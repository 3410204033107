import { Switch } from '@chakra-ui/react'

import useMutation from 'hooks/useMutation'
import { UPDATE_TICKET_NOTE_MARK_AS_DONE } from 'apollo/mutations/tickets'

export default function EditTicketNoteMarkAsDone ({ note }) {
  const [updateTicketNoteMarkAsDone] = useMutation(UPDATE_TICKET_NOTE_MARK_AS_DONE, {
    notification: 'Successfully updated the note mark as done',
    isPromise: false
  })

  const handleChange = () => {
    const data = { id: note.id, markAsDone: !note.markAsDone }
    updateTicketNoteMarkAsDone({ variables: { data } })
  }

  return (
    <Switch
      isChecked={note.markAsDone}
      onChange={handleChange}
    />
  )
}
