import { gql } from '@apollo/client'

export const GET_TOTAL_COUNT = gql`
  query GET_TOTAL_COUNT {
    getTotalCount
  }
`

export const GET_DASHBOARD_COUNTS = gql`
  query GET_DASHBOARD_COUNTS {
    getDashboardCounts
  }
`
