import { gql } from '@apollo/client'

export const GET_ALL_STORES = gql`
  query GET_ALL_STORES ($where: StoreWhereInput $orderBy: [StoreOrderByInput!] $take: Int $skip: Int) {
    response: getAllStores (where: $where orderBy: $orderBy take: $take skip: $skip) {
      allData: stores {
        id
        name
        location
        updatedAt
        createdAt
      }
      metrics {
        allCount
        filteredCount
      }
    }
  }
`
