import { useState } from 'react'

import {
  Alert,
  Modal,
  Stack,
  Button,
  AlertIcon,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton
} from '@chakra-ui/react'

import AsyncSelect from 'components/_select/AsyncSelect'

import useQuery from 'hooks/useQuery'
import { SEARCH_CUSTOMERS } from 'apollo/queries/customers'

import Loading from 'components/_common/Loading'
import ErrorAlert from 'components/_common/ErrorAlert'

import useMutation from 'hooks/useMutation'
import { UPDATE_SERVICE_CUSTOMER } from 'apollo/mutations/services'

export default function EditServiceCustomer ({ service, onClose }) {
  const { data, loading: loadingCustomers, error: errorLoadingCustomers, refetch } = useQuery(SEARCH_CUSTOMERS)

  const [customerID, setCustomerID] = useState()

  const customers = data?.response || []

  const transformCustomers = (customer) => ({ value: customer.id, label: customer.fullName })

  const options = customers.map(transformCustomers)

  const onSelectCustomer = ({ value }) => {
    setCustomerID(value)
  }

  const loadCustomers = async inputValue => {
    try {
      await refetch({ searchBy: inputValue })
      if (customers) {
        return customers.map(transformCustomers)
      } else {
        return []
      }
    } catch (error) {
      console.log(error)
      return []
    }
  }

  const handleClose = (toRefetch = false) => {
    reset()
    onClose(toRefetch)
  }

  const [updateServiceCustomer, { error, loading, reset }] = useMutation(UPDATE_SERVICE_CUSTOMER, {
    notification: 'Successfully updated the service customer',
    isPromise: false,
    onCompleted: () => {
      handleClose(true)
    }
  })

  const handleSubmit = () => {
    const data = { id: service.id, customerID: customerID || null }
    updateServiceCustomer({ variables: { data } })
  }

  return (
    <Modal isOpen onClose={onClose} size='lg' scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{service?.macID || service?.accountNumber}</ModalHeader>
        <ModalCloseButton />
        <ModalBody minH='300px'>
          <Stack spacing='4'>
            {loadingCustomers && <Loading />}
            {errorLoadingCustomers && <ErrorAlert status='error'>{errorLoadingCustomers.message}</ErrorAlert>}
            <Alert status='warning' borderRadius='lg'>
              <AlertIcon />
              Select a new customer to move this service to
            </Alert>
            <AsyncSelect
              options={options}
              onChange={onSelectCustomer}
              maxMenuHeight='200px'
              loadOptions={loadCustomers}
            />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Stack spacing='4' width='100%'>
            {error && <ErrorAlert> {error.message} </ErrorAlert>}
            <Button
              isFullWidth
              isLoading={loading}
              variant='solid'
              isDisabled={loading || !customerID}
              onClick={handleSubmit}
            >
              Move Service
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
