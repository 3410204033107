import useQuery from 'hooks/useQuery'
import { GET_ALL_APP_CONFIGS } from 'apollo/queries/settings'

import EditSetting from 'components/settings/EditSetting'

import {
  Modal,
  Stack,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton
} from '@chakra-ui/react'

import Loading from 'components/_common/Loading'
import ErrorAlert from 'components/_common/ErrorAlert'

export default function ViewSettings ({ onClose }) {
  const { data, loading: loadingSettings, error: errorLoading } = useQuery(GET_ALL_APP_CONFIGS)

  const settings = data?.getAllAppConfigs?.settings || []

  const settingsToEdit = ['ALLOWED_REQUEST_RESET_PW_PER_DAY', 'ALLOWED_LOGIN_APPROVAL_REQUESTS', 'FREQUENT_LOGIN_REQUEST_TIME_LIMIT']

  return (
    <Modal isOpen onClose={onClose} scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>App Settings</ModalHeader>
        <ModalCloseButton />
        <ModalBody mb='6'>
          {loadingSettings && <Loading />}
          {errorLoading && <ErrorAlert>{errorLoading.message} </ErrorAlert>}
          {!loadingSettings && !errorLoading && (
            <Stack spacing='8'>
              {settings.filter(({ key }) => settingsToEdit.includes(key)).map(({ key, value }) => <EditSetting key={key} label={key} value={value} />)}
            </Stack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
