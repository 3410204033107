import { useApolloClient } from '@apollo/client'

import { WHO_AM_I } from 'apollo/queries/auth'

export default function useAuthUser () {
  const client = useApolloClient()

  // Fetch the cached authUser
  const { whoAmI: authUser } = client.readQuery({ query: WHO_AM_I }) || {}

  return authUser
}
