import { gql } from '@apollo/client'

export const LOGIN = gql`
  mutation LOGIN ($data: LoginInput!) {
    login (data: $data) {
      id
      token
      otpHash
    }
  }
`

export const FORGOT_PASSWORD = gql`
  mutation FORGOT_PASSWORD ($data: ForgotPasswordInput!) {
    forgotPassword (data: $data)
  }
`

export const RESET_PASSWORD = gql`
  mutation RESET_PASSWORD ($data: ResetPasswordInput!) {
    resetPassword (data: $data)
  }
`

export const VALIDATE_OTP = gql`
  mutation VALIDATE_OTP ($data: OtpInput!) {
    validateOTP (data: $data) {
      id
      token
    }
  }
`
