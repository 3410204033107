import useAuthUser from 'hooks/useAuthUser'

import Select from 'components/_select/CustomSelect'

import useMutation from 'hooks/useMutation'
import { START_CONVERSATION } from 'apollo/mutations/chats'
import { GET_MY_CONVERSATIONS } from 'apollo/queries/chats'

import useQuery from 'hooks/useQuery'
import { GET_ALL_USERS_LEAN } from 'apollo/queries/users'

import Loading from 'components/_common/Loading'
import ErrorAlert from 'components/_common/ErrorAlert'

import { navigate } from 'hooks/useRoutes'

export default function NewConversation ({ conversations }) {
  const authUser = useAuthUser()

  const [startConversation] = useMutation(START_CONVERSATION, {
    notification: 'Successfully started new conversation',
    refetchQueries: [{ query: GET_MY_CONVERSATIONS }]
  })

  const { data, loading: loadingUsers, error: errorLoadingUsers } = useQuery(GET_ALL_USERS_LEAN)

  const users = data?.response || []

  if (errorLoadingUsers) return <ErrorAlert>{errorLoadingUsers.message}</ErrorAlert>

  const doesConversationExists = (toUserID) => {
    return conversations.find(({ toUser }) => toUser.id === toUserID)
  }

  const options = users
    .map(user => ({ value: user.id, label: user.fullName }))
    .filter(({ value }) => value !== authUser.id)

  const handleSelectChat = (user) => {
    const existingConversation = doesConversationExists(user.value)
    if (existingConversation) {
      navigate(null, `/chat/${existingConversation.id}`)
    } else {
      startConversation({ variables: { id: user.value } })
        .then(({ startConversation }) => {
          navigate(null, `/chat/${startConversation.id}`)
        })
        .catch(console.log)
    }
  }

  if (loadingUsers) return <Loading />

  return (
    <Select
      value={null}
      options={options}
      onChange={handleSelectChat}
      maxMenuHeight='300px'
      placeholder='Search or start new chat ...'
    />
  )
}
