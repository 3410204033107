import useMutation from 'hooks/useMutation'
import { UPDATE_SERVICE_IS_MONTHLY } from 'apollo/mutations/services'

import { Button } from '@chakra-ui/react'

export default function EditServiceIsMonthly ({ service }) {
  const [updateServiceIsMonthly, { loading }] = useMutation(UPDATE_SERVICE_IS_MONTHLY, {
    notification: 'Successfully updated the service',
    isPromise: false
  })

  const onToggleIsMonthly = () => {
    const data = { id: service.id, isMonthly: !service.isMonthly }
    updateServiceIsMonthly({ variables: { data } })
  }

  return (
    <Button
      onClick={onToggleIsMonthly}
      colorScheme='orange'
      isLoading={loading}
      variant={service.isMonthly ? 'solid' : 'outline'}
    >
      {!service.isMonthly ? 'Set as Monthly' : 'Remove as Monthly'}
    </Button>
  )
}
