import Select from 'components/_select/CustomSelect'

import useAuthUser from 'hooks/useAuthUser'

export default function StoresFilter ({ additionalVariables, setAdditionalVariables, isDisabled = false }) {
  const authUser = useAuthUser()

  const stores = authUser.stores

  const options = stores.map(store => ({ value: store.id, label: store.name }))
  options.unshift({ value: undefined, label: 'All Stores' })

  const handleSelectStore = ({ value: storeID }) => {
    setAdditionalVariables({ ...additionalVariables, storeID })
  }

  return (
    <Select
      value={options.find(({ value }) => value === additionalVariables.storeID)}
      options={options}
      onChange={handleSelectStore}
      maxMenuHeight='300px'
      isDisabled={isDisabled}
    />
  )
}
