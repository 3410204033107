import { gql } from '@apollo/client'

export const START_CONVERSATION = gql`
  mutation START_CONVERSATION ($id: String!) {
    startConversation(id: $id) {
      id
      toUser {
        id
        fullName
      }
      lastChatAt
      lastMessageStatus
      lastMessage
    }
  }
`

export const SEND_CHAT = gql`
  mutation SEND_CHAT ($data: SendChatInput!) {
    sendChat(data: $data) {
      id
    }
  }
`

export const BROADCAST_MESSAGE = gql`
  mutation BROADCAST_MESSAGE ($message: String!) {
    broadcast(message: $message)
  }
`

export const MARK_AS_READ = gql`
  mutation MARK_AS_READ ($id: String!) {
    markAsRead(id: $id)
  }
`
