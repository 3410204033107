import Checkbox from 'components/_inputs/Checkbox'

import { useReactiveVar } from '@apollo/client'
import { selectedServiceTypesVar } from 'utils/cache'

export default function ServiceTypeSelectCheckbox ({ serviceType }) {
  const selectedVar = useReactiveVar(selectedServiceTypesVar)

  const isChecked = Boolean(selectedVar[serviceType.id])

  const toggleSelect = () => {
    if (isChecked) {
      const newSelection = { ...selectedVar }
      delete newSelection[serviceType.id]
      selectedServiceTypesVar(newSelection)
    } else {
      selectedServiceTypesVar({
        ...selectedVar,
        [serviceType.id]: serviceType
      })
    }
  }

  return (
    <Checkbox isChecked={isChecked} toggleSelect={toggleSelect} />
  )
}
