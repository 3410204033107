import { useState, useEffect, useRef } from 'react'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object } from 'yup'

import {
  Text,
  Input,
  Stack,
  Textarea,
  FormLabel,
  IconButton,
  FormControl,
  useMergeRefs,
  FormErrorMessage
} from '@chakra-ui/react'

import {
  BiX,
  BiEdit,
  BiCheck
} from 'react-icons/bi'

export default function EditableInput (props) {
  const inputRef = useRef()
  const editButtonRef = useRef()

  const {
    type = 'text',
    name = 'inputFieldName',
    value = '',
    label = '',
    placeholder = 'Type here ...',
    onSubmit = console.log,
    validation,
    isLoading = false,
    error,
    clearOnSubmit,
    rows = 10,
    isInitiallyEditing = false,
    onDialogClose,
    inputWidth = '100%',
    numberInputProps = {},
    justifyContent = 'flex-start',
    textAlign
  } = props

  const [isEditing, setIsEditing] = useState(isInitiallyEditing)

  const InputElement = type === 'textarea' ? Textarea : Input

  const schemaValidation = object().shape({
    [name]: validation
  })

  useEffect(() => {
    isEditing && inputRef.current && inputRef.current.focus()
    // eslint-disable-next-line
  }, [isEditing])

  const { handleSubmit, formState: { errors }, register, reset } = useForm({
    defaultValues: { [name]: value },
    resolver: yupResolver(schemaValidation)
  })

  const handleOnSubmit = (data) => {
    // setLocalError(null)
    if (!isInitiallyEditing) {
      setIsEditing(false)
    }
    if (type === 'text' || type === 'textarea') {
      inputRef.current.value = inputRef.current.value.trim()
      onSubmit(data[name].trim())
        .then(() => onDialogClose && onDialogClose())
        .catch(console.log)
    } else {
      if (type === 'number') {
        onSubmit(parseInt(data[name]))
          .then(() => onDialogClose && onDialogClose())
          .catch(console.log)
      } else {
        onSubmit(data[name])
          .then(() => onDialogClose && onDialogClose())
          .catch(console.log)
      }
    }
    if (clearOnSubmit) {
      inputRef.current.value = ''
    }
    editButtonRef.current.focus()
  }

  const onEdit = () => {
    setIsEditing(true)
    inputRef.current.focus()
    if (type === 'text' || type === 'textarea') {
      window.requestAnimationFrame(() => {
        inputRef.current.setSelectionRange(0, inputRef.current.value.length)
      })
    }
  }

  const onCancel = () => {
    reset()
    setIsEditing(false)
    onDialogClose ? onDialogClose() : setTimeout(() => editButtonRef.current.focus(), 1)
  }

  const inputElementProps = {
    ...register(name),
    ref: useMergeRefs(inputRef, register(name).ref),
    type,
    placeholder,
    rows,
    width: inputWidth,
    display: !isEditing ? 'none' : 'inline-flex',
    p: '2'
  }

  return (
    <Stack as='form' onSubmit={handleSubmit(handleOnSubmit)} width='100%' direction='row' alignItems='center'>
      <FormControl isInvalid={errors[name] || Boolean(error)}>
        {label && (
          <FormLabel htmlFor={name} textAlign={textAlign}>
            {label}
          </FormLabel>
        )}
        <Stack spacing={4} justifyContent={textAlign === 'right' ? 'flex-end' : justifyContent} direction='row' alignItems='center'>
          <InputElement {...inputElementProps} {...numberInputProps} />
          {!isEditing && <Text m='0 !important' maxW='80%' textAlign={textAlign}>{value}</Text>}
          <IconButton
            size='sm'
            icon={<BiCheck fontSize='16px' />}
            isDisabled={errors[name]}
            type='submit'
            display={!isEditing ? 'none' : 'inline-flex'}
            isLoading={isLoading}
          />
          <IconButton
            size='sm'
            icon={<BiX fontSize='16px' />}
            onClick={onCancel}
            display={!isEditing ? 'none' : 'inline-flex'}
            isDisabled={isLoading}
            variant='outline'
          />
          <IconButton
            size='sm'
            icon={<BiEdit fontSize='16px' />}
            onClick={onEdit}
            display={isEditing ? 'none' : 'inline-flex'}
            ref={editButtonRef}
            isLoading={isLoading}
            variant='outline'
          />
        </Stack>
        <FormErrorMessage mt='0'>
          {errors[name]?.message}
          {error?.message}
        </FormErrorMessage>
      </FormControl>
    </Stack>
  )
}
