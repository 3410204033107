import { useEffect } from 'react'

import { CREATE_USER } from 'apollo/mutations/users'
import useMutation from 'hooks/useMutation'

import {
  Input,
  Stack,
  Select,
  Button,
  FormLabel,
  FormControl,
  useDisclosure,
  FormErrorMessage
} from '@chakra-ui/react'

import { FaPlus } from 'react-icons/fa'

import FormDialog from 'components/_common/FormDialog'
import PasswordInput from 'components/_inputs/PasswordInput'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'

const schemaValidation = object().shape({
  email: string().email().required(),
  fullName: string().required(),
  password: string().required().min(3),
  primaryPhoneNumber: string().required().matches(/^\+?\d{10,14}$/, { message: 'Invalid Phone Number' })
})

export default function CreateUser ({ refetch }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleClose = () => {
    onClose()
    resetMutation()
    resetForm()
  }

  const [createUser, { error, loading, reset: resetMutation }] = useMutation(CREATE_USER, {
    notification: 'Successfully created the user',
    isPromise: false,
    onCompleted: () => {
      handleClose()
      refetch()
    }
  })

  const { register, handleSubmit, formState: { errors }, reset: resetForm, setFocus } = useForm({
    resolver: yupResolver(schemaValidation)
  })

  useEffect(() => { isOpen && setTimeout(() => setFocus('email'), 1) }, [isOpen])

  const onSubmit = ({ email, fullName, password, role, primaryPhoneNumber }) => {
    const code = primaryPhoneNumber.charAt(0) !== '+' ? '+1' : ''
    const phoneNumbers = [{
      type: 'MOBILE',
      value: code + primaryPhoneNumber
    }]
    const data = { email, fullName, password, role, phoneNumbers, primaryPhoneNumber: code + primaryPhoneNumber }
    createUser({ variables: { data } })
  }

  return (
    <>
      <Button size='sm' leftIcon={<FaPlus />} onClick={onOpen}>
        New
      </Button>
      {isOpen && (
        <FormDialog
          title='Create User'
          formID='#createUser'
          error={error}
          loading={loading}
          onClose={handleClose}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack spacing='8'>
            <FormControl isRequired isInvalid={errors?.email}>
              <FormLabel>Email</FormLabel>
              <Input {...register('email')} />
              <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors?.fullName}>
              <FormLabel>Full Name</FormLabel>
              <Input {...register('fullName')} />
              <FormErrorMessage>{errors?.fullName?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id='password' isRequired isInvalid={errors?.password}>
              <FormLabel>Password</FormLabel>
              <PasswordInput {...register('password')} />
              <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors?.primaryPhoneNumber}>
              <FormLabel>Primary Phone Number</FormLabel>
              <Input {...register('primaryPhoneNumber')} />
              <FormErrorMessage>
                {errors?.primaryPhoneNumber?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel>Role</FormLabel>
              <Select {...register('role')}>
                <option value='STAFF'>STAFF</option>
                <option value='ADMIN'>ADMIN</option>
              </Select>
            </FormControl>
          </Stack>
        </FormDialog>
      )}
    </>
  )
}
