import { gql } from '@apollo/client'

export const CREATE_SOURCE = gql`
  mutation CREATE_SOURCE ($data: CreateSourceInput!) {
    createSource(data: $data) {
      id
    }
  }
`

export const UPDATE_SOURCE_NAME = gql`
  mutation UPDATE_SOURCE_NAME ($data: UpdateSourceInput!) {
    updateSource(data: $data) {
      id
      name
      updatedAt
    }
  }
`

export const UPDATE_SOURCE_DETAILS = gql`
  mutation UPDATE_SOURCE_DETAILS ($data: UpdateSourceInput!) {
    updateSource(data: $data) {
      id
      details
      updatedAt
    }
  }
`

export const DELETE_SOURCE = gql`
  mutation DELETE_SOURCE ($id: String!) {
    deleteSource(id: $id) {
      id
    }
  }
`
