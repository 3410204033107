import Checkbox from 'components/_inputs/Checkbox'

import { useReactiveVar } from '@apollo/client'
import { selectedUsersVar } from 'utils/cache'

export default function UserCheckbox ({ user }) {
  const selectedVar = useReactiveVar(selectedUsersVar)

  const isChecked = Boolean(selectedVar[user.id])

  const toggleSelect = () => {
    if (isChecked) {
      const newSelection = { ...selectedVar }
      delete newSelection[user.id]
      selectedUsersVar(newSelection)
    } else {
      selectedUsersVar({
        ...selectedVar,
        [user.id]: user
      })
    }
  }

  return (
    <Checkbox isChecked={isChecked} toggleSelect={toggleSelect} />
  )
}
