import { gql } from '@apollo/client'

export const UPDATE_APP_CONFIG = gql`
  mutation UPDATE_APP_CONFIG ($data: AppConfigInput!) {
    updateAppConfig (data: $data) {
      value
      key
    }
  }
`
