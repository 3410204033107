import useQuery from 'hooks/useQuery'
import { GET_ALL_USERS_LEAN } from 'apollo/queries/users'

import useMutation from 'hooks/useMutation'
import { UPDATE_TICKET_ASSIGNED_TO } from 'apollo/mutations/tickets'

import {
  Modal,
  Stack,
  ModalBody,
  IconButton,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalCloseButton,
  ModalFooter
} from '@chakra-ui/react'

import { BiPencil } from 'react-icons/bi'

import ErrorAlert from 'components/_common/ErrorAlert'
import Loading from 'components/_common/Loading'

import Select from 'components/_select/CustomSelect'

export default function EditTicketAssignedToTrigger ({ ticket, inline = false, ...rest }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  if (inline) return <EditTicketAssignedToInline {...rest} />

  return (
    <>
      {isOpen && <EditTicketAssignedTo ticket={ticket} onClose={onClose} />}
      <IconButton size='xs' variant='outline' icon={<BiPencil fontSize='16px' />} onClick={onOpen} />
    </>
  )
}

function EditTicketAssignedTo ({ onClose, ticket }) {
  const { data, loading: loadingUsers, error: errorLoadingUsers } = useQuery(GET_ALL_USERS_LEAN)

  const users = data?.response || []

  const handleClose = () => {
    resetMutation()
    onClose()
  }

  const [updateTicketAssignedTo, { error, loading, reset: resetMutation }] = useMutation(UPDATE_TICKET_ASSIGNED_TO, {
    notification: 'Successfully updated the ticket assignee',
    isPromise: false,
    onCompleted: handleClose
  })

  const onUpdateAssignedTo = ({ value, label }) => {
    const data = { id: ticket.id, assignedToUserID: value }
    updateTicketAssignedTo({ variables: { data } })
  }

  const options = users.map(user => ({ value: user.id, label: user.fullName }))

  return (
    <Modal isOpen onClose={handleClose} scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Assigned To</ModalHeader>
        <ModalCloseButton isDisabled={loading} />
        <ModalBody minH='200px'>
          <Stack spacing='8'>
            {loadingUsers && <Loading />}
            {!loadingUsers && (
              <Select
                options={options}
                onChange={onUpdateAssignedTo}
                defaultValue={options.find(({ value }) => value === ticket.assignedToUser?.id)}
                maxMenuHeight='200px'
              />
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Stack width='100%' spacing='4'>
            {errorLoadingUsers && <ErrorAlert>{errorLoadingUsers.message}</ErrorAlert>}
            {error && <ErrorAlert>{error.message}</ErrorAlert>}
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

function EditTicketAssignedToInline ({ onChange }) {
  const { data, loading: loadingUsers, error: errorLoadingUsers } = useQuery(GET_ALL_USERS_LEAN)

  const users = data?.response || []

  const options = users.map(user => ({ value: user.id, label: user.fullName }))

  return (
    <Stack spacing='8'>
      {loadingUsers && <Loading />}
      {errorLoadingUsers && <ErrorAlert>{errorLoadingUsers.message}</ErrorAlert>}
      {!loadingUsers && (
        <Select
          options={options}
          onChange={onChange}
          maxMenuHeight='200px'
        />
      )}
    </Stack>
  )
}
