import TableWrapper from 'components/table/TableWrapper'

import { GET_ALL_LOGIN_APPROVALS } from 'apollo/queries/loginApprovals'
import {
  loginApprovalFilterWhereVar,
  loginApprovalFilterOrderByVar
} from 'utils/cache'

import fields from 'components/loginApprovals/fields'

import {
  Modal,
  Badge,
  Button,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalCloseButton
} from '@chakra-ui/react'

export default function ViewUserLoginApprovals ({ inline = false, ...props }) {
  if (props.user.role === 'ADMIN') {
    return (
      <Badge borderRadius='md'>Full Access</Badge>
    )
  }

  return inline ? <ViewUserLoginApprovalsInline {...props} /> : <OpenUserLoginApprovalsTrigger {...props} />
}

function ViewUserLoginApprovalsInline ({ user }) {
  return (
    <TableWrapper
      isEmbedded
      query={GET_ALL_LOGIN_APPROVALS}
      fields={fields}
      hideFields={['requestedUser']}
      rowKey='id'
      rowType='loginApproval'
      fuzzyKey='loginApprovals'
      defaultOrderBy={[{ requestedAt: 'desc' }]}
      additionalWhere={{ requestedUser: { id: { equals: user.id } } }}
      filterWhereVar={loginApprovalFilterWhereVar}
      filterOrderByVar={loginApprovalFilterOrderByVar}
    />
  )
}

function OpenUserLoginApprovalsTrigger ({ user }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      {isOpen && <OpenUserLoginApprovals user={user} onClose={onClose} />}
      <Button onClick={onOpen} size='xs' variant='outline' isDisabled={user.requestedLoginApprovals.length === 0}>
        {user.requestedLoginApprovals.length} | Login Approvals
      </Button>
    </>
  )
}

export function OpenUserLoginApprovals ({ user, onClose }) {
  return (
    <Modal isOpen onClose={onClose} size='' scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent pb='6' width='80vw'>
        <ModalHeader>Login Approvals</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ViewUserLoginApprovals inline user={user} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
