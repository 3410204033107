import { useEffect } from 'react'

import { useSubscription } from '@apollo/client'

import { NEW_CHAT } from 'apollo/subscriptions'

import useAuthUser from 'hooks/useAuthUser'

import {
  Badge,
  useToast
} from '@chakra-ui/react'

export default function ChatNotification () {
  const toast = useToast()

  const authUser = useAuthUser()

  const { data } = useSubscription(NEW_CHAT, { variables: { id: authUser.id } })

  useEffect(() => {
    toast.closeAll()
    data && !data.newChat.read && toast({
      title: `Message from ${data.newChat.fromUser.fullName}`,
      description: data.newChat.message,
      status: 'info',
      position: 'top',
      duration: null,
      isClosable: true
    })
  }, [data?.newChat?.createdAt])

  if (data && !data.newChat.read) {
    return (
      <Badge mb='1' colorScheme='orange' borderRadius='md' px='2' fontSize='xs'>
        New
      </Badge>
    )
  } else {
    return <></>
  }
}
