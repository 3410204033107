import EditableInput from 'components/_inputs/EditableInput'

import { string } from 'yup'

import useMutation from 'hooks/useMutation'
import { UPDATE_LANGUAGE_DETAILS } from 'apollo/mutations/languages'

export default function EditLanguageDetails ({ language }) {
  const [updateDetails, { error, loading, reset }] = useMutation(UPDATE_LANGUAGE_DETAILS, {
    notification: 'Successfully updated the details'
  })

  const handleSubmit = details => {
    const data = { id: language.id, details }
    return updateDetails({ variables: { data } })
  }

  return (
    <EditableInput
      name='details'
      value={language.details || ''}
      label='Details'
      onSubmit={handleSubmit}
      validation={string().required()}
      isLoading={loading}
      error={error}
      reset={reset}
    />
  )
}
