import {
  Grid,
  Text,
  Stack
} from '@chakra-ui/react'

import useQuery from 'hooks/useQuery'
import { GET_DASHBOARD_COUNTS } from 'apollo/queries/dashboard'
import { GET_ALL_APP_CONFIGS } from 'apollo/queries/settings'

import Loading from 'components/_common/Loading'
import ErrorAlert from 'components/_common/ErrorAlert'

import CustomersCountCard from 'components/dashboard/CustomersCountCard'
import ServicesCountCard from 'components/dashboard/ServicesCountCard'
import TransactionsCountCard from 'components/dashboard/TransactionsCountCard'
import TicketsCountCard from 'components/dashboard/TicketsCountCard'
import UsersCountCard from 'components/dashboard/UsersCountCard'

import { FaUsers, FaUsersCog } from 'react-icons/fa'
import { BiSupport } from 'react-icons/bi'
import { MdDevices, MdAttachMoney } from 'react-icons/md'

import StatsWrapperSimple from 'components/_charts/StatsWrapperSimple'

import { startCase } from 'lodash'

import ToggleGraphs from 'components/dashboard/ToggleGraphs'

import {
  TOPUPS_REPORT,
  NEW_CUSTOMERS_REPORT,
  NEW_SERVICES_REPORT,
  TRANSACTIONS_REPORT,
  CREDITS_PURCHASED_REPORT
} from 'apollo/queries/reports'

const graphOptions = {
  TOPUPS: TOPUPS_REPORT,
  NEW_CUSTOMERS: NEW_CUSTOMERS_REPORT,
  NEW_SERVICES: NEW_SERVICES_REPORT,
  TRANSACTIONS: TRANSACTIONS_REPORT,
  CREDITS_PURCHASED: CREDITS_PURCHASED_REPORT
}

export default function Dashboard () {
  const { data: dataSettings, loading: loadingSettings, error: errorLoading } = useQuery(GET_ALL_APP_CONFIGS)

  const { data = {}, error, loading } = useQuery(GET_DASHBOARD_COUNTS)

  const settings = dataSettings?.getAllAppConfigs?.settings || []

  if (loading || loadingSettings) return <Loading />

  if (error || errorLoading) return <ErrorAlert> {error.message} </ErrorAlert>

  const dashboardReports = JSON.parse(settings.find(({ key }) => key === 'DASHBOARD_REPORTS')?.value)

  return (
    <Stack width='100%' spacing='12'>
      <Stack direction='row' justifyContent='space-between'>
        <CustomersCountCard
          label='Customers'
          values={data.getDashboardCounts.customer}
          icon={FaUsers}
        />
        <ServicesCountCard
          label='Services'
          values={data.getDashboardCounts.service}
          icon={MdDevices}
        />
        <TicketsCountCard
          label='Tickets'
          values={data.getDashboardCounts.ticket}
          icon={BiSupport}
        />
        <TransactionsCountCard
          label='Transactions'
          values={data.getDashboardCounts.transaction}
          icon={MdAttachMoney}
        />
        <UsersCountCard
          label='Users'
          values={data.getDashboardCounts.user}
          icon={FaUsersCog}
        />
      </Stack>
      <ToggleGraphs />
      <Grid templateColumns='1fr 1fr' gap='4' overflowY='auto'>
        {dashboardReports.map(report => (
          <Stack key={report} height='350px'>
            <Text fontWeight='bold'>{startCase(report)}</Text>
            <StatsWrapperSimple query={graphOptions[report]} variables={{ data: { period: 'WEEK' } }} />
          </Stack>
        ))}
      </Grid>
    </Stack>
  )
}
