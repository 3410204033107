import { gql } from '@apollo/client'

export const CREATE_SHIPMENT = gql`
  mutation CREATE_SHIPMENT ($data: CreateShipmentInput!) {
    createShipment(data: $data) {
      id
    }
  }
`

export const UPDATE_SHIPMENT_NAME = gql`
  mutation UPDATE_SHIPMENT_NAME ($data: UpdateShipmentInput!) {
    updateShipment(data: $data) {
      id
      name
      updatedAt
    }
  }
`

export const UPDATE_SHIPMENT_DETAILS = gql`
  mutation UPDATE_SHIPMENT_DETAILS ($data: UpdateShipmentInput!) {
    updateShipment(data: $data) {
      id
      details
      updatedAt
    }
  }
`

export const DELETE_SHIPMENT = gql`
  mutation DELETE_SHIPMENT ($id: String!) {
    deleteShipment(id: $id) {
      id
    }
  }
`
