import { useApolloClient } from '@apollo/client'

import { GET_TOTAL_COUNT } from 'apollo/queries/dashboard'

import { Badge } from '@chakra-ui/react'

export default function TodaysTopupsCount () {
  const client = useApolloClient()

  const { getTotalCount: data } = client.readQuery({ query: GET_TOTAL_COUNT }) || {}

  return (
    <Badge colorScheme='red' mb='1' fontSize='sm' borderRadius='md' px='2'>
      {data?.service?.todaysTopupServices?.total}
    </Badge>
  )
}
