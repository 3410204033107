import { useState } from 'react'

import useResponsiveDevice from 'hooks/useResponsiveDevice'

import useMutation from 'hooks/useMutation'
import { UPDATE_TRANSACTION_TYPE } from 'apollo/mutations/transactions'

import {
  Box,
  Text,
  Modal,
  Stack,
  Radio,
  ModalBody,
  RadioGroup,
  IconButton,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  ModalCloseButton
} from '@chakra-ui/react'

import { BiEdit } from 'react-icons/bi'

import Loading from 'components/_common/Loading'
import ErrorAlert from 'components/_common/ErrorAlert'

export default function EditTransactionTypeTrigger ({ transaction, justifyContent = 'left' }) {
  const { isTouch } = useResponsiveDevice()

  const [isVisible, setIsVisible] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const touchDeviceProps = isTouch ? { onClick: onOpen, cursor: 'pointer' } : {}

  const marginOffset = isVisible && justifyContent === 'center' ? '8' : ''

  return (
    <>
      {isOpen && <EditTransactionType transaction={transaction} onClose={onClose} />}
      <Stack
        direction='row'
        alignItems='center'
        width='100%'
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        justifyContent={justifyContent}
      >
        <Text ml={marginOffset} {...touchDeviceProps}>{transaction.transactionType}</Text>
        {isVisible && (
          <IconButton
            size='xs'
            variant='outline'
            icon={<BiEdit fontSize='16px' />}
            onClick={onOpen}
          />
        )}
      </Stack>
    </>
  )
}

function EditTransactionType ({ transaction, onClose }) {
  const handleOnClose = () => {
    reset()
    onClose()
  }

  const [updateTransactionType, { error, loading, reset }] = useMutation(UPDATE_TRANSACTION_TYPE, {
    notification: 'Successfully updated the transaction type',
    isPromise: false,
    onCompleted: handleOnClose
  })

  const handleSubmit = transactionType => {
    const data = { id: transaction.id, transactionType }
    return updateTransactionType({ variables: { data } })
  }

  return (
    <Modal isOpen onClose={handleOnClose} size='xs' scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Transaction Type</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <RadioGroup onChange={handleSubmit} value={transaction.transactionType}>
            <Stack spacing='4'>
              {['SALES', 'INVOICE', 'CREDIT', 'REVERSE', 'REFERRAL'].map(type => (
                <Box key={type}>
                  <Radio value={type}>{type}</Radio>
                </Box>
              ))}
            </Stack>
          </RadioGroup>
        </ModalBody>
        <ModalFooter>
          <Stack spacing='4' width='100%'>
            {loading && <Loading />}
            {error && <ErrorAlert> {error.message} </ErrorAlert>}
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
