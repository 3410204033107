import useMutation from 'hooks/useMutation'
import { UPDATE_SERVICE_EXPIRY_DATE } from 'apollo/mutations/services'

import DateTimePicker from 'components/_inputs/DateTimePicker'

export default function EditServiceExpiryDate ({ service, justifyContent, inline = false }) {
  const [updateExpiryDate, { error, loading, reset }] = useMutation(UPDATE_SERVICE_EXPIRY_DATE, {
    notification: 'Successfully updated the expiry date'
  })

  const handleSubmit = expiryDate => {
    const data = { id: service.id, expiryDate }
    return updateExpiryDate({ variables: { data } })
  }

  return (
    <DateTimePicker
      inline={inline}
      label='Expiry Date'
      value={service.expiryDate}
      onChange={handleSubmit}
      error={error}
      loading={loading}
      reset={reset}
      justifyContent={justifyContent}
    />
  )
}
