import { gql } from '@apollo/client'

export const TOPUPS_REPORT = gql`
  query TOPUPS_REPORT ($data: getTopupsCountInput!) {
    stats: getTopupsReport (data: $data)
  }
`

export const NEW_CUSTOMERS_REPORT = gql`
  query NEW_CUSTOMERS_REPORT ($data: getNewCustomersCountInput!) {
    stats: getNewCustomersReport (data: $data)
  }
`

export const NEW_SERVICES_REPORT = gql`
  query NEW_SERVICES_REPORT ($data: getNewServicesCountInput!) {
    stats: getNewServicesReport (data: $data)
  }
`

export const TRANSACTIONS_REPORT = gql`
  query TRANSACTIONS_REPORT ($data: getTotalCreditsPurchasedCountInput!) {
    stats: getTransactionsReport (data: $data)
  }
`

export const CREDITS_PURCHASED_REPORT = gql`
  query CREDITS_PURCHASED_REPORT ($data: getTotalCreditsPurchasedCountInput!) {
    stats: getTotalCreditsPurchasedReport (data: $data)
  }
`

export const USER_ACTIVITIES_REPORT = gql`
  query USER_ACTIVITIES_REPORT ($data: getUserReportsInput!) {
    stats: getUserActivityReport (data: $data)
  }
`

export const CUSTOMER_CREDIT_REPORT = gql`
  query CUSTOMER_CREDIT_REPORT ($data: getCustomerCreditHistoryInput!) {
    stats: getCustomerCreditHistoryReport (data: $data)
  }
`

export const SERVICE_TYPES_CREDITS = gql`
  query SERVICE_TYPES_CREDITS {
    stats: getServiceTypesCreditsCount
  }
`

export const ACTIVE_SERVICES = gql`
  query ACTIVE_SERVICES {
    stats: getServicesCount
  }
`

export const REMAINING_TOPUPS_REPORT = gql`
  query REMAINING_TOPUPS_REPORT ($data: getRemainingTopupsReportInput!) {
    stats: getRemainingTopupsReport (data: $data)
  }
`
