import { gql } from '@apollo/client'

export const GET_ALL_LANGUAGES = gql`
  query GET_ALL_LANGUAGES ($where: LanguageWhereInput $orderBy: [LanguageOrderByInput!] $take: Int $skip: Int) {
    response: getAllLanguages (where: $where orderBy: $orderBy take: $take skip: $skip) {
      allData: languages {
        id
        name
        details
        updatedAt
        createdAt
      }
      metrics {
        allCount
        filteredCount
      }
    }
  }
`
