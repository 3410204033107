import { useEffect } from 'react'
import useQuery from 'hooks/useQuery'

import { GET_TOTAL_COUNT } from 'apollo/queries/dashboard'
import { BILLING_PANEL_UPDATED } from 'apollo/subscriptions'

import { GET_ALL_SERVICE_TYPES } from 'apollo/queries/serviceTypes'

import Loading from 'components/_common/Loading'
import AlertDialog from 'components/_common/AlertDialog'

import {
  Link,
  Alert,
  Stack,
  Badge,
  Button,
  AlertIcon
} from '@chakra-ui/react'

import { navigate } from 'hooks/useRoutes'

export default function ServiceTypesFilter () {
  const { data: { getTotalCount } = {}, subscribeToMore, refetch } = useQuery(GET_TOTAL_COUNT, { fetchPolicy: 'cache-only' })
  useEffect(() => {
    const unsubscribe = subscribeToMore({ document: BILLING_PANEL_UPDATED, updateQuery: () => { refetch() } })
    return () => unsubscribe()
  }, [])

  const { data, loading, error } = useQuery(GET_ALL_SERVICE_TYPES, {
    variables: { orderBy: { order: 'asc' } }
  })

  const serviceTypes = data?.response?.allData || []

  if (loading) return <Loading />

  if (error) return <AlertDialog status='error'>{error.message}</AlertDialog>

  if (serviceTypes.length === 0) {
    return (
      <Alert status='warning' w='180px' borderRadius='lg'>
        <AlertIcon />
        No Service Types
      </Alert>
    )
  }

  return (
    <Stack
      w='200px'
      overflowY='auto'
      px='2'
      py='4'
      boxShadow='3px 0 3px -2px #D6BCFA'
    >
      {serviceTypes.map(({ id, name, color }) => (
        <Stack key={id} direction='row'>
          <Badge bg={color} width='12' borderRadius='lg' fontSize='md' textAlign='center' />
          <Button
            isFullWidth
            as={Link}
            size='xs'
            href={`/topUps/${name}`}
            onClick={event => navigate(event, `/topUps/${name}`)}
            _hover={{ textDecoration: 'none' }}
            variant={decodeURIComponent(window.location.pathname) === `/topUps/${name}` ? 'solid' : 'outline'}
          >
            {name}
          </Button>
          <Badge
            mb='1'
            borderRadius='md'
            height='fit-content'
            colorScheme='red'
          >
            {getTotalCount?.service?.todaysTopupServices[name]}
          </Badge>
        </Stack>
      ))}
    </Stack>
  )
}
