import EditableInput from 'components/_inputs/EditableInput'

import { string } from 'yup'

import useMutation from 'hooks/useMutation'
import { UPDATE_CUSTOMER_POSTAL_CODE } from 'apollo/mutations/customers'

export default function EditCustomerPostalCode ({ customer, inline = false, ...props }) {
  const [updatePostalCode, { error, loading, reset }] = useMutation(UPDATE_CUSTOMER_POSTAL_CODE, {
    notification: 'Successfully updated the full name'
  })

  const handleSubmit = postalCode => {
    const data = { id: customer.id, postalCode: postalCode ? postalCode.toUpperCase() : null }
    return updatePostalCode({ variables: { data } })
  }

  return (
    <EditableInput
      inline={inline}
      name='postalCode'
      value={customer.postalCode || ''}
      label='Postal Code'
      onSubmit={handleSubmit}
      validation={string().matches(/^(\d{5}(-\d{4})?|[A-CEGHJ-NPRSTVXY]\d[A-CEGHJ-NPRSTV-Z][ ]\d[A-CEGHJ-NPRSTV-Z]\d)$/, { message: 'Invalid Postal Code', excludeEmptyString: true })}
      isLoading={loading}
      error={error}
      reset={reset}
      {...props}
    />
  )
}
