import Checkbox from 'components/_inputs/Checkbox'

import { useReactiveVar } from '@apollo/client'
import { selectedShipmentsVar } from 'utils/cache'

export default function ShipmentCheckbox ({ shipment }) {
  const selectedVar = useReactiveVar(selectedShipmentsVar)

  const isChecked = Boolean(selectedVar[shipment.id])

  const toggleSelect = () => {
    if (isChecked) {
      const newSelection = { ...selectedVar }
      delete newSelection[shipment.id]
      selectedShipmentsVar(newSelection)
    } else {
      selectedShipmentsVar({
        ...selectedVar,
        [shipment.id]: shipment
      })
    }
  }

  return (
    <Checkbox isChecked={isChecked} toggleSelect={toggleSelect} />
  )
}
