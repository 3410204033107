import TableWrapper from 'components/table/TableWrapper'

import { GET_ALL_TRANSACTIONS } from 'apollo/queries/transactions'
import { transactionFilterWhereVar, transactionFilterOrderByVar } from 'utils/cache'

import fields from 'components/transactions/fields'

export default function Transactions () {
  return (
    <TableWrapper
      query={GET_ALL_TRANSACTIONS}
      fields={fields}
      rowKey='id'
      rowType='transaction'
      fuzzyKey='transactions'
      defaultOrderBy={[{ date: 'desc' }]}
      filterWhereVar={transactionFilterWhereVar}
      filterOrderByVar={transactionFilterOrderByVar}
    />
  )
}
