import Select from 'components/_select/CustomSelect'

import useQuery from 'hooks/useQuery'
import { GET_ALL_SOURCES } from 'apollo/queries/sources'

import Loading from 'components/_common/Loading'
import AlertDialog from 'components/_common/AlertDialog'

export default function SourcesFilter ({ additionalVariables, setAdditionalVariables, isDisabled = false }) {
  const { data, loading, error } = useQuery(GET_ALL_SOURCES, {
    variables: { orderBy: { name: 'asc' } }
  })

  const handleSelectSource = ({ value: sourceID }) => {
    setAdditionalVariables({ ...additionalVariables, sourceID })
  }

  const sources = data?.response?.allData || []

  if (loading) return <Loading />

  if (error) return <AlertDialog status='error'>{error.message}</AlertDialog>

  const options = sources.map(source => ({ value: source.id, label: source.name }))
  options.unshift({ value: undefined, label: 'All Sources' })

  return (
    <Select
      value={options.find(({ value }) => value === additionalVariables.sourceID)}
      options={options}
      onChange={handleSelectSource}
      maxMenuHeight='300px'
      isDisabled={isDisabled}
    />
  )
}
