import TableWrapper from 'components/table/TableWrapper'
import LanguagesSelectionMenu from 'components/languages/select/LanguagesSelectionMenu'
import CreateLanguage from 'components/languages/CreateLanguage'

import { GET_ALL_LANGUAGES } from 'apollo/queries/languages'
import { selectedLanguagesVar, languageFilterWhereVar, languageFilterOrderByVar } from 'utils/cache'

import fields from 'components/languages/fields'

export default function Languages () {
  return (
    <TableWrapper
      query={GET_ALL_LANGUAGES}
      fields={fields}
      rowKey='id'
      rowType='language'
      fuzzyKey='languages'
      defaultOrderBy={[{ name: 'asc' }]}
      filterWhereVar={languageFilterWhereVar}
      filterOrderByVar={languageFilterOrderByVar}
      createComponent={CreateLanguage}
      selectionComponent={LanguagesSelectionMenu}
      selectionVar={selectedLanguagesVar}
    />
  )
}
