import { useState, useEffect } from 'react'

import { SEND_CUSTOMER_MESSAGE } from 'apollo/mutations/customers'
import useMutation from 'hooks/useMutation'

import {
  Input,
  Stack,
  Button,
  Textarea,
  Checkbox,
  FormLabel,
  FormControl,
  useDisclosure,
  FormErrorMessage
} from '@chakra-ui/react'

import { FiSend } from 'react-icons/fi'

import FormDialog from 'components/_common/FormDialog'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'

const schemaValidation = object().shape({
  subject: string(),
  message: string().required()
})

const types = ['EMAIL', 'SMS', 'WHATSAPP']

export default function SendCustomerMessage ({ customer }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [checkedTypes, setCheckedTypes] = useState([false, false, false])

  const handleClose = () => {
    onClose()
    resetMutation()
    resetForm()
  }

  const [sendCustomerMessage, { error, loading, reset: resetMutation }] = useMutation(SEND_CUSTOMER_MESSAGE, {
    notification: 'Successfully sent the message',
    isPromise: false,
    onCompleted: () => {
      handleClose()
    }
  })

  const { register, handleSubmit, formState: { errors }, reset: resetForm, setFocus } = useForm({
    resolver: yupResolver(schemaValidation)
  })

  useEffect(() => { isOpen && setTimeout(() => setFocus('message'), 1) }, [isOpen])

  const onSubmit = ({ subject, message }) => {
    const data = { id: customer.id, subject, message, type: checkedTypes.map((val, idx) => val && types[idx]).filter(Boolean) }
    sendCustomerMessage({ variables: { data } })
  }

  return (
    <>
      <Button size='md' leftIcon={<FiSend />} onClick={onOpen}>
        Send Message
      </Button>
      {isOpen && (
        <FormDialog
          title='Send a Message'
          formID='#sendCustomerMessage'
          submitLabel='Send'
          error={error}
          loading={loading}
          onClose={handleClose}
          isDisabled={!checkedTypes.some(Boolean)}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack spacing='8'>
            {checkedTypes[0] && (
              <FormControl isRequired isInvalid={errors?.subject}>
                <FormLabel>Subject</FormLabel>
                <Input {...register('subject')} />
                <FormErrorMessage>{errors?.subject?.subject}</FormErrorMessage>
              </FormControl>
            )}
            <FormControl isRequired isInvalid={errors?.message}>
              <FormLabel>Message</FormLabel>
              <Textarea {...register('message')} rows={6} />
              <FormErrorMessage>{errors?.message?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Notification Type</FormLabel>
              <Stack direction='row' justifyContent='space-between' alignItems='center' mt='4' px='2'>
                <Checkbox
                  isChecked={checkedTypes[0]}
                  onChange={(e) => setCheckedTypes([e.target.checked, checkedTypes[1], checkedTypes[2]])}
                >
                  EMAIL
                </Checkbox>
                <Checkbox
                  isChecked={checkedTypes[1]}
                  onChange={(e) => setCheckedTypes([checkedTypes[0], e.target.checked, checkedTypes[2]])}
                >
                  SMS
                </Checkbox>
                <Checkbox
                  isChecked={checkedTypes[2]}
                  onChange={(e) => setCheckedTypes([checkedTypes[0], checkedTypes[1], e.target.checked])}
                >
                  WHATSAPP
                </Checkbox>
              </Stack>
            </FormControl>
          </Stack>
        </FormDialog>
      )}
    </>
  )
}
