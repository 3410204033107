import TableWrapper from 'components/table/TableWrapper'
import StoresSelectionMenu from 'components/stores/select/StoresSelectionMenu'
import CreateStore from 'components/stores/CreateStore'

import { GET_ALL_STORES } from 'apollo/queries/stores'
import { selectedStoresVar, storeFilterWhereVar, storeFilterOrderByVar } from 'utils/cache'

import fields from 'components/stores/fields'

export default function Stores () {
  return (
    <TableWrapper
      query={GET_ALL_STORES}
      fields={fields}
      rowKey='id'
      rowType='store'
      fuzzyKey='stores'
      defaultOrderBy={[{ name: 'asc' }]}
      filterWhereVar={storeFilterWhereVar}
      filterOrderByVar={storeFilterOrderByVar}
      createComponent={CreateStore}
      selectionComponent={StoresSelectionMenu}
      selectionVar={selectedStoresVar}
    />
  )
}
