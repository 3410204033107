import TableWrapper from 'components/table/TableWrapper'

import { GET_ALL_ACTIVITIES } from 'apollo/queries/activities'
import { activityFilterWhereVar, activityFilterOrderByVar } from 'utils/cache'

import fields from 'components/activities/fields'

export default function AllActivities () {
  return (
    <TableWrapper
      query={GET_ALL_ACTIVITIES}
      fields={fields}
      rowKey='id'
      rowType='activity'
      fuzzyKey='activities'
      defaultOrderBy={[{ date: 'desc' }]}
      filterWhereVar={activityFilterWhereVar}
      filterOrderByVar={activityFilterOrderByVar}
    />
  )
}
