// Reactive variables are a useful mechanism for storing local state outside of the Apollo Client cache.
// Modifying a reactive variable automatically triggers an update of every active query that depends on that variable.

import { makeVar } from '@apollo/client'

// selection
export const selectedServicesVar = makeVar({})
export const selectedCustomersVar = makeVar({})
export const selectedUsersVar = makeVar({})
export const selectedProductTypesVar = makeVar({})
export const selectedServiceTypesVar = makeVar({})
export const selectedShipmentsVar = makeVar({})
export const selectedStoresVar = makeVar({})
export const selectedTicketsVar = makeVar({})
export const selectedSourcesVar = makeVar({})
export const selectedLanguagesVar = makeVar({})

// fuzzy search
export const fuzzySearchVar = makeVar('')

// filters
const stringFilters = ['contains', 'equals', 'not', 'startsWith', 'endsWith']
const selectFilters = ['in']
const dateFilters = ['lt', 'lte', 'equals', 'not', 'gte', 'gt']
const intFilters = ['lt', 'lte', 'equals', 'not', 'gte', 'gt']

const initialWhereFilter = { value: undefined, isActive: false }
const initialOrderByFilter = { value: 'asc', isActive: false }

const defaultDate = new Date().toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })
const defaultDateTime = new Date().toISOString()

// common dateWhereFilter
const dateWhereFilter = {
  ...initialWhereFilter,
  filterOptions: dateFilters,
  filter: dateFilters[0],
  type: 'date',
  value: defaultDate
}

// common datetimeWhereFilter
const datetimeWhereFilter = {
  ...initialWhereFilter,
  filterOptions: dateFilters,
  filter: dateFilters[0],
  type: 'datetime',
  value: defaultDateTime
}

// common intWhereFilter
const intWhereFilter = {
  ...initialWhereFilter,
  filterOptions: intFilters,
  filter: intFilters[0],
  type: 'number',
  value: 0
}

// common stringWhereFilter
const stringWhereFilter = {
  ...initialWhereFilter,
  filterOptions: stringFilters,
  filter: stringFilters[0]
}

// common user or customer filters
const personWhereFilters = [
  {
    ...stringWhereFilter,
    field: 'fullName'
  },
  {
    ...stringWhereFilter,
    field: 'primaryPhoneNumber'
  },
  {
    ...stringWhereFilter,
    field: 'email'
  }
]

// stores
const storeFilterWhere = [
  {
    ...stringWhereFilter,
    field: 'name'
  },
  {
    ...stringWhereFilter,
    field: 'location'
  },
  {
    ...datetimeWhereFilter,
    field: 'updatedAt'
  },
  {
    ...datetimeWhereFilter,
    field: 'createdAt'
  }
]

const storeFilterOrderBy = [
  { field: 'name', ...initialOrderByFilter },
  { field: 'location', ...initialOrderByFilter },
  { field: 'updatedAt', ...initialOrderByFilter },
  { field: 'createdAt', ...initialOrderByFilter }
]

// phone numbers
const phoneNumberFilterWhere = [
  {
    ...initialWhereFilter,
    field: 'type',
    filterOptions: selectFilters,
    filter: selectFilters[0],
    valueOptions: [
      { label: 'MOBILE', value: 'MOBILE' },
      { label: 'LANDLINE', value: 'LANDLINE' },
      { label: 'BUSINESS', value: 'BUSINESS' }
    ],
    value: []
  },
  {
    ...stringWhereFilter,
    field: 'value'
  }
]

const phoneNumberFilterOrderBy = [
  { field: 'type', ...initialOrderByFilter },
  { field: 'value', ...initialOrderByFilter }
]

// login approvals
const loginApprovalFilterWhere = [
  {
    ...datetimeWhereFilter,
    field: 'requestedAt'
  },
  {
    ...initialWhereFilter,
    field: 'status',
    filterOptions: selectFilters,
    filter: selectFilters[0],
    valueOptions: [
      { label: 'ACTIVE', value: 'ACTIVE' },
      { label: 'APPROVED', value: 'APPROVED' },
      { label: 'DENIED', value: 'DENIED' }
    ]
  },
  {
    ...datetimeWhereFilter,
    field: 'expiresAt'
  },
  {
    field: 'requestedUser',
    single: true,
    relations: personWhereFilters
  },
  {
    field: 'processedAdmin',
    single: true,
    relations: personWhereFilters
  },
  {
    ...datetimeWhereFilter,
    field: 'approvedAt'
  }
]

const loginApprovalFilterOrderBy = [
  { field: 'requestedAt', ...initialOrderByFilter },
  { field: 'status', ...initialOrderByFilter },
  { field: 'expiresAt', ...initialOrderByFilter },
  { field: 'approvedAt', ...initialOrderByFilter }
]

// notes
const noteFilterWhere = [
  {
    ...stringWhereFilter,
    field: 'details'
  },
  {
    field: 'markAsDone',
    ...initialWhereFilter,
    filterOptions: selectFilters,
    filter: selectFilters[0],
    valueOptions: [
      { label: 'TRUE', value: true },
      { label: 'FALSE', value: false }
    ],
    value: [],
    isSingle: true
  }
]

// user filters
const userFilterWhere = [
  ...personWhereFilters,
  {
    field: 'role',
    ...initialWhereFilter,
    filterOptions: selectFilters,
    filter: selectFilters[0],
    valueOptions: [
      { label: 'STAFF', value: 'STAFF' },
      { label: 'ADMIN', value: 'ADMIN' }
    ],
    value: []
  },
  {
    field: 'isLocked',
    ...initialWhereFilter,
    filterOptions: selectFilters,
    filter: selectFilters[0],
    valueOptions: [
      { label: 'TRUE', value: true },
      { label: 'FALSE', value: false }
    ],
    value: [],
    isSingle: true
  },
  {
    ...datetimeWhereFilter,
    field: 'createdAt'
  },
  {
    ...datetimeWhereFilter,
    field: 'updatedAt'
  },
  {
    field: 'phoneNumbers',
    relations: phoneNumberFilterWhere
  },
  {
    field: 'requestedLoginApprovals',
    relations: loginApprovalFilterWhere
  }
]

const userFilterOrderBy = [
  { field: 'email', ...initialOrderByFilter },
  { field: 'fullName', ...initialOrderByFilter },
  { field: 'role', ...initialOrderByFilter },
  { field: 'isLocked', ...initialOrderByFilter },
  { field: 'createdAt', ...initialOrderByFilter },
  { field: 'updatedAt', ...initialOrderByFilter }
]

// service types
const serviceTypeFilterWhere = [
  {
    ...stringWhereFilter,
    field: 'name'
  },
  {
    ...stringWhereFilter,
    field: 'details'
  },
  {
    ...intWhereFilter,
    field: 'topupDueDays'
  },
  {
    ...intWhereFilter,
    field: 'reminderDays'
  },
  {
    ...intWhereFilter,
    field: 'order'
  },
  {
    ...datetimeWhereFilter,
    field: 'updatedAt'
  },
  {
    ...datetimeWhereFilter,
    field: 'createdAt'
  }
]

const serviceTypeFilterOrderBy = [
  { field: 'name', ...initialOrderByFilter },
  { field: 'details', ...initialOrderByFilter },
  { field: 'topupDueDays', ...initialOrderByFilter },
  { field: 'reminderDays', ...initialOrderByFilter },
  { field: 'order', ...initialOrderByFilter },
  { field: 'updatedAt', ...initialOrderByFilter },
  { field: 'createdAt', ...initialOrderByFilter }
]

// transactions
const transactionFilterWhere = [
  {
    ...datetimeWhereFilter,
    field: 'date'
  },
  {
    ...stringWhereFilter,
    field: 'transactionNumber'
  },
  {
    ...initialWhereFilter,
    field: 'transactionType',
    filterOptions: selectFilters,
    filter: selectFilters[0],
    valueOptions: [
      { label: 'SALES', value: 'SALES' },
      { label: 'INVOICE', value: 'INVOICE' },
      { label: 'REVERSE', value: 'REVERSE' },
      { label: 'REFERRAL', value: 'REFERRAL' }
    ],
    value: []
  },
  {
    ...intWhereFilter,
    field: 'credits'
  },
  {
    field: 'serviceType',
    single: true,
    relations: serviceTypeFilterWhere
  },
  {
    field: 'notes',
    relations: noteFilterWhere
  },
  {
    field: 'customer',
    single: true,
    relations: personWhereFilters
  },
  {
    field: 'createdByUser',
    single: true,
    relations: personWhereFilters
  }
]

const transactionFilterOrderBy = [
  { field: 'date', ...initialOrderByFilter },
  { field: 'transactionNumber', ...initialOrderByFilter },
  { field: 'transactionType', ...initialOrderByFilter },
  { field: 'credits', ...initialOrderByFilter },
  { field: 'createdAt', ...initialOrderByFilter },
  { field: 'updatedAt', ...initialOrderByFilter }
]

// sources
const sourceFilterWhere = [
  {
    ...stringWhereFilter,
    field: 'name'
  },
  {
    ...stringWhereFilter,
    field: 'details'
  },
  {
    ...datetimeWhereFilter,
    field: 'updatedAt'
  },
  {
    ...datetimeWhereFilter,
    field: 'createdAt'
  }
]

const sourceFilterOrderBy = [
  { field: 'name', ...initialOrderByFilter },
  { field: 'details', ...initialOrderByFilter },
  { field: 'updatedAt', ...initialOrderByFilter },
  { field: 'createdAt', ...initialOrderByFilter }
]

// languages
const languageFilterWhere = [
  {
    ...stringWhereFilter,
    field: 'name'
  },
  {
    ...stringWhereFilter,
    field: 'details'
  },
  {
    ...datetimeWhereFilter,
    field: 'updatedAt'
  },
  {
    ...datetimeWhereFilter,
    field: 'createdAt'
  }
]

const languageFilterOrderBy = [
  { field: 'name', ...initialOrderByFilter },
  { field: 'details', ...initialOrderByFilter },
  { field: 'updatedAt', ...initialOrderByFilter },
  { field: 'createdAt', ...initialOrderByFilter }
]

// services
const serviceFilterWhere = [
  {
    ...stringWhereFilter,
    field: 'macID'
  },
  {
    ...datetimeWhereFilter,
    field: 'createdDate'
  },
  {
    ...stringWhereFilter,
    field: 'accountNumber'
  },
  {
    ...intWhereFilter,
    field: 'credits'
  },
  {
    ...datetimeWhereFilter,
    field: 'expiryDate'
  },
  {
    ...datetimeWhereFilter,
    field: 'onHoldDate'
  },
  {
    ...initialWhereFilter,
    field: 'inactive',
    filterOptions: selectFilters,
    filter: selectFilters[0],
    valueOptions: [
      { label: 'TRUE', value: true },
      { label: 'FALSE', value: false }
    ],
    value: [],
    isSingle: true
  },
  {
    ...initialWhereFilter,
    field: 'isMonthly',
    filterOptions: selectFilters,
    filter: selectFilters[0],
    valueOptions: [
      { label: 'TRUE', value: true },
      { label: 'FALSE', value: false }
    ],
    value: [],
    isSingle: true
  },
  {
    ...initialWhereFilter,
    field: 'putOnHold',
    filterOptions: selectFilters,
    filter: selectFilters[0],
    valueOptions: [
      { label: 'TRUE', value: true },
      { label: 'FALSE', value: false }
    ],
    value: [],
    isSingle: true
  },
  {
    ...datetimeWhereFilter,
    field: 'updatedAt'
  },
  {
    ...datetimeWhereFilter,
    field: 'createdAt'
  },
  {
    field: 'notes',
    relations: noteFilterWhere
  },
  {
    field: 'customer',
    single: true,
    relations: personWhereFilters
  },
  {
    field: 'serviceType',
    single: true,
    relations: serviceTypeFilterWhere
  }
]

// customer filters
const customerFilterWhere = [
  ...personWhereFilters,
  {
    ...stringWhereFilter,
    field: 'postalCode'
  },
  {
    ...intWhereFilter,
    field: 'pin'
  },
  {
    ...datetimeWhereFilter,
    field: 'createdAt'
  },
  {
    ...datetimeWhereFilter,
    field: 'updatedAt'
  },
  {
    field: 'services',
    relations: serviceFilterWhere
  },
  {
    field: 'phoneNumbers',
    relations: phoneNumberFilterWhere
  },
  {
    field: 'store',
    single: true,
    relations: storeFilterWhere
  },
  {
    field: 'source',
    single: true,
    relations: sourceFilterWhere
  },
  {
    field: 'language',
    single: true,
    relations: languageFilterWhere
  },
  {
    field: 'transactions',
    relations: transactionFilterWhere
  }
]

const customerFilterOrderBy = [
  { field: 'joinedDate', ...initialOrderByFilter },
  { field: 'email', ...initialOrderByFilter },
  { field: 'fullName', ...initialOrderByFilter },
  { field: 'pin', ...initialOrderByFilter },
  { field: 'createdAt', ...initialOrderByFilter },
  { field: 'updatedAt', ...initialOrderByFilter }
]

// product types
const productTypeFilterWhere = [
  {
    ...stringWhereFilter,
    field: 'name'
  },
  {
    ...stringWhereFilter,
    field: 'details'
  },
  {
    ...datetimeWhereFilter,
    field: 'updatedAt'
  },
  {
    ...datetimeWhereFilter,
    field: 'createdAt'
  }
]

const productTypeFilterOrderBy = [
  { field: 'name', ...initialOrderByFilter },
  { field: 'details', ...initialOrderByFilter },
  { field: 'updatedAt', ...initialOrderByFilter },
  { field: 'createdAt', ...initialOrderByFilter }
]

// shipments
const shipmentFilterWhere = [
  {
    ...stringWhereFilter,
    field: 'name'
  },
  {
    ...stringWhereFilter,
    field: 'details'
  },
  {
    ...datetimeWhereFilter,
    field: 'updatedAt'
  },
  {
    ...datetimeWhereFilter,
    field: 'createdAt'
  }
]

const shipmentFilterOrderBy = [
  { field: 'name', ...initialOrderByFilter },
  { field: 'details', ...initialOrderByFilter },
  { field: 'updatedAt', ...initialOrderByFilter },
  { field: 'createdAt', ...initialOrderByFilter }
]

const serviceFilterOrderBy = [
  { field: 'createdDate', ...initialOrderByFilter },
  { field: 'macID', ...initialOrderByFilter },
  { field: 'accountNumber', ...initialOrderByFilter },
  { field: 'credits', ...initialOrderByFilter },
  { field: 'expiryDate', ...initialOrderByFilter },
  { field: 'onHoldDate', ...initialOrderByFilter },
  { field: 'updatedAt', ...initialOrderByFilter },
  { field: 'createdAt', ...initialOrderByFilter }
]

// support tickets
const ticketFilterWhere = [
  {
    ...datetimeWhereFilter,
    field: 'date'
  },
  {
    ...initialWhereFilter,
    field: 'status',
    filterOptions: selectFilters,
    filter: selectFilters[0],
    valueOptions: [
      { label: 'OPEN', value: 'OPEN' },
      { label: 'IN PROGRESS', value: 'IN_PROGRESS' },
      { label: 'RESOLVED', value: 'RESOLVED' }
    ],
    value: []
  },
  {
    ...datetimeWhereFilter,
    field: 'updatedAt'
  },
  {
    ...datetimeWhereFilter,
    field: 'createdAt'
  },
  {
    field: 'notes',
    relations: noteFilterWhere
  },
  {
    field: 'customer',
    single: true,
    relations: personWhereFilters
  },
  {
    field: 'createdByUser',
    single: true,
    relations: personWhereFilters
  },
  {
    field: 'updatedByUser',
    single: true,
    relations: personWhereFilters
  }
]

const ticketFilterOrderBy = [
  { field: 'date', ...initialOrderByFilter },
  { field: 'status', ...initialOrderByFilter },
  { field: 'details', ...initialOrderByFilter },
  { field: 'updatedAt', ...initialOrderByFilter },
  { field: 'createdAt', ...initialOrderByFilter }
]

// today's top-up services
const serviceTodayFilterWhere = [
  {
    ...stringWhereFilter,
    field: 'macID'
  },
  {
    ...stringWhereFilter,
    field: 'accountNumber'
  },
  {
    ...intWhereFilter,
    field: 'credits'
  },
  {
    ...dateWhereFilter,
    field: 'createdDate'
  },
  {
    ...dateWhereFilter,
    field: 'expiryDate'
  },
  {
    field: 'notes',
    relations: noteFilterWhere
  },
  {
    field: 'customer',
    single: true,
    relations: personWhereFilters
  }
]

const serviceTodayFilterOrderBy = [
  { field: 'macID', ...initialOrderByFilter },
  { field: 'credits', ...initialOrderByFilter },
  { field: 'createdDate', ...initialOrderByFilter },
  { field: 'expiryDate', ...initialOrderByFilter }
]

// activities
const activityFilterWhere = [
  {
    ...datetimeWhereFilter,
    field: 'date'
  },
  {
    ...stringWhereFilter,
    field: 'details'
  },
  {
    ...stringWhereFilter,
    field: 'type',
    transform: value => value.replace(/ /g, '_')
  },
  {
    field: 'createdByUser',
    single: true,
    relations: personWhereFilters
  }
]

const activityFilterOrderBy = [
  { field: 'date', ...initialOrderByFilter }
]

export const noteFilterWhereVar = makeVar(noteFilterWhere)

export const userFilterWhereVar = makeVar(userFilterWhere)
export const userFilterOrderByVar = makeVar(userFilterOrderBy)

export const customerFilterWhereVar = makeVar(customerFilterWhere)
export const customerFilterOrderByVar = makeVar(customerFilterOrderBy)

export const transactionFilterWhereVar = makeVar(transactionFilterWhere)
export const transactionFilterOrderByVar = makeVar(transactionFilterOrderBy)

export const serviceTypeFilterWhereVar = makeVar(serviceTypeFilterWhere)
export const serviceTypeFilterOrderByVar = makeVar(serviceTypeFilterOrderBy)

export const productTypeFilterWhereVar = makeVar(productTypeFilterWhere)
export const productTypeFilterOrderByVar = makeVar(productTypeFilterOrderBy)

export const serviceFilterWhereVar = makeVar(serviceFilterWhere)
export const serviceFilterOrderByVar = makeVar(serviceFilterOrderBy)

export const shipmentFilterWhereVar = makeVar(shipmentFilterWhere)
export const shipmentFilterOrderByVar = makeVar(shipmentFilterOrderBy)

export const sourceFilterWhereVar = makeVar(sourceFilterWhere)
export const sourceFilterOrderByVar = makeVar(sourceFilterOrderBy)

export const languageFilterWhereVar = makeVar(languageFilterWhere)
export const languageFilterOrderByVar = makeVar(languageFilterOrderBy)

export const storeFilterWhereVar = makeVar(storeFilterWhere)
export const storeFilterOrderByVar = makeVar(storeFilterOrderBy)

export const loginApprovalFilterWhereVar = makeVar(loginApprovalFilterWhere)
export const loginApprovalFilterOrderByVar = makeVar(loginApprovalFilterOrderBy)

export const ticketFilterWhereVar = makeVar(ticketFilterWhere)
export const ticketFilterOrderByVar = makeVar(ticketFilterOrderBy)

export const serviceTodayFilterWhereVar = makeVar(serviceTodayFilterWhere)
export const serviceTodayFilterOrderByVar = makeVar(serviceTodayFilterOrderBy)

export const phoneNumberFilterWhereVar = makeVar(phoneNumberFilterWhere)
export const phoneNumberFilterOrderByVar = makeVar(phoneNumberFilterOrderBy)

export const activityFilterWhereVar = makeVar(activityFilterWhere)
export const activityFilterOrderByVar = makeVar(activityFilterOrderBy)

export const resetCache = () => {
  // selection
  selectedServicesVar({})
  selectedCustomersVar({})
  selectedUsersVar({})
  selectedProductTypesVar({})
  selectedServiceTypesVar({})
  selectedShipmentsVar({})
  selectedStoresVar({})
  selectedTicketsVar({})

  // fuzzy search
  fuzzySearchVar('')

  // filters
  noteFilterWhereVar(noteFilterWhere)

  userFilterWhereVar(userFilterWhere)
  userFilterOrderByVar(userFilterOrderBy)

  customerFilterWhereVar(customerFilterWhere)
  customerFilterOrderByVar(customerFilterOrderBy)

  transactionFilterWhereVar(transactionFilterWhere)
  transactionFilterOrderByVar(transactionFilterOrderBy)

  serviceTypeFilterWhereVar(serviceTypeFilterWhere)
  serviceTypeFilterOrderByVar(serviceTypeFilterOrderBy)

  productTypeFilterWhereVar(productTypeFilterWhere)
  productTypeFilterOrderByVar(productTypeFilterOrderBy)

  serviceFilterWhereVar(serviceFilterWhere)
  serviceFilterOrderByVar(serviceFilterOrderBy)

  shipmentFilterWhereVar(shipmentFilterWhere)
  shipmentFilterOrderByVar(shipmentFilterOrderBy)

  sourceFilterWhereVar(sourceFilterWhere)
  sourceFilterOrderByVar(sourceFilterOrderBy)

  languageFilterWhereVar(languageFilterWhere)
  languageFilterOrderByVar(languageFilterOrderBy)

  storeFilterWhereVar(storeFilterWhere)
  storeFilterOrderByVar(storeFilterOrderBy)

  loginApprovalFilterWhereVar(loginApprovalFilterWhere)
  loginApprovalFilterOrderByVar(loginApprovalFilterOrderBy)

  ticketFilterWhereVar(ticketFilterWhere)
  ticketFilterOrderByVar(ticketFilterOrderBy)

  serviceTodayFilterWhereVar(serviceTodayFilterWhere)
  serviceTodayFilterOrderByVar(serviceTodayFilterOrderBy)

  phoneNumberFilterWhereVar(phoneNumberFilterWhere)
  phoneNumberFilterOrderByVar(phoneNumberFilterOrderBy)

  activityFilterWhereVar(activityFilterWhere)
  activityFilterOrderByVar(activityFilterOrderBy)
}
