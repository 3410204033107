import { Text } from '@chakra-ui/react'

export default function DateTimeRenderer ({ value, highlight = false, type = 'date', ...props }) {
  const highlightProps = highlight ? { bg: 'red.600', p: '1', color: 'white', fontWeight: 'bold', borderRadius: 'md' } : {}

  return (
    <Text fontSize='xs' {...props} {...highlightProps}>
      {value ? (type === 'time' ? new Date(value).toLocaleString('en-CA') : value) : '-'}
    </Text>
  )
}
