import CustomerSelectCheckbox from 'components/customers/select/CustomerSelectCheckbox'
import OpenCustomer from 'components/customers/view/OpenCustomer'
import EditCustomerJoinedDate from 'components/customers/edit/EditCustomerJoinedDate'
import EditCustomerFullName from 'components/customers/edit/EditCustomerFullName'
import EditCustomerEmail from 'components/customers/edit/EditCustomerEmail'
import ManageCustomerPhoneNumbers from 'components/customers/edit/ManageCustomerPhoneNumbers'
import ViewCustomerServices from 'components/customers/view/ViewCustomerServices'
import ViewCustomerTransactions from 'components/customers/view/ViewCustomerTransactions'
import ViewCustomerTickets from 'components/customers/view/ViewCustomerTickets'
import ViewCustomerNotes from 'components/customers/notes/ViewCustomerNotes'

const fields = [
  { key: 'view', width: '40px', renderTD: (props) => <OpenCustomer {...props} self /> },
  { key: 'select', width: '40px', renderTD: CustomerSelectCheckbox },
  { key: 'joinedDate', label: 'Joined At', width: '150px', renderTD: EditCustomerJoinedDate },
  { key: 'fullName', label: 'Full Name', width: '220px', renderTD: EditCustomerFullName },
  { key: 'email', label: 'Email', width: '200px', renderTD: EditCustomerEmail },
  { key: 'primaryPhoneNumber', label: 'Phone Number', width: '180px', renderTD: ManageCustomerPhoneNumbers },
  { key: 'services', label: 'Services', justifyContent: 'center', renderTD: ViewCustomerServices },
  { key: 'transactions', label: 'Transactions', justifyContent: 'center', renderTD: ViewCustomerTransactions },
  { key: 'tickets', label: 'Tickets', justifyContent: 'center', renderTD: ViewCustomerTickets },
  { key: 'notes', label: 'Notes', renderTD: ViewCustomerNotes }
]

export default fields
