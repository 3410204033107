import { useState } from 'react'

import { startCase } from 'lodash'

import useMutation from 'hooks/useMutation'
import { UPDATE_TICKET_STATUS } from 'apollo/mutations/tickets'
import { GET_TOTAL_COUNT } from 'apollo/queries/dashboard'

import {
  Icon,
  Text,
  Modal,
  Stack,
  Button,
  ModalBody,
  IconButton,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalCloseButton
} from '@chakra-ui/react'

import { BiPencil, BiSupport } from 'react-icons/bi'

import TicketStatusBadge from 'components/_badges/TicketStatusBadge'
import ErrorAlert from 'components/_common/ErrorAlert'

export default function EditTicketStatusTrigger ({ ticket, refetch }) {
  const [isEditVisible, setIsEditVisible] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      {isOpen && <EditTicketStatus ticket={ticket} onClose={onClose} refetch={refetch} />}
      <Stack
        direction='row'
        alignItems='center'
        width='100%'
        onMouseEnter={() => setIsEditVisible(true)}
        onMouseLeave={() => setIsEditVisible(false)}
      >
        <TicketStatusBadge status={ticket.status} />
        {isEditVisible && <IconButton size='xs' variant='outline' icon={<BiPencil fontSize='16px' />} onClick={onOpen} />}
      </Stack>
    </>
  )
}

function EditTicketStatus ({ onClose, ticket, refetch }) {
  const handleClose = (toRefetch = false) => {
    toRefetch && refetch()
    resetMutation()
    onClose()
  }

  const [updateStatus, { error, loading, reset: resetMutation }] = useMutation(UPDATE_TICKET_STATUS, {
    notification: 'Successfully update the status',
    isPromise: false,
    onCompleted: () => handleClose(true),
    refetchQueries: [{ query: GET_TOTAL_COUNT }]
  })

  const onUpdateStatus = (status) => {
    const data = { id: ticket.id, status }
    updateStatus({ variables: { data } })
  }

  return (
    <Modal isOpen onClose={handleClose} scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent pb='6'>
        <ModalHeader>Status</ModalHeader>
        <ModalCloseButton isDisabled={loading} />
        <ModalBody>
          {error && <ErrorAlert>{error.message}</ErrorAlert>}
          <Stack justifyContent='space-around' direction='row' alignItems='center'>
            {['OPEN', 'IN_PROGRESS', 'RESOLVED'].map(status => (
              <Button
                key={status}
                variant={ticket.status === status ? 'solid' : 'outline'}
                colorScheme={status === 'RESOLVED' ? 'green' : status === 'IN_PROGRESS' ? 'orange' : 'red'}
                _hover={{ bg: '' }}
                boxSize='120px'
                onClick={() => onUpdateStatus(status)}
                borderRadius='xl'
                isDisabled={ticket.status === status}
              >
                <Stack alignItems='center'>
                  <Icon as={BiSupport} boxSize='10' />
                  <Text fontWeight='semibold' fontSize='sm'>{startCase(status)}</Text>
                </Stack>
              </Button>
            ))}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
