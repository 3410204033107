import { useState } from 'react'

import {
  Text,
  Stack,
  IconButton,
  useDisclosure
} from '@chakra-ui/react'

import { AiOutlineEye } from 'react-icons/ai'

import ViewNotes from 'components/notes/ViewNotes'
import AddTicketNote from 'components/tickets/notes/AddTicketNote'
import EditTicketNoteDetails from 'components/tickets/notes/EditTicketNoteDetails'
import EditTicketNoteMarkAsDone from 'components/tickets/notes/EditTicketNoteMarkAsDone'

export default function ViewTicketNotesTrigger ({ ticket }) {
  const [isEditVisible, setIsEditVisible] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()

  // get the first not markAsDone note
  const latestNote = ticket.notes.find(note => !note.markAsDone)

  return (
    <>
      {isOpen && (
        <ViewNotes
          label='Notes'
          notes={ticket.notes}
          onClose={onClose}
          createComponent={<AddTicketNote ticket={ticket} />}
          editDetailsComponent={EditTicketNoteDetails}
          editMarkAsDoneComponent={EditTicketNoteMarkAsDone}
        />
      )}
      <Stack
        direction='row'
        alignItems='center'
        width='100%'
        onMouseEnter={() => setIsEditVisible(true)}
        onMouseLeave={() => setIsEditVisible(false)}
      >
        <Text fontSize='sm' overflowX='hidden'>
          {latestNote?.details || '-'}
        </Text>
        {isEditVisible && <IconButton size='xs' variant='outline' icon={<AiOutlineEye fontSize='16px' />} onClick={onOpen} isDisabled={!ticket} />}
      </Stack>
    </>
  )
}
