import TicketSelectCheckbox from 'components/tickets/select/TicketSelectCheckbox'
import DateTimeRenderer from 'components/_common/DateTimeRenderer'
import OpenCustomer from 'components/customers/view/OpenCustomer'
import ViewTicketNotes from 'components/tickets/notes/ViewTicketNotes'
import EditTicketStatus from 'components/tickets/edit/EditTicketStatus'
import OpenUser from 'components/users/view/OpenUser'
import EditTicketExpiry from 'components/tickets/edit/EditTicketExpiry'
import EditTicketAssignedTo from 'components/tickets/edit/EditTicketAssignedTo'

const fields = [
  { key: 'select', width: '40px', renderTD: TicketSelectCheckbox },
  { key: 'createdAt', label: 'Created At', width: '220px', renderTD: ({ ticket: { createdAt } }) => <DateTimeRenderer value={createdAt} type='time' fontSize='sm' /> },
  { key: 'assignedToUser', label: 'Assigned To', width: '160px', renderTD: ({ ticket }) => <OpenUser user={ticket.assignedToUser} edit={<EditTicketAssignedTo ticket={ticket} />} /> },
  { key: 'status', label: 'Status', width: '160px', forwardRefetch: true, renderTD: EditTicketStatus },
  { key: 'notes', label: 'Notes', width: '230px', renderTD: ViewTicketNotes },
  { key: 'expiresAt', label: 'Expires At', width: '250px', renderTD: EditTicketExpiry },
  { key: 'customer', label: 'Customer', width: '180px', renderTD: ({ ticket: { customer } }) => <OpenCustomer customer={customer} /> },
  { key: 'assignedByUser', label: 'Assigned By', width: '180px', renderTD: ({ ticket: { assignedByUser } }) => <OpenUser user={assignedByUser} /> },
  { key: 'createdByUser', label: 'Created By', renderTD: ({ ticket: { createdByUser } }) => <OpenUser user={createdByUser} /> }
]

export default fields
