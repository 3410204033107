import { Badge } from '@chakra-ui/react'

export default function AdminOnlyBadge (props) {
  return (
    <Badge
      fontSize='xs'
      textTransform='none'
      borderRadius='md'
      {...props}
    >
      Admin Only
    </Badge>
  )
}
