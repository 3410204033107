import { useEffect } from 'react'

import { CREATE_SERVICE, TOP_UP_SERVICE } from 'apollo/mutations/services'
import useMutation from 'hooks/useMutation'

import FormDialog from 'components/_common/FormDialog'
import DateTimePicker from 'components/_inputs/DateTimePicker'

import SelectServiceHandledByUser from 'components/services/select/SelectServiceHandledByUser'

import EditServiceServiceType from 'components/services/edit/EditServiceServiceType'
import EditServiceProductType from 'components/services/edit/EditServiceProductType'
import EditTransactionShipment from 'components/transactions/edit/EditTransactionShipment'

import { format, addMonths } from 'date-fns'

import {
  Stack,
  Input,
  Button,
  Select,
  Textarea,
  FormLabel,
  IconButton,
  FormControl,
  useDisclosure,
  FormErrorMessage
} from '@chakra-ui/react'

import { BiPlus } from 'react-icons/bi'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string, number } from 'yup'

import useAuthUser from 'hooks/useAuthUser'

import formatLocaleDate from 'utils/formatDate'

export default function CreateCustomerServiceTrigger ({ customer }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      {isOpen && <CreateCustomerService customer={customer} onClose={onClose} />}
      <IconButton
        size='xs'
        variant='outline'
        icon={<BiPlus fontSize='16px' />}
        onClick={onOpen}
      />
    </>
  )
}

const schemaValidation = object().shape({
  macID: string()
    .when('accountNumber', {
      is: (phone) => !phone || phone.length === 0,
      then: string().uppercase().matches(/^([0-9a-fA-F]{2}[:]){5}([0-9a-fA-F]{2})$/, { message: 'Invalid Mac Address', excludeEmptyString: true }).transform(value => value.toUpperCase()),
      otherwise: string()
    }),
  accountNumber: string().when('macID', {
    is: (macID) => !macID || macID.length === 0,
    then: string().required(),
    otherwise: string()
  }),
  handledByUserID: string().required('Processed User is required'),
  productTypeID: string().required('Product Type is required'),
  serviceTypeID: string().required('Service Type is required'),
  shipmentID: string(),
  credits: number().positive().required(),
  transactionNumber: string(),
  notes: string()
}, [['macID', 'accountNumber']])

function CreateCustomerService ({ customer, onClose }) {
  const authUser = useAuthUser()

  const handleClose = () => {
    onClose()
    resetCreateMutation()
    resetTopupMutation()
    resetForm()
  }

  const [createCustomerService, { error: createError, loading: createLoading, reset: resetCreateMutation }] = useMutation(CREATE_SERVICE, {
    notification: 'Successfully created the service'
  })

  const [topupService, { error: topupError, loading: topupLoading, reset: resetTopupMutation }] = useMutation(TOP_UP_SERVICE, {
    notification: 'Successfully topup the service',
    isPromise: false,
    onCompleted: () => {
      handleClose()
    }
  })

  const defaultExpiry = new Date()
  defaultExpiry.setDate(defaultExpiry.getDate() + 31)

  const { register, handleSubmit, formState: { errors }, reset: resetForm, setFocus, getValues, setValue, watch } = useForm({
    resolver: yupResolver(schemaValidation),
    defaultValues: {
      serviceIdentifier: 'MAC',
      handledByUserID: authUser.id,
      expiryDate: formatLocaleDate(defaultExpiry),
      createdDate: formatLocaleDate(new Date())
    }
  })

  watch(['serviceIdentifier', 'putOnHold', 'transactionType', 'expiryDate', 'createdDate'])

  useEffect(() => { setTimeout(() => { setFocus('macID'); setValue('putOnHold', false) }, 1) }, [])

  const onSubmit = ({ serviceIdentifier, createdDate, handledByUserID, macID, accountNumber, shipmentID, productTypeID, serviceTypeID, credits, transactionType, transactionNumber, putOnHold, notes, expiryDate }) => {
    const data = {
      customerID: customer.id,
      createdDate: createdDate + 'T04:00:00.000Z',
      handledByUserID,
      transactionDate: createdDate + 'T04:00:00.000Z',
      productTypeID,
      serviceTypeID,
      shipmentID,
      credits,
      transactionType,
      transactionNumber,
      putOnHold,
      notes
    }
    if (putOnHold) data.onHoldDate = format(addMonths(new Date(), 1), 'yyyy-MM-dd') + 'T04:00:00.000Z'
    if (serviceIdentifier === 'MAC') data.macID = macID
    if (serviceIdentifier === 'ACCOUNT') data.accountNumber = accountNumber
    createCustomerService({ variables: { data } })
      .then(response => {
        if (response) {
          // do initial topup if not on hold
          if (!putOnHold && credits > 0) {
            const data = {
              id: response.createService.id,
              expiryDate: expiryDate + 'T04:00:00.000Z'
            }
            topupService({ variables: { data } })
          } else {
            handleClose()
          }
        }
      })
      .catch(console.log)
  }

  return (
    <FormDialog
      title='Create Service'
      formID='#createCustomerService'
      error={createError || topupError}
      loading={createLoading || topupLoading}
      onClose={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      size='lg'
    >
      <Stack spacing='8'>
        <Stack direction='row' width='100%' alignItems='center'>
          <FormControl isRequired>
            <FormLabel>Created / Transaction Date</FormLabel>
            <DateTimePicker
              inline
              noSubmit
              value={getValues('createdDate')}
              onChange={createdDate => setValue('createdDate', createdDate)}
              error={errors?.createdDate}
            />
          </FormControl>
          <FormControl isRequired isInvalid={errors?.handledByUserID}>
            <FormLabel textAlign='right'>Processed By</FormLabel>
            <SelectServiceHandledByUser onSubmit={({ id }) => setValue('handledByUserID', id)} />
            <FormErrorMessage>{errors?.handledByUserID?.message}</FormErrorMessage>
          </FormControl>
        </Stack>

        <FormControl isRequired>
          <FormLabel>MAC or Account Number</FormLabel>
          <Stack direction='row' justifyContent='space-between' width='100%'>
            <Select {...register('serviceIdentifier')}>
              <option value='MAC'>MAC Address</option>
              <option value='ACCOUNT'>Account Number</option>
            </Select>
            {getValues('serviceIdentifier') === 'ACCOUNT'
              ? (
                <FormControl isRequired isInvalid={errors?.accountNumber}>
                  <Input {...register('accountNumber')} />
                  <FormErrorMessage>
                    {errors?.accountNumber?.message}
                  </FormErrorMessage>
                </FormControl>
                )
              : (
                <FormControl isRequired isInvalid={errors?.macID}>
                  <Input {...register('macID')} />
                  <FormErrorMessage>
                    {errors?.macID?.message}
                  </FormErrorMessage>
                </FormControl>
                )}
          </Stack>
        </FormControl>

        <Stack direction='row' width='100%'>
          <FormControl isRequired isInvalid={errors?.serviceTypeID}>
            <FormLabel>Service Type</FormLabel>
            <EditServiceServiceType inline onSubmit={({ id }) => setValue('serviceTypeID', id)} />
            <FormErrorMessage>{errors?.serviceTypeID?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={errors?.productTypeID}>
            <FormLabel textAlign='right'>Product Type</FormLabel>
            <EditServiceProductType inline onSubmit={({ id }) => setValue('productTypeID', id)} />
            <FormErrorMessage>{errors?.productTypeID?.message}</FormErrorMessage>
          </FormControl>
        </Stack>

        <Stack spacing='4'>
          <Stack direction='row' justifyContent='space-between' width='100%'>
            <FormControl>
              <FormLabel>Transaction Type</FormLabel>
              <Select {...register('transactionType')}>
                {['SALES', 'INVOICE', 'CREDIT', 'REVERSE', 'REFERRAL'].map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </Select>
            </FormControl>
            <FormControl isInvalid={errors?.transactionNumber} isRequired={getValues('transactionType') !== 'REVERSE'}>
              <FormLabel>Number</FormLabel>
              <Input type='number' {...register('transactionNumber')} />
              <FormErrorMessage>
                {errors?.transactionNumber?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors?.credits}>
              <FormLabel>Credits</FormLabel>
              <Input {...register('credits')} type='number' min='1' max='100' />
              <FormErrorMessage>{errors?.credits?.message}</FormErrorMessage>
            </FormControl>
          </Stack>

          <FormControl isInvalid={errors?.shipmentID}>
            <FormLabel>Shipment</FormLabel>
            <EditTransactionShipment inline onSubmit={({ id }) => setValue('shipmentID', id)} />
            <FormErrorMessage>{errors?.shipmentID?.message}</FormErrorMessage>
          </FormControl>
        </Stack>

        <Stack direction='row' justifyContent='space-between' width='100%' alignItems='flex-end'>
          <FormControl isRequired>
            <FormLabel>Expiry Date</FormLabel>
            <DateTimePicker
              inline
              noSubmit
              value={getValues('expiryDate')}
              onChange={expiryDate => setValue('expiryDate', expiryDate)}
              error={errors?.expiryDate}
              isDisabled={getValues('putOnHold')}
            />
          </FormControl>
          <FormControl>
            <Button
              isFullWidth
              variant={getValues('putOnHold') ? 'solid' : 'outline'}
              colorScheme='yellow'
              onClick={() => setValue('putOnHold', !getValues('putOnHold'))}
              size='sm'
            >
              {!getValues('putOnHold') ? 'Put' : 'Remove'} On Hold
            </Button>
          </FormControl>
        </Stack>

        <FormControl isInvalid={errors?.notes}>
          <FormLabel>Notes</FormLabel>
          <Textarea {...register('notes')} />
          <FormErrorMessage>
            {errors?.notes?.message}
          </FormErrorMessage>
        </FormControl>
      </Stack>
    </FormDialog>
  )
}
