import { useState } from 'react'

import {
  ADD_CUSTOMER_PHONE_NUMBER,
  UPDATE_CUSTOMER_PHONE_NUMBER,
  DELETE_CUSTOMER_PHONE_NUMBER,
  UPDATE_CUSTOMER_PRIMARY_PHONE_NUMBER
} from 'apollo/mutations/customers'

import {
  Box,
  Text,
  Modal,
  Stack,
  Divider,
  ModalBody,
  IconButton,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalCloseButton
} from '@chakra-ui/react'

import { BiPencil } from 'react-icons/bi'

import AddPhoneNumber from 'components/phoneNumbers/AddPhoneNumber'
import EditPhoneNumber from 'components/phoneNumbers/EditPhoneNumber'

export default function ManageCustomerPhoneNumbers ({ inline = false, ...props }) {
  return inline ? <ManageCustomerPhoneNumbersInline {...props} /> : <ManageCustomerPhoneNumbersTrigger {...props} />
}

function ManageCustomerPhoneNumbersTrigger ({ customer, disabled = false }) {
  const [isEditVisible, setIsEditVisible] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()

  if (disabled) {
    return (
      <Text>
        {customer.primaryPhoneNumber || '-'}
      </Text>
    )
  }
  return (
    <>
      {isOpen && <ManageCustomerPhoneNumbersDialog customer={customer} onClose={onClose} />}
      <Stack
        direction='row'
        alignItems='center'
        width='100%'
        onMouseEnter={() => setIsEditVisible(true)}
        onMouseLeave={() => setIsEditVisible(false)}
      >
        <Text>
          {customer.primaryPhoneNumber || '-'}
        </Text>
        {isEditVisible && <IconButton size='xs' variant='outline' icon={<BiPencil fontSize='16px' />} onClick={onOpen} />}
      </Stack>
    </>
  )
}

function ManageCustomerPhoneNumbersInline ({ customer }) {
  return (
    <Stack spacing='2'>
      <Box>
        <AddPhoneNumber id={customer.id} mutation={ADD_CUSTOMER_PHONE_NUMBER} />
        <Divider mt='2' />
      </Box>
      {customer.phoneNumbers.length === 0 && (
        <Text fontStyle='italic' fontSize='sm'>no phone numbers available</Text>
      )}
      {customer.phoneNumbers.map(phoneNumber => (
        <EditPhoneNumber
          key={phoneNumber.id}
          id={customer.id}
          phoneNumber={phoneNumber}
          primaryPhoneNumber={customer.primaryPhoneNumber}
          updatePhoneNumberMutation={UPDATE_CUSTOMER_PHONE_NUMBER}
          deletePhoneNumberMutation={DELETE_CUSTOMER_PHONE_NUMBER}
          setPrimaryNumberMutation={UPDATE_CUSTOMER_PRIMARY_PHONE_NUMBER}
        />
      ))}

    </Stack>
  )
}

function ManageCustomerPhoneNumbersDialog ({ customer, onClose }) {
  return (
    <Modal isOpen onClose={onClose} scrollBehavior='inside' size='2xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Phone Numbers</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing='4'>
            {customer.phoneNumbers.length === 0 && (
              <Text fontStyle='italic' fontSize='sm'>no phone numbers available</Text>
            )}
            {customer.phoneNumbers.map(phoneNumber => (
              <EditPhoneNumber
                key={phoneNumber.id}
                id={customer.id}
                phoneNumber={phoneNumber}
                primaryPhoneNumber={customer.primaryPhoneNumber}
                updatePhoneNumberMutation={UPDATE_CUSTOMER_PHONE_NUMBER}
                deletePhoneNumberMutation={DELETE_CUSTOMER_PHONE_NUMBER}
                setPrimaryNumberMutation={UPDATE_CUSTOMER_PRIMARY_PHONE_NUMBER}
              />
            ))}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Box width='100%'>
            <Divider my='4' />
            <AddPhoneNumber id={customer.id} mutation={ADD_CUSTOMER_PHONE_NUMBER} />
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
