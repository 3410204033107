import { gql } from '@apollo/client'

export const GET_ALL_TICKETS = gql`
  query GET_ALL_TICKETS ($where: TicketWhereInput $orderBy: [TicketOrderByInput!] $take: Int $skip: Int) {
    response: getAllTickets (where: $where orderBy: $orderBy take: $take skip: $skip) {
      allData: tickets {
        id
        createdAt
        customer {
          id
          fullName
          isDeleted
        }
        notes {
          id
          createdAt
          details
          markAsDone
          user {
            id
            fullName
            role
            isDeleted
          }
          updatedAt
        }
        status
        assignedToUser {
          id
          fullName
          role
          isDeleted
        }
        assignedByUser {
          id
          fullName
          role
          isDeleted
        }
        createdByUser {
          id
          fullName
          role
          isDeleted
        }
        expiresAt
      }
      metrics {
        allCount
        filteredCount
      }
    }
  }
`
