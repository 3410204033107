import { useState } from 'react'

import { SketchPicker } from 'react-color'

import useMutation from 'hooks/useMutation'
import { UPDATE_SERVICE_TYPE_COLOR } from 'apollo/mutations/serviceTypes'

import {
  Modal,
  Badge,
  Stack,
  ModalBody,
  IconButton,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalCloseButton
} from '@chakra-ui/react'

import { BiPencil } from 'react-icons/bi'

export default function EditServiceTypeColorTrigger ({ serviceType, justifyContent }) {
  const [isEditVisible, setIsEditVisible] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const marginOffset = isEditVisible && justifyContent === 'center' ? '8' : ''

  return (
    <>
      {isOpen && <EditServiceTypeColor serviceType={serviceType} onClose={onClose} />}
      <Stack
        direction='row'
        alignItems='center'
        width='100%'
        onMouseEnter={() => setIsEditVisible(true)}
        onMouseLeave={() => setIsEditVisible(false)}
        justifyContent={justifyContent}
      >
        <Badge bg={serviceType.color} width='16' borderRadius='lg' fontSize='sm' ml={marginOffset}>
          &nbsp;
        </Badge>
        {isEditVisible && <IconButton size='xs' variant='outline' icon={<BiPencil fontSize='16px' />} onClick={onOpen} />}
      </Stack>
    </>
  )
}

function EditServiceTypeColor ({ serviceType, onClose }) {
  const handleClose = () => {
    reset()
    onClose()
  }

  const [updateColor, { loading, reset }] = useMutation(UPDATE_SERVICE_TYPE_COLOR, {
    notification: 'Successfully updated the color',
    isPromise: false,
    onCompleted: handleClose
  })

  const handleSubmit = color => {
    const data = { id: serviceType.id, color: color.hex }
    updateColor({ variables: { data } })
  }

  return (
    <Modal isOpen onClose={handleClose} scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent pb='6'>
        <ModalHeader>Pick a color</ModalHeader>
        <ModalCloseButton isDisabled={loading} />
        <ModalBody mx='auto'>
          <SketchPicker color={serviceType.color} onChangeComplete={handleSubmit} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
