import { useState } from 'react'

import {
  Text,
  Stack,
  IconButton,
  useDisclosure
} from '@chakra-ui/react'

import { AiOutlineEye } from 'react-icons/ai'

import ViewNotes from 'components/notes/ViewNotes'
import AddTransactionNote from 'components/transactions/notes/AddTransactionNote'
import EditTransactionNoteDetails from 'components/transactions/notes/EditTransactionNoteDetails'
import EditTransactionNoteMarkAsDone from 'components/transactions/notes/EditTransactionNoteMarkAsDone'

export default function ViewTransactionNotesTrigger ({ transaction }) {
  const [isEditVisible, setIsEditVisible] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()

  // get the first not markAsDone note
  const latestNote = transaction.notes.find(note => !note.markAsDone)

  return (
    <>
      {isOpen && (
        <ViewNotes
          label='Notes'
          notes={transaction.notes}
          onClose={onClose}
          createComponent={<AddTransactionNote transaction={transaction} />}
          editDetailsComponent={EditTransactionNoteDetails}
          editMarkAsDoneComponent={EditTransactionNoteMarkAsDone}
        />
      )}
      <Stack
        direction='row'
        alignItems='center'
        width='100%'
        onMouseEnter={() => setIsEditVisible(true)}
        onMouseLeave={() => setIsEditVisible(false)}
      >
        <Text fontSize='sm' overflowX='hidden'>
          {latestNote?.details || '-'}
        </Text>
        {isEditVisible && <IconButton size='xs' variant='outline' icon={<AiOutlineEye fontSize='16px' />} onClick={onOpen} isDisabled={!transaction} />}
      </Stack>
    </>
  )
}
