import { Badge } from '@chakra-ui/react'

export default function ViewUserRole ({ user }) {
  return (
    <Badge
      colorScheme={user.role === 'ADMIN' ? 'green' : 'orange'}
      borderRadius='lg'
      fontSize='sm'
    >
      {user.role}
    </Badge>
  )
}
